import Link from "next/link";
import {
  BiSlider,
  BiSupport,
  BiCustomize,
  BiBarChartSquare,
  BiCheckShield,
  BiTimer,
  BiChat,
  BiBookContent,
  BiBookmarks,
  BiTrip,
  BiTask,
  BiWorld,
  BiDevices,
} from "react-icons/bi";
import {
  HiOutlineClipboardCheck,
  HiOutlineClipboardList,
  HiOutlineDesktopComputer,
  HiOutlineShieldCheck,
} from "react-icons/hi";
import {
  BsBoundingBoxCircles,
  BsBuilding,
  BsBuildingFillGear,
  BsCalendar2Check,
  BsChatSquareTextFill,
  BsClipboardCheck,
  BsClock,
  BsDatabase,
  BsFillHddStackFill,
  BsGenderTrans,
  BsPersonGear,
  BsPhone,
  BsPhoneFlip,
  BsStarFill,
  BsStars,
  BsTaxiFrontFill,
} from "react-icons/bs";
import {
  FaBalanceScale,
  FaBell,
  FaBezierCurve,
  FaBriefcase,
  FaBriefcaseMedical,
  FaBuilding,
  FaBusinessTime,
  FaCalculator,
  FaCalendarAlt,
  FaCar,
  FaChalkboard,
  FaChartBar,
  FaChartLine,
  FaClipboardCheck,
  FaClock,
  FaCodeBranch,
  FaCog,
  FaCogs,
  FaComment,
  FaComments,
  FaCompress,
  FaCreditCard,
  FaDollarSign,
  FaExchangeAlt,
  FaExpandAlt,
  FaExpandArrowsAlt,
  FaFileInvoiceDollar,
  FaGift,
  FaGlobe,
  FaGlobeAmericas,
  FaHandHoldingMedical,
  FaHandshake,
  FaHeadset,
  FaHouseUser,
  FaLanguage,
  FaLaptopMedical,
  FaLayerGroup,
  FaLightbulb,
  FaListUl,
  FaMapMarkedAlt,
  FaMobileAlt,
  FaMoneyBillWave,
  FaMoneyCheckAlt,
  FaNetworkWired,
  FaPaintBrush,
  FaPalette,
  FaPiggyBank,
  FaPlane,
  FaPlayCircle,
  FaPuzzlePiece,
  FaRandom,
  FaRegAddressCard,
  FaRegBuilding,
  FaRegListAlt,
  FaRocket,
  FaRoute,
  FaSatellite,
  FaShieldAlt,
  FaShippingFast,
  FaSlidersH,
  FaSmile,
  FaStar,
  FaSync,
  FaSyncAlt,
  FaTag,
  FaTaxi,
  FaThumbsUp,
  FaTools,
  FaTrophy,
  FaTruck,
  FaTruckMoving,
  FaUserCheck,
  FaUserCog,
  FaUserFriends,
  FaUsers,
  FaUsersCog,
  FaUtensils,
} from "react-icons/fa";

import {
  TbBrandGoogleAnalytics,
  TbBuildingCommunity,
  TbChartHistogram,
  TbDeviceMobileMessage,
  TbEaseInOutControlPoints,
  TbGps,
  TbLiveView,
  TbMessageReport,
  TbPackage,
  TbTruckDelivery,
} from "react-icons/tb";
import {
  AiFillAlert,
  AiFillContainer,
  AiFillMedicineBox,
  AiOutlineAlert,
  AiOutlineApartment,
  AiOutlineDeploymentUnit,
  AiOutlineFundView,
  AiOutlineGateway,
  AiOutlineSafety,
  AiOutlineSchedule,
  AiOutlineSliders,
  AiOutlineSolution,
} from "react-icons/ai";
import { TiFlowMerge } from "react-icons/ti";

import img1 from "../assets/img/2.png";

import OverView from "../assets/img/home/Platforms/overview.webp";
import Tools from "../assets/img/home/Platforms/tools.webp";
import Integration from "../assets/img/home/Platforms/integration.webp";
import Feature from "../assets/img/home/Platforms/Feature.webp";

import AirportVIPshuttle from "../assets/img/home/RideHailing/Airport & VIP shuttle.webp";
import ChauffeurServices from "../assets/img/home/RideHailing/Chauffeur services.webp";
import RideSharing from "../assets/img/home/RideHailing/Ride Sharing.webp";
import RideHailing from "../assets/img/home/RideHailing/Taxi hailining.webp";

import Food_Delivery from "../assets/img/home/Commerce/Food Delivery.webp";
import grocery_deliver from "../assets/img/home/Commerce/Grocery Delivery.webp";
import materials_delivery from "../assets/img/home/Commerce/Materials delivery.webp";
import pharmacy_delivery from "../assets/img/home/Commerce/Pharmacy delivery.webp";

import CiityCouriers from "../assets/img/home/Logistics/City Couriers.webp";
import DeliveryOperators from "../assets/img/home/Logistics/Delivery Operators.webp";
import Packagedelivery from "../assets/img/home/Logistics/Package delivery.webp";
import Thirdpartylogistics from "../assets/img/home/Logistics/Third - party logistics.webp";

import Facilityservicing from "../assets/img/home/Services/Facility servicing.webp";
import FieldServicing from "../assets/img/home/Services/Field Servicing.webp";
import FleetServiicng from "../assets/img/home/Services/Fleet Serviicng.webp";
import MovingService from "../assets/img/home/Services/Moving Service.webp";

import TaxiDispatchSolutions from "../assets/img/home/Solutions/Icons/taxi.png";
import LimoDispatchSolutions from "../assets/img/home/Solutions/Icons/limo.png";
import RideSharingSolutions from "../assets/img/home/Solutions/Icons/ride sharing.png";
import AirportTransferSolutions from "../assets/img/home/Solutions/Icons/airport.png";
import EmployeeTransportationSolutions from "../assets/img/home/Solutions/Icons/employee transport.png";
import FleetManagementSolutions from "../assets/img/home/Solutions/Icons/fleet management .png";

import {
  RiAnchorLine,
  RiCheckboxMultipleFill,
  RiCurrencyFill,
  RiCustomerServiceLine,
  RiDashboardLine,
  RiMapPinTimeLine,
  RiMoneyDollarBoxLine,
  RiMoneyDollarCircleLine,
  RiNodeTree,
  RiRemoteControlLine,
  RiSecurePaymentLine,
  RiSoundModuleFill,
  RiTaxiWifiLine,
  RiUserLocationLine,
} from "react-icons/ri";
import {
  MdAssignmentAdd,
  MdAutoGraph,
  MdBookOnline,
  MdBusAlert,
  MdConveyorBelt,
  MdDisplaySettings,
  MdEmojiTransportation,
  MdEventAvailable,
  MdInsights,
  MdInterpreterMode,
  MdMedicationLiquid,
  MdMiscellaneousServices,
  MdMobileFriendly,
  MdMovieEdit,
  MdNewLabel,
  MdNotificationsActive,
  MdOutlineAssignmentInd,
  MdOutlineAssignmentTurnedIn,
  MdOutlineAssistant,
  MdOutlineBento,
  MdOutlineBookOnline,
  MdOutlineCastForEducation,
  MdOutlineContactSupport,
  MdOutlineEdgesensorHigh,
  MdOutlineEmojiTransportation,
  MdOutlineEventAvailable,
  MdOutlineFeaturedPlayList,
  MdOutlineHomeRepairService,
  MdOutlineIntegrationInstructions,
  MdOutlineInventory2,
  MdOutlineLocalAirport,
  MdOutlineLocalGroceryStore,
  MdOutlineLocalOffer,
  MdOutlineLoyalty,
  MdOutlineManageAccounts,
  MdOutlineManageHistory,
  MdOutlineNotStarted,
  MdOutlineNotificationsActive,
  MdOutlineOnDeviceTraining,
  MdOutlinePersonalInjury,
  MdOutlinePrivacyTip,
  MdOutlineQuickreply,
  MdOutlineReviews,
  MdOutlineShareLocation,
  MdOutlineSpatialTracking,
  MdOutlineSystemSecurityUpdateGood,
  MdOutlineWarehouse,
  MdPayment,
  MdRecordVoiceOver,
  MdScreenShare,
  MdScreenshotMonitor,
  MdShareLocation,
  MdSupportAgent,
  MdPhoneAndroid,
  MdLanguage,
  MdSchedule,
  MdCommute,
  MdDeviceHub,
  MdOutlineCancel,
} from "react-icons/md";
import {
  HiOutlineGlobeAsiaAustralia,
  HiOutlineSquaresPlus,
  HiOutlineUserPlus,
} from "react-icons/hi2";
import img4 from "../assets/img/home/Inside img/Companies/ZervxCompany.jpg";
// Tools
import PassengerApp from "../assets/img/home/Inside img/Tools_page/Passenger_App.webp";
import Driver_App from "../assets/img/home/Inside img/Tools_page/Driver_App.webp";
import Admin_Panel from "../assets/img/home/Inside img/Tools_page/Admin_Panel.webp";
import Corporate from "../assets/img/home/Inside img/Tools_page/Corporate_Panel.webp";
import Dispatcher from "../assets/img/home/Inside img/Tools_page/Dispatcher_Panel.webp";
import Web_Booking from "../assets/img/home/Inside img/Tools_page/Web_Booking.webp";
import Website from "../assets/img/home/Inside img/Tools_page/Website.webp";

import BlogOne from "../assets/img/home/Inside img/BlogImage/blogOne.webp";
import BlogTwo from "../assets/img/home/Inside img/BlogImage/blogTwo/Taxi-app.jpg";
import BlogThree from "../assets/img/home/Inside img/BlogImage/blogThree/taxi-app.jpg";

import blogImgFirstOne from "../assets/img/home/Inside img/BlogImage/blogFirst/How_to_build_a_taxi_app.webp";
import blogImgFirstTwo from "../assets/img/home/Inside img/BlogImage/blogFirst/How_to_Build_a_Successful_Taxi_App_in_2024.webp";
import blogImgFirstThree from "../assets/img/home/Inside img/BlogImage/blogFirst/Taxi_apps.webp";
import blogImgFirstFour from "../assets/img/home/Inside img/BlogImage/blogFirst/Steps_to_build_a_taxi_app.webp";

import BlogProfileOne from "../assets/img/home/Inside img/BlogImage/Sibin.jpg";
import BlogProfileTwo from "../assets/img/home/Inside img/BlogImage/Sibin.jpg";
import BlogProfileThree from "../assets/img/home/Inside img/BlogImage/Sibin.jpg";

import blogImageTwo from "../assets/img/home/Inside img/BlogImage/BlogTwo.webp";
import blogImageThree from "../assets/img/home/Inside img/BlogImage/BlogThree.webp";
import {
  IoFastFoodOutline,
  IoGitBranchOutline,
  IoGitCompareSharp,
  IoNavigateCircleOutline,
  IoQrCode,
  IoRocketOutline,
  IoSettingsSharp,
  IoSpeedometer,
} from "react-icons/io5";

import DriverManagement from "../assets/img/home/Inside img/service/Driver Management.svg";
import customersupport from "../assets/img/home/Inside img/service/24-7 customer support.svg";
import CustomizableBranding from "../assets/img/home/Inside img/service/Customizable Branding.svg";
import Multilingual from "../assets/img/home/Inside img/service/Multilingual.svg";
import MultiplePaymentOptions from "../assets/img/home/Inside img/service/Multiple Payment Options.svg";
import VehicleManagement from "../assets/img/home/Inside img/service/Vehicle Management.svg";
import {
  GiAbstract013,
  GiAbstract092,
  GiAlarmClock,
  GiBoxUnpacking,
  GiBusStop,
  GiCardExchange,
  GiConfirmed,
  GiConversation,
  GiDiscussion,
  GiFoodTruck,
  GiMedicinePills,
  GiPathDistance,
  GiPlayerTime,
  GiProgression,
  GiSandsOfTime,
  GiSmartphone,
  GiStarsStack,
  GiToolbox,
} from "react-icons/gi";
// clients
import Ezieke from "../assets/img/home/ClientImg/EKE.jpeg";
import Truth from "../assets/img/home/ClientImg/Truth.jpeg";
import Valdir from "../assets/img/home/ClientImg/Valdir.jpeg";
import HelpCenters from "../assets/img/home/Resourse/Help center.svg";
import Blogs from "../assets/img/home/Resourse/Blog.svg";
import {
  CgArrowLongRightC,
  CgMenuBoxed,
  CgToolbox,
  CgWebsite,
} from "react-icons/cg";
import {
  SiAppstore,
  SiFastapi,
  SiHandshake,
  SiScala,
  SiSlideshare,
  SiSpringsecurity,
} from "react-icons/si";
import {
  VscCommentUnresolved,
  VscFeedback,
  VscReferences,
} from "react-icons/vsc";
import {
  ImGooglePlus,
  ImLocation,
  ImMobile,
  ImNewspaper,
  ImPaypal,
} from "react-icons/im";
import { FiCheckCircle, FiEdit } from "react-icons/fi";

// intergration logo

import AppleMaps from "../assets/img/home/Inside img/IntegrationLogo/AppleMap.png";
import Facebook from "../assets/img/home/Inside img/IntegrationLogo/Facebook.webp";
import Firebase from "../assets/img/home/Inside img/IntegrationLogo/Firebase.png";
import Flutterwave from "../assets/img/home/Inside img/IntegrationLogo/Flutterwave.png";
import GoogleMaps from "../assets/img/home/Inside img/IntegrationLogo/Google-Maps.png";
import OneSignal from "../assets/img/home/Inside img/IntegrationLogo/OneSignal.webp";
import peachPayment from "../assets/img/home/Inside img/IntegrationLogo/peachPayment.png";
import Razorpay from "../assets/img/home/Inside img/IntegrationLogo/Razorpay.png";
import SINCH from "../assets/img/home/Inside img/IntegrationLogo/Sinch-2.png";
import Stripe from "../assets/img/home/Inside img/IntegrationLogo/Stripe.png";
import Teleport from "../assets/img/home/Inside img/IntegrationLogo/Teleport-Logo.png";
import Sendinblue from "../assets/img/home/Inside img/IntegrationLogo/Sendinblue.png";
import Twilio from "../assets/img/home/Inside img/IntegrationLogo/Twilio.png";
import WhatsApp from "../assets/img/home/Inside img/IntegrationLogo/wp.webp";
import Waze from "../assets/img/home/Inside img/IntegrationLogo/Waze.png";
import cybersource from "../assets/img/home/Inside img/IntegrationLogo/CyberSource.jpeg";
import zervX from "../assets/img/home/fav_icon.svg";
import plus from "../assets/img/home/Inside img/IntegrationLogo/Plus.webp";

import Taxi from "../assets/img/home/Inside img/BlogImage/blogTwo/Taxi.jpg";
import FeaturesOfTaxiApp from "../assets/img/home/Inside img/BlogImage/blogTwo/Features-of-taxi-app.jpg";
import blogImgFourFirst from "../assets/img/home/Inside img/BlogImage/blogFour/What_is_on_demand_service.webp";
import blogImgFourSecond from "../assets/img/home/Inside img/BlogImage/blogFour/On_demand_services.webp";
import blogImgFourThird from "../assets/img/home/Inside img/BlogImage/blogFour/On_demand_services_industries.webp";
import blogImgFourFourth from "../assets/img/home/Inside img/BlogImage/blogFour/Benefits_of_on_demand_services.webp";

import blogImgThreeSec from "../assets/img/home/Inside img/BlogImage/blogThree/driver-components.jpg";
import blogImgThreeThired from "../assets/img/home/Inside img/BlogImage/blogThree/backend.jpg";
import blogImgThreeFourth from "../assets/img/home/Inside img/BlogImage/blogThree/gps-tracking.jpg";
import blogImgFiveFirst from "../assets/img/home/Inside img/BlogImage/blogFive/Taxi dispatch software.jpg";
import blogImgFiveSecond from "../assets/img/home/Inside img/BlogImage/blogFive/White label taxi software for taxi business.jpg";
import blogImgFiveThird from "../assets/img/home/Inside img/BlogImage/blogFive/Taxi dispatch system.jpg";
import blogImgSixFirst from "../assets/img/home/Inside img/BlogImage/blogSix/Best-Uber-clone.webp";
import blogImgSixSecond from "../assets/img/home/Inside img/BlogImage/blogSix/Uber-clone-script.webp";
import blogImgSixThird from "../assets/img/home/Inside img/BlogImage/blogSix/Uber-clone-software-development company.webp";
import blogImgSixFourth from "../assets/img/home/Inside img/BlogImage/blogSix/Uber clone app.webp";
import blogImgSixFifth from "../assets/img/home/Inside img/BlogImage/blogSix/Uber-clone-solution.webp";
import blogImgSeventhFirst from "../assets/img/home/Inside img/BlogImage/blogSeven/Surge_pricing.webp";
import blogImgSeventhSecond from "../assets/img/home/Inside img/BlogImage/blogSeven/AI_powered_surge_pricing.webp";
import blogImgEightFirst from "../assets/img/home/Inside img/BlogImage/blogEight/carrentalmanagementsoftware.webp";
import blogImgEightSecond from "../assets/img/home/Inside img/BlogImage/blogEight/car rental company software.webp";
import blogImgEightThird from "../assets/img/home/Inside img/BlogImage/blogEight/car rental booking software.webp";
import blogImgEightFourth from "../assets/img/home/Inside img/BlogImage/blogEight/vehicle booking system.webp";
import blogImgNineFirst from "../assets/img/home/Inside img/BlogImage/blogNine/Client-achievements.webp";
import blogImgNineSecond from "../assets/img/home/Inside img/BlogImage/blogNine/Advanced ride hailing features.webp";
import blogImgTenFirst from "../assets/img/home/Inside img/BlogImage/blogTen/Clients-achievements-with-our-taxi-software.webp";
import blogImgTenSecond from "../assets/img/home/Inside img/BlogImage/blogTen/Advanced taxi app features.webp";
import blogImgElevenFirst from "../assets/img/home/Inside img/BlogImage/blogEleven/Super-apps.webp";
import blogImgElevenSecond from "../assets/img/home/Inside img/BlogImage/blogEleven/All-in-one-app-for-on-demand-businesses.webp";
import blogImgElevenThird from "../assets/img/home/Inside img/BlogImage/blogEleven/What-is-a-super-app.webp";
import blogImgElevenFourth from "../assets/img/home/Inside img/BlogImage/blogEleven/Super-app-development.webp";
import blogImgTwelveFirst from "../assets/img/home/Inside img/BlogImage/blogTwelve/White_label_dispatch_software.webp";
import blogImgTwelveSecond from "../assets/img/home/Inside img/BlogImage/blogTwelve/White_label_taxi_dispatch_software.webp";
import blogImgTwelveThrid from "../assets/img/home/Inside img/BlogImage/blogTwelve/White_label_taxi_dispatch_software_features.webp";
import blogImgTwelveFourth from "../assets/img/home/Inside img/BlogImage/blogTwelve/Benefits_of_white_label_taxi_dispatch_system.webp";
import blogImgThirteenFirst from "../assets/img/home/Inside img/BlogImage/blogThirteen/fleet_management_software.webp";
import blogImgThirteenSecond from "../assets/img/home/Inside img/BlogImage/blogThirteen/fleet_management_system.webp";
import blogImgThirteenThird from "../assets/img/home/Inside img/BlogImage/blogThirteen/vehicle_management_software.webp";
import blogImgFourteenFirst from "../assets/img/home/Inside img/BlogImage/blogFourteen/How_to_start_a_taxi_business.webp";
import blogImgFourteenSecond from "../assets/img/home/Inside img/BlogImage/blogFourteen/How_to_start_a_taxi_business_like_Uber.webp";
import blogImgFourteenThird from "../assets/img/home/Inside img/BlogImage/blogFourteen/Taxi_market_size_estimates.webp";
import blogImgFourteenFourth from "../assets/img/home/Inside img/BlogImage/blogFourteen/Taxi_business_plan.webp";
import blogImgFourteenFifth from "../assets/img/home/Inside img/BlogImage/blogFourteen/Grow_your_taxi_business.webp";
import blogImgFifteenFirst from "../assets/img/home/Inside img/BlogImage/blogFifteen/limousine_dispatch_software.webp";
import blogImgFifteenSecond from "../assets/img/home/Inside img/BlogImage/blogFifteen/limo_software.webp";
import blogImgFifteenThird from "../assets/img/home/Inside img/BlogImage/blogFifteen/limo_reservation_&_booking_software.webp";
import blogImgSixteenFirst from "../assets/img/home/Inside img/BlogImage/blogSixteen/Ride_hailing_app.webp";
import blogImgSixteenSecond from "../assets/img/home/Inside img/BlogImage/blogSixteen/Ride_hailing_app_development.webp";
import blogImgSixteenThird from "../assets/img/home/Inside img/BlogImage/blogSixteen/Ride_hailing_platform.webp";
import blogImgSeventeenFirst from "../assets/img/home/Inside img/BlogImage/blogSeventeen/Taxi_booking_software.webp";
import blogImgSeventeenSecond from "../assets/img/home/Inside img/BlogImage/blogSeventeen/Taxi_online_booking_system.webp";
import blogImgSeventeenThird from "../assets/img/home/Inside img/BlogImage/blogSeventeen/Taxi_booking_system.webp";
import blogImgEighteenFirst from "../assets/img/home/Inside img/BlogImage/blogEighteen/taxi_dispatch_system18.webp";
import blogImgEighteenSecond from "../assets/img/home/Inside img/BlogImage/blogEighteen/taxi_cab_dispatch_system.webp";
import blogImgEighteenThird from "../assets/img/home/Inside img/BlogImage/blogEighteen/best_online_taxi_dispatch_system.webp";
import blogImgNineteenFirst from "../assets/img/home/Inside img/BlogImage/blogNineteen/Top-5-taxi-dispatch-system.webp";
import blogImgNineteenSecond from "../assets/img/home/Inside img/BlogImage/blogNineteen/Top-taxi-dispatch-software.webp";
import blogImgNineteenThird from "../assets/img/home/Inside img/BlogImage/blogNineteen/Best-taxi-dispatch-software-solutions.webp";
import blogImgNineteenFourth from "../assets/img/home/Inside img/BlogImage/blogNineteen/Taxi-dispatch-software-.webp";
import blogImgTwentyZero from "../assets/img/home/Inside img/BlogImage/blogTwenty/What_is_a_super_app.webp";
import blogImgTwentyFirst from "../assets/img/home/Inside img/BlogImage/blogTwenty/Super_app.webp";
import blogImgTwentySecond from "../assets/img/home/Inside img/BlogImage/blogTwenty/Key_reasons_for_the_popularity_of_super_apps.webp";
import blogImgTwentyThird from "../assets/img/home/Inside img/BlogImage/blogTwenty/Super_app_USA.webp";
import blogImgTwentyFourth from "../assets/img/home/Inside img/BlogImage/blogTwenty/What_is_a_super_app_everything_you_need_to_know.webp";
import { IoMdCar, IoMdCheckmarkCircleOutline } from "react-icons/io";
import compareImg1 from "../assets/img/home/Compare/Taxi/Customize.webp";
import compareImg2 from "../assets/img/home/Compare/Taxi/Reliable_Support.webp";
import compareImg3 from "../assets/img/home/Compare/Taxi/Scale_Your_Business.webp";
import compareImg4 from "../assets/img/home/Inside img/Industies/Industry-Leading-Features.webp";
import compareListImg1 from "../assets/img/home/Compare/ZervX_vs_TaxiMobility.webp";
import compareListImg2 from "../assets/img/home/Compare/ZervX_vs_iCabbi.webp";
import compareListImg3 from "../assets/img/home/Compare/ZervX_vs_Yelowsoft.webp";
import compareListImg4 from "../assets/img/home/Compare/ZervX_vs_Onde.webp";
import compareListImg5 from "../assets/img/home/Compare/ZervX_vs_Jugnoo.webp";
import Read from "@components/common/Read";
import BlogBottomBox from "@components/blogs/BlogBottomBox";

const TestimonialData = [
  {
    id: 1,
    image: Valdir,
    authorImg: "/testimonial/3.jpg",
    authorName: "Valdir Correia",
    authorTitle: "CEO, Starmov",
    quoteTitle: "",
    authorQuote:
      "Our experience with ZervX has been outstanding. The company has developed excellent software for our project, StarMov. The team is dedicated and always available to answer questions and receive feedback.",
    imgalt: "Client Valdir Correia",
    imgtitle: "Valdir Correia",
  },
  {
    id: 2,
    image: Ezieke,
    authorImg: "/testimonial/1.jpg",
    authorName: "Ezieke Amaefula",
    authorTitle: "CEO, Pamdrive",
    quoteTitle: "",
    authorQuote:
      "ZervX team is exceptional. They are not only smart and intelligent, but also incredibly supportive. One of our challenges was having an engaged team that truly cares, and we didn't have that until we met the ZervX team. Their product is very stable and highly competitive.",
    imgalt: "Client Ezieke Amaefula",
    imgtitle: "Ezieke Amaefula",
  },
  {
    id: 3,
    image: Truth,
    authorImg: "/testimonial/2.jpg",
    authorName: "Truth Wiseone",
    authorTitle: "CEO, Jetride",
    quoteTitle: "",
    authorQuote:
      "Choosing ZervX was the best decision I made for my Jetride ride hailing business. They are super professional, timely, and attentive to all my requests. The best aspect about them is their reliability and commitment to delivering the best.",
    imgalt: "Client Truth Wiseone",
    imgtitle: "Truth Wiseone",
  },
];

///footer data
const footerIndusties1 = [
  {
    title: "Ride Hailing",
    href: "/industries/ride-hailing",
  },
  {
    title: "Ride Sharing",
    href: "/industries/ride-sharing",
  },
  {
    title: "Corporate Transportation",

    href: "/industries/corporate-transportation",
  },
  {
    title: "Airport & VIP Shuttle",

    href: "/industries/airport-vip-shuttle",
  },
];
const footerIndusties2 = [
  {
    title: "Food Delivery",

    href: "/industries/food-delivery",
  },
  {
    title: "Grocery Delivery",

    href: "/industries/grocery-delivery",
  },
  {
    title: "Pharmacy Delivery",

    href: "/industries/pharmacy-delivery",
  },
  {
    title: "Materials Delivery",

    href: "/industries/materials-delivery",
  },
];
const footerIndusties3 = [
  {
    title: "City Couriers",

    href: "/industries/city-couriers",
  },
  {
    title: "Package Delivery",

    href: "/industries/package-delivery",
  },
  {
    title: "Delivery Operators",

    href: "/industries/delivery-operators",
  },
  {
    title: "Third Party Logistics",

    href: "/industries/third-party-logistics",
  },
];
const footerIndusties4 = [
  {
    title: "Moving Services",

    href: "/industries/moving-services",
  },
  {
    title: "Fleet Servicing ",

    href: "/industries/fleet-servicing",
  },
  {
    title: "Facility Servicing",

    href: "/industries/facility-servicing",
  },
  {
    title: "Field Services ",

    href: "/industries/field-services",
  },
];

const footerResources = [
  {
    title: "Help Center",
    //href: "/resources/help-center",
    href: "/coming-soon",
  },
  {
    title: "Blogs",
    href: "/blog/articles",
  },
  {
    title: "Compare",
    href: "/compare",
  },
];
const footerSolutions = [
  {
    title: "Taxi Dispatch",
    href: "/solutions/taxi-dispatch-software/",
  },
  {
    title: "Limo Dispatch",
    href: "/solutions/limousine-dispatch-software/",
  },
  {
    title: "Ride Sharing",
    href: "/solutions/rideshare-carpool-software/",
  },
  {
    title: "Airport Transfer",
    href: "/solutions/airport-taxi-transfer-software/",
  },
  {
    title: "Employee Transportation",
    href: "/solutions/employee-transport-management-software/",
  },
  {
    title: "Fleet Management",
    href: "/solutions/fleet-management-software/",
  },
];
const footerPrimaryPages = [
  {
    title: "Overview",
    href: "/platform/overview",
  },
  {
    title: "Tools",
    href: "/platform/tools",
  },
  {
    title: "Features",
    href: "/platform/features",
  },
  {
    title: "Integrations",
    href: "/platform/integrations",
  },
  {
    title: "Ride Hailing Platform",
    href: "/industries/ride-hailing",
  },
  {
    title: "Delivery Platform",
    href: "/industries/delivery-operators",
  },
  {
    title: "Pricing",
    href: "/plans",
  },
  {
    title: "Start Free Trial",
    href: "/industries/contact-sales",
  },
];

const footerTemplate = [
  {
    title: "About Us",
    href: "/about-us",
  },
  {
    title: "Careers",
    href: "/career",
  },
];

const navHomeOne = [
  {
    title: "Overview",
    info: "Explore the most empowering solution available today, enabling businesses to offer efficient and reliable transportation services.",
    href: "/platform/overview",
    icon: OverView,
    altText: "Overview icon",
  },
  {
    title: "Tools",
    info: "Next-generation software tools for expanding your transportation business from last-mile delivery to ride-sharing.",
    href: "/platform/tools",
    icon: Tools,
    altText: "Tools icon",
  },
  {
    title: "Features",
    info: " The technology foundation that provides your business with a speedy, adaptable, and expandable infrastructure.    ",
    href: "/platform/features",
    icon: Feature,
    altText: "Features icon",
  },

  {
    title: "Integrations",
    info: " Our ecosystem of technology partners offers unlimited integration options for your transportation business.",
    href: "/platform/integrations",
    icon: Integration,
    altText: "Integrations icon",
  },
];

const solutionLinks = [
  {
    icon: RideHailing,
    imgalt: "Taxi Dispatch",
    title: "Taxi Dispatch",
    href: "/solutions/taxi-dispatch-software/",
  },
  {
    icon: ChauffeurServices,
    imgalt: "Limo Dispatch",
    title: "Limo Dispatch",
    href: "/solutions/limousine-dispatch-software/",
  },
  {
    icon: RideSharing,
    imgalt: "Ride Sharing",
    title: "Ride Sharing",
    href: "/solutions/rideshare-carpool-software/",
  },
  {
    icon: AirportVIPshuttle,
    imgalt: "Airport Transfer",
    title: "Airport Transfer",
    href: "/solutions/airport-taxi-transfer-software/",
  },
  {
    icon: RideHailing,
    imgalt: "Employee Transportation",
    title: "Employee Transportation",
    href: "/solutions/employee-transport-management-software/",
  },
  {
    icon: Thirdpartylogistics,
    imgalt: "Fleet Management",
    title: "Fleet Management",
    href: "/solutions/fleet-management-software/",
  },
];

const zervXServices = [
  {
    img: img1,
    title: "Ride Hailing",
  },
  {
    img: img1,
    title: "Package delivery",
  },
  {
    img: img1,
    title: "Courier service",
  },
  {
    img: img1,
    title: "Grocery delivery",
  },
  {
    img: img1,
    title: "Pharmacy delivery",
  },
  {
    img: img1,
    title: "Food delivery",
  },
  {
    img: img1,
    title: "Materials delivery",
  },
  {
    img: img1,
    title: "Field service",
  },
  {
    img: img1,
    title: "Fleet servicing",
  },
  {
    img: img1,
    title: "Materials delivery",
  },
  {
    img: img1,
    title: "Field service",
  },
  {
    img: img1,
    title: "Fleet servicing",
  },
];
const RideHailling = [
  {
    title: "Ride Hailing",
    info: " App and Software Landing",
    href: "/industries/ride-hailing",
    icon: RideHailing,
    imgalt: "Ride-hailing",
  },
  {
    title: "Ride Sharing",
    info: "Web Software Company",
    href: "/industries/ride-sharing",
    icon: RideSharing,
    imgalt: " Ride-sharing",
  },
  {
    title: "Corporate Transportation",
    info: "technology platform f.",
    href: "/industries/corporate-transportation",
    icon: ChauffeurServices,
    imgalt: "Corporate transportation",
  },
  {
    title: "Airport & VIP Shuttle",
    info: " Modern Sass agency",
    href: "/industries/airport-vip-shuttle",
    icon: AirportVIPshuttle,
    imgalt: "Airport and vip shuttle",
  },
];
const Commerce = [
  {
    title: "Food Delivery",
    info: "technology platform f.",
    href: "/industries/food-delivery",
    icon: Food_Delivery,
    imgalt: "Food delivery",
  },
  {
    title: "Grocery Delivery",
    info: " Modern Sass agency",
    href: "/industries/grocery-delivery",
    icon: grocery_deliver,
    imgalt: "Grocery delivery",
  },
  {
    title: "Pharmacy Delivery",
    info: " App and Software Landing",
    href: "/industries/pharmacy-delivery",
    icon: pharmacy_delivery,
    imgalt: "Pharmacy delivery",
  },
  {
    title: "Materials Delivery",
    info: "Web Software Company",
    href: "/industries/materials-delivery",
    icon: materials_delivery,
    imgalt: "Materials delivery",
  },
];
const Logistics = [
  {
    title: "City Couriers",
    info: "technology platform f.",
    href: "/industries/city-couriers",
    icon: CiityCouriers,
    imgalt: "City couriers",
  },

  {
    title: "Package Delivery",
    info: "Web Software Company",
    href: "/industries/package-delivery",
    icon: Packagedelivery,
    imgalt: "Package delivery",
  },
  {
    title: "Delivery Operators",
    info: " Modern Sass agency",
    href: "/industries/delivery-operators",
    icon: DeliveryOperators,
    imgalt: "Delivery operators",
  },
  {
    title: "Third Party Logistics",
    info: " App and Software Landing",
    href: "/industries/third-party-logistics",
    icon: Thirdpartylogistics,
    imgalt: "Third party logistics",
  },
];
const Services = [
  {
    title: "Moving Services",
    info: " App and Software Landing",
    href: "/industries/moving-services",
    icon: MovingService,
    imgalt: "Moving services",
  },
  {
    title: "Fleet Servicing ",
    info: "Web Software Company",
    href: "/industries/fleet-servicing",
    icon: FleetServiicng,
    imgalt: "Fleet servicing",
  },

  {
    title: "Facility Servicing",
    info: "technology platform f.",
    href: "/industries/facility-servicing",
    icon: Facilityservicing,
    imgalt: "Facility servicing",
  },
  {
    title: "Field Services ",
    info: " Modern Sass agency",
    href: "/industries/field-services",
    icon: FieldServicing,
    imgalt: "Field services",
  },
];
const Industies = [
  {
    title: "Airport & VIP Shuttle",
    info: "technology platform f.",
    href: "/industries/industries-services",
    icon: AirportVIPshuttle,
    imgalt: " Airport and vip shuttle",
  },
  {
    title: "Chauffeur services",
    info: " Modern Sass agency",
    href: "/industries/industries-services",
    icon: ChauffeurServices,
    imgalt: " Corporate transportation",
  },
  {
    title: "Ride Sharing",
    info: "Web Software Company",
    href: "/industries/industries-services",
    icon: RideSharing,
    imgalt: " Ride-sharing",
  },
  {
    title: "RideHailing",
    info: " App and Software Landing",
    href: "/industries/industries-services",
    icon: RideHailing,
    imgalt: " Ride-hailing",
  },
];
const Resources = [
  {
    title: "Help Center",
    info: "technology platform f.",
    href: "/coming-soon",
    imgalt: "ZervX help center",
    icon: HelpCenters,
  },
  {
    title: "Blogs",
    info: " App and Software Landing",
    href: "/blog/articles",
    icon: Blogs,
    imgalt: "Blogs",
  },
];
//pricing data
const pricingData = [
  {
    title: "Basic",
    desc: "For small businesses just getting started.",
    price: "$300",
    time: "/month",
    bgColor: "bg-white",
    textColor: "text-primary",
    btnColor: "btn-primary",
    shape: "left--40 bottom--40",
    recomended: "",
    recomendedColor: "bg-primary",
    border_color: "border-primary",
    list1: "Up to 3000 bookings/month",
    list2: "Up to 3 service areas",
    listItem: [
      {
        li: "Unlimited Drivers",
      },
      {
        li: "Admin panel for setup, planning and dispatch",
      },
      {
        li: "Mobile app for workers to receive rides/tasks, log work & view ride/task details",
      },
      {
        li: "Web booker for customers        ",
      },
      {
        li: "Live ETAs based on real time tracking",
      },
      {
        li: "Live notifications",
      },
      {
        li: "Referral program for drivers and customers",
      },
      {
        li: "Promotion campaigns",
      },
      {
        li: "Premium customer support",
      },
    ],
  },

  {
    title: "Startup",
    desc: "Suitable for growing businesses that require more features.",
    price: "$500",
    time: "/month",
    bgColor: "bg-white",
    textColor: "text-primary",
    btnColor: "btn-primary",
    recomended: "",
    recomendedColor: "bg-primary",
    border_color: "border-primary",
    list1: "Up to 12000 bookings/month",
    list2: "Up to 5 service areas",
    subHeading: "Everything in Basic, plus:",
    listItem: [
      {
        li: "Corporate bookings",
      },
      {
        li: "Customer mobile application",
      },
      {
        li: "Ride sharing",
      },
      {
        li: "Rewards program",
      },
      {
        li: "Outstation bookings",
      },
      {
        li: "Customized delivery options        ",
      },
      {
        li: "Premium customer support",
      },
    ],
  },
  {
    title: "Growth",
    desc: "For established businesses looking to scale.",
    price: "$800",
    time: "/month",
    recomended: "Most Popular",
    recomendedColor: "bg-green",
    bgColor: "bg-light-green-colour",
    textColor: "text-green",
    btnColor: "btn-green",
    border_color: "border-green",
    list1: "Up to 25000 bookings/month",
    list2: "Up to 10 service areas",
    subHeading: "Everything in Startup, plus:",
    listItem: [
      {
        li: "Intercity bookings",
      },
      {
        li: "Franchise management",
      },
      {
        li: "Custom popular places & POI setup",
      },
      {
        li: "Hourly based rental bookings",
      },
      {
        li: "Movers support",
      },
      {
        li: "Premium customer support",
      },
    ],
  },
  {
    title: "Enterprise",
    desc: "A fully customized solution that provides maximum flexibility.",
    price: "Custom",
    time: "",
    bgColor: "bg-white",
    textColor: "text-charcoal",
    btnColor: "btn-charcoal",
    shape: "right--40 top--40",
    recomended: "",
    recomendedColor: "bg-charcoal",
    border_color: "border-charcoal",
    list1: "Unlimited bookings",
    list2: "Unlimited service areas",
    subHeading: "Everything in Growth, plus:",
    listItem: [
      {
        li: "Extended white-labelling options",
      },
      {
        li: "Advanced optimization features",
      },
      {
        li: "More accurate ETAs & advanced insights",
      },
      {
        li: "Map pricing optimization management",
      },
      {
        li: "Personal onboarding & customer success manager",
      },
      {
        li: "Access to extensions",
      },
      {
        li: "Premium customer support",
      },
    ],
  },
];
const integrationFeature = [
  {
    logo: AppleMaps,
    type: "Navigation",
    class: "bg-primary-soft text-primary",
    title: "Apple Maps",
    info: "Seamlessly integrate precise mapping solutions into your business operations with Apple’s intuitive mapping platform.",
  },
  {
    logo: Waze,
    type: "Navigation",
    class: "bg-warning-soft text-warning",
    title: "Waze Maps",
    info: "Optimize fleet management and get real-time traffic updates and crowd sourced navigation assistance.",
  },
  {
    logo: GoogleMaps,
    type: "Navigation",
    class: "bg-success-soft text-success",
    title: "Google Maps",
    info: "Create real time experience with dynamic maps, routes and places APIs from Google Maps.",
  },
  {
    logo: zervX,
    type: "Navigation",
    class: "bg-warning-soft text-warning",
    title: "ZervX Maps",
    info: "Precise, advanced and cost effective navigation solution compared to others, can save 90% of cost.",
  },
  {
    logo: cybersource,
    type: "Payment",
    class: "bg-primary-soft text-primary",
    title: "Cybersource",
    info: "Secure fast effective payment processing with a comprehensive fraud management solution.    ",
  },
  {
    logo: Stripe,
    type: "Payment",
    class: "bg-danger-soft text-danger",
    title: "Stripe",
    info: "Leading trusted digital business payments service which simplifies online payment transactions.",
  },
  {
    logo: peachPayment,
    type: "Payment",
    class: "bg-warning-soft text-warning",
    title: "Peach Payments",
    info: "Enables businesses to accept payments from their customers in a secure and efficient manner.",
  },
  {
    logo: Razorpay,
    type: "Payment",
    class: "bg-success-soft text-success",
    title: "Razorpay",
    info: "Simplify online payments for your customers with the easy-to-use and robust payment gateway",
  },
  {
    logo: Flutterwave,
    type: "Payment",
    class: "bg-warning-soft text-warning",
    title: "Flutterwave",
    info: "Unified payment gateway for secure and seamless online payment transactions for global payment processing.",
  },
  {
    logo: Twilio,
    type: "Communication",
    class: "bg-primary-soft text-primary",
    title: "Twilio",
    info: "Enable communication through voice, SMS, and messaging APIs for personalized customer experience..",
  },
  {
    logo: SINCH,
    type: "Communication",
    class: "bg-warning-soft text-warning",
    title: "Sinch",
    info: "Reliable, cost effective, and scalable cloud communication solutions for seamless customer interactions.",
  },
  {
    logo: OneSignal,
    type: "Communication",
    class: "bg-success-soft text-success",
    title: "Onesignal",
    info: "   Engage and retain users with timely targeted personalized push notifications across multiple platforms.",
  },
  {
    logo: Sendinblue,
    type: "Communication",
    class: "bg-warning-soft text-warning",
    title: "Sendinblue",
    info: "Automate email marketing campaigns and optimize customer engagement with personalized messages.",
  },
  {
    logo: WhatsApp,
    type: "Communication",
    class: "bg-primary-soft text-primary",
    title: "WhatsApp",
    info: "Connect businesses and customers with messaging capabilities for instant communication and support.    ",
  },
  {
    logo: Facebook,
    type: "Analytics",
    class: "bg-danger-soft text-danger",
    title: "Facebook",
    info: "Connect with your customers and use social media to your advantage to reach and engage with them.",
  },
  {
    logo: Firebase,
    type: "Analytics",
    class: "bg-warning-soft text-warning",
    title: "Firebase",
    info: "Offers a plethora of tools and provides a real-time database that allows data to be synchronized seamlessly across devices.",
  },
  {
    logo: Teleport,
    type: "Advanced",
    class: "bg-success-soft text-success",
    title: "Teleport",
    info: "Teleport provides an automated and holistic view of all privileged infrastructure resources in a single place to manage.",
  },
  {
    logo: plus,
    type: "",
    class: "bg-primary-soft text-primary",
    title: "Custom Integrations",
    info: "Want to connect more? Reach out to us to discuss your ideas and needs for custom integrations.",
  },
];
//ourTeam data
const ourTeam = [
  {
    name: "John Sullivan",
    title: "Front End Developer",
    image: img4,
  },
  {
    name: "John Sullivan",
    title: "Front End Developer",
    image: img4,
  },
  {
    name: "John Sullivan",
    title: "Front End Developer",
    image: img4,
  },
  {
    name: "John Sullivan",
    title: "Front End Developer",
    image: img4,
  },
  {
    name: "John Sullivan",
    title: "Front End Developer",
    image: img4,
  },
  {
    name: "John Sullivan",
    title: "Front End Developer",
    image: img4,
  },
  {
    name: "John Sullivan",
    title: "Front End Developer",
    image: img4,
  },
  {
    name: "John Sullivan",
    title: "Front End Developer",
    image: img4,
  },
];
const blogFeatureData = [
  {
    currentBlogId: 1,
    image: blogImgSixteenFirst,
    type: "Mobile App",
    header: "Role of Ride Hailing App in Taxi Business",
    info: "The ride hailing has become an innovation of transport, disrupting the transport sector and in particular the taxi business in urban regions.",
    profilePic: BlogProfileThree,
    author: "John Sibin Raj",
    data: "June 15, 2024",
    class: "bg-danger-soft",
    href: "/blog",
    param: "role-of-ride-hailing-app-in-taxi-business",
    imgalt: "Ride hailing app",
    imgtitle: "",
  },
  {
    currentBlogId: 2,
    image: blogImgFifteenFirst,
    type: "Growth Hacks",
    header: "Why Your Limo Business Needs the Best Limousine Booking Software",
    info: "Present limousine service is more and more focused on new technologies that can improve efficiency and delivery.",
    profilePic: BlogProfileThree,
    author: "John Sibin Raj",
    data: "June 10, 2024",
    class: "bg-warning-soft",
    href: "/blog",
    param: "why-business-needs-best-limousine-booking-software",
    imgalt: "limousine dispatch software",
    imgtitle: "",
  },
  {
    currentBlogId: 3,
    image: blogImgFourteenFirst,
    type: "Growth Hacks",
    header: "How to Start a Taxi Business like Uber in 2025",
    info: "Thinking of starting a taxi business in 2025? Follow our expert guide for strategies, market insights, and tips to build a successful ride-hailing business.",
    profilePic: BlogProfileThree,
    author: "John Sibin Raj",
    data: "June 05, 2024",
    class: "bg-danger-soft",
    href: "/blog",
    param: "how-to-start-taxi-business-like-uber",
    imgalt: "How to start a taxi business",
    imgtitle: "",
  },
  {
    currentBlogId: 4,
    image: blogImgThirteenFirst,
    type: "Growth Hacks",
    header: "5 Key Factors in Selecting the Best Fleet Management Software",
    info: "In the competitive landscape of business, the choices you make can often mean the difference between soaring success and struggling to keep afloat. very pleasant for drivers as well as passengers.",
    profilePic: BlogProfileThree,
    author: "John Sibin Raj",
    data: "May 09, 2024",
    class: "bg-primary-soft",
    href: "/blog",
    param: "fleet-management-software",
    imgalt: "Fleet management software",
    imgtitle: "",
  },
  {
    currentBlogId: 5,
    image: blogImgTwelveFirst,
    type: "Growth Hacks",
    header: "Why Your Taxi Business Needs White Label Dispatch Software",
    info: "Learn why white label taxi dispatch software is essential for your business. Launch faster, reduce costs, and compete effectively in the ride-hailing market!",
    profilePic: BlogProfileThree,
    author: "John Sibin Raj",
    data: "Apr 28, 2024",
    class: "bg-danger-soft",
    href: "/blog",
    param: "white-label-taxi-software",
    imgalt: "White label dispatch software",
    imgtitle: "",
  },
  {
    currentBlogId: 6,
    image: blogImgElevenFirst,
    type: "Mobile App",
    header: "Super Apps - The Key to On-Demand Business Success",
    info: "Life in the 21st century is undeniably busy. In tandem, the ever-evolving digital landscape is catering to these dynamic lifestyles by offering solutions that unite various different services into one single platform. These are famously known as super apps.",
    profilePic: BlogProfileThree,
    author: "John Sibin Raj",
    data: "Mar 28, 2024",
    class: "bg-warning-soft",
    href: "/blog",
    param: "why-on-demand-businesses-need-a-super-app",
    imgalt: "Super apps",
    imgtitle: "",
  },
  {
    currentBlogId: 7,
    image: blogImgEightFirst,
    type: "Growth Hacks",
    header:
      "How Car Rental Management Software Can Help Improve Your Rental Business",
    info: "Did you realize that car rental companies worldwide are riding the wave of software-led operations? The fine line between staying archaic and moving towards a digitized future is blurring every day.",
    profilePic: BlogProfileThree,
    author: "John Sibin Raj",
    data: "Feb 22, 2024",
    class: "bg-primary-soft",
    href: "/blog",
    param: "how-car-rental-management-software-help-improve-rental-businesses",
    imgalt: "Car rental management software",
    imgtitle: "Car rental management software",
  },
  {
    currentBlogId: 8,
    image: blogImgSixFirst,
    type: "Growth Hacks",
    header: "How to Launch a Successful Taxi Business with an Uber Clone",
    info: "Are you looking to enter the taxi industry with your own startup? A great way to kick off your venture is by launching an Uber taxi clone.",
    profilePic: BlogProfileThree,
    author: "John Sibin Raj",
    data: "Jan 31, 2024",
    class: "bg-danger-soft",
    href: "/blog",
    param: "how-to-launch-a-successful-taxi-startup-with-an-uber-clone",
    imgalt: "Best Uber clone",
    imgtitle: "Taxi dispatch software",
  },
  {
    currentBlogId: 9,
    image: blogImgFiveFirst,
    type: "Growth Hacks",
    header: "How to Choose the Best Taxi Dispatch Software",
    info: "In the highly competitive taxi industry, having the right software can make all thedifference. The best taxi dispatch software can streamline operations...",
    profilePic: BlogProfileThree,
    author: "John Sibin Raj",
    data: "Dec 20, 2023",
    class: "bg-warning-soft",
    href: "/blog",
    param: "how-to-choose-the-best-taxi-dispatch-software",
    imgalt: "Taxi dispatch software",
    imgtitle: "Taxi dispatch software",
  },
  {
    currentBlogId: 10,
    image: BlogThree,
    type: "technology",
    header: "How Taxi Apps Work? All You Need to Know!",
    info: "In recent years, the emergence of taxi apps has revolutionized the transportation industry, offering convenient and efficient ride-hailing services. ",
    profilePic: BlogProfileThree,
    author: "John Sibin Raj",
    data: "August 20, 2023",
    class: "bg-danger-soft",
    href: "/blog",
    param: "how-taxi-apps-work",
    imgalt: "How taxi apps work",
    imgtitle: "How taxi apps work",
  },
  {
    currentBlogId: 11,
    image: BlogTwo,
    type: "technology",
    header: "The Rise of Taxi Apps: A Revolution in Urban Transportation",
    info: "The advent of taxi apps has transformed urban transportation, providing riders with convenient and affordable services.",
    profilePic: BlogProfileTwo,
    author: "John Sibin Raj",
    data: "July 24, 2023",
    class: "bg-primary-soft",
    href: "/blog",
    param: "the-rise-of-taxi-apps",
    imgalt: "The rise of taxi apps and its",
    imgtitle: "The rise of taxi apps effects on urban transportation",
  },
  {
    currentBlogId: 12,
    image: blogImgFirstOne,
    type: "technology",
    header:
      "How to Build a Successful Taxi App in 2024 | A Comprehensive Guide ",
    info: "As the demand for ride-hailing services grows worldwide, taxi app development has become a great opportunity for businesses looking to tap into the mobility market. ",
    profilePic: BlogProfileOne,
    author: "John Sibin Raj",
    data: "June 12, 2023",
    class: "bg-warning-soft",
    href: "/blog",
    param: "guide-to-develop-successful-taxi-app",
    imgalt: "How to build a taxi app",
    imgtitle: "How to build a taxi app",
  },
  {
    currentBlogId: 13,
    image: blogImgFourFirst,
    type: "technology",
    header: "On-Demand Services | What They Are and How They Work",
    info: "On-demand services have become a big part of our lives today. They make it easy to get things like food, rides, or home services with just a few clicks on your phone. But what exactly are these on-demand services, and why are they becoming so popular?",
    profilePic: BlogProfileThree,
    author: "John Sibin Raj",
    data: "May 28, 2023",
    class: "bg-danger-soft",
    href: "/blog",
    param: "on-demand-services-explained",
    imgalt: "What is on-demand service",
    imgtitle: "",
  },
  {
    currentBlogId: 14,
    image: blogImgTenFirst,
    type: "Case Studies",
    header: "JetRide's Success Story with ZervX | Case study",
    info: "JetRide is a fast-growing ride-hailing business enterprise in Nigeria that offers cost-effective transportation to customers. Established in 2017, JetRide aims to meet the transportation needs of individuals in Lagos, Ibadan, and Abuja by offering competitive pricing for their services.",
    profilePic: BlogProfileThree,
    author: "John Sibin Raj",
    data: "Feb 03, 2023",
    class: "bg-primary-soft",
    href: "/blog",
    param: "a-case-study-on-jetrides-transformation-with-zervx",
    imgalt: "Client’s achievements with our taxi software",
    imgtitle: "",
  },
  {
    currentBlogId: 15,
    image: blogImgNineFirst,
    type: "Case Studies",
    header: "A Case Study on ZayRide's Transformation with ZervX",
    info: "Explore how ZayRide overcame operational challenges and achieved market leadership with ZervX's innovative ride-hailing platform.",
    profilePic: BlogProfileThree,
    author: "John Sibin Raj",
    data: "Dec 01, 2022",
    class: "bg-danger-soft",
    href: "/blog",
    param: "a-case-study-on-zayrides-transformation-with-zervx",
    imgalt: "Client’s achievements",
    imgtitle: "",
  },
  {
    currentBlogId: 16,
    image: blogImgSeventhFirst,
    type: "Product Updates",
    header: "Our Latest AI Powered Surge Pricing Update",
    info: "In our relentless pursuit of excellence in on-demand service solutions, we are happy to unveil our latest enhancement to the ZervX: the surge pricing.",
    profilePic: BlogProfileThree,
    author: "John Sibin Raj",
    data: "Nov 13, 2022",
    class: "bg-warning-soft",
    href: "/blog",
    param: "surge-pricing",
    imgalt: "Surge pricing",
    imgtitle: "",
  },
  {
    currentBlogId: 17,
    image: blogImgSeventeenFirst,
    type: "Growth Hacks",
    header: "5 Reasons Why You Choose Taxi Booking Software for Your Business",
    info: "Taxi booking software is an application used to offer a platform for transportation hailing where clients can easily hire cars and get an appropriate driver ready to take them to the desired destination.",
    profilePic: BlogProfileThree,
    author: "John Sibin Raj",
    data: "June 22, 2024",
    class: "bg-primary-soft",
    href: "/blog",
    param: "5-reasons-to-choose-taxi-booking-software-for-your-business",
    imgalt: "Taxi booking software",
    imgtitle: "",
  },
  {
    currentBlogId: 18,
    image: blogImgEighteenFirst,
    type: "Growth Hacks",
    header: "Why Taxi Dispatch Systems are a Must-Have for Taxi Businesses",
    info: "A taxi dispatch system serves as the backbone of any modern taxi business, managing the entire lifecycle of a ride from booking to payment.",
    profilePic: BlogProfileThree,
    author: "John Sibin Raj",
    data: "June 27, 2024",
    class: "bg-warning-soft",
    href: "/blog",
    param: "why-taxi-dispatch-systems-are-a-must-have-for-taxi-businesses",
    imgalt: "taxi dispatch system",
    imgtitle: "",
  },
  {
    currentBlogId: 19,
    image: blogImgNineteenFirst,
    type: "Compare",
    header: "Top 5 Best Reliable Taxi Dispatch Software for 2024",
    info: "As the taxi industry continues to evolve, businesses are seeking efficient ways to manage their operations, improve customer satisfaction, and increase revenue.",
    profilePic: BlogProfileThree,
    author: "John Sibin Raj",
    data: "Sep 19, 2024",
    class: "bg-danger-soft",
    href: "/blog",
    param: "top-taxi-dispatch-software",
    imgalt: "Top taxi dispatch software",
    imgtitle: "",
  },
  {
    currentBlogId: 20,
    image: blogImgTwentyZero,
    type: "Mobile App",
    header:
      "What is a Super App? Everything You Need to Know About This All-in-One Platform",
    info: "What if there was one app that you could use for shopping, booking rides, messaging your friends, paying bills, and taking care of your money? Sounds convenient, right? ",
    profilePic: BlogProfileThree,
    author: "John Sibin Raj",
    data: "Oct 16, 2024",
    class: "bg-primary-soft",
    href: "/blog",
    param: "what-is-a-super-app",
    imgalt: "What is a super app",
    imgtitle: "",
  },
];

const CompareMainData = [
  {
    currentBlogId: 1,
    image: compareListImg1,
    header:
      "ZervX vs TaxiMobility: Which Taxi Dispatch Software is Right for You?",
    href: "/zervx-vs-taximobility/",
    imgalt: "ZervX vs TaxiMobility",
  },
  {
    currentBlogId: 2,
    image: compareListImg2,
    header: "ZervX vs iCabbi: Which Taxi Dispatch Software is Right for You?",
    href: "/zervx-vs-icabbi/",
    imgalt: "ZervX vs iCabbi",
  },
  {
    currentBlogId: 3,
    image: compareListImg3,
    header:
      "ZervX vs Yelowsoft: Which Taxi Dispatch Software Fits Your Business Best?",
    href: "/zervx-vs-yelowsoft/",
    imgalt: "ZervX vs Yelowsoft",
  },
  {
    currentBlogId: 4,
    image: compareListImg4,
    header: "ZervX vs Onde: Which Taxi Dispatch Software is Right for You?",
    href: "/zervx-vs-onde/",
    imgalt: "ZervX vs Onde",
  },
  {
    currentBlogId: 5,
    image: compareListImg5,
    header: "ZervX vs Jugnoo: Which Taxi Dispatch Software is Right for You?",
    href: "/zervx-vs-jugnoo/",
    imgalt: "ZervX vs Jugnoo",
  },
];

const blogFeatureDataTwo = [
  {
    image: "https://quiety-next.themetags.com/testimonial/6.jpg",
    type: "Design",
    header: "Do you really understand the concept of product value?",
    info: "Society is fragmenting into two parallel realities. In one reality, you have infinite upside and opportunity. In the other reality, you’ll continue to see the gap between your standard of living and those at the top grow more and more.",
    profilePic: "https://quiety-next.themetags.com/testimonial/1.jpg",
    author: "Jane Martin",
    data: "April 24, 2021",
    class: "bg-warning-soft",
    href: "/blog/blog-single",
    param: "BlogOne",
  },
  {
    image: "https://quiety-next.themetags.com/testimonial/6.jpg",
    type: "Customer",
    header: "Why communities help you build better products for your business",
    info: "Society is fragmenting into two parallel realities. In one reality, you have infinite upside and opportunity. In the  other reality, you’ll continue to see the gap between your standard of living and those at the top grow more and more.",
    profilePic: "https://quiety-next.themetags.com/testimonial/6.jpg",
    author: "Veronica P. Byrd",
    data: "April 24, 2021",
    class: "bg-primary-soft",
    href: "/blog/blog-single",
    param: "BlogTwo",
  },
  {
    image: "https://quiety-next.themetags.com/testimonial/6.jpg",
    type: "Development",
    header: "Why communities help you build better products",
    info: "Society is fragmenting into two parallel realities. In one reality, you have infinite upside and opportunity. In the other reality, you’ll continue to see the gap between your standard of living and those at the top grow more and more.",
    profilePic: "https://quiety-next.themetags.com/testimonial/3.jpg",
    author: "Martin Gilbert",
    data: "May 20, 2021",
    class: "bg-danger-soft",
    href: "/blog/blog-single",
    param: "BlogThree",
  },
];
const blogFeatureDataThree = [
  {
    image: "https://quiety-next.themetags.com/testimonial/3.jpg",
    type: "Design",
    header: "Do you really understand the concept of product value?",
    info: "Society is fragmenting into two parallel realities. In one reality, you have infinite upside and opportunity. In the other reality, you’ll continue to see the gap between your standard of living and those at the top grow more and more.",
    profilePic: "https://quiety-next.themetags.com/testimonial/1.jpg",
    author: "Jane Martin",
    data: "April 24, 2021",
    class: "bg-warning-soft",
    href: "/blog/blog-single",
    param: "BlogThree",
  },
  {
    image: "https://quiety-next.themetags.com/testimonial/3.jpg",
    type: "Customer",
    header: "Why communities help you build better products for your business",
    info: "Society is fragmenting into two parallel realities. In one reality, you have infinite upside and opportunity. In the  other reality, you’ll continue to see the gap between your standard of living and those at the top grow more and more.",
    profilePic: "https://quiety-next.themetags.com/testimonial/6.jpg",
    author: "Veronica P. Byrd",
    data: "April 24, 2021",
    class: "bg-primary-soft",
    href: "/blog/blog-single",
    param: "BlogThree",
  },
  {
    image: "https://quiety-next.themetags.com/testimonial/3.jpg",
    type: "Development",
    header: "Why communities help you build better products",
    info: "Society is fragmenting into two parallel realities. In one reality, you have infinite upside and opportunity. In the other reality, you’ll continue to see the gap between your standard of living and those at the top grow more and more.",
    profilePic: "https://quiety-next.themetags.com/testimonial/3.jpg",
    author: "Martin Gilbert",
    data: "May 20, 2021",
    class: "bg-danger-soft",
    href: "/blog/blog-single",
    param: "BlogThree",
  },
];
const blogScriptOne = [
  {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://www.zervx.com/blog/guide-to-develop-successful-taxi-app/",
    },
    headline: "How to Build a Successful Taxi App in 2024",
    description:
      "Want to build a taxi app in 2024? Our guide covers key features, development steps, and pricing to help you succeed in the ride-hailing industry.",
    image:
      "https://www.zervx.com/_next/static/media/How_to_Build_a_Successful_Taxi_App_in_2024.6658d1a8.webp",
    author: {
      "@type": "Person",
      name: "John Sibin Raj",
    },
    publisher: {
      "@type": "Organization",
      name: "ZervX",
      logo: {
        "@type": "ImageObject",
        url: "https://www.zervx.com/_next/static/media/logo_white.4ec3ec6f.svg",
      },
    },
    datePublished: "2021-04-24",
    dateModified: "2023-08-09",
  },
];
const blogScriptTwo = [
  {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://www.zervx.com/blog/the-rise-of-taxi-apps/",
    },
    headline: "The Rise of Taxi Apps: A Revolution in Urban Transportation",
    description:
      "Taxi apps have revolutionized urban transportation, providing riders access to affordable services. Learn how taxi apps are transforming the way people travel.",
    image: "https://www.zervx.com/_next/static/media/Taxi-app.764347da.jpg",
    author: {
      "@type": "Person",
      name: "John Sibin Raj",
    },
    publisher: {
      "@type": "Organization",
      name: "ZervX",
      logo: {
        "@type": "ImageObject",
        url: "https://www.zervx.com/_next/static/media/logo_white.4ec3ec6f.svg",
      },
    },
    datePublished: "2021-04-24",
    dateModified: "2023-08-09",
  },
];
const blogScriptThree = [
  {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://www.zervx.com/blog/how-taxi-apps-work/",
    },
    headline: "How to Choose the Best Taxi Dispatch Software",
    description:
      "Find the best taxi dispatch software for your taxi business. Learn about the features you should look for, and how to evaluate the best software for your needs.",
    image: "https://www.zervx.com/_next/static/media/taxi-app.ce6e355d.jpg",
    author: {
      "@type": "Person",
      name: "John Sibin Raj",
    },
    publisher: {
      "@type": "Organization",
      name: "ZervX",
      logo: {
        "@type": "ImageObject",
        url: "https://www.zervx.com/_next/static/media/logo_white.4ec3ec6f.svg",
      },
    },
    datePublished: "2021-05-20",
    dateModified: "2023-08-09",
  },
];
const blogScriptFour = [
  {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://www.zervx.com/blog/on-demand-services-explained/",
    },
    headline: "On-Demand Services | What They Are and How They Work",
    description:
      "On-demand services have become the latest trend and these services have become an integral part of our daily lives. But what exactly are on-demand services?",
    image:
      "https://www.zervx.com/_next/static/media/What_Are_On_Demand_Services.3e91c303.jpg",
    author: {
      "@type": "Person",
      name: "John Sibin Raj",
    },
    publisher: {
      "@type": "Organization",
      name: "ZervX",
      logo: {
        "@type": "ImageObject",
        url: "https://www.zervx.com/_next/static/media/logo_white.4ec3ec6f.svg",
      },
    },
    datePublished: "2021-05-20",
    dateModified: "2023-08-09",
  },
];
const blogScriptFive = [
  {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id":
        "https://www.zervx.com/blog/how-to-choose-the-best-taxi-dispatch-software/",
    },
    headline: "How to Choose the Best Taxi Dispatch Software",
    description:
      "Find the best taxi dispatch software for your taxi business. Learn about the features you should look for, and how to evaluate the best software for your needs.",
    image:
      "https://www.zervx.com/_next/static/media/Taxi-dispatch-software-og.0b3078f8.jpg",
    author: {
      "@type": "Person",
      name: "John Sibin Raj",
    },
    publisher: {
      "@type": "Organization",
      name: "ZervX",
      logo: {
        "@type": "ImageObject",
        url: "https://www.zervx.com/_next/static/media/logo_white.4ec3ec6f.svg",
      },
    },
    datePublished: "2023-12-20",
    dateModified: "",
  },
];
const blogScriptSix = [
  {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id":
        "https://www.zervx.com/blog/how-to-launch-a-successful-taxi-startup-with-an-uber-clone/",
    },
    headline: "How to Launch a Successful Taxi Startup with an Uber Clone",
    description:
      "Discover the secrets to a booming taxi business with an Uber clone. Get your hands on the ultimate Uber clone script for guaranteed success. Don't miss out, start your journey today!",
    image:
      "https://www.zervx.com/_next/static/media/Best-Uber-clone.879dc430.webp",
    author: {
      "@type": "Person",
      name: "John Sibin Raj",
    },
    publisher: {
      "@type": "Organization",
      name: "ZervX",
      logo: {
        "@type": "ImageObject",
        url: "https://www.zervx.com/_next/static/media/logo_white.4ec3ec6f.svg",
      },
    },
    datePublished: "2024-01-31",
    dateModified: "",
  },
];
const blogScriptSeven = [
  {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://www.zervx.com/blog/surge-pricing/",
    },
    headline: "Our Latest AI Powered Surge Pricing Update",
    description:
      "Discover how automatic AI driven algorithms and manual adjustments empower taxi businesses to navigate demand fluctuations effectively.",
    image:
      "https://www.zervx.com/_next/static/media/Surge_pricing.8723b452.webp",
    author: {
      "@type": "Person",
      name: "John Sibin Raj",
    },
    publisher: {
      "@type": "Organization",
      name: "ZervX",
      logo: {
        "@type": "ImageObject",
        url: "https://www.zervx.com/_next/static/media/logo_white.4ec3ec6f.svg",
      },
    },
    datePublished: "2022-11-13",
    dateModified: "",
  },
];
const blogScriptEight = [
  {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id":
        "https://www.zervx.com/blog/how-car-rental-management-software-help-improve-rental-businesses/",
    },
    headline: "Grow Your Rental Business with Car Rental Management Software",
    description:
      "Discover how car rental management software, including a powerful vehicle booking system, can boost your rental business.",
    image:
      "https://www.zervx.com/_next/static/media/carrentalmanagementsoftware.b87acaff.webp",
    author: {
      "@type": "Person",
      name: "John Sibin Raj",
    },
    publisher: {
      "@type": "Organization",
      name: "ZervX",
      logo: {
        "@type": "ImageObject",
        url: "https://www.zervx.com/_next/static/media/logo_white.4ec3ec6f.svg",
      },
    },
    datePublished: "2024-02-22",
    dateModified: "",
  },
];
const blogScriptNine = [
  {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id":
        "https://www.zervx.com/blog/a-case-study-on-zayrides-transformation-with-zervx/",
    },
    headline: "ZayRide's Success Story with ZervX | Case study",
    description:
      "Explore how ZayRide overcame operational challenges and achieved market leadership with ZervX's innovative ride-hailing platform.",
    image:
      "https://www.zervx.com/_next/static/media/Client-achievements.c19117d4.webp",
    author: {
      "@type": "Person",
      name: "John Sibin Raj",
    },
    publisher: {
      "@type": "Organization",
      name: "ZervX",
      logo: {
        "@type": "ImageObject",
        url: "https://www.zervx.com/_next/static/media/logo_white.4ec3ec6f.svg",
      },
    },
    datePublished: "2022-12-01",
    dateModified: "",
  },
];
const blogScriptTen = [
  {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id":
        "https://www.zervx.com/blog/a-case-study-on-jetrides-transformation-with-zervx/",
    },
    headline: "JetRide's Success Story with ZervX | Case study",
    description:
      "Find out how ZervX's software solution helped JetRide overcome challenges and achieve remarkable results.",
    image:
      "https://www.zervx.com/_next/static/media/Clients-achievements-with-our-taxi-software.97083e99.webp",
    author: {
      "@type": "Person",
      name: "John Sibin Raj",
    },
    publisher: {
      "@type": "Organization",
      name: "ZervX",
      logo: {
        "@type": "ImageObject",
        url: "https://www.zervx.com/_next/static/media/logo_white.4ec3ec6f.svg",
      },
    },
    datePublished: "2023-02-03",
    dateModified: "",
  },
];
const blogScriptEleven = [
  {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id":
        "https://www.zervx.com/blog/why-on-demand-businesses-need-a-super-app/",
    },
    headline: "Super Apps - The Key to On-Demand Business Success",
    description:
      "Find out what changes super apps bring to on-demand services and understand why businesses need it for operational efficiency and enhanced customer engagement.",
    image: "https://www.zervx.com/_next/static/media/Super-apps.22fd0928.webp",
    author: {
      "@type": "Person",
      name: "John Sibin Raj",
    },
    publisher: {
      "@type": "Organization",
      name: "ZervX",
      logo: {
        "@type": "ImageObject",
        url: "https://www.zervx.com/_next/static/media/logo_white.4ec3ec6f.svg",
      },
    },
    datePublished: "2024-03-28",
    dateModified: "",
  },
];
const blogScriptTwelve = [
  {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://www.zervx.com/blog/white-label-taxi-software/",
    },
    headline: "Why Your Taxi Business Needs White Label Dispatch Software",
    description:
      "Learn why white label taxi dispatch software is essential for your business. Launch faster, reduce costs, and compete effectively in the ride-hailing market!",
    image:
      "https://www.zervx.com/_next/static/media/White_label_dispatch_software.8f6683ac.webp",
    author: {
      "@type": "Person",
      name: "John Sibin Raj",
    },
    publisher: {
      "@type": "Organization",
      name: "ZervX",
      logo: {
        "@type": "ImageObject",
        url: "https://www.zervx.com/_next/static/media/logo_white.4ec3ec6f.svg",
      },
    },
    datePublished: "2024-04-23",
    dateModified: "",
  },
];
const blogScriptThirteen = [
  {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://www.zervx.com/blog/fleet-management-software/",
    },
    headline: "5 Key Factors in Choosing the Best Fleet Management Software",
    description:
      "Make the right choice for your fleet management needs. Explore the 5 key factor that will help you select the best software solution for your business.",
    image:
      "https://www.zervx.com/_next/static/media/fleet_management_software.9cc83de7.webp",
    author: {
      "@type": "Person",
      name: "John Sibin Raj",
    },
    publisher: {
      "@type": "Organization",
      name: "ZervX",
      logo: {
        "@type": "ImageObject",
        url: "https://www.zervx.com/_next/static/media/logo_white.4ec3ec6f.svg",
      },
    },
    datePublished: "2024-05-09",
    dateModified: "",
  },
];
const blogScriptFourteen = [
  {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://www.zervx.com/blog/how-to-start-taxi-business-like-uber/",
    },
    headline: "How to Start a Taxi Business like Uber in 2025",
    description:
      "Thinking of starting a taxi business in 2025? Follow our expert guide for strategies, market insights, and tips to build a successful ride-hailing business.",
    image:
      "https://www.zervx.com/_next/static/media/How_to_start_a_taxi_business.7423dc1c.webp",
    author: {
      "@type": "Person",
      name: "John Sibin Raj",
    },
    publisher: {
      "@type": "Organization",
      name: "ZervX",
      logo: {
        "@type": "ImageObject",
        url: "https://www.zervx.com/_next/static/media/logo_white.4ec3ec6f.svg",
      },
    },
    datePublished: "2024-06-05",
    dateModified: "",
  },
];
const blogScriptFifteen = [
  {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id":
        "https://www.zervx.com/blog/why-business-needs-best-limousine-booking-software/",
    },
    headline: "Why Your Business Needs the Best Limousine Booking Software",
    description:
      "Maximize the potential of your limo business with the best limousine booking software. Drive growth and customer satisfaction. Find out more!",
    image:
      "https://www.zervx.com/_next/static/media/limousine_dispatch_software.9a8d61df.webp",
    author: {
      "@type": "Person",
      name: "John Sibin Raj",
    },
    publisher: {
      "@type": "Organization",
      name: "ZervX",
      logo: {
        "@type": "ImageObject",
        url: "https://www.zervx.com/_next/static/media/logo_white.4ec3ec6f.svg",
      },
    },
    datePublished: "2024-06-10",
    dateModified: "",
  },
];
const blogScriptSixteen = [
  {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id":
        "https://www.zervx.com/blog/role-of-ride-hailing-app-in-taxi-business/",
    },
    headline:
      "What Role Do Ride Hailing Apps Play in the Growth of Taxi Businesses?",
    description:
      "Explore the significance of ride hailing apps in the taxi industry. Find out why taxi businesses cannot ignore the benefits these apps bring to their operations.",
    image:
      "https://www.zervx.com/_next/static/media/Ride_hailing_app.1cfb941f.webp",
    author: {
      "@type": "Person",
      name: "John Sibin Raj",
    },
    publisher: {
      "@type": "Organization",
      name: "ZervX",
      logo: {
        "@type": "ImageObject",
        url: "https://www.zervx.com/_next/static/media/logo_white.4ec3ec6f.svg",
      },
    },
    datePublished: "2024-06-15",
    dateModified: "",
  },
];
const blogScriptSeventeen = [
  {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id":
        "https://www.zervx.com/blog/5-reasons-to-choose-taxi-booking-software-for-your-business/",
    },
    headline:
      "5 Reasons Why You Choose Taxi Booking Software for Your Business",
    description:
      "Take your business to new heights with taxi booking software! Discover the top 5 reasons why taxi booking system is the ideal choice for your business.",
    image:
      "https://www.zervx.com/_next/static/media/Taxi_booking_software.f1454114.webp",
    author: {
      "@type": "Person",
      name: "John Sibin Raj",
    },
    publisher: {
      "@type": "Organization",
      name: "ZervX",
      logo: {
        "@type": "ImageObject",
        url: "https://www.zervx.com/_next/static/media/logo_white.4ec3ec6f.svg",
      },
    },
    datePublished: "2024-06-22",
    dateModified: "",
  },
];
const blogScriptEighteen = [
  {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id":
        "https://www.zervx.com/blog/why-taxi-dispatch-systems-are-a-must-have-for-taxi-businesses/",
    },
    headline: "Why Taxi Dispatch Systems are a Must-Have for Taxi Businesses",
    description:
      "Explore the key benefits of taxi dispatch systems and why they are essential for any taxi business looking to stay competitive and profitable in the long run.",
    image:
      "https://www.zervx.com/_next/static/media/taxi_dispatch_system18.f8a56c3c.webp",
    author: {
      "@type": "Person",
      name: "John Sibin Raj",
    },
    publisher: {
      "@type": "Organization",
      name: "ZervX",
      logo: {
        "@type": "ImageObject",
        url: "https://www.zervx.com/_next/static/media/logo_white.4ec3ec6f.svg",
      },
    },
    datePublished: "2024-06-27",
    dateModified: "",
  },
];
const blogScriptNineteen = [
  {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://www.zervx.com/blog/top-taxi-dispatch-software/",
    },
    headline: "Top 5 Taxi Dispatch Software Solutions for 2024",
    description:
      "Looking for the top taxi dispatch software of 2024? Find out which software offers the best features for managing your fleet and improving customer experience.",
    image:
      "https://www.zervx.com/_next/static/media/Top-5-taxi-dispatch-system.da2579f9.webp",
    author: {
      "@type": "Person",
      name: "John Sibin Raj",
    },
    publisher: {
      "@type": "Organization",
      name: "ZervX",
      logo: {
        "@type": "ImageObject",
        url: "https://www.zervx.com/_next/static/media/logo_white.4ec3ec6f.svg",
      },
    },
    datePublished: "2024-09-19",
    dateModified: "",
  },
];
const blogScriptTwenty = [
  {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "https://www.zervx.com/blog/what-is-a-super-app/",
    },
    headline: "What Is a Super App? Everything You Need to Know",
    description:
      "Find out what a super app is and why it’s gaining popularity. Learn about its key features, benefits, and the future of this all-in-one platform.",
    image:
      "https://www.zervx.com/_next/static/media/What_is_a_super_app_everything_you_need_to_know.91696f02.webp",
    author: {
      "@type": "Person",
      name: "John Sibin Raj",
    },
    publisher: {
      "@type": "Organization",
      name: "ZervX",
      logo: {
        "@type": "ImageObject",
        url: "https://www.zervx.com/_next/static/media/logo_white.4ec3ec6f.svg",
      },
    },
    datePublished: "2024-10-16",
    dateModified: "",
  },
];
const FeatureImgCommonList = [
  {
    id: 1,
    img: Admin_Panel,
    imgalt: "ZervX App for Taxi",
    title: "Unparalleled Performance",
    description:
      "Our technology platform is built to handle even the most demanding workloads, providing you with the power and performance you need to stay ahead of the competition.",
    iconOne: <FaShippingFast />,
    titleTwo: "Superior Speed and Efficiency",
    titleTwo_content:
      "Streamline operations and deliver results faster than ever, surpassing customer expectations and gaining a competitive edge.      ",
    iconTwo: <FaCompress />,
    titleThree: "Competitive Advantage",
    titleThree_content:
      "Seize opportunities faster with exceptional performance. Make confident data-driven decisions, and deliver superior services, and customer experiences that set you apart.",
    iconThree: <MdConveyorBelt />,
    titleFour: "Uncompromising Reliability    ",
    titleFour_content:
      "You can count on our proven reliability and trustworthy performance. Our robust infrastructure and quality assurance measures ensure consistent performance and trusted business continuity.",
  },
  {
    id: 2,
    img: Admin_Panel,
    imgalt: "ZervX app for booking taxi",
    title: "Future-Proof Solution",
    description:
      "With scalability at the forefront of its design, our unmatched app for booking taxis effortlessly expands and adapts alongside your evolving needs and growing business.",
    iconOne: <TbEaseInOutControlPoints />,
    titleTwo: "Scalability Without Limits",
    titleTwo_content:
      "Scale effortlessly as your business grows. Our cab hailing app solution can handle expanding workloads, increasing user volumes, and long-term growth, ensuring you never outgrow your technology infrastructure.",
    iconTwo: <RiSoundModuleFill />,
    titleThree: "Technological Advancements",
    titleThree_content:
      "Look to the future with assurance, as our future-proofed solution utilizes constant refinement to provide you latest technological advancements and capabilities for maintaining a competitive edge.",
    iconThree: <RiMoneyDollarCircleLine />,
    titleFour: "New Revenue Streams",
    titleFour_content:
      "Expand and diversify with certainty, as our future-proofed solution arms you with the means to tap new markets, capitalize on trends, and reach untapped customers for sustainable growth.",
  },
  {
    id: 3,
    img: Admin_Panel,
    imgalt: "ZervX hailing app",
    title: "Dedicated Support and Partnership",
    description:
      "We help make your success happen through collaboration, with 24/7 support that swiftly addresses any technical questions or issues to minimize disruptions and maximize productivity.",
    iconOne: <MdOutlineAssistant />,
    titleTwo: "Technical Assistance",
    titleTwo_content:
      "Our team of experts is available 24/7 to provide technical assistance, resolving any issues swiftly and ensuring uninterrupted operations.",
    iconTwo: <BiSupport />,
    titleThree: "Skilled Support",
    titleThree_content:
      "Our responsive experts offer quick solutions and accurate guidance through deep knowledge of our technology and your business needs, getting you back on track swiftly.",
    iconThree: <FaHandshake />,
    titleFour: "Building Strong Partnerships",
    titleFour_content:
      "We take the time to understand clients unique challenges and goals, collaborating closely to deliver customized solutions that meet the specific needs.",
  },
];
const FeatureImgCommonListToolsPage = [
  {
    id: 1,
    tag: "passenger-app",
    img: PassengerApp,
    title: "Passenger App",
    idKey: "PassengerApp",
    description:
      " Our passenger app is the perfect solution for all your ride needs. With user-friendly design and custom features like booking a ride, geolocation, location & fare calculation, payments, messaging and much more. We provide a complete end-to-end ride experience.",
    iconOne: <GiBusStop />,
    titleTwo: "Multiple Drop-Offs    ",
    titleTwo_content:
      " Effortlessly add multiple stops to your ride with a few taps on your device. Make your journeys to all destinations an interrupted journey of comfort and convenience.",

    iconTwo: <BsPhoneFlip />,
    titleThree: "Call Masking",
    titleThree_content:
      "Best solution to maintain the customer’s privacy and identity without getting their personal data misplaced.",
    iconThree: <MdAutoGraph />,

    titleFour: "Automated Language Detection",
    titleFour_content:
      "With our multi-language support feature, passengers can access our app in their preferred language",
    imgalt: "ZervX passenger app interface",
  },
  {
    id: 2,
    tag: "driver-app",
    img: Driver_App,
    title: "Driver App",
    idKey: "DriverApp",
    description:
      "Drive smarter, not harder with the new Driver App. With just a few taps, you can accept or decline bookings made by passengers. The app features include streamlined registration, user-friendly profile management, precise GPS and so on.",
    iconOne: <GiSmartphone />,

    titleTwo: " Easy Accessible Interface ",
    titleTwo_content:
      "Navigate with ease using the driver-friendly interface. Designed for an optimal user experience, our app prioritizes convenience and ease of use. With an intuitive design, accessing essential features has never been simpler.",
    iconTwo: <IoSpeedometer />,

    titleThree: "Live Meter",
    titleThree_content:
      "No more guessing the cost of the ride, as our meter automatically calculates the waiting time, traffic delay, and changes in speed to ensure you pay only for the time and distancetraveled.",

    iconThree: <FaMoneyCheckAlt />,
    titleFour: "Earning Stats",
    titleFour_content:
      " Get real-time updates on your daily, weekly and monthly earnings, track your progress and see how much you have earned to date. Make informed decisions and optimize your driving strategy to increase your earnings.",
    imgalt: "ZervX driver app interface",
  },
  {
    id: 3,
    tag: "admin-panel",
    img: Admin_Panel,
    title: "Admin Panel",
    idKey: "AdminPanel",
    description:
      "Empower your team with the ability to manage configurations, settings and features, as well as carry out essential oversight functions. Simplify your work processes and streamline your operations with our advanced management tools.",
    iconOne: <IoSettingsSharp />,
    titleTwo: " No-Code Dynamic Settings ",
    titleTwo_content:
      "With our no-code approach, you can easily create dynamic settings and tailor your application to your exact specifications.",
    iconTwo: <BsChatSquareTextFill />,
    titleThree: "Chat Support",
    titleThree_content:
      " Our chat support team is here to assist you 24 hours a day, 7 days a week. Will answer any questions you have and resolve any issues you may encounter in a timely and efficient manner.",
    iconThree: <FaSatellite />,
    titleFour: "Satellite View",
    titleFour_content:
      " With this feature, you can easily view the vehicles and their locations, whether managing a fleet of vehicles or just a few, satellite view provides you with a bird’s eye view of your operations.",
    imgalt: "ZervX admin panel dashboard",
  },
  {
    id: 4,
    img: Corporate,
    tag: "corporate-panel",
    title: "Corporate Panel",
    idKey: "CorporatePanel",
    description:
      "Corporate panel offers a centralized platform for businesses to manage their transportation needs. Admins can create multiple user accounts for employees, set ride policies and track expenses.",
    iconOne: <FaRegListAlt />,
    titleTwo: " Separate Fare Setup ",
    titleTwo_content:
      " With corporate fare management, you have the flexibility to create customized fare policies that are tailored to your company’s specific needs. Our platform allows you to set up custom fare policies for your employees, giving you more control over your travel budget.",
    iconTwo: <FaRegAddressCard />,
    titleThree: "Create Employee Profile",
    titleThree_content:
      " You can manage and organize your company’s workforce by creating and updating employee profiles.",
    iconThree: <TbChartHistogram />,
    titleFour: "Manage Rides",
    titleFour_content:
      " With just a few clicks, you can arrange rides for employees or clients, view ride history and keep track of all ride details in real-time.",
    imgalt: "ZervX corporate panel dashboard",
  },
  {
    id: 5,
    img: Dispatcher,
    tag: "dispatcher-panel",
    title: "Dispatcher Panel",
    idKey: "DispatcherPanel",
    description:
      "Designed for maximum efficiency, our panel delivers lightning-fast responsiveness and a highly effective driver database management system. Create ride requests for registered or unregistered passengers with ease and offer anytime booking facilities for your customers through your dispatchers.",
    iconOne: <MdBookOnline />,
    titleTwo: " Easy Booking ",
    titleTwo_content:
      " You can book rides for your customers with ease and our interface makes it simple to choose destinations, select travel dates, and confirm bookings.",
    iconTwo: <GiAbstract092 />,
    titleThree: "Track Rides",
    titleThree_content:
      "With our panel, you can easily manage the bookings and rides, and provide exceptional service to your customers.",
    iconThree: <AiOutlineApartment />,
    titleFour: "Smart Assignment",
    titleFour_content:
      "Our smart assignment algorithm assigns jobs to drivers based on factors such as location, availability, and vehicle type, ensuring that your fleet is utilized to its fullest potential.",
    imgalt: "ZervX dispatcher panel dashboard",
  },
  {
    id: 6,
    img: Web_Booking,
    tag: "web-booking",
    title: "Web Booking",
    idKey: "WebBooking",
    description:
      "Eliminate the inconvenience of downloading multiple applications and relish in a hassle-free ride booking process straight from your web browser.",
    iconOne: <RiCurrencyFill />,
    titleTwo: "  Multiple languages and currencies ",
    titleTwo_content:
      "Support for multiple languages and currencies to cater to customers from different regions.",
    iconTwo: <MdEventAvailable />,
    titleThree: "Real-Time Availability    ",
    titleThree_content:
      "Passengers can check the real-time availability of drivers and book a ride accordingly, making their ride experience much smoother.",
    iconThree: <IoQrCode />,
    titleFour: "Promo Codes",
    titleFour_content:
      "Passengers can apply promo codes to avail discounts on their rides. Whether it's a first-time rider or a loyal customer, everyone can enjoy the benefits of promo codes through our web booking panel.",
    imgalt: "ZervX web booking page",
  },
  {
    id: 7,
    img: Website,
    tag: "websiteid",
    title: "Website",
    idKey: "Website",
    description:
      "Our tailored website solutions for business partners offer a range of features designed to boost your online presence and enhance your overall success.",
    iconOne: <TbBuildingCommunity />,
    titleTwo: "Enhanced Visibility",
    titleTwo_content:
      "With your own website, you can establish a unique online identity for your mobility business.",
    iconTwo: <RiUserLocationLine />,
    titleThree: "Customer Acquisition",
    titleThree_content:
      "Having an online presence, you can expand your reach beyond your local area and attract customers from different locations.",
    iconThree: <CgWebsite />,
    titleFour: "Custom Website Design",
    titleFour_content:
      "Our team of experienced designers will create a visually appealing and user-friendly website tailored to your brand and target audience.",
    imgalt: "Website solutions for business partners",
  },
];
const FeatureImgCommonList2 = [
  {
    id: 1,
    img: Admin_Panel,
    imgalt: "ZervX alternative to uber clone",
    title: "Unmatched Efficiency",
    description:
      "Experience a level of operational efficiency that traditional uber clone scripts simply cannot match.",
    iconOne: <BsDatabase />,
    titleTwo: "Streamline Operations",
    titleTwo_content:
      "By leveraging advanced algorithms and intelligent workflows, our software eliminates manual tasks, reducing human error and increasing overall efficiency.",
    iconTwo: <MdEventAvailable />,
    titleThree: "Real-Time Data Access",
    titleThree_content:
      "Our mobility software provides businesses with up-to-the-minute insights, empowering you to act quickly and strategically. No more waiting for reports or relying on outdated information.",
    iconThree: <SiHandshake />,
    titleFour: "Matchless Performance   ",
    titleFour_content:
      "Designed for huge scalability and peak performance under heavy loads, far exceeding basic Uber/Gojek clone scripts through advanced techniques and dedicated infrastructure.",
  },
  {
    id: 2,
    img: Admin_Panel,
    imgalt: "Best software for ride hailing service",
    title: "Ride Hailing Service Reimagined",
    description:
      "Our software takes your ride hailing service to the next level, empowering you to provide seamless, convenient transportation to your customers.",
    iconOne: <GiDiscussion />,
    titleTwo: "Optimized Routing and Dispatching",
    titleTwo_content:
      "Our software uses smart technology to quickly choose the best routes and taxis, saving businesses time, money and making good use of their taxis.    ",
    iconTwo: <BiChat />,
    titleThree: "Real-Time Fleet Monitoring",
    titleThree_content:
      "Our platform offers comprehensive real-time fleet monitoring and management features empowering businesses with valuable insights and control.",
    iconThree: <VscCommentUnresolved />,
    titleFour: "Tailored Solutions",
    titleFour_content:
      "Tailoring flexible, customizable software for businesses, our solutions align with objectives ensuring optimal performance for taxi services of any size.",
  },
  {
    id: 3,
    img: Admin_Panel,
    imgalt: "ZervX alternative to gojek clone",
    title: "Scalability for Business Growth    ",
    description:
      "MSupercharge your business growth with effortless integration, scalable solution, and powerful performance insights.aximize your mobility business potential through data-driven strategies and decision making.",
    iconOne: <TbBrandGoogleAnalytics />,
    titleTwo: "Easy Integration",
    titleTwo_content:
      "Leverage cutting-edge data analytics and visualization to gain powerful insights, accelerate decisions and gain a competitive edge.    ",
    iconTwo: <MdOutlineSpatialTracking />,
    titleThree: "Scalable Solution",
    titleThree_content:
      "Our flexible solution is designed to accommodate increasing demands, and with our scalable  software, you can future-proof your operations and drive sustained growth.  ",
    iconThree: <MdOutlineCastForEducation />,
    titleFour: "Performance Insights",
    titleFour_content:
      "Gain valuable insights into your taxi operations with our comprehensive analytics and performance tracking capabilities.    ",
  },
];
const FeatureImgCommonList3 = [
  {
    id: 1,
    img: Admin_Panel,
    imgalt: "ZervX cab booking app",
    title: "Revolutionize Your Taxi Operations",
    description:
      "Leverage advanced technological solutions to give your customers a seamless transportation experience.As a cab booking app for taxi companies, or car ride sharing app, increase efficiency, productivity and profitability with smart mobility tools.",
    iconOne: <GiConversation />,
    titleTwo: "Boost Efficiency and Convenience",
    titleTwo_content:
      "Our software reshapes your transportation operations by providing robust features for fleet management, online bookings, digital payments, and more.  ",
    iconTwo: <FaRoute />,
    titleThree: "Insights Led Innovation",
    titleThree_content:
      "The powerful features and analytics can optimize operations, streamline processes, increase efficiencies, and enhance customer experiences across different ride-hailing models.",
    iconThree: <MdScreenshotMonitor />,
    titleFour: "Competitive Advantage   ",
    titleFour_content:
      "Seize opportunities faster with exceptional performance. Make confident data-driven decisions and deliver superior services, and customer experiences that set you apart.",
  },
  {
    id: 2,
    img: Admin_Panel,
    imgalt: "Best app for taxi service",
    title: "Customizable for Seamless Business Integration",
    description:
      "Remodel your taxi business with our adaptable mobility software, providing unmatched convenience and seamless efficiency to boost your revenue.",
    iconOne: <GiStarsStack />,
    titleTwo: "Tailored Solutions",
    titleTwo_content:
      "Tailoring flexible, customizable software for businesses, our solutions align with objectives, ensuring seamless integration and optimal performance for fleets and transportation networks of any size.    ",
    iconTwo: <AiOutlineSchedule />,
    titleThree: "Advanced Booking and Scheduling",
    titleThree_content:
      "Allows taxi companies to easily manage complex transportation arrangements, providing employees, clients, or customers with a hassle-free and reliable transportation experience.",
    iconThree: <AiOutlineSafety />,
    titleFour: "Real-Time Visibility Across Your Fleet   ",
    titleFour_content:
      "Comprehensive fleet monitoring and management tools provide valuable insights and control. Dispatchers can track vehicle locations, passenger details, ETAs, and more through our software.    ",
  },
  {
    id: 3,
    img: Admin_Panel,
    imgalt: "ZervX car ride sharing",
    title: "Scalable Platform for Steady Growth",
    description:
      "Our solutions allow effortless expansion as your business grows. White label and customize our app to launch your own branded platform. Leverage insights and scalable features to continuously optimize and future-proof your operations.    ",
    iconOne: <MdOutlineIntegrationInstructions />,
    titleTwo: "Easy Integration    ",
    titleTwo_content:
      "Our highly adaptable software may transform your transportation industry and seamlessly integrate with your existing systems.",
    iconTwo: <SiScala />,
    titleThree: "Optimal Route Planning",
    titleThree_content:
      "Intelligent algorithms quickly choose the best routes and assign the nearest available vehicle, whether a taxi, rental car or carpool ride.",
    iconThree: <MdInsights />,
    titleFour: "Robust Safety Measures",
    titleFour_content:
      "Our software prioritizes safety with driver verification, real-time tracking, and emergency assistance, ensuring a secure experience for all users.",
  },
];
const FeatureImgCommonList4 = [
  {
    id: 1,
    img: Admin_Panel,
    imgalt: "ZervX ride hailing application",
    title: "Increase Booking Efficiency",
    description:
      "Save time, reduce errors, and enhance customer satisfaction with our taxi cab software. This leads to more business opportunities and growth.",
    iconOne: <RiTaxiWifiLine />,
    titleTwo: "Real-Time Booking Management",
    titleTwo_content:
      "Our mobility tool provides a comprehensive real-time booking management system, seamlessly handle bookings, cancellations, and rescheduling.",
    iconTwo: <MdOutlineEventAvailable />,
    titleThree: "Automated Availability Updates",
    titleThree_content:
      "Automatically updates availability across multiple platforms, ensuring accurate and up-to-date information for customers, resulting in more bookings.",
    iconThree: <TbBrandGoogleAnalytics />,
    titleFour: "Booking Analytics",
    titleFour_content:
      "Gain valuable insights into the booking data, understand trends, preferences, and identify areas of improvement.",
  },
  {
    id: 2,
    img: Admin_Panel,
    imgalt: "ZervX taxicab dispatching software",
    title: "Drive Repeat Bookings",
    description:
      "Our innovative mobility software ignites customer loyalty, empowering businesses to build enduring relationships.",
    iconOne: <ImMobile />,
    titleTwo: "User-Friendly Booking Interface",
    titleTwo_content:
      "Simplify the booking process, offer customization options, and provide a seamless experience, encouraging repeat bookings and customer loyalty.    ",
    iconTwo: <MdRecordVoiceOver />,
    titleThree: "Personalized Recommendations",
    titleThree_content:
      "Delight customers with personalized recommendations based on previous bookings and sending tailored suggestions and promotional offers.",
    iconThree: <VscFeedback />,
    titleFour: "Customer Feedback",
    titleFour_content:
      "Collect valuable feedback from the customers, allowing Business to improve their services and build a positive online reputation.    ",
  },
  {
    id: 3,
    img: Admin_Panel,
    imgalt: "ZervX on-demand dispatch software",
    title: "Expand Your Reach",
    description:
      "Maximize your potential and increase bookings with our growth-focused ride hailing application and on demand dispatch software.",
    iconOne: <GiCardExchange />,
    titleTwo: "Multi-Channel Booking Capabilities    ",
    titleTwo_content:
      "Reach a wider audience through various channels, including websites and mobile apps.",
    iconTwo: <MdOutlineLoyalty />,
    titleThree: "Loyalty Programs",
    titleThree_content:
      "Encourage customer loyalty with loyalty programs, rewards, and incentives using our mobility software.",
    iconThree: <VscReferences />,
    titleFour: "Referral Programs",
    titleFour_content:
      "Encourage satisfied customers to refer friends and family, rewarding them with discounts, and credits.",
  },
];
const careerJobCard = [
  {
    type: "Onsite - Full Time",
    position: "Business",
    title: "Business Analyst",
    className: "bg-primary-soft text-primary",
    listItem: [
      {
        media: "Google",
        location: "London, UK",
        salary: "$35-$45k",
      },
    ],
  },
  {
    type: "Onsite - Full Time",
    position: "Business",
    title: "Business Development Co-ordinator",
    className: "bg-danger-soft text-danger",
    listItem: [
      {
        media: "Figma",
        location: "San Francissco",
        salary: "$25-$35k",
      },
    ],
  },
  {
    type: "Onsite - Full Time",
    position: "Developer",
    title: "Full stack / Mern Developer",
    className: "bg-success-soft text-success",
    listItem: [
      {
        media: "Dribble",
        location: "California",
        salary: "$55-$65k",
      },
    ],
  },
  {
    type: "Onsite - Full Time",
    position: "Developer",
    title: "iOS Developer",

    listItem: [
      {
        media: "Slack",
        location: "United State US",
        salary: "$55-$62k",
      },
    ],
  },
  {
    type: "Onsite - Full Time",
    position: "Developer",
    title: "Android Developer",
    listItem: [
      {
        media: "Slack",
        location: "United State US",
        salary: "$55-$62k",
      },
    ],
  },
  {
    type: "Onsite - Full Time",
    position: "Tester",
    title: "Software Tester",
    listItem: [
      {
        media: "Slack",
        location: "United State US",
        salary: "$55-$62k",
      },
    ],
  },
  {
    type: "Onsite - Full Time",
    position: "Designer",
    title: "UI/UX Designer",
    listItem: [
      {
        media: "Slack",
        location: "United State US",
        salary: "$55-$62k",
      },
    ],
  },
  {
    type: "Onsite - Full Time",
    position: "Designer",
    title: "Graphic Designer",
    listItem: [
      {
        media: "Slack",
        location: "United State US",
        salary: "$55-$62k",
      },
    ],
  },
];
const helpCenterFaq = [
  {
    title: "All Support Articles",
    target: "support-tab-1",
    class: "show active",
    listItem: [
      {
        header: "What is the monthly cost of your app?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "Do you have any local branches?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "What do I need to create an account?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "Are you open for new podcast guests?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "When is the upcoming annual event?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "What is the monthly cost of your app?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "Do you offer refunds for the subscriptions?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "What’s inside the Facebook community?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "How often is there a subscribers stream?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "What certifications does Agency has?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
    ],
  },
  {
    title: "Payments Query",
    target: "support-tab-2",
    listItem: [
      {
        header: "Do you offer refunds for the subscriptions?",
        desc: "Credibly evisculate mission-critical with high standards in e-markets. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "What is the monthly cost of your app?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "Do you have any local branches?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "What do I need to create an account?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "Are you open for new podcast guests?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "When is the upcoming annual event?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "What is the monthly cost of your app?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "What’s inside the Facebook community?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "What is the monthly cost of your app?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "What is the monthly cost of your app?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
    ],
  },
  {
    title: "Setup or Installment",
    target: "support-tab-3",
    listItem: [
      {
        header: "What is the monthly cost of your app?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "Do you have any local branches?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "What do I need to create an account?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "Are you open for new podcast guests?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "When is the upcoming annual event?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "What is the monthly cost of your app?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "Do you offer refunds for the subscriptions?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "What’s inside the Facebook community?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "How often is there a subscribers stream?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "What certifications does Agency has?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
    ],
  },
  {
    title: "Technical Support",
    target: "support-tab-4",
    listItem: [
      {
        header: "What is the monthly cost of your app?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "Do you have any local branches?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "What do I need to create an account?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "Are you open for new podcast guests?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "When is the upcoming annual event?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "What is the monthly cost of your app?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "Do you offer refunds for the subscriptions?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "What’s inside the Facebook community?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "How often is there a subscribers stream?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "What certifications does Agency has?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
    ],
  },
  {
    title: "Retailers & Customer",
    target: "support-tab-5",
    listItem: [
      {
        header: "What is the monthly cost of your app?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "Do you have any local branches?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "What do I need to create an account?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "Are you open for new podcast guests?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "When is the upcoming annual event?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "What is the monthly cost of your app?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "Do you offer refunds for the subscriptions?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "What’s inside the Facebook community?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "How often is there a subscribers stream?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "What certifications does Agency has?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
    ],
  },
  {
    title: "Security Issues",
    target: "support-tab-6",
    listItem: [
      {
        header: "What is the monthly cost of your app?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "Do you have any local branches?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "What do I need to create an account?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "Are you open for new podcast guests?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "When is the upcoming annual event?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "What is the monthly cost of your app?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "Do you offer refunds for the subscriptions?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "What’s inside the Facebook community?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "How often is there a subscribers stream?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "What certifications does Agency has?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
    ],
  },
  {
    title: "Brand Creation",
    target: "support-tab-7",
    listItem: [
      {
        header: "What is the monthly cost of your app?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "Do you have any local branches?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "What do I need to create an account?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "Are you open for new podcast guests?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "When is the upcoming annual event?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "What is the monthly cost of your app?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "Do you offer refunds for the subscriptions?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "What’s inside the Facebook community?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "How often is there a subscribers stream?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "What certifications does Agency has?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
    ],
  },
  {
    title: "Legal Support",
    target: "support-tab-8",
    listItem: [
      {
        header: "What is the monthly cost of your app?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "Do you have any local branches?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "What do I need to create an account?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "Are you open for new podcast guests?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "When is the upcoming annual event?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "What is the monthly cost of your app?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "Do you offer refunds for the subscriptions?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "What’s inside the Facebook community?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "How often is there a subscribers stream?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
      {
        header: "What certifications does Agency has?",
        desc: "Credibly evisculate mission-critical products before quality e-services. Intrinsicly evisculate e-business best practices productivate standardized convergence...",
        href: "/resources/help-center-single",
      },
    ],
  },
];
const helpCenterFaqDetails = [
  {
    title: "All Support Articles",
    target: "support-tab-2",
    class: "show active",
    listItem: [
      {
        header:
          "YOUR DATA PROTECTION RIGHTS UNDER GENERAL DATA PROTECTION REGULATION (GDPR)",
        desc: "We aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data.",
        desc2:
          " If you wish to be informed what Personal Data, we hold about you and if you want it to be removed from our systems, please email us at ",
        link: "https://www.berarkrays.com/contact-us/ ",
        List: [
          {
            head: "In certain circumstances, you have the following data protection rights:",
            Listdes:
              "The right to access, update or delete the information we have on you. ",
          },
          {
            Listdes:
              "The right of rectification. You have the right to have your information rectified if that information is inaccurate or incomplete. ",
          },
          {
            Listdes:
              "The right to object. You have the right to object to our processing of your Personal Data ",
          },
          {
            Listdes:
              "The right of restriction. You have the right to request that we restrict the processing of your personal information ",
          },
          {
            Listdes:
              "The right to data portability. You have the right to be provided with a copy of your Personal Data in a structured, machine-readable and commonly used format ",
          },
          {
            Listdes:
              "The right to withdraw consent. You also have the right to withdraw your consent at any time where we rely on your consent to process your personal information ",
          },
        ],
      },
    ],
  },
  {
    title: "PRIVACY POLICY",
    target: "support-tab-1",
    class: "show active",

    listItem: [
      {
        header: "WELCOME TO ZERVX",
        desc: "Our Privacy Policy governs your visit to zervx.com and explains how we collect, safeguard and disclose information that results from your use of our service. We use your data to provide and improve our service. By using the service, you agree to the collection and use of information in accordance with this policy.  ",
      },
      {
        header: "INFORMATION COLLECTION AND USE",
        desc: "We collect several different types of information for various purposes to provide and improve our service to you. ",
      },
      {
        header: "TYPES OF DATA COLLECTED",
      },
      {
        header: "THE INFORMATION WE COLLECT",
        desc: "When You Use The ZervX Platform, We Collect The Information You Provide, Usage Information, And Information About Your Device. We Also Collect Information About You From Other Sources Like Third-Party Services, And Optional Programs In Which You Participate, Which We May Combine With Other Information We Have About You. Here Are The Types Of Information We Collect About You:",
      },
      {
        header: "A. INFORMATION YOU PROVIDE TO US",
        BoldHeading: "Account Registration.",
        desc: "When You Create An Account With ZervX, We Collect The Information You Provide Us, Such As Your Name, Email Address, Phone Number, Birth Date, And Payment Information. You May Choose To Share Additional Info With Us For Your Rider Profile, Like Your Photo Or Saved Addresses (E.G., Home Or Work), And Set Up Other Preferences (Such As Your Preferred Pronouns).",
      },
      {
        BoldHeading: "Driver Information.",
        desc: "If You Apply To Be A Driver, We Will Collect The Information You Provide In Your Application, Including Your Name, Email Address, Phone Number, Birth Date, Profile Photo, Physical Address, Government Identification Number (Such As Social Security Number), Driver’s License Information, Vehicle Information, And Car Insurance Information. We Collect The Payment Information You Provide Us, Including Your Bank Routing Numbers, And Tax Information. Depending On Where You Want To Drive, We May Also Ask For Additional Business License Or Permit Information Or Other Information To Manage Driving And Programs Relevant To That Location. We May Need Additional Information From You At Some Point After You Become A Driver, Including Information To Confirm Your Identity (Like A Photo).",
      },
      {
        BoldHeading: "Ratings And Feedback. ",
        desc: " When You Rate And Provide Feedback About Riders Or Drivers, We Collect All Of The Information You Provide In Your Feedback. Communications. When You Contact Us Or We Contact You, We Collect Any Information That You Provide, Including The Contents Of The Messages Or Attachments You Send Us.",
      },
      {
        header: "B. INFORMATION WE COLLECT WHEN YOU USE THE ZERVX PLATFORM",
        List: [
          {
            head: "Location Information. Great Rides Start With An Easy And Accurate Pickup. The ZervX Platform Collects Location Information (Including GPS And WiFi Data) Differently Depending On Your ZervX App Settings And Device Permissions As Well As Whether You Are Using The Platform As A Rider Or Driver:",
            Listdes:
              "Riders: We collect your device’s precise location when you open and use the ZervX app, including while the app is running in the background from the time you request a ride until it ends. ZervX also tracks the precise location of all vehicle at all times.",
          },
          {
            Listdes:
              "Drivers: We collect your device’s precise location when you open and use the app, including while the app is running in the background when it is in driver mode. We also collect precise location for a limited time after you exit driver mode in order to detect ride incidents, and continue collecting it until a reported or detected incident is no longer active.",
          },
        ],
      },
      {
        BoldHeading: "Usage Information. ",
        desc: "We Collect Information About Your Use Of The ZervX Platform, Including Ride Information Like The Date, Time, Destination, Distance, Route, Payment, And Whether You Used A Promotional Or Referral Code. We Also Collect Information About Your Interactions With The ZervX Platform Like Our Apps And Websites, Including The Pages And Content You View And The Dates And Times Of Your Use.",
      },
      {
        BoldHeading: "Device Information.",
        desc: "We Collect Information About The Devices You Use To Access The ZervX Platform, Including Device Model, IP Address, Type Of Browser, Version Of Operating System, Identity Of Carrier And Manufacturer, Radio Type (Such As 4G), Preferences And Settings (Such As Preferred Language), Application Installations, Device Identifiers, Advertising Identifiers, And Push Notification Tokens. If You Are A Driver, We Also Collect Mobile Sensor Data From Your Device (Such As Speed, Direction, Height, Acceleration, Deceleration, And Other Technical Data).",
      },
      {
        BoldHeading: "Communications Between",
        desc: "Riders And Drivers. We Work With A Third Party To Facilitate Phone Calls And Text Messages Between Riders And Drivers Without Sharing Either Party’s Actual Phone Number With The Other. But While We Use A Third Party To Provide The Communication Service, We Collect Information About These Communications, Including The Participants’ Phone Numbers, The Date And Time, And The Contents Of SMS Messages. For Security Purposes, We May Also Monitor Or Record The Contents Of Phone Calls Made Through The ZervX Platform, But We Will Always Let You Know We Are About To Do So Before The Call Begins.",
      },
      {
        BoldHeading: "Address Book Contacts. ",
        desc: "You May Set Your Device Permissions To Grant ZervX Access To Your Contact Lists And Direct ZervX To Access Your Contact List, For Example To Help You Refer Friends To ZervX, Send Emergency SOS Alert Notification and In app Wallet to Wallet transaction. If You Do This, We Will Access And Store The Names And Contact Information Of The People In Your Address Book.        ",
      },
      {
        BoldHeading: "Cookies, Analytics, And Third-Party Technologies.",
        desc: "We Collect Information Through The Use Of “Cookies”, Tracking Pixels, Data Analytics Tools Like Google Analytics, SDKs, And Other Third-Party Technologies To Understand How You Navigate Through The ZervX Platform And Interact With ZervX Advertisements, To Make Your ZervX Experience Safer, To Learn What Content Is Popular, To Improve Your Site Experience, To Serve You Better Ads On Other Sites, And To Save Your Preferences. Cookies Are Small Text Files That Web Servers Place On Your Device; They Are Designed To Store Basic Information And To Help Websites And Apps Recognize Your Browser. We May Use Both Session Cookies And Persistent Cookies. A Session Cookie Disappears After You Close Your Browser. A Persistent Cookie Remains After You Close Your Browser And May Be Accessed Every Time You Use The ZervX Platform. You Should Consult Your Web Browser(S) To Modify Your Cookie Settings. Please Note That If You Delete Or Choose Not To Accept Cookies From Us, You May Miss Out On Certain Features Of The ZervX Platform.",
      },
      {
        header: "C. INFORMATION WE COLLECT FROM THIRD PARTIES",
        BoldHeading: "Third-Party Services.",
        desc: "Third-Party Services Provide Us With Information Needed For Core Aspects Of The ZervX Platform, As Well As For Additional Services, Programs, Loyalty Benefits, And Promotions That Can Enhance Your ZervX Experience. These Third-Party Services Include Background Check Providers, Insurance Partners, Financial Service Providers, Marketing Providers, And Other Businesses. We Obtain The Following Information About You From These Third-Party Services:",
        List: [
          {
            head: "",
            Listdes:
              "Information to make the ZervX Platform safer, like background check information for drivers;",
          },
          {
            Listdes:
              "Information about your participation in third-party programs that provide things like insurance coverage and financial instruments, such as insurance, payment, transaction, and fraud detection information;",
          },
          {
            Listdes:
              "Information to operationalize loyalty and promotional programs or applications, services, or features you choose to connect or link to your ZervX account, such as information about your use of such programs, applications, services, or features; and",
          },
          {
            Listdes:
              "Information about you provided by specific services, such as demographic and market segment information.",
          },
        ],
      },
      {
        header: "",
        BoldHeading: "Enterprise Programs.",
        desc: "If You Use ZervX Through Your Employer Or Other Organization That Participates In One Of Our ZervX Business Enterprise Programs, We Will Collect Information About You From Those Parties, Such As Your Name And Contact Information.",
      },
      {
        header: "",
        BoldHeading: "Concierge Service. ",
        desc: "Sometimes Another Business Or Entity May Order You A ZervX Ride. If An Organization Has Ordered A Ride For You Using Our Concierge Service, They Will Provide Us Your Contact Information And The Pickup And Drop-Off Location Of Your Ride.        ",
      },
      {
        header: "",
        BoldHeading: "Referral Programs. ",
        desc: "Friends Help Friends Use The ZervX Platform. If Someone Refers You To ZervX, We Will Collect Information About You From That Referral Including Your Name And Contact Information.",
      },
      {
        header: "",
        BoldHeading: "Other Users And Sources. ",
        desc: "Other Users Or Public Or Third-Party Sources Such As Law Enforcement, Insurers, Media, Or Pedestrians May Provide Us Information About You, For Example As Part Of An Investigation Into An Incident Or To Provide You Support.",
      },
      {
        header: "HOW WE USE YOUR INFORMATION",
        BoldHeading: "",
        desc: "",
        List: [
          {
            head: "We Use Your Personal Information To:",
            Listdes: "Provide the ZervX Platform;",
          },
          {
            Listdes: "Maintain the security and safety of the ZervX Platform;",
          },
          {
            Listdes: "and its users;",
          },
          {
            Listdes: "Build and maintain the ZervX community;",
          },
          {
            Listdes: "Provide customer support;",
          },
          {
            Listdes: "Improve the ZervX Platform; and",
          },
          {
            Listdes: "Respond to legal proceedings and obligations.",
          },
        ],
      },
      {
        header: "",
        BoldHeading: "",
        desc: "",
        List: [
          {
            head: "Providing The ZervX Platform. We Use Your Personal Information To Provide An Intuitive, Useful, Efficient, And Worthwhile Experience On Our Platform. To Do This, We Use Your Personal Information To:",
            Listdes:
              "Verify your identity and maintain your account, settings, and preferences;",
          },
          {
            Listdes: "Connect you to your rides and track their progress;",
          },
          {
            Listdes: "Calculate prices and process payments;",
          },
          {
            Listdes:
              "Allow Riders and Drivers to connect regarding their ride and to choose to share their location with others;",
          },
          {
            Listdes: "Communicate with you about your rides and experience;",
          },
          {
            Listdes: "Collect feedback regarding your experience;",
          },
          {
            Listdes:
              "Facilitate additional services and programs with third parties; and",
          },
          {
            Listdes: "Operate contests, sweepstakes, and other promotions.",
          },
        ],
      },
      {
        header: "",
        BoldHeading:
          "Maintaining The Security And Safety Of The ZervX Platform And Its Users.",
        desc: "Providing You A Secure And Safe Experience Drives Our Platform, Both On The Road And On Our Apps. To Do This, We Use Your Personal Information To:",
        List: [
          {
            head: "",
            Listdes: "Authenticate users",
          },
          {
            Listdes:
              "Verify that Drivers and their vehicles meet safety requirements;",
          },
          {
            Listdes:
              "Investigate and resolve incidents, accidents, and insurance claims;",
          },
          {
            Listdes:
              "Encourage safe driving behaviour and avoid unsafe activities;",
          },
          {
            Listdes: "Find and prevent fraud; and",
          },
          {
            Listdes:
              "Block and remove unsafe or fraudulent users from the ZervX Platform.",
          },
        ],
      },
      {
        header: "",
        BoldHeading: "Building And Maintaining The ZervX Community.",
        desc: "ZervX Works To Be A Positive Part Of The Community. We Use Your Personal Information To:        ",
        List: [
          {
            head: "",
            Listdes:
              "Communicate with you about events, promotions, elections, and campaigns; ",
          },
          {
            Listdes:
              "Personalize and provide content, experiences, communications, and advertising to promote and grow the ZervX Platform; and",
          },
          {
            Listdes:
              "Help facilitate donations you choose to make through the ZervX Platform.",
          },
        ],
      },
      {
        header: "",
        BoldHeading: "Providing Customer Support. ",
        desc: "We Work Hard To Provide The Best Experience Possible, Including Supporting You When You Need It. To Do This, We Use Your Personal Information To:        ",
        List: [
          {
            head: "",
            Listdes:
              "Investigate and assist you in resolving questions or issues you have regarding the ZervX Platform; and",
          },
          {
            Listdes: "Provide you support or respond to you.",
          },
        ],
      },
      {
        header: "",
        BoldHeading: "Improving The ZervX Platform. ",
        desc: " We Are Always Working To Improve Your Experience And Provide You With New And Helpful Features. To Do This, We Use Your Personal Information To:",

        List: [
          {
            head: "",
            Listdes: "Perform research, testing, and analysis;",
          },
          {
            Listdes:
              "Develop new products, features, partnerships, and services;",
          },
          {
            Listdes:
              "Prevent, find, and resolve software or hardware bugs and issues; and",
          },
          {
            Listdes:
              "Monitor and improve our operations and processes, including security practices, algorithms, and other modelling.",
          },
        ],
      },
      {
        header: "",
        BoldHeading: "Responding To Legal Proceedings And Requirements.",
        desc: "Sometimes The Law, Government Entities, Or Other Regulatory Bodies Impose Demands And Obligations On Us With Respect To The Services We Seek To Provide. In Such A Circumstance, We May Use Your Personal Information To Respond To Those Demands Or Obligations.",
      },
      {
        header: "HOW WE SHARE YOUR INFORMATION",
        BoldHeading: "",
        desc: "We Do Not Sell Your Personal Information. To Make The ZervX Platform Work, We May Need To Share Your Personal Information With Other Users, Third Parties, And Service Providers. This Section Explains When And Why We Share Your Information.        ",
      },
      {
        header: "A. SHARING BETWEEN ZERVX USERS RIDERS AND DRIVERS.        ",
        BoldHeading: "Rider Information Shared With Driver:",
        desc: "Upon Receiving A Ride Request, We Share With The Driver The Rider’s Pickup Location, Name, Profile Photo, Rating, Rider Statistics (Like Approximate Number Of Rides And Years As A Rider), And Information The Rider Includes In Their Rider Profile (Like Preferred Pronouns). Upon Pickup And During The Ride, We Share With The Driver The Rider’s Destination And Any Additional Stops The Rider Inputs Into The ZervX App. Once The Ride Is Finished, We Also Eventually Share The Rider’s Rating And Feedback With The Driver. (We Remove The Rider’s Identity Associated With Ratings And Feedback When We Share It With Drivers, But A Driver May Be Able To Identify The Rider That Provided The Rating Or Feedback.)",
      },
      {
        header: "",
        BoldHeading: "Driver Information Shared With Rider:",
        desc: "Upon A Driver Accepting A Requested Ride, We Will Share With The Rider The Driver’s Name, Profile Photo, Preferred Pronouns, Rating, Real-Time Location, And The Vehicle Make, Model, Color, And License Plate, As Well As Other Information In The Driver’s ZervX Profile, Such As Information Drivers Choose To Add (Like Country Flag And Why You Drive) And Driver Statistics (Like Approximate Number Of Rides And Years As A Driver).Although We Help Riders And Drivers Communicate With One Another To Arrange A Pickup, We Do Not Share Your Actual Phone Number Or Other Contact Information With Other Users. If You Report A Lost Or Found Item To Us, We Will Seek To Connect You With The Relevant Rider Or Driver, Including Sharing Actual Contact Information With Your Consent.",
      },
      {
        header: "",
        BoldHeading: "Shared Ride Riders.",
        desc: "When Riders Use A ZervX Shared Ride, We Share Each Rider’s Name And Profile Picture To Ensure Safety. Riders May Also See Each Other’s Pickup And Drop-Off Locations As Part Of Knowing The Route While Sharing The Ride.        ",
      },
      {
        header: "",
        BoldHeading: "Rides Requested Or Paid For By Others.",
        desc: "Some Rides You Take May Be Requested Or Paid For By Others. If You Take One Of Those Rides Using Your ZervX Business Profile Account, A Code Or Coupon, A Subsidized Program (E.G., Transit Or Government), Or A Corporate Credit Card Linked To Another Account, Or Another User Otherwise Requests Or Pays For A Ride For You, We May Share Some Or All Of Your Ride Details With That Other Party, Including The Date, Time, Charge, Rating Given, Region Of Trip, And Pick Up And Drop Off Location Of Your Ride.        ",
      },
      {
        header: "",
        BoldHeading: "Referral Programs. ",
        desc: "If You Refer Someone To The ZervX Platform, We Will Let Them Know That You Generated The Referral. If Another User Referred You, We May Share Information About Your Use Of The ZervX Platform With That User. For Example, A Referral Source May Receive A Bonus When You Join The ZervX Platform Or Complete A Certain Number Of Rides And Would Receive Such Information. ",
      },
      {
        header:
          "B. SHARING WITH THIRD-PARTY SERVICE PROVIDERS FOR BUSINESS PURPOSES",
        BoldHeading: "",
        desc: "Depending On Whether You’re A Rider Or A Driver, ZervX May Share The Following Categories Of Your Personal Information For A Business Purpose (As We Have Done For The Preceding 12 Months) To Provide You With A Variety Of The ZervX Platform’s Features And Services:",
        List: [
          {
            head: "",
            Listdes:
              "Personal identifiers, such as your name, address, email address, phone number, date of birth, government identification number (such as social security number), driver’s license information, vehicle information, and car insurance information;",
          },
          {
            Listdes:
              "Financial information, such as bank routing numbers, tax information, and any other payment information you provide us;",
          },
          {
            Listdes:
              "Commercial information, such as ride information, Driver/Rider statistics and feedback, and Driver/Rider transaction history;",
          },
          {
            Listdes:
              "Internet or other electronic network activity information, such as your IP address, type of browser, version of operating system, carrier and/or manufacturer, device identifiers, and mobile advertising identifiers; and",
          },
          {
            Listdes: "Location data",
          },
        ],
      },
      {
        header: "",
        BoldHeading: "",
        desc: "",
        List: [
          {
            head: "We Disclose Those Categories Of Personal Information To Service Providers To Fulfil The Following Business Purposes:",
            Listdes: "Maintaining and servicing your ZervX account;",
          },
          {
            Listdes: "Processing or fulfilling rides;",
          },
          {
            Listdes: "Providing you customer service;",
          },
          {
            Listdes: "Processing Rider transactions;",
          },
          {
            Listdes: "Processing Driver applications and payments;",
          },
          {
            Listdes: "Verifying the identity of users;",
          },
          {
            Listdes: "Detecting and preventing fraud;",
          },
          {
            Listdes: "Processing insurance claims;",
          },
          {
            Listdes: "Providing Driver loyalty and promotional programs;",
          },
          {
            Listdes: "Providing marketing and advertising services to ZervX;",
          },
          {
            Listdes: "Providing financing;",
          },
          {
            Listdes: "Providing requested emergency services;",
          },
          {
            Listdes: "Providing analytics services to ZervX; and",
          },
          {
            Listdes:
              "Undertaking internal research to develop the ZervX Platform.",
          },
        ],
      },
      {
        header: "C. FOR LEGAL REASONS AND TO PROTECT THE ZERVX PLATFORM",
        BoldHeading: "",
        desc: "We May Share Your Personal Information In Response To A Legal Obligation, Or If We Have Determined That Sharing Your Personal Information Is Reasonably Necessary Or Appropriate To:",
        List: [
          {
            head: "",
            Listdes:
              "Comply with any applicable federal, state, or local law or regulation, civil, criminal or regulatory inquiry, investigation or legal process, or enforceable governmental request;",
          },
          {
            Listdes:
              "Respond to legal process (such as a search warrant, subpoena, summons, or court order);",
          },
          {
            Listdes: "Enforce our Terms of Service;",
          },
          {
            Listdes:
              "Cooperate with law enforcement agencies concerning conduct or activity that we reasonably and in good faith believe may violate federal, state, or local law; or",
          },
          {
            Listdes:
              "Exercise or defend legal claims, protect against harm to our rights, property, interests,or safety or the rights, property, interests, or safety of you, third parties, or the public as required or permitted by law.",
          },
        ],
      },
      {
        header: "D. IN CONNECTION WITH SALE OR MERGER",
        BoldHeading: "",
        desc: "We May Share Your Personal Information While Negotiating Or In Relation To A Change Of Corporate Control Such As A Restructuring, Merger, Or Sale Of Our Assets.",
      },
      {
        header: "E. UPON YOUR FURTHER DIRECTION",
        BoldHeading: "",
        desc: "With Your Permission Or Upon Your Direction, We May Disclose Your Personal Information To Interact With A Third Party Or For Other Purposes.",
      },
      {
        header: "HOW WE STORE AND PROTECT YOUR INFORMATION",
        BoldHeading: "",
        desc: "We Retain Your Information For As Long As Necessary To Provide You And Our Other Users The ZervX Platform. This Means We Keep Your Profile Information For As Long As You Maintain An Account. We Retain Transactional Information Such As Rides And Payments For At Least Seven Years To Ensure We Can Perform Legitimate Business Functions, Such As Accounting For Tax Obligations. If You Request Account Deletion, We Will Delete Your Information As Set Forth In The “Deleting Your Account” Section Below.        We Take Reasonable And Appropriate Measures Designed To Protect Your Personal Information. But No Security Measures Can Be 100% Effective, And We Cannot Guarantee The Security Of Your Information, Including Against Unauthorized Intrusions Or Acts By Third Parties.",
      },
      {
        header: "YOUR RIGHTS AND CHOICES REGARDING YOUR DATA",
        BoldHeading: "",
        desc: "As Explained More Below And On Our Privacy Homepage, ZervX Provides Ways For You To Access And Delete Your Personal Information As Well As Exercise Other Data Rights That Give You Certain Control Over Your Personal Information.",
      },
      {
        header: "",
        BoldHeading: "Email Subscriptions.",
        desc: "You Can Always Unsubscribe From Our Commercial Or Promotional Emails By Clicking Unsubscribe In Those Messages. We Will Still Send You Transactional And Relational Emails About Your Use Of The ZervX Platform.",
      },
      {
        header: "",
        BoldHeading: "Push Notifications.",
        desc: "You Can Opt Out Of Receiving Push Notifications Through Your Device Settings. Please Note That Opting Out Of Receiving Push Notifications May Impact Your Use Of The ZervX Platform (Such As Receiving A Notification That Your Ride Has Arrived).",
      },
      {
        header: "",
        BoldHeading: "Profile Information.",
        desc: "You Can Review And Edit Certain Account Information You Have Chosen To Add To Your Profile By Logging In To Your Account Settings And Profile.        ",
      },
      {
        header: "",
        BoldHeading: "Location Information. ",
        desc: "You Can Prevent Your Device From Sharing Location Information Through Your Device’s System Settings. But If You Do, This May Impact ZervX’s Ability To Provide You Our Full Range Of Features And Services.",
      },
      {
        header: "",
        BoldHeading: "Cookie Tracking. ",
        desc: "You Can Modify Your Cookie Settings On Your Browser, But If You Delete Or Choose Not To Accept Our Cookies, You May Be Missing Out On Certain Features Of The ZervX Platform.        ",
      },
      {
        header: "",
        BoldHeading: "Do Not Track.",
        desc: "Your Browser May Offer You A “Do Not Track” Option, Which Allows You To Signal To Operators Of Websites And Web Applications And Services That You Do Not Want Them To Track Your Online Activities. The ZervX Platform Does Not Currently Support Do Not Track Requests At This Time.        ",
      },
      {
        header: "",
        BoldHeading: "Deleting Your Account. ",
        desc: "You have the option to delete your account either directly from the ZervX app or by sending us an email. Please note that before we proceed with the deletion, ZervX will ask you to verify your phone number. You may also provide a reason for the deletion, but this is optional. Once the deletion request is submitted, your account will be permanently deleted.",
        List: [
          {
            head: "To delete your account from the app:",
            Listdes: "Delete your account from the app",
          },
          {
            Listdes: "Go to Account/Profile",
          },
          {
            Listdes: "Scroll down and tap Delete Account",
          },
          {
            Listdes:
              "Follow the remaining steps in the app to complete the process",
          },
        ],
        List1: [
          {
            head: "To delete your account via email:",
            Listdes: (
              <>
                Please send an email to{" "}
                <Link href="mailto:hello@zervx.com" passHref>
                  <a>hello@zervx.com</a>
                </Link>{" "}
                  with your ZervX account details. We will then contact you and
                ask you to verify your phone number. You may also provide a
                reason for the deletion, but this is optional. Please be aware
                that once the deletion process is initiated, your account will
                be permanently deleted.
              </>
            ),
          },
        ],
      },
      {
        header: "SECURITY OF DATA",
        BoldHeading: "",
        desc: "The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security. ",
      },
      {
        header: "CHILDREN'S PRIVACY",
        BoldHeading: "",
        desc: "Our Services are not intended for use by children under the age of 13. We do not knowingly collect personally identifiable information from Children under 13. If you become aware that a Child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from Children without verification of parental consent, we take steps to remove that information from our servers. ",
      },
      {
        header: "CHANGES TO THIS PRIVACY POLICY",
        BoldHeading: "",
        desc: "We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this privacy policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page. ",
      },
      {
        header: "CONTACT US",
        BoldHeading: "",
        desc: "If you have any questions about this Privacy Policy, please contact us   ",
        link: "https://www.zervx.com/contact-us/",
      },
      {
        header: "COOKIES",
        BoldHeading: "",
        desc: "ZervX uses “Cookies” to identify the areas of our website that you have visited. A Cookie is a small piece of data stored on your computer or mobile device by your web browser. We use Cookies to personalize the Content that you see on our website. Most web browsers can be set to disable the use of Cookies. However, if you disable Cookies, you may not be able to access functionality on our website correctly or at all. ",
      },
    ],
  },
  {
    title: "TERMS AND CONDITIONS",
    target: "support-tab-3",
    class: "show active",

    listItem: [
      {
        header: "INFORMATION COLLECTION AND USE",
        desc: "By accessing and placing an order with ZervX, you confirm that you are in agreement with and bound by the terms and conditions outlined below. These terms apply to the entire website and any email or other type of communication between you and ZervX. ZervX will not be responsible for any outcome that may occur during the course of usage of our resources. We reserve the rights to change prices and revise the resources usage policy in any moment.",
      },
      {
        header: "DEFINITIONS AND KEY TERMS",
        desc: "To help explain things as clearly as possible in this Terms and Conditions, every time any of these terms are referenced, are strictly defined as ",
      },
      {
        header: "COOKIE",
        desc: "Small amount of data generated by a website and saved by your web browser. It is used to identify your browser, provide analytics, remember information about you such as your language preference or login information. ",
      },
      {
        header: "COMPANY",
        desc: "When this terms mention “Company,” “we,” “us,” or “our,” it refers to ZervX that is responsible for your information under this Terms and Condition.         ",
      },
      {
        header: "DEVICE",
        desc: "Any internet connected device such as a phone, tablet, computer or any other device that can be used to visit ZervX and use the services. ",
      },
      {
        header: "DEFINITIONS AND KEY TERMS",
        desc: "By using our site, registering an account, or making a purchase, you consent to this Terms and Condition. ",
      },
      {
        header: "CHANGES TO OUR TERMS AND CONDITIONS",
        desc: "You acknowledge and agree that ZervX may stop (permanently or temporarily) providing the Service (or any features within the Service) to you or to users generally at BerarkRays’s sole discretion, without prior notice to you. You may stop using the Service at any time. You do not need to specifically inform ZervX when you stop using the Service. You acknowledge and agree that if BerarkRays  disables access to your account, you may be prevented from accessing the Service, your account details or any files or other materials which is contained in your account.",
      },
      {
        header: "CONTACT US",
        desc: "Don’t hesitate to contact us if you have any questions.  Via Email: ",
        link: " https://www.zervx.com/contact-us/",
      },
    ],
  },
];
//Service IT
const ItServiceData = [
  {
    serviceImg: DriverManagement,
    serviceTitle: "Driver Management",
    serviceDesc:
      "Manage drivers, assign trips, and track their performance in real-time for better control and accountability.",
    className: "border-bottom border-end",
  },
  {
    serviceImg: Multilingual,
    serviceTitle: "Multilingual",
    serviceDesc:
      "With multi-language support, access our platform in preferred languages to cater to diverse customer needs.",
    className: "border-bottom border-end",
  },
  {
    serviceImg: customersupport,
    serviceTitle: "24/7 customer support",
    serviceDesc:
      "Get round the clock support from our experienced support team to resolve any issues or concerns.",
    className: "border-bottom",
  },
  {
    serviceImg: CustomizableBranding,
    serviceTitle: "Customizable Branding",
    serviceDesc:
      "Customize the platform with your own branding, logo, and color scheme for increased brand awareness.",
    className: "border-end",
  },
  {
    serviceImg: VehicleManagement,
    serviceTitle: "Vehicle Management",
    serviceDesc:
      "Manage your fleet, track your vehicles, schedule maintenance and repairs for optimal performance and safety.",
    className: "border-end",
  },
  {
    serviceImg: MultiplePaymentOptions,
    serviceTitle: "Multiple Payment Options",
    serviceDesc:
      "Offers a range of payment options including cash, cards and wallet to cater to different customer preferences.",
    className: "",
  },
];

const Service = [
  { id: 1, name: "Customer App" },
  { id: 2, name: "Driver App" },
  { id: 3, name: "Admin Panel" },
  { id: 4, name: "Web Booking" },
];
const ServicesContent = [
  {
    id: 1,
    ServicesTitle: "Streamline Your Courier Service",
    ServicesTitleContent:
      "Our customer app is the perfect solution for businesses that need a reliable and efficient courier service. With our app, your customers can easily request a pickup, track their package, and receive real-time notifications about their delivery. The app is fully customizable, so you can tailor it to your business's branding.",
    img: PassengerApp,
    idKey: "PassengerApp",
    imgalt: " ZervX passenger app interface",
  },
  {
    id: 2,
    ServicesTitle: "Empower Your Drivers",
    ServicesTitleContent:
      "Our driver app provides your drivers with everything they need to successfully manage their deliveries. Our app lets your drivers remain on top of their deliveries and optimize their routes with features like real-time navigation, delivery status updates, and improved communication channels with customers and dispatchers. The app is intended to be simple to use so that your drivers can concentrate on delivering products and giving excellent service to your customers.",
    img: Driver_App,
    idKey: "DriverApp",
    imgalt: "ZervX driver app interface",
  },
  {
    id: 3,
    ServicesTitle: "Take Command of Your Courier Service",
    ServicesTitleContent:
      "Our admin panel provides you with a comprehensive overview of your courier service operations. From managing customer and driver accounts to tracking deliveries and processing payments, our panel offers a user-friendly interface that simplifies your daily tasks. With powerful analytics and reporting tools, you can gain valuable insights into your business performance and make data-driven decisions that help you grow and scale your operations.",
    img: Admin_Panel,
    idKey: "AdminPanel",
    imgalt: "ZervX admin panel dashboard",
  },
  {
    id: 4,
    ServicesTitle: "Expand Your Reach",
    ServicesTitleContent:
      "Our web booking platform enables your customers to schedule and track their shipments from anywhere, at any time. With a simple and secure interface that integrates seamlessly with your transportation platform, your customers can easily place their orders and receive real-time updates on delivery status. Our platform also enables you to expand your customer base and increase your revenue by offering online booking to a wider audience.",
    img: Web_Booking,
    idKey: "WebBooking",
    imgalt: "ZervX web booking page",
  },
];
const faqList = [
  {
    id: 1,
    faqTitle:
      "What is your city courier software and how can it benefit my business?      ",
    faqTitleContent:
      "Our software is a comprehensive platform that enables businesses to streamline their courier operations, improve efficiency, and provide exceptional service to their customers. It offers real-time package tracking, optimized route planning, and much more.      ",
    icons: <GiBoxUnpacking className="fal mb-2" />,
  },
  {
    id: 2,
    faqTitle: "Is your software secure and reliable?      ",
    faqTitleContent:
      "Yes, our software is built on a secure and reliable platform that ensures the safety and privacy of your data and operations.",
    icons: <BiCheckShield className="fal mb-2" />,
  },
  {
    id: 3,
    faqTitle:
      "How can your software help me reduce delivery times and improve customer satisfaction?",
    faqTitleContent:
      "Our software optimizes delivery routes, helps you avoid traffic congestion and other delays, and provides real-time package tracking, enabling you to provide faster and more efficient delivery service to your customers.",
    icons: <BiTimer className="fal mb-2" />,
  },
  {
    id: 4,
    faqTitle:
      "Can your software be customized to meet the specific needs of my business?      ",
    faqTitleContent:
      "Yes, we offer customizable solutions that can be tailored to meet the unique needs of your business.",
    icons: <FaRegBuilding className="fal mb-2" />,
  },
  {
    id: 5,
    faqTitle: "What kind of customer support do you offer?      ",
    faqTitleContent:
      "We offer comprehensive customer support, including onboarding, technical assistance, and ongoing support to ensure that our customers are always up and running smoothly.      ",
    icons: <RiCustomerServiceLine className="fal mb-2" />,
  },
  {
    id: 6,
    faqTitle: "How much does your city delivery software cost?      ",
    faqTitleContent:
      "Our pricing plans are flexible and designed to meet the needs of businesses of all sizes. Contact us for more information on pricing and to request a quote tailored to your specific needs.",
    icons: <RiMoneyDollarCircleLine className="fal mb-2" />,
  },
];
const ServiceRaidHailing = [
  { id: 1, name: "Passenger App" },
  { id: 2, name: "Driver App" },
  { id: 3, name: "Admin Panel" },
  { id: 4, name: " Corporate Panel" },
  { id: 5, name: "Dispatcher Panel" },
  { id: 6, name: "Web Booking" },
];
const ServicesContentRideHailing = [
  {
    id: 1,
    ServicesTitle: "Enhance the rider experience",
    ServicesTitleContent:
      "Provide a seamless experience to your customers with our user-friendly taxi app. Our solution offers advanced features that make it easy for your customers to enjoy the comfort, convenience, and hassle-free experience every time they ride with you. Increase customer satisfaction, brand loyalty, and grow your business.",
    img: PassengerApp,
    idKey: "PassengerApp",
    imgalt: " Ride hailing platform",
  },
  {
    id: 2,
    ServicesTitle: "Empower Drivers for Better Performance Outcomes ",
    ServicesTitleContent:
      "Our software includes a driver app designed to help drivers maximize their earnings and improve their efficiency. With real-time ride notifications, instant trip acceptance, and smooth navigation, drivers can easily manage their rides and stay on track, ensuring a seamless process for both drivers and passengers.",
    img: Driver_App,
    idKey: "DriverApp",
    imgalt: "Best ride hailing software",
  },
  {
    id: 3,
    ServicesTitle: "Centralized management",
    ServicesTitleContent:
      "Our admin panel is built to provide full control and oversight over ride hailing operations allowing businesses to manage drivers, passengers, and rides all in one place. With our white label taxi software, you can streamline your operations, improve efficiency,and maximize profits.",
    img: Admin_Panel,
    idKey: "AdminPanel",
    imgalt: "On demand ride hailing app",
  },
  {
    id: 4,
    ServicesTitle: "Tailored corporate solution",
    ServicesTitleContent:
      "The corporate panel provides a tailored solution for managing corporate accounts and bookings. With customizable features like billing and invoicing options, trip approval workflows, and dedicated support channels, our platform is aimed to meet the unique needs of your corporate clients. You can easily track and analyze your corporate account performance.",
    img: Corporate,
    idKey: "CorporatePanel",
    imgalt: "Ride hailing solution",
  },
  {
    id: 5,
    ServicesTitle: "Optimized dispatching",
    ServicesTitleContent:
      "The dispatcher panel is optimized for peak effectiveness, providing lightning-fast responsiveness and a comprehensive driver database management system. With our dispatch system, you can easily create ride requests for both registered and unregistered passengers. Your dispatchers can offer anytime booking facilities to customers, making the dispatching process a breeze",
    img: Dispatcher,
    idKey: "DispatcherPanel",
    imgalt: "White label ride hailing platform",
  },
  {
    id: 6,
    ServicesTitle: "Seamless online booking",
    ServicesTitleContent:
      "Provides a convenient way for your passengers to book rides online, with a simple and user-friendly interface. Makes it easy for your riders to access your transportation services from anywhere, at any time",
    img: Web_Booking,
    idKey: "WebBooking",
    imgalt: "On demand ride hailing solution",
  },
];

const ServicesContentRideHailingForHome = [
  {
    id: 1,
    ServicesTitle: "Making Rides Easy for Customers",
    ServicesTitleContent:
      "Our white-labeled passenger app makes it easy for customers to book rides, track vehicles, and manage payments, helping your brand stand out and attract new customers. It includes all the  advanced features to make their ride smooth, and with automated taxi dispatch, your business can grow even further.",
    subList: [
      {
        subDesc: "Simplified ride requests with just a few taps.",
      },
      {
        subDesc: "Multiple payment methods for a seamless transaction.",
      },
      {
        subDesc:
          "Passengers can see their driver’s location and estimated arrival time.",
      },
    ],
    img: PassengerApp,
    idKey: "PassengerApp",
    imgalt: "Taxi dispatch solution",
  },
  {
    id: 2,
    ServicesTitle: "Helping Drivers Do Their Best",
    ServicesTitleContent:
      "The driver app equips drivers with essential tools to manage their rides efficiently and effectively. It includes premier features that set industry standards, along with a flexible rewards and incentives program to encourage drivers and enhance the quality of your taxi service.",
    subList: [
      {
        subDesc:
          "Integrated maps help drivers find the quickest routes, minimizing delays.",
      },
      {
        subDesc:
          "Drivers can easily track their earnings and view payment history.",
      },
      {
        subDesc:
          "Allow drivers to choose their own hours, making it easier for them to manage their time.",
      },
    ],
    img: Driver_App,
    idKey: "DriverApp",
    imgalt: "Taxi management software",
  },
  {
    id: 3,
    ServicesTitle: "Easy Management for Operators",
    ServicesTitleContent:
      "Admin panel simplifies the management of your taxi operations. You can control everything from a single panel with many functionalities, allowing you to manage tasks directly and access data to improve your services. The main feature is that it automates the entire taxi dispatch process.",
    subList: [
      {
        subDesc:
          "Access detailed reports to identify trends and improve operational strategies.",
      },
      {
        subDesc:
          "Manage drivers, vehicles, and ride requests from a single dashboard, simplifying oversight.",
      },
      {
        subDesc:
          "Easily manage user access and permissions to maintain security and efficiency.",
      },
    ],
    img: Admin_Panel,
    idKey: "AdminPanel",
    imgalt: "On demand taxi dispatch software",
  },
  {
    id: 4,
    ServicesTitle: "Tailored Solutions for Corporate Clients",
    ServicesTitleContent:
      "The Corporate Panel is designed for businesses that need to manage employee transportation efficiently. The dashboard allows you to manage corporate transportation without much effort.",
    subList: [
      {
        subDesc:
          "Keep track of transportation expenses for budgeting and reporting.",
      },
      {
        subDesc:
          "Employees can quickly request rides tailored to their corporate needs.",
      },
      {
        subDesc:
          "Easily create and update employee profiles, including trip history and preferences.",
      },
    ],
    img: Corporate,
    idKey: "CorporatePanel",
    imgalt: "Automated taxi dispatch software",
  },
  {
    id: 5,
    ServicesTitle: "Efficient Dispatch Management",
    ServicesTitleContent:
      "The Dispatcher Panel puts you in control. Manually dispatch rides, monitor your fleet in real-time, and stay connected with drivers. Assign rides, communicate seamlessly, and access performance insights to optimize your operations.",
    subList: [
      {
        subDesc:
          "Access reports on driver performance, ride completion rates, and other key metrics to improve operations.",
      },
      {
        subDesc:
          "Receive alerts for any issues, such as delays or driver unavailability, to take immediate action.",
      },
      {
        subDesc:
          "Quickly assign rides to drivers based on their availability and proximity to the pick-up location.",
      },
    ],
    img: Dispatcher,
    idKey: "DispatcherPanel",
    imgalt: "Taxi software",
  },
  {
    id: 6,
    ServicesTitle: "Book Rides Instantly Without an App",
    ServicesTitleContent:
      "Allows your passengers to book rides directly from their browsers, eliminating the need for an app. With a user-friendly interface, passengers can request rides, schedule them in advance, and make payments easily all without leaving their web browser.",
    subList: [
      {
        subDesc: "Simple design makes it easy for passengers to navigate.",
      },
      {
        subDesc:
          "Provides a convenient alternative to mobile app-based booking.",
      },
      {
        subDesc: "Simplifies the ride booking experience for passengers.",
      },
    ],
    img: Web_Booking,
    idKey: "WebBooking",
    imgalt: "Taxi dispatch",
  },
];
const ServicesContentRideSharing = [
  {
    id: 1,
    ServicesTitle: "Improve the rider experience",
    ServicesTitleContent:
      "Provide a seamless experience to your customers with user-friendly interface and advanced carpool app to make your customers enjoy the comfort, convenience and hassle-free ride experience every time. Increase customer happiness, brand loyalty, and business growth.      ",
    img: PassengerApp,
    idKey: "PassengerApp",
    imgalt: "ZervX carpooling app",
  },
  {
    id: 2,
    ServicesTitle: "Empower drivers for better business outcomes    ",
    ServicesTitleContent:
      "The driver app is intended to assist drivers in increasing their earnings and improving their efficiency. Drivers may effortlessly manage their rides and keep on track with features such as real-time ride alerts, fast trip acceptance, and seamless navigation.",
    img: Driver_App,
    idKey: "DriverApp",
    imgalt: "ZervX ride sharing app",
  },
  {
    id: 3,
    ServicesTitle: "Management centralized",
    ServicesTitleContent:
      "Our admin panel is designed to provide businesses with complete control and supervision over their ride share operations, allowing them to manage drivers, passengers, and trips all in a single dashboard. Assists you in streamlining business processes, increasing efficiency, and maximizing revenues.",
    img: Admin_Panel,
    idKey: "AdminPanel",
    imgalt: "Zervx ride sharing software",
  },
  {
    id: 4,
    ServicesTitle: "Tailored corporate solution",
    ServicesTitleContent:
      "The corporate panel offers a customized solution for handling corporate accounts and reservations. Our platform is designed to address the specific demands of your business clients, with features such as configurable billing and invoicing choices, trip approval protocols, and specialized support channels. You can simply track and assess the performance of the corporate accounts.",
    img: Corporate,
    idKey: "CorporatePanel",
    imgalt: "ZervX corporate panel",
  },
  {
    id: 5,
    ServicesTitle: "Optimized dispatching",
    ServicesTitleContent:
      "The dispatcher panel is designed to be as efficient as possible, with super-fast response times and a driver database management system. Can quickly make ride requests for both registered and unregistered passengers and your dispatchers are able to provide clients with anytime booking options, simplifying the dispatching process.",
    img: Dispatcher,
    idKey: "DispatcherPanel",
    imgalt: "ZervX dispatcher panel ",
  },
  {
    id: 6,
    ServicesTitle: "Convenient online booking    ",
    ServicesTitleContent:
      "Provides a simple, user-friendly interface that makes it easy for your customers to schedule rides online. Enables customers to easily use your ride-sharing services at any time and from anywhere. ",
    img: Web_Booking,
    idKey: "WebBooking",
    imgalt: "ZervX web booking for rideshare  ",
  },
];
const faqListRideHailing = [
  {
    id: 1,
    faqTitle: "How does your ride-hailing software help my taxi business?",
    faqTitleContent:
      "Our ride-hailing software connects your taxi business with more customers through a user-friendly app, increasing ride requests. It optimizes driver dispatching to ensure that the closest available driver reaches the customer quickly. All these features work together to improve your service quality and boost your revenue, making your business more efficient and profitable.",
    icons: <FaTaxi className="fal mb-2" />,
  },
  {
    id: 2,
    faqTitle:
      "What sets your ride hailing software apart from a typical Uber clone?",
    faqTitleContent:
      "While uber clones mimic the basic functionality, our system goes beyond imitation. We offer scalability, tailor-made customization, and a comprehensive suite of features that allow your business to stand out in a competitive market. Our mobility platform is designed for your unique needs, not just a cookie-cutter solution.",
    icons: <FaRocket className="fal mb-2" />,
  },
  {
    id: 3,
    faqTitle: "Can I customize the app to fit my brand?",
    faqTitleContent:
      "Yes, our software is highly customizable. You can incorporate your logo, colors, and other elements to make the app uniquely yours, ensuring it represents your business identity. This flexibility helps create a consistent and professional look for your customers.",
    icons: <FaPaintBrush className="fal mb-2" />,
  },
  {
    id: 4,
    faqTitle: "Does your ride hailing solution include a passenger app?",
    faqTitleContent:
      "Yes, our solution includes a passenger app where customers can request rides, track their drivers in real-time, and make payments easily. It provides a convenient and user-friendly way for passengers to book and manage their rides anytime, anywhere.",
    icons: <FaMobileAlt className="fal text-primary mb-2" />,
  },
  {
    id: 5,
    faqTitle: "Is there a way to track my fleet in real-time?",
    faqTitleContent:
      "Absolutely! Our software allows you to track your fleet in real-time using GPS. You can monitor each vehicle's location, manage rides, and respond quickly to your customers' needs, ensuring better service and smoother operations.",
    icons: <FaMapMarkedAlt className="fal mb-2" />,
  },
  {
    id: 6,
    faqTitle: "What kind of support is available for clients?",
    faqTitleContent:
      "We offer technical support and customer service via phone, email, and chat. Our team is available to help with any issues or questions you have throughout your journey, guaranteeing you receive the assistance you need.",
    icons: <FaHeadset className="fal mb-2" />,
  },
];
const faqListRideSharing = [
  {
    id: 1,
    faqTitle: "What features does your carpooling software offer?    ",
    faqTitleContent:
      "ZervX offers features such as ride booking, driver management, multiple payment options, real-time tracking and so on.      ",
    icons: <SiSlideshare className="fal mb-2" />,
  },
  {
    id: 2,
    faqTitle:
      "What types of transportation service providers can benefit from your software?      ",
    faqTitleContent:
      "Any transportation service provider that offers carpool services, including taxi companies, limousine services, and car rental companies can benefit from our sharing software.",
    icons: <MdEmojiTransportation className="fal mb-2" />,
  },
  {
    id: 3,
    faqTitle: "Is ride share software customizable to my business needs?    ",
    faqTitleContent:
      "Yes, ride sharing software can be customized to meet the specific needs of your business. We work closely with each of our clients to tailor the software to their unique requirements.      ",
    icons: <MdScreenShare className="fal mb-2" />,
  },
  {
    id: 4,
    faqTitle:
      "How secure is the payment processing feature of your software?    ",
    faqTitleContent:
      "The payment processing feature of our software is fully secure, using industry-standard encryption and tokenization to protect sensitive payment information.      ",
    icons: <MdPayment className="fal mb-2" />,
  },
  {
    id: 5,
    faqTitle: "How long does it take to implement your software?      ",
    faqTitleContent:
      "Implementation timelines can vary depending on the specific needs of your business, but we work closely with each of our clients to ensure a smooth and timely implementation process.      ",
    icons: <RiMapPinTimeLine className="fal mb-2" />,
  },
  {
    id: 6,
    faqTitle: "How much does the ride sharing software cost?",
    faqTitleContent:
      "Pricing for ride sharing software varies depending on the specific needs of your business. Contact us today to learn more about our pricing options and to request a customized quote.      ",
    icons: <RiMoneyDollarCircleLine className="fal mb-2" />,
  },
];
const ServicesContentFieldService = [
  {
    id: 1,
    ServicesTitle: "Make Your Field Service Operations More Efficient",
    ServicesTitleContent:
      "Our customer app is specifically tailored to meet the needs of field service businesses, providing a seamless and efficient experience for your customers. With our app, you can effortlessly connect your customers to your services, resulting in improved customer satisfaction and increased operational efficiency.    ",
    img: PassengerApp,
    idKey: "PassengerApp",
    imgalt: "ZervX passenger app interface",
  },
  {
    id: 2,
    ServicesTitle: "Simplify Management with Our Admin Panel",
    ServicesTitleContent:
      "Take control of your field service operations with our comprehensive admin panel. The admin panel provides you with a centralized hub to manage and monitor your entire business ecosystem. From overseeing service requests, assigning tasks, tracking performance, to generating reports, our admin panel streamlines your managerial tasks, allowing you to make data-driven decisions, optimize resource allocation, and ensure a smooth workflow across your organization.    ",
    img: Admin_Panel,
    idKey: "AdminPanel",
    imgalt: "ZervX admin panel dashboard",
  },
  {
    id: 3,
    ServicesTitle: "Book Smarter With Web Booking    ",
    ServicesTitleContent:
      "Effortlessly streamline your booking process with our web booking platform designed for field service businesses. The user-friendly and intuitive web booking system allows your customers to conveniently schedule and manage service requests online. By providing a seamless and accessible booking experience, you can attract and retain more customers, while saving time and resources on manual booking processes.",
    img: Web_Booking,
    idKey: "WebBooking",
    imgalt: "ZervX web booking page",
  },
];
const ServicesContentCorporateTransportation = [
  {
    id: 1,
    ServicesTitle: "Seamless commutes for your employees",
    ServicesTitleContent:
      "Our employee app is designed to streamline the travel experience of your workforce. With user-friendly features and intuitive interface, employees can effortlessly schedule rides, track their journey, receive updates on arrival times, and manage their payments all in one place.",
    img: PassengerApp,
    idKey: "PassengerApp",
    imgalt: "Employee transport management system",
  },
  {
    id: 2,
    ServicesTitle: "Effortless navigation, exceptional service",
    ServicesTitleContent:
      "Equip your drivers with the tools they need to deliver impeccable service. Our app simplifies  route planning, provides real-time updates on pick-ups and drop-offs, and enables seamless communication with employees and administrators.",
    img: Driver_App,
    idKey: "DriverApp",
    imgalt: "Employee transport app solution",
  },
  {
    id: 3,
    ServicesTitle: "Take control of your employee transportation management",
    ServicesTitleContent:
      "The admin panel provides you with all the tools you need to manage your corporate transportation operations effectively. With customizable settings and intuitive interface, you can manage your entire fleet, monitor performance metrics, and generate reports all in one place resulting in optimal efficiency and cost effectiveness.",
    img: Admin_Panel,
    idKey: "AdminPanel",
    imgalt: "Employee transportation software",
  },
  {
    id: 4,
    ServicesTitle: "Easy online booking for your employees",
    ServicesTitleContent:
      "Simplify the booking process with our user-friendly web booking platform. Enable employees to schedule rides, track expenses, and manage preferences effortlessly from any device with internet access.",
    img: Web_Booking,
    idKey: "WebBooking",
    imgalt: "Employee transportation booking system",
  },
];
const ServicesContentAirport_VIPShuttle = [
  {
    id: 1,
    ServicesTitle: "Enhance Your Passengers' Experience    ",
    ServicesTitleContent:
      "Allow your customers to easily book and track their rides with our intuitive Passenger app. They can view real-time driver location, pay for the ride, and receive updates on their trip status, making for a seamless experience. Improve your customer satisfaction ratings and build loyalty among your passengers.    ",
    img: PassengerApp,
    idKey: "PassengerApp",
    imgalt: "ZervX passenger app interface",
  },
  {
    id: 2,
    ServicesTitle: "Empower Your Drivers    ",
    ServicesTitleContent:
      "Makes it easy for your shuttle drivers to manage their trips, communicate with passengers, and navigate to their destinations. The app also features trip history and payment records, simplifying the administrative side of the business. Help you ensure that your drivers are providing top-notch service to your passengers.",
    img: Driver_App,
    idKey: "DriverApp",
    imgalt: "ZervX driver app interface",
  },
  {
    id: 3,
    ServicesTitle: "Take Control of Your Services",
    ServicesTitleContent:
      "The admin panel is the heart of our transportation platform, providing you with the tools you need to manage your entire operation from a single dashboard. Manage all aspects of your airport and VIP shuttle service. You can monitor user accounts with ease, saving you time and streamlining your operations.    ",
    img: Admin_Panel,
    idKey: "AdminPanel",
    imgalt: "ZervX admin panel dashboard",
  },
  {
    id: 4,
    ServicesTitle: "A Customized Solution for Your Corporate Clients    ",
    ServicesTitleContent:
      "The corporate panel provides a tailored solution for managing corporate accounts and bookings. With customizable features like billing and invoicing options, trip approval workflows, and dedicated support channels, our platform is aimed to meet the unique needs of your corporate clients. You can easily track and analyze your corporate account performance.",
    img: Corporate,
    idKey: "CorporatePanel",
    imgalt: "ZervX corporate panel dashboard",
  },
  {
    id: 5,
    ServicesTitle: "Streamline Your Dispatching Process    ",
    ServicesTitleContent:
      "Designed to help you manage your shuttle service more efficiently and effectively. With features like real-time ride tracking and automatic ride assignment, our dispatcher panel can help you ensure that your drivers are always where they need to be, and your passengers are always on time.",
    img: Dispatcher,
    idKey: "DispatcherPanel",
    imgalt: "ZervX dispatcher panel dashboard",
  },
  {
    id: 6,
    ServicesTitle: "Reach More Customers",
    ServicesTitleContent:
      "Our web booking feature allows your customers to book a ride through your website with ease, increasing your reach and visibility online. With seamless integration with your transportation platform and secure payment processing, our web booking feature can help you capture more bookings and grow your business.",
    img: Web_Booking,
    idKey: "WebBooking",
    imgalt: "ZervX web booking page",
  },
];
const faqListAirport_VIPShuttle = [
  {
    id: 1,
    faqTitle: "How does an airport and VIP shuttle service software work?    ",
    faqTitleContent:
      "Our software includes a range of features and tools that help you manage your shuttle operations more efficiently, such as passenger and driver apps, admin and dispatcher panels, and web booking integrations. These tools are designed to work together seamlessly, providing you with a complete transportation platform.",

    icons: <MdOutlineLocalAirport className="fal mb-2" />,
  },
  {
    id: 2,
    faqTitle:
      "How secure is the payment processing feature of ride sharing software?    ",
    faqTitleContent:
      "Any transportation service provider that offers airport and VIP shuttle services can benefit from using our software, including private car services, limousine companies, and corporate transportation providers.    ",
    icons: <MdPayment className="fal mb-2" />,
  },
  {
    id: 3,
    faqTitle:
      "How can an airport and VIP shuttle service software help me save time and money? ",
    faqTitleContent:
      "Our software automates many of the manual tasks involved in managing shuttle operations, such as ride scheduling, payment processing, and driver assignment. This can help you save time and reduce overhead costs.    ",
    icons: <GiSandsOfTime className="fal mb-2" />,
  },
  {
    id: 4,
    faqTitle: "Is the software customizable to my business needs?    ",
    faqTitleContent:
      "Yes, our software is customizable to meet the specific needs of your business. We work closely with each of our clients to tailor the software to their unique requirements.",

    icons: <HiOutlineClipboardCheck className="fal mb-2" />,
  },
  {
    id: 5,
    faqTitle: "What type of support is available for the software?    ",
    faqTitleContent:
      "We offer comprehensive technical support for all of our clients, as well as ongoing updates and improvements to the software.    ",

    icons: <HiOutlineClipboardList className="fal mb-2" />,
  },
  {
    id: 6,
    faqTitle: "Can the software help me expand my business?    ",
    faqTitleContent:
      "Yes, our software is designed to help you grow your business by increasing efficiency, improving customer satisfaction, and reaching more customers through booking channels.    ",
    icons: <FaCodeBranch className="fal mb-2" />,
  },
];
const ServicePackageDelivery = [
  { id: 1, name: "Customer App" },
  { id: 2, name: "Driver App" },
  { id: 3, name: "Admin Panel" },
  { id: 4, name: "Web Booking" },
];
const ServicesContentPackageDelivery = [
  {
    id: 1,
    ServicesTitle: "Keep Your Customers Satisfied",
    ServicesTitleContent:
      "Our customer app makes it simple for your clients to schedule and track their package deliveries. With real-time updates and notifications, they can stay informed about the status of their shipments and receive delivery confirmations. Our intuitive interface is easy to use, so your customers can quickly and easily book, modify, or cancel their deliveries as needed.",
    img: PassengerApp,
    idKey: "PassengerApp",
    imgalt: "ZervX passenger app interface",
  },
  {
    id: 2,
    ServicesTitle: "Optimize Your Delivery Operations",
    ServicesTitleContent:
      "The driver app is intended to assist your drivers in staying on schedule and on time. Our app helps your drivers maximize their productivity and execute deliveries fast and precisely by providing detailed delivery instructions, real-time route optimization, and GPS monitoring. Furthermore, our straightforward interface allows your drivers to manage their schedules and communicate with your team.",
    img: Driver_App,
    idKey: "DriverApp",
    imgalt: "ZervX driver app interface",
  },
  {
    id: 3,
    ServicesTitle: "Simplify Your Package Delivery Management",
    ServicesTitleContent:
      "Our admin panel provides a centralized platform for managing all aspects of your package delivery service. From tracking shipments and managing drivers to generating reports and tracking performance metrics, our platform makes it easy to stay on top of your operations. With customizable settings and role-based access, you can tailor our platform to meet your specific needs and optimize your workflow.",
    img: Admin_Panel,
    idKey: "AdminPanel",
    imgalt: "ZervX admin panel dashboard",
  },
  {
    id: 4,
    ServicesTitle: "Seamlessly Book and Manage Your Package Deliveries Online",
    ServicesTitleContent:
      "Our web booking platform makes it simple for your customers to schedule and manage their package deliveries online. With a user-friendly interface and real-time updates, your customers can quickly and easily book, modify, or cancel their deliveries at any time, and our platform integrates seamlessly with your existing systems, so you can streamline your operations and provide exceptional service to your customers.",
    img: Web_Booking,
    idKey: "WebBooking",
    imgalt: "ZervX web booking page",
  },
];
const faqListPackageDelivery = [
  {
    id: 1,
    faqTitle: "What is package delivery software?",
    faqTitleContent:
      "Package delivery software is a tool that helps businesses manage their package delivery operations by automating tasks such as order processing, dispatching, and tracking.   ",
    icons: <TbPackage className="fal mb-2" />,
  },
  {
    id: 2,
    faqTitle:
      "What are the benefits of using your package delivery software?   ",
    faqTitleContent:
      "The benefits of using our package delivery software include improved efficiency, faster delivery times, increased accuracy, and better customer service.    ",
    icons: <SiFastapi className="fal mb-2" />,
  },
  {
    id: 3,
    faqTitle: "Can I customize the software to meet my business needs?    ",
    faqTitleContent:
      "Yes, our package delivery software is fully customizable to meet the unique needs of your business.",
    icons: <BiCustomize className="fal mb-2" />,
  },
  {
    id: 4,
    faqTitle:
      "What type of businesses can benefit from using your package delivery software?   ",
    faqTitleContent:
      "Any business that deals with package delivery can benefit from using package delivery software, including e-commerce businesses, retail stores, and logistics companies. ",
    icons: <MdOutlineBento className="fal mb-2" />,
  },
  {
    id: 5,
    faqTitle: "Does the software offer real-time tracking?  ",
    faqTitleContent:
      "Yes, our package delivery software offers real-time tracking so you and your customers can track packages in real-time.   ",
    icons: <TbLiveView className="fal mb-2" />,
  },
  {
    id: 6,
    faqTitle: "What kind of support do you offer?    ",
    faqTitleContent:
      "We offer comprehensive support to help you get started with our package delivery software and technical support to ensure that it runs smoothly. Our support team is available 24/7 to answer any questions or concerns you may have.",
    icons: <MdOutlineContactSupport className="fal mb-2" />,
  },
];
const ServicesContentDeliveryOperators = [
  {
    id: 1,
    ServicesTitle: "Hassle-Free Deliveries",
    ServicesTitleContent:
      "Designed to provide your customers with a convenient and easy way to order and track their deliveries. With a user-friendly interface, your customers can browse and order their desired products with just a few clicks. Our customer app also features real-time order tracking, so your customers can see the exact location of their package and estimated delivery time.      ",
    img: PassengerApp,
    idKey: "PassengerApp",
    imgalt: "ZervX passenger app interface",
  },
  {
    id: 2,
    ServicesTitle: "Streamlined Delivery Operations",
    ServicesTitleContent:
      "The perfect tool for managing and optimizing your delivery operations. With a simple interface, your drivers can easily accept, manage, and complete delivery requests, ensuring speedy and efficient deliveries. Our driver app also features real-time navigation and tracking, so your drivers can find the quickest and most efficient routes to their destinations and allows your drivers to manage their work schedules and view their earnings, providing them with the flexibility and control they need.",
    img: Driver_App,
    idKey: "DriverApp",
    imgalt: "ZervX driver app interface",
  },
  {
    id: 3,
    ServicesTitle: "Comprehensive and Customizable Management    ",
    ServicesTitleContent:
      "Powerful tool that allows you to manage and customize your delivery operations according to your business needs. With advanced features like real-time tracking, order management, and analytics, you can monitor and optimize your delivery operations in real-time. Our panel also allows you to manage your fleet of drivers, assign tasks, and track their performance. You can also customize your app settings and branding, giving your customers a unique and personalized experience.",
    img: Admin_Panel,
    idKey: "AdminPanel",
    imgalt: "ZervX admin panel dashboard",
  },
  {
    id: 4,
    ServicesTitle: "Seamless Order Placements",
    ServicesTitleContent:
      "Our web booking is designed to provide your customers with a seamless and convenient way to place their orders. With a simple and user-friendly interface, your customers can easily browse your products and place their orders online. Allow your customers to track their orders in real-time and communicate with your drivers, ensuring timely and accurate deliveries.",
    img: Web_Booking,
    idKey: "WebBooking",
    imgalt: "ZervX web booking page",
  },
];
const faqListDeliveryOperators = [
  {
    id: 1,
    faqTitle: "How can your delivery management software help my business?    ",
    faqTitleContent:
      "Our delivery management software can help your business streamline your delivery process, improve delivery accuracy, reduce costs, and increase customer satisfaction.      ",
    icons: <TbTruckDelivery className="fal mb-2" />,
  },
  {
    id: 2,
    faqTitle: "What features does your delivery management software include?  ",
    faqTitleContent:
      "Our software includes features such as real-time tracking, route optimization, delivery confirmation, analytics and reporting, integration and so on.      ",
    icons: <MdOutlineFeaturedPlayList className="fal mb-2" />,
  },
  {
    id: 3,
    faqTitle:
      "Can your software be customized to fit my business's unique needs?    ",
    faqTitleContent:
      "Yes, our software can be customized to fit your business's specific needs, including adding custom fields, workflows, and integrations.     ",
    icons: <AiOutlineDeploymentUnit className="fal mb-2" />,
  },
  {
    id: 4,
    faqTitle: "How quickly can I get up and running with your software?     ",
    faqTitleContent:
      "      Our software is designed to be easy to set up and use, and our team supports you to ensure a smooth transition. Depending on your needs, you can typically start using the software within a few days or weeks.      ",
    icons: <MdOutlineQuickreply className="fal mb-2" />,
  },
  {
    id: 5,
    faqTitle: "What kind of customer support do you offer?    ",
    faqTitleContent:
      "We offer comprehensive customer support, including onboarding, technical assistance, and ongoing support to ensure that our customers are always up and running smoothly.   ",
    icons: <BiSupport className="fal mb-2" />,
  },
  {
    id: 6,
    faqTitle: "How much does your city delivery software cost?   ",
    faqTitleContent:
      "Our pricing plans are flexible and designed to meet the needs of businesses of all sizes. Contact us for more information on pricing and to request a quote tailored to your specific needs. ",
    icons: <RiMoneyDollarCircleLine className="fal mb-2" />,
  },
];
const ServicesContentThirdPartyLogistics = [
  {
    id: 1,
    ServicesTitle: "Effortless Order Management",
    ServicesTitleContent:
      "Our customer app offers a simple and intuitive interface, allowing your customers to easily place orders and track their shipments in real-time. With features like in-app messaging and secure payment options, your customers will feel confident and secure throughout the entire logistics process. And because our app is fully customizable, you can tailor it to your specific needs and branding.",
    img: PassengerApp,
    idKey: "PassengerApp",
    imgalt: "ZervX passenger app interface",
  },
  {
    id: 2,
    ServicesTitle: "Stay on Top of Deliveries",
    ServicesTitleContent:
      "The driver app is designed to simplify the delivery process, making it easy for your drivers to manage their routes, track shipments, and communicate with customers. With features like GPS tracking and signature confirmation, your drivers will have all the tools they need to deliver shipments safely and efficiently.    ",
    img: Driver_App,
    idKey: "DriverApp",
    imgalt: "ZervX driver app interface",
  },
  {
    id: 3,
    ServicesTitle: "Centralized Logistics Management",
    ServicesTitleContent:
      "The admin panel provides a comprehensive platform for managing your logistics services, gives you complete control over your operations, allowing you to manage everything from order processing to driver assignments. The panel also provides an option for managing drivers and assigning orders, ensuring that you can efficiently manage your logistics team and optimize your delivery processes.",
    img: Admin_Panel,
    idKey: "AdminPanel",
    imgalt: "ZervX admin panel dashboard",
  },
  {
    id: 4,
    ServicesTitle: "Streamlined Dispatching",
    ServicesTitleContent:
      "Our dispatcher panel makes the dispatching process easy, allowing you to manage and assign orders to drivers seamlessly. The panel provides real-time updates on order status, driver location, estimated delivery times and more. With the dispatcher, you can optimize routes, track drivers, shipments and manage deliveries effectively.",
    img: Web_Booking,
    idKey: "WebBooking",
    imgalt: "ZervX web booking page",
  },
];
const faqListThirdPartyLogistics = [
  {
    id: 1,
    faqTitle:
      "What are the benefits of using your third party s service software?    ",
    faqTitleContent:
      "Some benefits of using our third-party logistics service software include streamlining logistics operations, improving supply chain visibility, reducing costs, increasing efficiency, and improving customer satisfaction. ",
    icons: <RiAnchorLine className="fal mb-2" />,
  },
  {
    id: 2,
    faqTitle:
      "Can we track our shipments in real-time with your third-party logistics service software?",
    faqTitleContent:
      "Our software includes features such as real-time tracking, route optimization, delivery confirmation, analytics and reporting, integration and so on.      ",
    icons: <IoGitBranchOutline className="fal mb-2" />,
  },
  {
    id: 3,
    faqTitle:
      "Can your software be customized to fit my business's unique needs?      ",
    faqTitleContent:
      "     Yes, our software can be customized to fit your business's specific needs, including adding custom fields, workflows, and integrations.",
    icons: <MdOutlineHomeRepairService className="fal mb-2" />,
  },
  {
    id: 4,
    faqTitle: "How quickly can I get up and running with your software?    ",
    faqTitleContent:
      "Our software is designed to be easy to set up and use, and our team supports you to ensure a smooth transition. Depending on your needs, you can typically start using the software within a few days or weeks.",
    icons: <BsStars className="fal mb-2" />,
  },
  {
    id: 5,
    faqTitle: "What kind of customer support do you offer?    ",
    faqTitleContent:
      "We offer comprehensive customer support, including onboarding, technical assistance, and ongoing support to ensure that our customers are always up and running smoothly.",
    icons: <MdOutlineLocalOffer className="fal mb-2" />,
  },
  {
    id: 6,
    faqTitle: "How much does your third party logistics software cost?",
    faqTitleContent:
      "Our pricing plans are flexible and designed to meet the needs of businesses of all sizes. Contact us for more information on pricing and to request a quote tailored to your specific needs.",
    icons: <RiMoneyDollarCircleLine className="fal mb-2" />,
  },
];
const CorporateTransportationServicesList = [
  { id: 1, name: "Employee App" },
  { id: 2, name: "Driver App" },
  { id: 3, name: "Admin Panel" },
  // { id: 4, name: "Dispatcher Panel" },
  { id: 4, name: "Web Booking" },
];
const ServicesChauffeurList = [
  {
    id: 1,
    ServicesTitle: "Seamless Transportation Experience    ",
    ServicesTitleContent:
      "Our passenger app offers a simple and user-friendly interface, allowing your customers to easily book and manage their rides. With advanced features like real-time tracking, in-app messaging, and secure payment options, your customers will enjoy a seamless transportation experience from start to finish.   ",
    img: PassengerApp,
    idKey: "PassengerApp",
    imgalt: "ZervX passenger app interface",
  },
  {
    id: 2,
    ServicesTitle: "Empowering Your Drivers for Success",
    ServicesTitleContent:
      "Our driver app is designed to help your drivers be successful on the road. With features like real-time trip notifications, navigation tools, and built-in communication options, your drivers will be able to provide the best possible service to your customers and offer tools to help drivers manage their schedules, earnings, and performance metrics, making it easy for them to stay on top of their business.",
    img: Driver_App,
    idKey: "DriverApp",
    imgalt: "ZervX driver app interface",
  },
  {
    id: 3,
    ServicesTitle: "Manage Your Business with Ease",
    ServicesTitleContent:
      "The admin panel offers a comprehensive suite of tools to help you manage your chauffeur service business with ease. From trip management and driver management to financial reporting and customer analytics, our platform provides you with everything you need to stay on top of your business and makes it easy to navigate and access the information you need, when you need it.    ",
    img: Admin_Panel,
    idKey: "AdminPanel",
    imgalt: "ZervX admin panel dashboard",
  },
  {
    id: 4,
    ServicesTitle: "Streamline Your Operations",
    ServicesTitleContent:
      "Our dispatcher panel helps you streamline your operations and manage your fleet effectively. With features like real-time driver tracking, trip assignment workflows, and built-in communication options, you can easily manage your entire fleet from a single dashboard. With advanced reporting and analytics features, you can gain insights into your business performance and optimize your operations for success.    ",
    img: Dispatcher,
    idKey: "DispatcherPanel",
    imgalt: "ZervX dispatcher panel dashboard",
  },
  {
    id: 5,
    ServicesTitle: "Seamless Booking from Anywhere, Anytime    ",
    ServicesTitleContent:
      "Web booking offers a convenient and secure way for your customers to book and manage their rides from anywhere, anytime. With a simple and intuitive interface, customers can easily enter their pickup and drop-off locations, select their vehicle type, and track their ride in real-time and offer a range of payment options to ensure a seamless and secure booking experience.    ",
    img: Web_Booking,
    idKey: "WebBooking",
    imgalt: "ZervX web booking page",
  },
];
const faqListCorporateTransportation = [
  {
    id: 1,
    faqTitle: "Can your employee transport management system be customized?",
    faqTitleContent:
      "Yes, our software can be customized to meet the unique needs of your business. We offer customizable features and integrations.",

    icons: <BiCustomize className="fal mb-2" />,
  },
  {
    id: 2,
    faqTitle: "How quickly can I get up and running with your software?",
    faqTitleContent:
      "Our employee transportation software is designed to be easy to set up and use, and our team supports you to ensure a smooth transition. Depending on your needs, you can typically start using the software within a few days or weeks.",
    icons: <MdOutlineQuickreply className="fal mb-2" />,
  },
  {
    id: 3,
    faqTitle: "Is your software suitable for businesses of all sizes?    ",
    faqTitleContent:
      "Yes, our employee transport management solution is suitable for businesses of all sizes, from small to large corporate transport businesses.",
    icons: <MdDeviceHub className="fal mb-2" />,
  },
  {
    id: 4,
    faqTitle: "What kind of support do you offer?",
    faqTitleContent:
      "We offer comprehensive customer support, including onboarding, technical assistance, and ongoing support to ensure that our clients are always up and running smoothly.",

    icons: <MdSupportAgent className="fal mb-2" />,
  },
  {
    id: 5,
    faqTitle: "How much does your employee transport management solution cost?",
    faqTitleContent:
      "Our pricing plans are flexible and designed to meet the needs of businesses of all sizes. Contact us for more information on pricing and to request a quote tailored to your specific needs.",

    icons: <RiMoneyDollarCircleLine className="fal mb-2" />,
  },
  {
    id: 6,
    faqTitle: "Is your employee transportation software secure?",
    faqTitleContent:
      "Yes, our employee transport management system is designed with security in mind. It uses advanced encryption protocols to protect sensitive data and prevent unauthorized access.",
    icons: <HiOutlineShieldCheck className="fal mb-2" />,
  },
];
const faqListFieldServices = [
  {
    id: 1,
    faqTitle: "Is your software customizable to my business needs?    ",
    faqTitleContent:
      "Yes, our software can be customized to meet the specific needs of your business. You can choose the features you need and configure the software accordingly.    ",

    icons: <BiCustomize className="fal mb-2" />,
  },
  {
    id: 2,
    faqTitle: "What kind of support do you offer?",
    faqTitleContent:
      "We offer comprehensive customer support, including onboarding, technical assistance, and ongoing support to ensure that our clients are always up and running smoothly.    ",
    icons: <MdSupportAgent className="fal mb-2" />,
  },
  {
    id: 3,
    faqTitle: "Is your field services software secure? ",
    faqTitleContent:
      "Yes, our field services software is designed with security in mind. It uses advanced encryption protocols to protect sensitive data and prevent unauthorized access.    ",
    icons: <HiOutlineShieldCheck className="fal mb-2" />,
  },
  {
    id: 4,
    faqTitle: "Is your field services software user-friendly?",
    faqTitleContent:
      "Yes, our software has a user-friendly interface that is easy to navigate and use, even for non-technical staff members.",

    icons: <FaUserFriends className="fal mb-2" />,
  },
  {
    id: 5,
    faqTitle:
      "How long does it take to set up your field services software?    ",
    faqTitleContent:
      "The setup time for our field services software can vary depending on the complexity of the business's requirements. However, we work closely with our clients to ensure that the implementation process is as smooth and efficient as possible.",

    icons: <MdMiscellaneousServices className="fal mb-2" />,
  },
  {
    id: 6,
    faqTitle: "How much does your software cost?",
    faqTitleContent:
      "Our pricing plans are flexible and designed to meet the needs of businesses of all sizes. Contact us for more information on pricing and to request a quote tailored to your specific needs.",
    icons: <RiMoneyDollarCircleLine className="fal mb-2" />,
  },
];
const MovingServices = [
  { id: 1, name: "Customer App" },
  { id: 2, name: "Driver App" },
  { id: 3, name: "Admin Panel" },
  { id: 4, name: "Dispatcher Panel" },
  { id: 5, name: "Web Booking" },
];
const FleetServicing = [
  { id: 1, name: "Customer App" },
  { id: 2, name: "Admin Panel" },
  { id: 3, name: "Web Booking" },
];
const FieldService = [
  { id: 1, name: "Customer App" },
  { id: 2, name: "Admin Panel" },
  { id: 3, name: "Web Booking" },
];
const ServicesContentFoodDelivery = [
  {
    id: 1,
    ServicesTitle:
      "Keep your customers satisfied with food delivered fast and fresh",
    ServicesTitleContent:
      "Customers can order food from their preferred restaurants and place the order simply using mobile app with just a few clicks. They can follow the delivery status in real time, be notified about their order updates, and offer a pleasant and soothing user interface to grow their customer loyalty and retention. ",
    img: PassengerApp,
    idKey: "PassengerApp",
    imgalt: "ZervX passenger app interface",
  },
  {
    id: 2,
    ServicesTitle: "Faster and smoother deliveries with our driver app",
    ServicesTitleContent:
      "The driver app helps your delivery drivers complete orders more efficiently and deliver food to your customers faster. Our app offers in-built navigation and order management features, which help minimize your delivery time and improve customer satisfaction with better ratings. Our real-time tracking and communication features to ensure that your drivers are always on time and your customers are always informed. ",
    img: Driver_App,
    idKey: "DriverApp",
    imgalt: "ZervX driver app interface",
  },
  {
    id: 3,
    ServicesTitle: "Manage your food delivery operations from one place",
    ServicesTitleContent:
      "The admin panel gives you full control over your food delivery operations, including order management, menu maintenance, sales tracking and performance measures. With configurable dashboards and reporting tools you are able to base your decisions on data and your business grows faster. Fully scalable and can support multiple locations and delivery zones, making it easy to expand your business as you grow.",
    img: Admin_Panel,
    idKey: "AdminPanel",
    imgalt: "ZervX admin panel dashboard",
  },
  {
    id: 4,
    ServicesTitle: "Make the ordering process easier for your customers",
    ServicesTitleContent:
      "Our user-friendly web booking system is designed to make it effortless for your customers to place food orders from any location, at any time. You can tailor the system to your business by customizing the brand. The system provides your customers with a hassle-free ordering experience. Real-time tracking and automatic updates are also included, so that your customers stay informed about their orders from start to finish. ",
    img: Web_Booking,
    idKey: "WebBooking",
    imgalt: "ZervX web booking page",
  },
];
const ServicesContentGroceryDelivery = [
  {
    id: 1,
    ServicesTitle: "Easy Grocery Shopping    ",
    ServicesTitleContent:
      "The customer app offers a simple and user-friendly interface, allowing your customers to easily browse and order groceries from the comfort of their homes. With advanced features like real-time order tracking, in-app messaging, and secure payment options, your customers will enjoy a seamless grocery shopping experience from start to finish.    ",
    img: PassengerApp,
    idKey: "PassengerApp",
    imgalt: "ZervX passenger app interface",
  },
  {
    id: 2,
    ServicesTitle: "Reliable Delivery Services    ",
    ServicesTitleContent:
      "Our driver app makes your delivery operations seamless and efficient. With features like optimized routing, real-time order tracking, and in-app communication with customers, our driver app ensures that your deliveries are made on time and with utmost care.    ",
    img: Driver_App,
    idKey: "DriverApp",
    imgalt: "ZervX driver app interface",
  },
  {
    id: 3,
    ServicesTitle: "Streamline Your Operations    ",
    ServicesTitleContent:
      "The admin panel, a centralized platform to manage your grocery delivery business. From managing orders and payments to tracking deliveries and analyzing data, our admin panel provides you with the tools to streamline your operations and make data-driven decisions for your business.",
    img: Admin_Panel,
    idKey: "AdminPanel",
    imgalt: "ZervX admin panel dashboard",
  },
  {
    id: 4,
    ServicesTitle: "Make the Ordering Process Easier    ",
    ServicesTitleContent:
      "Web booking offers a hassle-free grocery shopping experience to your customers. With features like user-friendly interface, secure payment options, and real-time order tracking, our web booking platform allows your customers to easily browse and order groceries online, without the need to download an app.    ",
    img: Web_Booking,
    idKey: "WebBooking",
    imgalt: "ZervX web booking page",
  },
];
const ServicesContentPharmacyDelivery = [
  {
    id: 1,
    ServicesTitle: "Seamless Experience for Customers    ",
    ServicesTitleContent:
      "Our customer app provides a hassle-free experience for your customers to order and manage their pharmacy deliveries. With our user-friendly interface, customers can quickly and easily book their orders, track their deliveries in real-time, and communicate with their delivery driver through in-app messaging.    ",
    img: PassengerApp,
    idKey: "PassengerApp",
    imgalt: "ZervX passenger app interface",
  },
  {
    id: 2,
    ServicesTitle: "Efficient Delivery Management    ",
    ServicesTitleContent:
      "Our driver app is designed to help your delivery team optimize their routes and improve their efficiency. With real-time order updates, turn-by-turn directions, and in-app messaging, your drivers will have all the tools they need to make timely deliveries and keep your customers happy.",
    img: Driver_App,
    idKey: "DriverApp",
    imgalt: "ZervX driver app interface",
  },
  {
    id: 3,
    ServicesTitle:
      "Complete Control and Management of Your Pharmacy Delivery    ",
    ServicesTitleContent:
      "The admin panel offers complete control and management of your pharmacy delivery business. With features like order management, driver management, and real-time analytics, our platform provides you with the insights and tools needed to efficiently manage your business. You can also set delivery zones, pricing, and promotions, providing you with flexibility and customization to meet your business needs.",
    img: Admin_Panel,
    idKey: "AdminPanel",
    imgalt: "ZervX admin panel dashboard",
  },
  {
    id: 4,
    ServicesTitle: "Easy Online Booking for Customers",
    ServicesTitleContent:
      "Provides an easy and convenient way for your customers to place orders online. With a user-friendly interface and secure payment options, your customers can quickly and easily order their medications from the comfort of their homes, with real-time updates on order status and delivery tracking, they'll always know when their medications are on the way.    ",
    img: Web_Booking,
    idKey: "WebBooking",
    imgalt: "ZervX web booking page",
  },
];
const ServicesContentMaterialsDelivery = [
  {
    id: 1,
    ServicesTitle: "Easy and Convenient Delivery for Your Customers    ",
    ServicesTitleContent:
      "The customer app offers a seamless and hassle-free delivery experience for your customers. With a user-friendly interface and real-time tracking, customers can easily track their orders and receive notifications about their delivery status. Secure payment options and easy-to-use navigation make it simple for customers to place orders and get their materials delivered right to their doorstep.",
    img: PassengerApp,
    idKey: "PassengerApp",
    imgalt: "ZervX passenger app interface",
  },
  {
    id: 2,
    ServicesTitle: "Streamline Your Delivery Operations    ",
    ServicesTitleContent:
      "Our driver app is aimed to streamline the delivery process for your drivers, making it easy to manage orders, routes, and deliveries. With features like real-time order updates, GPS tracking, and optimized route planning, your drivers can make deliveries quickly and efficiently. The app also offers secure payment options, ensuring a safe and hassle-free delivery experience for your drivers and customers.    ",
    img: Driver_App,
    idKey: "DriverApp",
    imgalt: "ZervX driver app interface",
  },
  {
    id: 3,
    ServicesTitle: "Simplified Management",
    ServicesTitleContent:
      "Admin panel provides a comprehensive overview of your delivery operations, allowing you to manage orders, drivers, and deliveries with ease. With detailed analytics and reporting, you can make informed decisions and optimize your operations for maximum efficiency and also offer customizable settings and 24/7 support, ensuring that your delivery operations run smoothly at all times.",
    img: Admin_Panel,
    idKey: "AdminPanel",
    imgalt: "ZervX admin panel dashboard",
  },
  {
    id: 4,
    ServicesTitle: "Make the Ordering Process Easier",
    ServicesTitleContent:
      "Web booking platform allows customers to easily place orders for delivery and track their order status in real-time. With a simple and user-friendly interface, customers can easily select their preferred delivery time and location. Other features like secure payment options and easy order management, ensures a seamless delivery experience for your customers.    ",
    img: Web_Booking,
    idKey: "WebBooking",
    imgalt: "ZervX web booking page",
  },
];
const ServicesContentMovingServices = [
  {
    id: 1,
    ServicesTitle: "Enhance Customer Experience",
    ServicesTitleContent:
      "Moving can be stressful, but with our customer app, your customers can simplify the entire process. Our user-friendly app allows customers to easily book and manage their moving services from the convenience of their smartphone. From selecting the desired moving date and time to tracking the movers in real time, our app makes it easy to stay informed throughout the moving process.",
    img: PassengerApp,
    idKey: "PassengerApp",
    imgalt: "ZervX passenger app interface",
  },
  {
    id: 2,
    ServicesTitle: "Efficiency and Control for Your Moving Team",
    ServicesTitleContent:
      "Our driver app streamlines the moving process for your team, providing them with the tools they need to efficiently manage their moving services. With features like real-time job assignments, GPS tracking and digital payment options, your movers will have all the necessary resources at their fingertips.",
    img: Driver_App,
    idKey: "DriverApp",
    imgalt: "ZervX driver app interface",
  },
  {
    id: 3,
    ServicesTitle: "Simplify Your Moving Operations",
    ServicesTitleContent:
      "The advanced admin panel gives you complete control over your moving service business. From managing your team’s schedules and assignments to tracking your revenue and customer feedback, our comprehensive platform streamlines your business operations and provides you with insights to help you make right decisions.",
    img: Admin_Panel,
    idKey: "AdminPanel",
    imgalt: "ZervX admin panel dashboard",
  },
  {
    id: 4,
    ServicesTitle: "Optimize Your Moving Services",
    ServicesTitleContent:
      "The Dispatcher panel provides you with the tools you need to optimize your moving services and improve your customer satisfaction. You can effortlessly track your team’s location and progress in real-time, efficiently assign jobs to the team to meet customer demand and provide the best possible moving experience for your customers.",
    img: Dispatcher,
    idKey: "DispatcherPanel",
    imgalt: "ZervX dispatcher panel dashboard",
  },
  {
    id: 5,
    ServicesTitle: "Easy and Convenient Online Booking",
    ServicesTitleContent:
      "Web booking allows your customers to easily book and manage their moving services online. Your customers can select their desired moving date and time, choose their preferred services and securely pay for their booking in just a few clicks, ensuring a smooth and stress-free experience.",
    img: Web_Booking,
    idKey: "WebBooking",
    imgalt: "ZervX web booking page",
  },
];
const ServicesContentFleetservicing = [
  {
    id: 1,
    ServicesTitle: "Book, Track and Communicate",
    ServicesTitleContent:
      "Our customer app offers a modern and intuitive interface, allowing your customers to easily book and manage their fleet services from anywhere. With advanced features like GPS tracking, in-app communication, and secure payment options, your customers will enjoy a seamless fleet service experience from start to finish. ",
    img: PassengerApp,
    idKey: "PassengerApp",
    imgalt: "ZervX passenger app interface",
  },
  {
    id: 2,
    ServicesTitle: "Effortless Fleet Management    ",
    ServicesTitleContent:
      "The centralized platform for managing your fleet servicing operations. Our modern and intuitive interface makes it easy to manage your fleet from a single location, can track the location of the fleet, monitor fuel consumption, and receive alerts and notifications. It streamlines fleet management and saves you time and money.    ",
    img: Driver_App,
    idKey: "DriverApp",
    imgalt: "ZervX driver app interface",
  },
  {
    id: 3,
    ServicesTitle: "Convenient and Easy Booking    ",
    ServicesTitleContent:
      "Our web booking platform is aimed to make fleet servicing as convenient as possible for your customers. They can easily book and manage their reservations from any device. With our advanced features you can simplify the booking process and improve your customer satisfaction.",
    img: Web_Booking,
    idKey: "WebBooking",
    imgalt: "ZervX web booking page",
  },
];
const ServicesContentFacilityServicing = [
  {
    id: 1,
    ServicesTitle: "Empower Your Customer",
    ServicesTitleContent:
      "With the customer app, your customers can easily schedule and manage their facility servicing requests all from the convenience of their mobile devices and businesses can reduce the time and effort required to manage the operations, leading to increased efficiency and customer satisfaction.      ",
    img: PassengerApp,
    idKey: "PassengerApp",
    imgalt: "ZervX passenger app interface",
  },
  {
    id: 2,
    ServicesTitle: "Simplify Your Facility Service Operations",
    ServicesTitleContent:
      "The admin panel is your one-stop-shop for managing your facility service operations. You can access all of your data in one place, including customer requests, work orders, inventory, scheduling, and billing, our admin panel streamlines processes and eliminates the need for manual tracking and record-keeping. By providing a central hub for managing all aspects of facility service operations, businesses can reduce costs, increase efficiency, and improve customer experience.",
    img: Admin_Panel,
    idKey: "AdminPanel",
    imgalt: "ZervX admin panel dashboard",
  },
  {
    id: 3,
    ServicesTitle: "Boost Your Facility Service Business",
    ServicesTitleContent:
      "Our web booking solution is a powerful tool for streamlining facility service operations and growing your business. It allows businesses to offer easy online booking for their service. With web booking businesses can attract new customers, reduce phone call volume, improve service delivery and make it easier for customers to do business with you.    ",
    img: Web_Booking,
    idKey: "WebBooking",
    imgalt: "ZervX web booking page",
  },
];
const faqListFoodDelivery = [
  {
    id: 1,
    faqTitle:
      "Can I customize your restaurant delivery software to meet my specific needs? ",
    faqTitleContent:
      "Yes, our restaurant delivery software can be customized to meet the specific needs of your business. Our team will work with you to understand your requirements and tailor the software to fit your unique needs. ",
    icons: <FaCog className="fal mb-2" />,
  },

  {
    id: 2,
    faqTitle:
      "What are the benefits of using your food delivery software for small businesses?",
    faqTitleContent:
      "Our software offers various benefits for small businesses. To learn more, please contact our sales team to discuss how our solution can support your business.",
    icons: <FiCheckCircle className="fal mb-2" />,
  },
  {
    id: 3,
    faqTitle:
      "How does the restaurant delivery software handle payment processing? ",
    faqTitleContent:
      "Our software typically supports various payment systems such as cash, credit cards, and digital wallets to ensure secure and simple transactions. ",
    icons: <RiUserLocationLine className="fal mb-2" />,
  },
  {
    id: 4,
    faqTitle:
      "What advantages does your online food delivery software bring to my restaurant business?",
    faqTitleContent:
      "Our online food delivery software can address your restaurant business problems with respect to labor costs, customer satisfaction, and revenue via an easy and fast order dispatching system.",
    icons: <FaUtensils className="fal mb-2" />,
  },
  {
    id: 5,
    faqTitle:
      "How does food delivery software improve my restaurant's customer retention?",
    faqTitleContent:
      "Food delivery software can improve customer retention by providing personalized promotions, loyalty programs, and real-time order tracking, ensuring that customers receive their orders quickly and efficiently.",
    icons: <IoFastFoodOutline className="fal mb-2" />,
  },

  {
    id: 6,
    faqTitle:
      "Can your online food delivery management software integrate with my existing system? ",
    faqTitleContent:
      "Yes, our food delivery management software can be integrated with your existing systems and  workflows, allowing for seamless operations and minimal disruption to your business.",
    icons: <AiOutlineGateway className="fal mb-2" />,
  },
];
const faqListGroceryDelivery = [
  {
    id: 1,
    faqTitle:
      "What are the benefits of using your grocery delivery software solution?    ",
    faqTitleContent:
      "Our software solution offers many benefits, including increased sales and revenue, improved customer satisfaction and loyalty, reduced operational costs, and streamlined inventory management and order fulfillment.    ",
    icons: <MdOutlineLocalGroceryStore className="fal mb-2" />,
  },

  {
    id: 2,
    faqTitle:
      "Is your software solution customizable to meet our business needs?    ",
    faqTitleContent:
      "Yes, our software solution is highly customizable to meet the unique needs of your grocery delivery business. We work closely with our clients to understand their requirements and develop customized solutions that meet their specific needs.    ",
    icons: <MdOutlineEdgesensorHigh className="fal mb-2" />,
  },
  {
    id: 3,
    faqTitle: "What kind of support do you provide to your clients?    ",
    faqTitleContent:
      "We provide comprehensive support to our clients, including technical support, ongoing maintenance and updates to ensure that our software solution continues to meet their evolving needs.",
    icons: <RiRemoteControlLine className="fal mb-2" />,
  },
  {
    id: 4,
    faqTitle: "How long does it take to implement your software solution?    ",
    faqTitleContent:
      "The implementation timeline for our software solution varies depending on the complexity of the project and the specific requirements of the client. However, we work closely with our clients to ensure that the implementation process is as smooth and efficient as possible.    ",
    icons: <AiOutlineSolution className="fal mb-2" />,
  },
  {
    id: 5,
    faqTitle: "How do you ensure the security and privacy of our data?    ",
    faqTitleContent:
      "We take data security and privacy very seriously and have implemented strict measures to ensure the protection of our clients' data. This includes regular security audits, encryption of sensitive data, and strict access controls.    ",
    icons: <MdOutlinePrivacyTip className="fal mb-2" />,
  },

  {
    id: 6,
    faqTitle:
      "How do we get started with your grocery delivery software solution?    ",
    faqTitleContent:
      "To get started, simply contact us to schedule a consultation. We'll work with you to understand your needs and develop a customized solution that meets your specific requirements.",
    icons: <MdOutlineNotStarted className="fal mb-2" />,
  },
];
const faqListPharmacyDelivery = [
  {
    id: 1,
    faqTitle:
      "What are the benefits of using your pharmacy delivery software?    ",
    faqTitleContent:
      "Our software can help pharmacies increase revenue, improve customer satisfaction, and streamline operations.",
    icons: <GiMedicinePills className="fal mb-2" />,
  },

  {
    id: 2,
    faqTitle: "Is your pharmacy delivery software customizable?    ",
    faqTitleContent:
      "Yes, our software can be customized to meet the specific needs of your business. Features such as branding, payment options, and delivery zones can be tailored to your requirements.    ",
    icons: <FaLaptopMedical className="fal mb-2" />,
  },
  {
    id: 3,
    faqTitle: "Is your pharmacy delivery software secure?    ",
    faqTitleContent:
      "Yes, it is designed with security in mind. The app uses encryption and other measures to protect sensitive customer and pharmacy data.    ",
    icons: <AiFillMedicineBox className="fal mb-2" />,
  },
  {
    id: 4,
    faqTitle: "How long does it take to set up pharmacy delivery software?    ",
    faqTitleContent:
      "The setup time for pharmacy delivery software can vary depending on the complexity of the requirements. However, we work closely with our clients to ensure that the implementation process is as smooth and efficient as possible.",
    icons: <FaHandHoldingMedical className="fal mb-2" />,
  },
  {
    id: 5,
    faqTitle: "How much does your pharmacy delivery software cost?    ",
    faqTitleContent:
      "Pricing for our software varies depending on the specific needs of your business. Contact us today to learn more about our pricing options and to request a customized quote.",
    icons: <RiMoneyDollarBoxLine className="fal mb-2" />,
  },
  {
    id: 6,
    faqTitle: "How does pharmacy delivery software work?    ",
    faqTitleContent:
      "Pharmacy delivery software works by connecting customers with nearby pharmacies and drivers. Customers can place orders through the app, and drivers can use the app to navigate to the pharmacy and deliver the order.    ",
    icons: <MdMedicationLiquid className="fal mb-2" />,
  },
];
const faqListMaterialsDelivery = [
  {
    id: 1,
    faqTitle:
      "What are the benefits of using your materials delivery software?",
    faqTitleContent:
      "Our materials delivery software can help businesses increase efficiency, reduce costs, and improve customer satisfaction.",
    icons: <FaTools className="fal mb-2" />,
  },
  {
    id: 2,
    faqTitle: "How does materials delivery software work?    ",
    faqTitleContent:
      "Materials delivery software works by connecting businesses with nearby drivers and providing them with tools to manage their delivery operations. Businesses can use the software to track deliveries in real-time, optimize routes, and communicate with drivers.    ",
    icons: <GiToolbox className="fal mb-2" />,
  },
  {
    id: 3,
    faqTitle: "Is your materials delivery software customizable?    ",
    faqTitleContent:
      "Yes, our materials delivery software can be customized to meet the specific needs of each business. Features such as branding, payment options, and delivery zones can be tailored to the business's requirements.    ",
    icons: <CgToolbox className="fal mb-2" />,
  },
  {
    id: 4,
    faqTitle: "Is materials delivery software secure?    ",
    faqTitleContent:
      " Yes, materials delivery software is designed with security in mind. The app uses encryption and other measures to protect sensitive customer and business data.",

    icons: <BiCheckShield className="fal mb-2" />,
  },
  {
    id: 5,
    faqTitle: "How long does it take to set up materials delivery software?",
    faqTitleContent:
      "The setup time for materials delivery software can vary depending on the complexity of the business's requirements. However, we work closely with our clients to ensure that the implementation process is as smooth and efficient as possible.",
    icons: <BiTimer className="fal mb-2" />,
  },
  {
    id: 6,
    faqTitle: "How much does your materials delivery software cost?",
    faqTitleContent:
      "Pricing for our software varies depending on the specific needs of your business. Contact us today to learn more about our pricing options and to request a customized quote.    ",
    icons: <RiMoneyDollarCircleLine className="fal mb-2" />,
  },
];
const faqListMovingServices = [
  {
    id: 1,
    faqTitle:
      "Can your software be customized to fit my business's unique needs?      ",
    faqTitleContent:
      "Yes, our software is fully customizable, allowing you to configure it to fit your unique business needs.",
    icons: <FaTruckMoving className="fal mb-2" />,
  },
  {
    id: 2,
    faqTitle: "How quickly can I get up and running with your software?",
    faqTitleContent:
      "Our software is designed to be easy to set up and use, and our team supports you to ensure a smooth transition. Depending on your needs, you can typically start using the software within a few days or weeks. ",
    icons: <MdOutlineQuickreply className="fal mb-2" />,
  },
  {
    id: 3,
    faqTitle: "Is your moving software suitable for businesses of all sizes?",
    faqTitleContent:
      "Yes, our software is suitable for businesses of all sizes, from small to large moving businesses.",
    icons: <HiOutlineSquaresPlus className="fal mb-2" />,
  },
  {
    id: 4,
    faqTitle: "What kind of support do you offer?    ",
    faqTitleContent:
      "   We offer comprehensive customer support, including onboarding, technical assistance, and ongoing support to ensure that our clients are always up and running smoothly.",
    icons: <MdOutlineLocalOffer className="fal mb-2" />,
  },
  {
    id: 5,
    faqTitle: "How much does your moving services software cost?    ",
    faqTitleContent:
      "Our pricing plans are flexible and designed to meet the needs of businesses of all sizes. Contact us for more information on pricing and to request a quote tailored to your specific needs.",
    icons: <RiMoneyDollarCircleLine className="fal mb-2" />,
  },
  {
    id: 6,
    faqTitle: "Is your moving software secure?    ",
    faqTitleContent:
      " Yes, our software is designed with security in mind. It uses advanced encryption protocols to protect sensitive data and prevent unauthorized access.",
    icons: <BiCheckShield className="fal mb-2" />,
  },
];
const faqListFleetServicing = [
  {
    id: 1,
    faqTitle: "Is your software customizable to my business needs?      ",
    faqTitleContent:
      "Yes, our software can be customized to meet the specific needs of your business. You can choose the features you need and configure the software accordingly.      ",
    icons: <BsFillHddStackFill className="fal mb-2" />,
  },
  {
    id: 2,
    faqTitle: "What kind of support do you offer?    ",
    faqTitleContent:
      "We offer comprehensive customer support, including onboarding, technical assistance, and ongoing support to ensure that our clients are always up and running smoothly.",
    icons: <MdSupportAgent className="fal mb-2" />,
  },
  {
    id: 3,
    faqTitle:
      "Is fleet servicing software suitable for businesses of all sizes?    ",
    faqTitleContent:
      "Yes, fleet servicing software is suitable for businesses of all sizes, from small fleets to large enterprise-level fleets.",
    icons: <HiOutlineSquaresPlus className="fal mb-2" />,
  },
  {
    id: 4,
    faqTitle:
      "How can your fleet servicing software help me manage vehicle maintenance?    ",
    faqTitleContent:
      "Our fleet servicing software provides tools for tracking vehicle maintenance schedules, generating work orders and managing repair history. This can help you stay on top of vehicle maintenance and reduce downtime.  ",
    icons: <MdBusAlert className="fal mb-2" />,
  },
  {
    id: 5,
    faqTitle: "Is your fleet servicing software secure?    ",
    faqTitleContent:
      "Yes, our fleet servicing software is designed with security in mind. It uses advanced encryption protocols to protect sensitive data and prevent unauthorized access.    ",
    icons: <BiCheckShield className="fal mb-2" />,
  },
  {
    id: 6,
    faqTitle: "How much does your fleet servicing software cost?  ",
    faqTitleContent:
      "Our pricing plans are flexible and designed to meet the needs of businesses of all sizes. Contact us for more information on pricing and to request a quote tailored to your specific needs.",
    icons: <RiMoneyDollarCircleLine className="fal mb-2" />,
  },
];
const faqListFacilityServicing = [
  {
    id: 1,
    faqTitle: "Is your software customizable to my business needs?",
    faqTitleContent:
      "Yes, our software can be customized to meet the specific needs of your business. You can choose the features you need and configure the software accordingly.",
    icons: <FaChalkboard className="fal mb-2" />,
  },
  {
    id: 2,
    faqTitle: "What kind of support do you offer?",
    faqTitleContent:
      "We offer comprehensive customer support, including onboarding, technical assistance, and ongoing support to ensure that our clients are always up and running smoothly.    ",
    icons: <MdSupportAgent className="fal mb-2" />,
  },
  {
    id: 3,
    faqTitle: "Is your facility servicing software secure? ",
    faqTitleContent:
      "Yes, our facility servicing software is designed with security in mind. It uses advanced encryption protocols to protect sensitive data and prevent unauthorized access. ",
    icons: <BiCheckShield className="fal mb-2" />,
  },
  {
    id: 4,
    faqTitle: "Is your facility servicing software user-friendly?      ",
    faqTitleContent:
      "Yes, our facility servicing software has a user-friendly interface that is easy to navigate and use, even for non-technical staff members.",
    icons: <MdMobileFriendly className="fal mb-2" />,
  },
  {
    id: 5,
    faqTitle:
      "How long does it take to set up your facility servicing software?",
    faqTitleContent:
      "The setup time for our facility servicing software can vary depending on the complexity of the business's requirements. However, we work closely with our clients to ensure that the implementation process is as smooth and efficient as possible.    ",
    icons: <CgArrowLongRightC className="fal mb-2" />,
  },
  {
    id: 6,
    faqTitle: "How much does your facility servicing software cost?    ",
    faqTitleContent:
      "Our pricing plans are flexible and designed to meet the needs of businesses of all sizes. Contact us for more information on pricing and to request a quote tailored to your specific needs.",
    icons: <RiMoneyDollarCircleLine className="fal mb-2" />,
  },
];
const MobilindustrySpecificSolutionstyBusiness = [
  {
    title: "Ride Hailing",
    content:
      "We provide an extensive software platform that includes everything from ride dispatch and real-time tracking to fare calculation and payment processing.",
    color: "colorblue",
  },
  {
    title: "Logistics ",
    content:
      "With our platform, you can easily manage your entire logistics operation from a single dashboard and make data-driven decisions to augment your business.",
    color: "colorRed",
  },
  {
    title: "Commerce",
    content:
      "Our tailored solution empowers merchants, retailers, and e-commerce businesses to effortlessly handle their deliveries. We simplify your logistics and ensure seamless order management for your customers.",
    color: "colorgreen",
  },
  {
    title: "Services",
    content:
      "Our software platform revolutionizes service-based companies, optimizing operations, cutting costs, and enhancing customer satisfaction. From fleet servicing to field services, we have you covered.",
    color: "coloryellow",
  },
];
const MobilityBusiness = [
  {
    title: "Predictive ETA",
    content:
      "Advanced algorithms predict the estimated time of arrival for passengers, improving the reliability of the service and reducing wait times.",
  },
  {
    title: "Notifications ",
    content:
      "Passengers receive notifications for ride confirmations, driver assignments, and trip updates, staying informed throughout the process.",
  },
  {
    title: "Fare Estimates",
    content:
      "Passengers can conveniently view fare estimates before booking a ride, eliminating surprises and promoting transparency.",
  },
  {
    title: "SOS",
    content:
      "Passengers have access to emergency assistance through an SOS button, providing a quick response in case of any unforeseen incidents.",
  },
  {
    title: "Driver Tracking",
    content:
      "Fleet managers can track real-time driver locations, monitor driving behavior, and ensure efficient utilization of resources.",
  },
  {
    title: "Geo-Fencing",
    content:
      "Admin can define geographic boundaries and receive alerts when vehicles enter or leave designated areas, managing fleets effectively.      ",
  },
  {
    title: "Route Optimization",
    content:
      "Our system provides the most optimal route for drivers, significantly reducing travel time and increasing operational efficiency.",
  },
  {
    title: "Demand Forecasting",
    content:
      "Fleet managers can analyze historical data to predict future demand, helping them make informed decisions about resource allocation.",
  },
  {
    title: "In-App Communication",
    content:
      "Passengers and drivers can communicate through our in-app messaging system, providing a seamless experience.      ",
  },
  {
    title: "Booking Management",
    content:
      "Fleet managers can manage and monitor bookings, ensuring a smooth and hassle-free experience for passengers.",
  },
  {
    title: "Automated Dispatching",
    content:
      "System automatically assigns drivers to passengers based on their location and availability, streamlining the dispatch process.",
  },
  {
    title: "Customizable Dashboard",
    content:
      "Operators can customize their dashboard to suit their specific needs, providing them with relevant and actionable insights. ",
  },
];
const ResourceHelpCenter = [
  {
    icon: <FaListUl />,
    title: "Predictive ETA",
    content:
      "Advanced algorithms predict estimated time of arrival for passengers, improving service's reliability and reducing wait times.",
  },
  {
    icon: <MdOutlineNotificationsActive />,
    title: "Notifications ",
    content:
      "Passengers receive notifications for ride confirmations, driver assignments, and updates, staying informed throughout the process.",
  },
  {
    icon: <AiOutlineFundView />,
    title: "Fare Estimates",
    content:
      "Passengers can conveniently view fare estimates before booking a ride, eliminating surprises and promoting transparency.",
  },
  {
    icon: <AiOutlineAlert />,
    title: "SOS",
    content:
      "Passengers can get quick emergency assistance through an SOS button in case of any unforeseen incidents.",
  },
  {
    icon: <MdOutlineOnDeviceTraining />,
    title: "Driver Tracking",
    content:
      "Fleet managers can track real-time driver locations, monitor driving behavior, and ensure efficient utilization of resources.",
  },
  {
    icon: <BsBoundingBoxCircles />,
    title: "Geo-Fencing",
    content:
      "Admin can define geographic boundaries and receive alerts when vehicles enter or leave designated areas, managing fleets effectively.      ",
  },
  {
    icon: <BiSlider />,
    title: "Route Optimization",
    content:
      "Our system provides the most optimal route for drivers, reducing travel time and increasing efficiency.",
  },
  {
    icon: <TbBrandGoogleAnalytics />,
    title: "Intercity rides",
    content:
      "Intercity rides between service areas allow businesses to easily increase the reach of their services and connect cities.",
  },
  {
    icon: <GiDiscussion />,
    title: "In-App Communication",
    content:
      "Passengers and drivers can interact with each other through our in-app messaging system, providing a smooth and convenient experience.",
  },
  {
    icon: <MdBookOnline />,
    title: "Booking Management",
    content:
      "Fleet managers can manage and monitor bookings, ensuring a smooth and hassle-free experience for passengers.    ",
  },
  {
    icon: <MdDisplaySettings />,
    title: "Automated Dispatching",
    content:
      "System automatically assigns drivers to passengers based on their location and availability, streamlining the dispatch process.",
  },
  {
    icon: <RiDashboardLine />,
    title: "Customizable Dashboard",
    content:
      "Operators can customize their dashboard to suit their specific needs, providing them with relevant and actionable insights.",
  },
];
const HelpCenter = [
  {
    icon: <FiEdit />,
    title: "Customization",
    content:
      "The platform offers the flexibility to be customized with specialized workflows, features and system integrations aligned with the business unique operating model.",
  },
  {
    icon: <MdNewLabel />,
    title: "White Labeling",
    content:
      "Make an impactful brand impression with our white label solution, enabling you to integrate your unique brand elements for customer trust, recognition, and loyalty.      ",
  },
  {
    icon: <MdOutlineIntegrationInstructions />,
    title: "Integrations",
    content:
      "Our taxi app platform seamlessly interconnects with an array of industry leaders, empowering you to leverage partner solutions for enhancing operational efficiency.",
  },
  {
    icon: <BiBookContent />,
    title: "Custom Content",
    content:
      "Add individualized, structured content into the platform to provide specific instructions, tasks, or information to drivers, ensuring smooth and efficient operations.",
  },
  {
    icon: <TbBrandGoogleAnalytics />,
    title: "Analytics",
    content:
      "Gain a decisive strategic advantage through actionable insights distilled from real-time fleet history and driver data, enabling continuous performance enhancements.",
  },
  {
    icon: <HiOutlineDesktopComputer />,
    title: "Intuitive Interface",
    content:
      "An easy-to-use interface designed based on thorough user research to streamline complex workflows and optimize overall efficiency with intuitive navigation and controls.",
  },
];
const HelpCenter2 = [
  {
    icon: <FaNetworkWired />,
    title: "Customizable Dashboard",
    content:
      "Customize your dashboard to display the most relevant data and features for your business operations. Visualize key metrics, enabling better monitoring and analysis.",
  },
  {
    icon: <MdInsights />,
    title: "Integration Capabilities",
    content:
      "Our comprehensive integration process ensures you to seamlessly integrate our software with your existing systems and tools to achieve a unified workflow.",
  },
  {
    icon: <RiDashboardLine />,
    title: "Smart Algorithmic Dispatch    ",
    content:
      "Our smart algorithms dramatically enhance dispatch efficiency, drastically lower wait times, and boost client satisfaction, all in all significantly improving service quality.    ",
  },
  {
    icon: <TbBrandGoogleAnalytics />,
    title: "Integrated Payment Gateways",
    content:
      "Businesses can provide customers with seamless payment options by integrating secure payment gateways for cashless, credit card, or digital wallet transactions.    ",
  },
  {
    icon: <MdNotificationsActive />,
    title: "ETA Notification",
    content:
      "Estimated time of arrival notification feature enhances transparency and improves customer satisfaction by keeping them informed about their expected arrival time.",
  },
  {
    icon: <AiOutlineAlert />,
    title: "Panic Button",
    content:
      "Both our passenger and driver apps include an emergency button, enabling quick response and resolution for both drivers and passengers in critical situations.",
  },
];
const HelpCenter3 = [
  {
    icon: <MdPhoneAndroid />,
    title: "Cab Booking App",
    content:
      "Allow customers to book taxi, rental car, or carpool rides online. Integrated with an automated dispatch system for confirmation.",
  },
  {
    icon: <IoMdCar />,
    title: "Driver Registration",
    content:
      "Onboard and manage driver profiles digitally with the upload of documents, licenses, vehicle details and more for credential validation.",
  },
  {
    icon: <ImLocation />,
    title: "Real-Time Tracking",
    content:
      "Pinpoint fleet vehicle locations on a live map powered by GPS. Monitor driver and passenger safety with live tracking.",
  },
  {
    icon: <MdLanguage />,
    title: "Multi-lingual Support",
    content:
      "Facilitate global operations and localization with interface translations, multi-currency support and localized fare settings.    ",
  },
  {
    icon: <MdPayment />,
    title: "Cashless Payments",
    content:
      "Integrate payment gateways for secure digital payments via cards, digital wallets or stored account balances for seamless transactions.",
  },
  {
    icon: <MdSchedule />,
    title: "Scheduling for Carpools",
    content:
      "Plan shared commutes for groups with the calendar booking of fixed carpool rides to recurring locations at scheduled times.",
  },
];
const HelpCenter4 = [
  {
    icon: <GiPlayerTime />,
    title: "Real-time Availability",
    content:
      "Efficiently manage bookings with real-time availability updates and instant confirmations, ensuring smooth communication and reducing the risk of double bookings.    ",
  },
  {
    icon: <BsBuildingFillGear />,
    title: "Centralized Management    ",
    content:
      "Easily handle bookings and reservations across multiple locations through a centralized management system, providing a unified view and simplifying operations.",
  },
  {
    icon: <GiConfirmed />,
    title: "Instant Confirmations",
    content:
      "Businesses can automate booking confirmations, instantly notifying customers about successful bookings, ensuring a prompt and professional customer experience.    ",
  },
  {
    icon: <BiBookmarks />,
    title: "Multiple Booking Channels",
    content:
      "Supports multiple booking channels, such as websites, and mobile apps enabling businesses to reach a wider audience, and increases customer accessibility.",
  },
  {
    icon: <GiAlarmClock />,
    title: "Automated Reminders",
    content:
      "By keeping everyone informed about upcoming bookings, businesses can optimize their resources and maintain a consistent flow of customers.    ",
  },
  {
    icon: <MdOutlinePersonalInjury />,
    title: "Tailored Solutions",
    content:
      "Businesses can offer tailored, personalized solutions such as flexible booking and add-ons to create unique experiences that precisely meet customer needs.",
  },
];
const IndustiesRidehailing = [
  {
    icon: <TbGps />,
    title: "Real-time GPS Tracking",
    content:
      "Can keep track of your drivers and passengers and helps you to stay on top of your operations and ensure timely pickups and drop-offs.",
  },
  {
    icon: <RiSecurePaymentLine />,
    title: "Automated Payments",
    content:
      "Our platform automates payments and billing, making it easy for your customers to handle transactions.",
  },
  {
    icon: <MdMovieEdit />,
    title: "Customizable Promotions ",
    content:
      "With our ride hailing software solution, you can create customized promotions to attract and retain customers.",
  },
  {
    icon: <TbBrandGoogleAnalytics />,
    title: "Analytics and Reporting",
    content:
      "Get valuable insights into your business operations with our advanced analytics and help optimize your business for success.",
  },
  {
    icon: <BsStars />,
    title: "Customizable Branding",
    content:
      "Customize the platform with your own branding, logo, and color scheme for increased brand awareness.",
  },
  {
    icon: <MdSupportAgent />,
    title: "Customer Support",
    content:
      "Our team is available 24/7 to help you with any issues or questions you may have. We are here to support your business every step of the way.",
  },
];
const IndustiesRideSharing = [
  {
    icon: <TbLiveView />,
    title: "Real Time Tracking",
    content:
      "Our software allows you to track your vehicles and drivers in real-time, giving you complete visibility and control over your operations.    ",
  },
  {
    icon: <MdDisplaySettings />,
    title: "Quick Bookings",
    content:
      "Easily book rides with just a few taps. Our passenger app ensures swift and hassle free reservations for customers convenience.   ",
  },
  {
    icon: <RiCheckboxMultipleFill />,
    title: "Multi-Platform Support",
    content:
      "Supports multiple platforms, including web, iOS, and Android, ensuring that your customers can access your services from anywhere.    ",
  },
  {
    icon: <BsStars />,
    title: "Smart Rideshare Matching",
    content:
      "Our driver app employs intelligent algorithms to match rides efficiently and optimize routes. It matches drivers with the right passengers.",
  },
  {
    icon: <IoNavigateCircleOutline />,
    title: "Integrated Navigation    ",
    content:
      "Integrates with popular navigation tools like Google Maps and Waze, ensuring that your drivers always take the most efficient routes.",
  },
  {
    icon: <MdOutlineManageHistory />,
    title: "Dynamic Pricing",
    content:
      "Our rideshare software uses dynamic pricing, maintaining a balanced pricing structure that offers flexibility to passengers and drivers.",
  },
];
const IndustiesAirport_VIP_Shuttle = [
  {
    icon: <TbLiveView />,
    title: "Real-time tracking",
    content:
      "Keep track of all your shuttle vehicles in real-time, ensuring that they arrive on time and reach their destinations safely.",
  },
  {
    icon: <CgMenuBoxed />,
    title: "Automated fare calculation",
    content:
      "Offer customers the ability to book their shuttle rides through a range of channels, including website and mobile app.",
  },
  {
    icon: <MdOutlineBookOnline />,
    title: "Customizable booking options",
    content:
      "Offer customers the ability to book their shuttle rides through a range of channels, including website and mobile app.",
  },
  {
    icon: <GiConversation />,
    title: "In-app communication",
    content:
      "Enable drivers and passengers to communicate in real-time through the app, ensuring a seamless and stress-free ride experience.",
  },
  {
    icon: <MdOutlineAssignmentTurnedIn />,
    title: "Automatic ride assignment",
    content:
      "Automatically assign rides to the nearest available driver, improving efficiency and reducing wait times for passengers.",
  },
  {
    icon: <RiSecurePaymentLine />,
    title: "Flexible payment options",
    content:
      "Accept a range of payment options, including credit cards, mobile payments, and corporate billing, to cater diverse customer needs.",
  },
];
const IndustiesCorporateTransportation = [
  {
    icon: <BiTask />,
    title: "Assigned Trips",
    content:
      "Stay organized with a clear overview of the upcoming trip assignments, allowing you to prioritize tasks and manage time effectively.",
  },
  {
    icon: <GiPathDistance />,
    title: "Vehicle Tracking",
    content:
      "Provide real-time updates on the location of vehicles, allowing users to monitor their arrival time and plan accordingly.",
  },
  {
    icon: <FaUsersCog />,
    title: "Manage Employees",
    content:
      "Streamline employee transport management by easily adding, updating, organizing details, and trip schedules within the admin panel.",
  },
  {
    icon: <MdCommute />,
    title: "Fleet Management",
    content:
      "Manage fleet operations with comprehensive tools that allow you to add, manage, assign rides, and optimize routes.",
  },
  {
    icon: <MdNotificationsActive />,
    title: "Notifications & Alerts",
    content:
      "Receive real-time notifications and alerts to stay updated on trip assignments, schedule changes, and important updates.",
  },
  {
    icon: <AiFillAlert />,
    title: "Emergency Button",
    content:
      "Access immediate assistance in case of emergency, prioritizing safety and providing peace of mind for both employees and drivers.",
  },
];
const IndustiesFieldService = [
  {
    icon: <MdOutlineAssignmentInd />,
    title: "Real-Time Job Assignment",
    content:
      "Efficiently assign field service tasks to your mobile workforce in real-time, ensuring optimal resource allocation, minimizing unnecessary delays, and maximizing productivity.",
  },
  {
    icon: <AiFillContainer />,
    title: "Work Order Management",
    content:
      "Effectively manage and track work orders from initiation to completion, providing your team with clear and concise instructions and reducing paperwork.",
  },
  {
    icon: <MdOutlineInventory2 />,
    title: "Inventory Management",
    content:
      "Keep track of your inventory in real-time, ensuring that your field service technicians have the right tools, equipment, and parts to complete their tasks efficiently.",
  },
  {
    icon: <GiConversation />,
    title: "Customer Communication",
    content:
      "Enhance customer satisfaction by enabling seamless communication between your field service team and customers, providing updates with customers to track service progress.",
  },
  {
    icon: <BiSlider />,
    title: "Route Optimization",
    content:
      "Optimize scheduling and routing for field service technicians to minimize travel time, reduce fuel costs, and maximize the number of service calls completed per day.    ",
  },
  {
    icon: <TbGps />,
    title: "GPS Tracking",
    content:
      "Track the real-time location of your field service technicians, allowing you to efficiently assign and reroute resources based on their proximity to customer locations.",
  },
];
const IndustiesFoodDelivery = [
  {
    icon: <TbGps />,
    title: "Track Delivery",
    content:
      "Provides food delivery companies the ability to monitor their delivery agents and track delivery status in real-time hence timely and accurate deliveries are guaranteed.",
  },
  {
    icon: <RiSecurePaymentLine />,
    title: "Easy Payment Options",
    content:
      "Our software offers multiple payment options, including credit cards, online wallets, and cash-on-delivery, making it easier for customers to pay and increase repeat business.",
  },
  {
    icon: <MdNotificationsActive />,
    title: "Push Notifications",
    content:
      "WiFood delivery business can send timely updates to their customers about their order status, promotion and offer as well as they will have good customer-retention and engagement.",
  },
  {
    icon: <HiOutlineClipboardList />,
    title: "Effortless Order Placement",
    content:
      "Our software's simplified ordering procedure ensures customers can place their orders without any difficulty, which helps to improve sales and customer experience.",
  },
  {
    icon: <MdOutlineReviews />,
    title: "Reviews & Ratings",
    content:
      "With the reviews and ratings feature of our software, customers can share feedback on your services, making it possible to improve service quality and attract more customers.",
  },
  {
    icon: <MdMobileFriendly />,
    title: "In-app Messages",
    content:
      "Businesses can use in-app messages to send personalized promotions and offers to customers during their app usage. This helps increase customer engagement and drive sales.",
  },
];
const IndustiesGroceryDelivery = [
  {
    icon: <TbLiveView />,
    title: "Real-Time Delivery Tracking",
    content:
      "Keep track of your delivery agents and track the status of grocery deliveries in real-time with GPS tracking.",
  },
  {
    icon: <MdDisplaySettings />,
    title: "Automated Dispatching",
    content:
      "Automatically dispatch orders to drivers based on real-time proximity, instant availability, and other relevant criteria.",
  },
  {
    icon: <RiSecurePaymentLine />,
    title: "Secure Payment",
    content:
      "Process payments securely, seamlessly, and easily with a wide range of convenient payment options.",
  },
  {
    icon: <TbDeviceMobileMessage />,
    title: "In-App Messaging",
    content:
      "Keep customers informed with in-app messaging about their orders, delivery times, and more.",
  },
  {
    icon: <TbBrandGoogleAnalytics />,
    title: "Advanced Analytics",
    content:
      "Get real-time insights into your business with advanced, customized analytics and comprehensive reporting features.",
  },
  {
    icon: <SiSpringsecurity />,
    title: "Robust Security",
    content:
      "Keep your business and customer data completely safe and secure with advanced security features and stringent data protection.",
  },
];
const IndustiesPharmacyDelivery = [
  {
    icon: <MdDisplaySettings />,
    title: "Automated Dispatch",
    content:
      "Our software comes equipped with an automated dispatch feature that assigns orders to delivery personnel based on their proximity to the pick-up location.",
  },
  {
    icon: <TbLiveView />,
    title: "Real-Time Tracking",
    content:
      "Businesses can keep track of all their drivers in real-time, ensuring timely deliveries and providing customers with accurate updates on the status of their orders.      ",
  },
  {
    icon: <RiSecurePaymentLine />,
    title: " Secure Payment",
    content:
      "Our pharmacy delivery software comes with a secure payment gateway that ensures safe transactions, giving customers peace of mind while paying.",
  },
  {
    icon: <FaLanguage />,
    title: "Multilingual Support",
    content:
      "Our app supports multiple languages, making it accessible to a wider audience and allowing you to cater to customers who speak different languages.    ",
  },
  {
    icon: <TbDeviceMobileMessage />,
    title: "In-App Chat",
    content:
      "Our app comes with an in-app chat feature that allows customers to easily communicate with delivery personnel and get real-time updates on their orders.",
  },
  {
    icon: <TbTruckDelivery />,
    title: "Delivery Confirmation",
    content:
      "Our app sends an automatic delivery confirmation message to customers once their orders have been delivered, reducing the need for manual follow-ups.    ",
  },
];
const IndustiesMaterialsDelivery = [
  {
    icon: <MdDisplaySettings />,
    title: "Automated Dispatching",
    content:
      "Automatically assigns drivers to delivery requests based on their availability and proximity to the pickup location.",
  },
  {
    icon: <TbLiveView />,
    title: "Real-time Tracking",
    content:
      "With our software, you can track the location of your drivers and deliveries in real-time, ensuring you always know where your materials are.",
  },
  {
    icon: <BiSlider />,
    title: "Route Optimization",
    content:
      "Our software optimizes delivery routes to reduce travel time, fuel costs, and increase delivery efficiency.",
  },
  {
    icon: <FaFileInvoiceDollar />,
    title: "Automated Invoicing",
    content:
      "Our software automates the invoicing process, reducing administrative work and improving cash flow.",
  },
  {
    icon: <BsStars />,
    title: "Customizable Branding",
    content:
      "Our software is fully customizable to your branding, ensuring consistency and professionalism.",
  },
  {
    icon: <RiSecurePaymentLine />,
    title: "Payment Integration",
    content:
      "Our software integrates with payment gateways, making it easy to receive payments for deliveries",
  },
];
const IndustiesCityCouriers = [
  {
    icon: <MdDisplaySettings />,
    title: "Automated Dispatching",
    content:
      "Our software automates the dispatching process, enabling you to quickly and efficiently assign couriers to deliveries.",
  },
  {
    icon: <MdNotificationsActive />,
    title: "Customizable Notifications",
    content:
      "Businesses can customize notifications to keep customers informed about their package status and provide accurate delivery estimates.",
  },

  {
    icon: <GiConversation />,
    title: "In-App Communication",
    content:
      "Enable seamless communication between couriers and customers, and receive instant feedback and ratings.",
  },
  {
    icon: <TbLiveView />,
    title: "Real-Time Tracking",
    content:
      "Track packages in real-time, and keep your customers informed of their delivery status every step of the way.",
  },
  {
    icon: <MdAutoGraph />,
    title: "Multi-Language",
    content:
      "Supports multiple languages, enabling you to serve customers in diverse regions and expand your business internationally.",
  },
  {
    icon: <MdOutlineManageAccounts />,
    title: "Invoice Management",
    content:
      "Our software enables you to manage invoices and billing, making it easy to keep track of payments and reduce administrative overhead.",
  },
];
const IndustiesPackageDelivery = [
  {
    icon: <TbLiveView />,
    title: "Real-Time Tracking",
    content:
      "Provides businesses with real-time GPS tracking, allowing them to monitor, so that businesses can provide customers with accurate delivery information.",
  },
  {
    icon: <TbTruckDelivery />,
    title: "Proof of Delivery",
    content:
      "This feature enables businesses to capture and store photos of the delivery location, along with time and date, providing verifiable digital records that reduce errors or disputes.",
  },
  {
    icon: <MdDisplaySettings />,
    title: "Automated Dispatching ",
    content:
      "Our advanced dispatching system automatically assigns delivery personnel to delivery requests based on their availability and proximity to the pickup location.",
  },
  {
    icon: <MdNotificationsActive />,
    title: "Notifications",
    content:
      "Provides businesses with a range of customer notification options, including SMS, email, and in-app notifications to keep the customers informed about their delivery status.",
  },
  {
    icon: <MdOutlineManageAccounts />,
    title: "Driver Management",
    content:
      "Businesses can easily and efficiently track delivery personnel locations and performance, assign routes and tasks, and monitor delivery progress in real-time.",
  },
  {
    icon: <TbBrandGoogleAnalytics />,
    title: "Analytics and Reporting",
    content:
      "Businesses can effectively track key performance metrics and gain valuable insights into their delivery operations and make meaningful improvements.",
  },
];
const IndustiesDeliveryOperators = [
  {
    icon: <BiSlider />,
    title: "Route Optimization",
    content:
      "Our efficient route planning and optimization helps businesses optimize their delivery routes, minimize distance traveled, and reduce fuel costs.",
  },
  {
    icon: <TbLiveView />,
    title: "Real-Time Tracking",
    content:
      "Businesses can track their delivery vehicles and monitor their location to ensure timely delivery and provide customers with accurate delivery updates.",
  },
  {
    icon: <MdDisplaySettings />,
    title: "Automatic Dispatch",
    content:
      "Allows businesses to automate the dispatching process, reducing the need for manual intervention, manage delivery schedules and optimize resources.",
  },
  {
    icon: <TbBrandGoogleAnalytics />,
    title: "Analytics",
    content:
      "Analytics and reporting help businesses track their delivery performance, identify areas for improvement, and make data-driven decisions    ",
  },
  {
    icon: <MdNotificationsActive />,
    title: "Customer Notifications",
    content:
      "Help businesses keep their customers informed about their delivery status and provide them with accurate delivery updates.",
  },
  {
    icon: <TbTruckDelivery />,
    title: "Proof of Delivery",
    content:
      "Enable businesses to collect digital signatures and photos to confirm that the delivery has been made to avoid disputes.",
  },
];
const IndustiesThirdPartyLogistics = [
  {
    icon: <TbLiveView />,
    title: "Real-Time Shipment Tracking",
    content:
      "Provides you with real-time updates on the status & location of your shipments and can track shipments at any time, from anywhere, and stay up-to-date on their progress.",
  },
  {
    icon: <AiOutlineSliders />,
    title: "Business Intelligence",
    content:
      "Gain insights into your logistics operations by tracking key performance indicators and generating customizable reports to help you improve overall logistics efficiency.",
  },
  {
    icon: <MdOutlineInventory2 />,
    title: "Inventory Management",
    content:
      "You can track and manage inventory levels to reduce excess inventory and improv order fulfillment rates, ensuring that you always have the right amount of stock on hand.",
  },
  {
    icon: <MdOutlineWarehouse />,
    title: "Warehouse Management",
    content:
      "Businesses can manage their warehouse space, track real-time inventory data, and optimize the picking and packing processes, reducing errors and improving efficiency.",
  },
  {
    icon: <RiSecurePaymentLine />,
    title: "Billing and Invoicing",
    content:
      " Simplifies your billing and invoicing processes, reducing errors and improving accuracy. You can generate invoices, process payments, and generate reports.",
  },
  {
    icon: <BiSlider />,
    title: "Route Optimization",
    content:
      "Plan efficient routes and reduce transportation costs by optimizing your delivery schedule. This feature allows you to minimize the number of miles traveled and ensure on-time deliveries.",
  },
];
const IndustiesMovingServices = [
  {
    icon: <BsGenderTrans />,
    title: "Job Tracking",
    content:
      "Offers real-time job tracking, allowing you to keep tabs on your team’s progress and ensure timely completion of each job.",
  },
  {
    icon: <MdAssignmentAdd />,
    title: "Job Assignments",
    content:
      "You can assign jobs to workers based on their availability and skillset, guaranteeing the best possible outcome for each job.",
  },
  {
    icon: <RiSecurePaymentLine />,
    title: "Digital Payment Options",
    content:
      "Provides a variety of digital payment options, making it easy for your customers to pay for their moving services securely and conveniently.    ",
  },
  {
    icon: <MdNotificationsActive />,
    title: "Status Notification",
    content:
      "Automatically sends job status notifications to workers and customers, keeping everyone informed throughout the moving process.",
  },
  {
    icon: <AiOutlineSchedule />,
    title: "Schedule Management",
    content:
      "Offers advanced scheduling management tools, making it easy for you to efficiently allocate resources and balance your team’s workload.",
  },
  {
    icon: <SiSpringsecurity />,
    title: "Robust Security",
    content:
      "Equipped with advanced security features, ensuring that your business data and customer information are protected at all times.    ",
  },
];
const IndustiesFleetServicing = [
  {
    icon: <TbLiveView />,
    title: "Real-time Fleet Tracking",
    content:
      "Provides businesses with real-time tracking of their vehicles, allowing them to effectively and accurately monitor fleet locations, status, and performance at all the time. ",
  },
  {
    icon: <RiTaxiWifiLine />,
    title: "Fleet Maintenance",
    content:
      "Our solution offers tools for managing vehicle maintenance and repair schedules, guarantees that your fleet is always in top condition and reduces the risk of breakdowns and downtime.",
  },
  {
    icon: <TbMessageReport />,
    title: "Reporting",
    content:
      "Allows you to generate comprehensive reports on your fleet operations, performance and costs, and helps you make informed decisions about your business.",
  },
  {
    icon: <MdNotificationsActive />,
    title: "Customer Notification    ",
    content:
      "You can communicate with your customers easily with our software, including sending automated notifications, responding to inquiries and tracking customer feedback.    ",
  },
  {
    icon: <RiSecurePaymentLine />,
    title: "Invoicing and Billing",
    content:
      "Simplifies your invoicing process with our automated billing and invoicing feature, customizable billing cycles and integrated payment options.    ",
  },
  {
    icon: <BiSlider />,
    title: "Route Optimization    ",
    content:
      "Optimize your fleet routes to reduce travel time and fuel expenses, with real-time traffic data and powerful intelligent route planning algorithms. ",
  },
];
const IndustiesFacilityServicing = [
  {
    icon: <AiFillContainer />,
    title: "Work Order Management    ",
    content:
      "The software provides a comprehensive digital work order management system that allows businesses to track, prioritize, and manage all their service requests effectively.",
  },
  {
    icon: <TbLiveView />,
    title: "Real-time Tracking",
    content:
      "Businesses can track service progress and updates in real time, enabling them to make timely decisions and respond quickly to any issues that may arise during service fulfillment.",
  },
  {
    icon: <MdOutlineInventory2 />,
    title: "Inventory Management",
    content:
      "Provides inventory management capabilities that allow businesses to accurately track inventory levels and ensure adequate supplies are available for servicing.",
  },
  {
    icon: <MdOutlineIntegrationInstructions />,
    title: "Seamless Integration    ",
    content:
      "Allows businesses to properly consolidate data and streamline their operations by automating manual processes, improving efficiency and minimizing the risk of errors.",
  },
  {
    icon: <MdOutlineShareLocation />,
    title: "Multi-location Support",
    content:
      "Enables businesses to strategically manage facility service operations across different locations, ensuring consistency, reliability and efficiency in service delivery.",
  },
  {
    icon: <AiOutlineSchedule />,
    title: "Scheduling",
    content:
      "Our solution provides an easy-to-use scheduling system that allows businesses to schedule service appointments and assign technicians to specific tasks.    ",
  },
];
const BusinessPartners = [
  {
    title: "App Store Optimization",
    content:
      "We expertly optimize your app using industry-leading techniques, ensuring it is easily discoverable by potential customers.",
    icons: <SiAppstore />,
  },
  {
    title: "Acquisition Campaigns",
    content:
      "Expand your customer base, our campaigns are aimed to attract new customers and increase your brand visibility.",
    icons: <IoGitCompareSharp />,
  },
  {
    title: "Expert Consulting",
    content:
      "Our experienced business experts offer valuable advice and guidance to help you navigate the dynamic mobility industry.      ",

    icons: <FaUserCheck />,
  },
  {
    title: "Customer Retention",
    content:
      "We help you engage and retain your existing customers with our expert assistance and effective strategies.",
    icons: <MdInterpreterMode />,
  },
];
const BusinessAbout = [
  {
    title: "Check out our blog",
    content:
      "Stay informed and connected with the latest news, trends, and insightful updates on a daily basis.",
    icons: <SiAppstore />,
    href: "/blog/articles",
  },
  {
    title: "Look into careers",
    content:
      "Be part of our team and embark on an exciting journey filled with growth, opportunities, and success.",
    icons: <IoGitCompareSharp />,
    href: "/career",
  },
  {
    title: "Stay connected on LinkedIn",
    content:
      "Join us on our LinkedIn page to keep up with the latest updates and be part of our network.      ",

    icons: <FaUserCheck />,
    href: "https://www.linkedin.com/company/zervx-all-services-in-one-app/",
  },
  {
    title: "Help center",
    content:
      "Discover valuable resources and expert assistance in our comprehensive help center for all your needs.",
    icons: <MdInterpreterMode />,
    href: "/coming-soon",
  },
];
const CraftingSuccess = [
  {
    title: "100+",
    title2: "Cities Served",
    content:
      "Our software extends its reach to numerous cities worldwide, serving businesses.",
    icons: (
      <HiOutlineGlobeAsiaAustralia
        className="h-100 text-green"
        style={{ width: 47 }}
      />
    ),
  },
  {
    title: "80k+",
    title2: "Drivers Onboarded",
    content:
      "Our platform enabled businesses to effectively onboard a large number of drivers.",
    icons: (
      <HiOutlineUserPlus className="h-100 text-green" style={{ width: 47 }} />
    ),
  },
  {
    title: "10M+",
    title2: "Jobs Completed",
    content:
      "Businesses have successfully completed over millions of rides, deliveries, and services with us.",
    icons: (
      <BsClipboardCheck className="h-100 text-green" style={{ width: 41 }} />
    ),
  },
  {
    title: "99.99%",
    title2: "Average Uptime",
    content:
      "Our platform enabled businesses to effectively onboard a large number of drivers.",
    icons: (
      <IoRocketOutline className="h-100 text-green" style={{ width: 45 }} />
    ),
  },
];
const ToolsList = [
  {
    id: 1,
    title: "Passenger App",
    href: "PassengerApp",
    tag: "#passenger-app",
  },
  {
    id: 2,
    title: " Driver App",
    href: "DriverApp",
    tag: "#driver-app",
  },
  {
    id: 3,
    title: "Admin Panel",
    href: "AdminPanel",
    tag: "#admin-panel",
  },
  {
    id: 4,
    title: "Dispatcher Panel",
    href: "DispatcherPanel",
    tag: "#dispatcher-panel",
  },
  {
    id: 5,
    title: "Corporate Panel",
    href: "CorporatePanel",
    tag: "#corporate-panel",
  },
  {
    id: 6,
    title: "Web Booking",
    href: "WebBooking",
    tag: "#web-booking",
  },
  {
    id: 7,
    title: "Website",
    href: "Website",
    tag: "#websiteid",
  },
];
const OfficeLocated = [
  {
    title: "India",
    desc1: "3K, Vantage Plaza, 1, LB Rd,",
    desc2: "Subramaniam Colony, Adyar, Chennai.",
    desc3: "",
    desc4: "117B, Pammam,",
    desc5: "Marthandam, Kanyakumari.",
    desc6: "",
    desc7: "",
    desc8: "",
    img: img4,
  },
  {
    title: "UAE",
    desc1: "#66 Al Yanbou St,",
    desc2: "Al Danah,",
    desc3: "Abu Dhabi, UAE.",
    img: img4,
  },
  {
    title: "Ireland",

    desc1: "6, Maxwell street,",
    desc2: "Saint Catherine's,",
    desc3: "  Dublin 8",
    img: img4,
  },
];
const nonTitleBlog = [
  {
    id: 1,
    title: "",
    img: blogImgFirstOne,
    imgalt: "Taxi app development",
    descArray: [
      {
        desc: "In today's fast moving world, taxi apps have become an integral part of our lives. They provide convenient transportation services and have revolutionized the way we travel. However, developing a successful taxi app is no easy feat. It requires careful planning, implementation, and adherence to best practices to ensure its success.",
      },
      {
        desc: " In this blog post, we will explore the best practices and key considerations while developing a taxi app that stands out from the competition and captures the attention of users.",
      },
    ],
  },
];
const nonTitleBlogThree = [
  {
    id: 1,
    title: "",
    img: BlogThree,
    imgalt: "Traditional taxis",

    descArray: [
      {
        desc: "In recent years, the emergence of taxi apps has revolutionized the transportation industry, offering convenient and efficient ride-hailing services. These innovative platforms connect passengers with nearby drivers through user-friendly mobile applications, transforming the traditional taxi experience.            ",
      },
      {
        desc: "In this blog, we will delve into the technical architecture and inner workings of taxi apps, highlighting their benefits and exploring the future of ride-hailing technology.",
      },
    ],
  },
];
const nonTitleBlogTwo = [
  {
    id: 1,
    title: "",
    img: BlogTwo,
    imgalt: "Rise of taxi apps",

    descArray: [
      {
        desc: "The advent of taxi apps has transformed urban transportation, providing riders with convenient and affordable services. These apps have not only made hailing a cab easier but also revolutionized the way people travel in cities.            ",
      },
      {
        desc: "Let's explore the impact of taxi apps on urban transportation and understand the benefits they bring.",
      },
    ],
  },
  {
    id: 2,
    title: "What Are Taxi Apps?",
    img: Taxi,
    imgalt: "Benefits of taxi apps",

    descArray: [
      {
        desc: "The taxi app is a smartphone application that allows users to order and pay for a taxi without having to hail one on the street. The first of its kind was Uber, which launched in 2009. Since then, Lyft, and other companies have followed suit.",
      },
      {
        desc: "Taxi apps have been a boon for urbanites who often find themselves in situations where hailing a cab is difficult, if not impossible. They've also been great for people who are new to a city or unfamiliar with its streets.",
      },
      {
        desc: "But beyond convenience, these apps have also had a profound impact on urban transportation. They've helped to reduce traffic congestion and pollution, and have made it easier for people to get around without owning a car.",
      },
    ],
  },
  {
    id: 2,
    title: "How Do Taxi Apps Work?",
    img: FeaturesOfTaxiApp,
    imgalt: "On-demand services",
    descArray: [
      {
        desc: "If you've ever taken a taxi, you know the drill. You hail a cab, get in, and tell the driver where you're going. The driver then turns on the meter, and you're off!      ",
      },
      {
        desc: "But what if there was a better way? What if you could use your smartphone to hail a cab and pay for the ride, all without ever having to talk to the driver?      ",
      },
      {
        desc: "That's where taxi apps come in. Taxi apps like Uber and Lyft are changing the way people get around cities. With a few taps on your smartphone, you can order a car to pick you up and take you to your destination.",
      },
      { desc: "But how do these apps work? Let's take a closer look." },
      {
        desc: "When you open up a taxi app, the first thing you'll need to do is enter your location. The app will then use your phone's GPS to find nearby drivers. Once the app has found a driver, it will give you an estimate of how long it will take for the driver to reach you.",
      },
      {
        desc: "Once you've hailed a driver, you can track their progress on the map in the app. This way, you will know exactly when they're going to arrive.      ",
      },
      {
        desc: "When the driver arrives, they will confirm your destination and then start the meter. You will be able to see the fare adding up in real-time in the app. And when you arrive at your destination, the app will automatically charge your credit card and give the driver a tip (if you choose to add one).",
      },
      {
        desc: "It's really that simple! With a taxi app, you can say goodbye to hailing cabs and haggling over fares. Just enter your destination, sit back, and relax until you arrive at your destination.      ",
      },
    ],
  },
];

const nonTitleBlogFive = [
  {
    id: 1,
    title: "",
    descArray: [
      {
        desc: "In the highly competitive taxi industry, having the right software can make all the difference. The best taxi dispatch software can streamline operations, improve efficiency, and enhance customer satisfaction. But with so many options available, choosing the perfect software for your business can be overwhelming. That is why we have created this ultimate guide to help you navigate through the complexities of taxi dispatch systems.",
      },
      {
        desc: "From understanding the features to considering your business needs, we will provide all the information you need to make an informed decision. Whether you are a small start-up or an established taxi company, this guide will take you step-by-step on your journey towards selecting the ideal taxi dispatch system for your business.",
      },
    ],
  },
  {
    id: 2,
    title: "What is Taxi Dispatch Software?",
    descArray: [
      {
        desc: "Let's first understand what Taxi dispatching software is and its significance in the taxi industry.",
      },
      {
        desc: "Taxi dispatch software is a technological solution designed to facilitate efficient fleet management and dispatching of taxis. It replaces traditional taxi booking and manual dispatch methods by digitizing and automating various processes, improving overall operations.",
      },
    ],
  },
  {
    id: 3,
    title: "",
    img: blogImgFiveFirst,
    imgalt: "Taxi dispatch software",
    descArray: [
      {
        desc: "",
      },
    ],
  },
];
const nonTitleBlogSix = [
  {
    id: 1,
    title: "",
    img: blogImgSixFirst,
    imgalt: "Best Uber clone",
    descArray: [
      {
        desc: "Do you want to build your own taxi company? Starting with Uber taxi clone will be a great start for the business. What, exactly, is an Uber clone script app and what can you do with it to win in this extremely competitive market?",
      },
      {
        desc: "In this blog, we will go through everything you need to know about developing your own taxi business like Uber, its background, key features to modifying the app according to your brand identity and scaling the business.",
      },
      {
        desc: "At the end of this post, you will have all the vital knowledge and tips to start your taxi business and to be able to outstand in the market full of competitors.",
      },
    ],
  },
];
const nonTitleBlogSixSecond = [
  {
    id: 1,
    title: "",
    descArray: [
      {
        desc: "Are you looking to enter the taxi industry with your own startup? A great way to kick off your venture is by launching an Uber taxi clone. But what exactly is an Uber clone script, and how can it help you succeed in this highly competitive market?",
      },
      {
        desc: "In this blog, we'll take a deep dive into everything you need to know about launching your own taxi startup with an Uber like app, from understanding its concept and its crucial features to customizing it according to your brand identity and expanding your business.",
      },
      {
        desc: "By the end of this blog, you'll be equipped with all the essential information required to launch a successful taxi start-up that can stand out in a crowded market.",
      },
    ],
  },
];
const nonTitleBlogSeven = [
  {
    id: 1,
    title: "",
    img: blogImgSeventhFirst,
    imgalt: "Surge pricing",
    descArray: [
      {
        desc: "",
      },
    ],
  },
  {
    id: 2,
    title: "",
    descArray: [
      {
        desc: (
          <>
            In our relentless pursuit of excellence in on-demand service
            solutions, we are happy to unveil our latest enhancement to the
            ZervX: the surge pricing. As a leading provider of{" "}
            <Link href="/" passHref>
              <a> ride hailing software </a>
            </Link>{" "}
            solutions for businesses in the transportation sector, we understand
            the importance of efficiency, reliability and transparency. This
            blog will delve into the significance of this new feature,
            explaining what surge pricing is, how it works, and the benefits it
            brings to passengers, drivers and the taxi businesses.
          </>
        ),
      },
    ],
  },
  {
    id: 3,
    title: "What is surge pricing?",
    descArray: [
      {
        desc: "Surge pricing is a dynamic pricing mechanism that adjusts fares based on supply and demand dynamics. During periods of high demand and limited driver availability, prices increase to incentivize more drivers to enter the market, thereby balancing supply with demand. This ensures that passengers can still secure rides when demand peaks, albeit at a slightly higher cost.",
      },
    ],
  },
  {
    id: 4,
    title: "How it works",
    descArray: [
      {
        desc: "When the demand for rides is high and there are not enough drivers nearby, the price of a ride goes up temporarily. This encourages more drivers to come online and pick up passengers, helping to balance things out, and the surge in pricing does not last forever. Once the demand goes down or more drivers come online, the prices return to normal. We have implemented two types of surge pricing to better serve our clients and their unique needs.",
      },
    ],
  },
  {
    id: 5,
    title: "Automatic surge pricing",
    descArray: [
      {
        desc: "Our automated surge pricing uses advanced artificial intelligence algorithms, and it continuously analyzes demand patterns and driver availability in real-time. When the system detects high demand or limited driver availability in certain areas, it automatically adjusts fares to incentivize more drivers to come online.",
      },
    ],
  },
  {
    id: 6,
    title: "Manual surge pricing",
    img: blogImgSeventhSecond,
    imgalt: "AI powered surge pricing",
    descArray: [
      {
        desc: "In the manual surge pricing, admins have the ability to manually adjust pricing thresholds and surge multipliers based on specific demand patterns. This flexibility allows for customized pricing strategies tailored to specific market conditions or business objectives.",
      },
    ],
  },
  {
    id: 7,
    title: "",
    descArray: [
      {
        desc: "",
      },
    ],
  },
  {
    id: 8,
    title: "Admin panel and driver app updates",
    descArray: [
      {
        desc: "In addition to the surge pricing, our admin panel and driver app have undergone significant updates to support this new feature. Admins now have greater control over surge pricing parameters, allowing them to adjust pricing thresholds and surge multipliers based on demand fluctuations.",
      },
      {
        desc: "Drivers, on the other hand, receive instant notifications about surge zones and also able to view the heat map, enabling them to respond promptly and efficiently to changing demand patterns.",
      },
    ],
  },
];
const nonTitleBlogEight = [
  {
    id: 1,
    title: "",
    img: blogImgEightFirst,
    imgalt: "Car rental management software",
    descArray: [
      {
        desc: "Did you realize that car rental companies worldwide are riding the wave of software-led operations? The fine line between staying archaic and moving towards a digitized future is blurring every day. To stay competitive, it's high time businesses like yours turn to vehicle rental software.",
      },
    ],
  },
  {
    id: 2,
    title: "",
    img: "",
    imgalt: "",
    descArray: [
      {
        desc: "",
      },
    ],
  },
  {
    id: 3,
    title: "What is Car Rental Software?",
    img: "",
    imgalt: "",
    descArray: [
      {
        desc: "Car rental management software is a purpose-built technological solution meant to assist car rental businesses with key operations. From fleet management and invoicing to reservations and analytics reporting, this software is designed to handle a broad range of activities integral to running a successful car rental business.",
      },
    ],
  },
  {
    id: 4,
    title: "How Does It Work?",
    img: "",
    imgalt: "",
    descArray: [
      {
        desc: "In practice, rental software operates as a one-stop-shop for managing rental operations. The vehicle booking system is at the heart of this software, allowing businesses to keep track of vehicle availability and handle rental scheduling efficiently. It has features that help in pricing models management, whether they be seasonal, distance-based, or promotional. This software also offers real-time fleet tracking, assisting rental companies in maintaining up-to-the-minute insights on contracts, damages, and maintenance schedules.",
      },
      {
        desc: "Advanced versions of this software provide mobile apps for customer check-in and check-out, damage reporting, and contract management, making customer relations effortless. The software also integrates features for driver verification, maintenance alerts, payment processing, and analytics reporting, virtually erasing the need for paperwork. It comes loaded with a myriad of other functionalities, such as customer profile creation, promotional offers management, roadside assistance, and more.",
      },
      {
        desc: "What this translates into is a reduction in workload, cost-effectiveness, and enhanced revenue generation. And the best part? This breakthrough software is not just meant for cars; it can be adapted for boats, planes, buses and even construction equipment, making it a flexible solution for a variety of rental industries.",
      },
    ],
  },
];
const nonTitleBlogNine = [
  {
    id: 1,
    title: "",
    img: blogImgNineFirst,
    imgalt: "Client’s achievements",
    descArray: [
      {
        desc: "",
      },
    ],
  },
  {
    id: 2,
    title: "",
    descArray: [
      {
        desc: "",
      },
    ],
  },
  {
    id: 3,
    title: "About the Company",
    descArray: [
      {
        desc: "ZayRide is a prominent on-demand taxi service company based in Ethiopia, with its  headquarters in Addis Ababa. It is a leading player in the Ethiopian ride hailing industry.  Established as a customer-centric service, ZayRide offers fast and convenient taxi services  throughout Ethiopia, including cities like Hawassa, Bahirdar, and Gonder.",
      },
      {
        desc: "The company prides itself on providing quality services anytime, anywhere, with a 24/7 call center to ensure customer satisfaction. ZayRide stands out as an innovative taxi-hailing app in  Ethiopia, emphasizing technology-driven transport solutions and boasting a vast network of well-trained and secure taxi drivers. Apart from taxi services, they also provide delivery services.",
      },
    ],
  },
];
const nonTitleBlogTen = [
  {
    id: 1,
    title: "",
    img: blogImgTenFirst,
    imgalt: "Client’s achievements with our taxi software",
    descArray: [
      {
        desc: "",
      },
    ],
  },
  {
    id: 2,
    title: "",
    descArray: [
      {
        desc: "",
      },
    ],
  },
  {
    id: 3,
    title: "About the Company",
    descArray: [
      {
        desc: "JetRide is a fast-growing ride-hailing business enterprise in Nigeria that offers cost-effective transportation to customers. Established in 2017, JetRide aims to meet the transportation needs of individuals in Lagos, Ibadan, and Abuja by offering competitive pricing for their services. The company's commitment to affordability distinguishes it in the transportation industry, making it an appealing choice for commuters seeking budget-friendly travel option.",
      },
    ],
  },
];
const nonTitleBlogEleven = [
  {
    id: 1,
    title: "",

    descArray: [
      {
        desc: (
          <>
            Life in the 21st century is undeniably busy. In tandem, the
            ever-evolving digital landscape is catering to these dynamic
            lifestyles by offering solutions that unite various different
            services into one single platform. These are famously known as super
            apps. They consolidate services like{" "}
            <Link href="/industries/ride-hailing/" passHref>
              <a>ride-hailing, </a>
            </Link>
            food delivery, digital payments, among others, into one seamless
            user experience. The attractiveness lies in eliminating the need to
            switch between multiple apps, thus saving time and, crucially data
            and memory space on devices.
          </>
        ),
      },
    ],
  },
  {
    id: 2,
    title: "",
    img: blogImgElevenFirst,
    imgalt: "Super apps",
    descArray: [
      {
        desc: "",
      },
    ],
  },
  {
    id: 3,
    title: "",
    descArray: [
      {
        desc: "",
      },
    ],
  },
  {
    id: 4,
    title: "",
    descArray: [
      {
        desc: "Addressing the pressing relevance of this topic in today's on-demand business landscape, this article provides an informative resource. Whether you are an executive grappling with digital transformation or a business owner seeking to harness technology for operational efficiency, this article is for you.",
      },
    ],
  },
  {
    id: 5,
    title: "",
    descArray: [
      {
        desc: "The numbers of these apps are growing fast on the internet space. The situation has been made possible by the momentum in artificial intelligence, cloud computing and blockchain and the rapid adoption of these diverse applications. Moreover, the number of Americans (67%) who want integrated digital experiences to enhance their apps is no longer a question of ‘if’ they will spread across the United States, but a question of ‘when’.",
      },
    ],
  },
  {
    id: 6,
    title: "What is a Super App?",
    descArray: [
      {
        desc: "A super app literally means a platform that provides a variety of integrated services at a single interface. Service categories include communication, payments, e-commerce, transport, food delivery, and so on. At the heart of this digital revolution is the most vital aspect, i.e. convenience. Now, users can perform multiple things at the click of a button.",
      },
      {
        desc: "Superapp is the next level in the application development technology. It is a single system combining the individual characteristics of each service, and moreover, provides a uniform user experience throughout. This product is the main driver of operational efficiency by combination, which eliminates the need to keep switching between the applications. A super app thus exists as a single mobile-first platform, supporting an ecosystem of smaller mini apps, which cater to a user's diverse needs.",
      },
    ],
  },
  {
    id: 7,
    title: "History and Evolution",
    descArray: [
      {
        desc: "The concept of a super app ignited in China with the launch of WeChat, which integrated features like messaging and digital wallet into one platform. Other major players like Grab, and Gojek have embarked on similar ventures, particularly targeting the Southeast Asian market. Over time, these apps have started offering services beyond their initial core functionality, leveraging their large user base, rich data, and trust to reach a broader market effectively.",
      },
    ],
  },
  {
    id: 8,
    title: "Importance in the Mobile Application Market",
    descArray: [
      {
        desc: "These apps are revolutionizing the on-demand services sector. They enrich customer retention strategies by providing a one-stop solution for a variety of needs.",
      },
      {
        desc: "One of the key tenets of these integrated platforms is customization. The primary advantage of offering diverse services within a high-functioning and user-friendly interface is that it allows for improved user experience (UX). In an intensely competitive application market, personalization can dramatically drive engagement and, consequently, revenue growth.",
      },
      {
        desc: "Another factor enhancing the relevance of these apps is the seamless interconnectivity they offer between different other features. This reduces the need for multiple standalone apps,leading to significant storage and time savings for end users. Corporate entities also benefit from cost savings by managing multiple features within one platform.",
      },
    ],
  },
];
const nonTitleBlogTwelve = [
  {
    id: 1,
    title: "",
    img: "",
    imgalt: "",
    descArray: [
      {
        desc: "Are you looking to improve your taxi service? White label taxi solutions could be the solution. This system is user-friendly and can be tailored to meet your business needs, hence making it simple to run and very pleasant for drivers as well as passengers.",
      },
    ],
  },
  {
    id: 2,
    title: "",
    img: "",
    imgalt: "",
    descArray: [
      {
        desc: (
          <>
            In this article, we'll look at why taxi businesses are using{" "}
            <Link href="/industries/ride-hailing" passHref>
              <a> white label taxi software </a>
            </Link>
            to improve services and remain competitive in the evolving
            transportation industry. Find out how white label software can help
            your taxi business.
          </>
        ),
      },
    ],
  },
  {
    id: 3,
    title: "",
    img: blogImgTwelveThrid,
    imgalt: "White label taxi software",
    descArray: [
      {
        desc: "",
      },
    ],
  },
];
const nonTitleBlogThirteen = [
  {
    id: 1,
    title: "",
    img: "",
    imgalt: "",
    descArray: [
      {
        desc: "In the competitive landscape of business, the choices you make can often mean the difference between soaring success and struggling to keep afloat. Consider two business owners: one used the fleet management software and noticed an amazingly huge increase in their income while another one still stuck to the old traditional methods of managing the fleets and got behind the competition. The difference in their results shows how much the software for fleet management affects the new business trends.",
      },
    ],
  },
  {
    id: 2,
    title: "",
    img: "",
    imgalt: "",
    descArray: [
      {
        desc: "In this blog, we will go through the functions, advantages, and key factors to look for in vehicle management software when you need to pick the best option for your business.",
      },
    ],
  },
  {
    id: 3,
    title: "",
    img: "",
    imgalt: "",
    descArray: [
      {
        desc: "",
      },
    ],
  },
  {
    id: 4,
    title: "",
    img: blogImgThirteenFirst,
    imgalt: "Fleet Management Software",
    descArray: [
      {
        desc: "",
      },
    ],
  },
  {
    id: 5,
    title: "What is fleet management software?",
    img: "",
    imgalt: "",
    descArray: [
      {
        desc: "Fleet management system is a complete management software that is developed to operate a variety of aspects of fleet operation in an effective and efficient way. From tracking vehicles, maintenance schedules to planning routes and monitoring accessories, this software helps enterprises to manage their fleets with accuracy and proficiency.",
      },
    ],
  },
];
const nonTitleBlogFourteen = [
  {
    id: 1,
    title: "",
    img: blogImgFourteenFirst,
    imgalt: "How to start a taxi business",
    descArray: [
      {
        desc: "Are you thinking about starting your own taxi business like Uber? The transportation industry is evolving due to technology and changing consumer habits.",
      },
      {
        desc: "Here is a guide to help how to start a taxi business and enter the competitive world of ride-hailing services. The main intention of this detailed guide is to help people who want to be very successful when they start the taxi business. Keeping the reality of the world in 2024 in mind, taxi business has revealed a very bright earning style capable of which is multiplies by the flexibility and scalability of the business.",
      },
      {
        desc: "The guide will thoroughly address the aspects of market research, business planning strategies, complying with the required regulations and licensings, as well as how to incorporate the advancements in the taxi industry.",
      },
    ],
  },
  {
    id: 2,
    title: "",
    descArray: [
      {
        desc: "Establishment of a successful cab service means a thorough planning, recognition of rival business, strict connection with norms of the regulatory segment, prudent budgeting, fresh ideas, as well as efficient marketing. It's not just about investing in a fleet and putting them on the road. A successful taxi business requires thorough industry knowledge, key strategy development and an in-depth understanding of your potential customers' demands.",
      },
      {
        desc: "Through this guide, you will learn effective strategies for launching a successful taxi cab business, and gain insights into structuring a sound business model for your venture.",
      },
    ],
  },
  {
    id: 3,
    title: "An Overview of the Taxi Industry",
    descArray: [
      {
        desc: "The taxi industry has a rich history that shows that the service is still as relevant today as it was in the past. This industry, starting from the development of the metered taxicab in the early 1900s, has incorporated many changes throughout the years through technology, customer demands, legislation, and competitors.",
      },
      {
        desc: "The beginning of the twenty-first century and the introduction of smartphone applications drastically changed the industry and gave birth to market leaders like Uber and Lyft. Such companies have been prominent in the recent past to provide the market with a mobile application that connects drivers using their personally owned cars with customers who need transportation.",
      },
    ],
  },
];
const nonTitleBlogFifteen = [
  {
    id: 1,
    title: "",
    descArray: [
      {
        desc: "Present limousine service is more and more focused on new technologies that can improve efficiency and delivery.",
      },
      {
        desc: "Modern life is unthinkable without digital connections, and technology also remains a driving force for successful limousine businesses. New communication technology, scheduling, tracking of vehicles, billing and invoicing services are few of the many ways technology plays an important role in the limo business. This has in turn created the need for limousine companies to adopt specialized software into its system.",
      },
    ],
  },
  {
    id: 2,
    title: "",
    img: blogImgFifteenFirst,
    imgalt: "limousine dispatch software",
    descArray: [
      {
        desc: "",
      },
    ],
  },
  {
    id: 3,
    title: "",
    descArray: [
      {
        desc: "In this blog we will discuss why your limo business needs the best limo booking software to survive and grow in this competitive market as well as to become more efficient and offer the best rates and services to the customers.",
      },
    ],
  },
  {
    id: 4,
    title: "What is a Limousine Software?",
    img: "",
    imgalt: "",
    descArray: [
      {
        desc: (
          <>
            Limo software, or commonly known as limousine dispatch software, is
            an all-in-one solution that encompasses management for limo
            businesses. It can be distinguished from conventional{" "}
            <Link href="/blog/fleet-management-software/" passHref>
              <a> fleet management system </a>
            </Link>
            in several ways. It is developed specially for limo businesses and
            is based on the some key factors like luxury service provision,
            treatment of valued clients, and time management. This system often
            includes features such as route optimization, reservations, billing
            and invoicing, dispatch, and tracking through GPS. The idea is to
            improve business management, organizational effectiveness, and
            services delivery to clients.
          </>
        ),
      },
    ],
  },
  {
    id: 5,
    title: "The Advantages of Limousine Reservation Software in Limo Industry",
    descArray: [
      {
        desc: "There are many advantages of implementing limousine software in a limo service. For instance, the use of this software can be regarded as vital for effective route management. It is important to know where to go and which route to take in order to save time and fuel which is a very valuable resource.",
      },
      {
        desc: "Furthermore, limousine management system assists in better fleet management and maintenance. By adopting this approach, businesses can be in a position to avoid shocks of vehicle breaks downs and expenses incurred to have the vehicle serviced and repaired.",
      },
      {
        desc: "In addition, this software greatly improves customer satisfaction. Real-time access to the booking information can also help customer requests be addressed with more certainty, thus making for more satisfied clients and repeat turnovers.",
      },
    ],
  },
];
const nonTitleBlogSixteen = [
  {
    id: 1,
    title: "",
    descArray: [
      {
        desc: "The ride hailing has become an innovation of transport, disrupting the transport sector and in particular the taxi business in urban regions. The ride hailing applications have become popular nowadays and very commonly used since they are safe, comfortable and efficient in use.",
      },
      {
        desc: "In this blog post, we will discuss the impact of ride hailing platforms in the market of taxi services, which will include the overview of the development of such applications, their main advantages, and their impact on taxi businesses.",
      },
    ],
  },
  {
    id: 2,
    title: "",
    img: blogImgSixteenFirst,
    imgalt: "Ride hailing app",
    descArray: [
      {
        desc: "",
      },
    ],
  },
  {
    id: 3,
    title: "What is Ride Hailing?",
    descArray: [
      {
        desc: "Ride hailing is a service where passengers can order rides online through an application on a smartphone similar to a taxi service. It connects passengers with drivers of vehicles for hire, meaning that it offers a convenient transport system in a flexible manner.",
      },
    ],
  },
  {
    id: 4,
    title: "What is a Ride Hailing App?",
    img: "",
    imgalt: "",
    descArray: [
      {
        desc: "The ride hailing application is a piece of software that supports the provision of the ride hailing service. For the passengers, they can book, manage and pay for the trip while the driver side enables drivers to work their schedules, see all the passengers’ requests, and communicate in real-time. These apps are intended for user-friendly experience presenting options like tracking the route of a taxi in real-time, sharing estimated time of arrival to a destination, and multiple payment options.",
      },
    ],
  },
  {
    id: 5,
    title:
      "Current Market Status and Major Players of the Ride Hailing Service",
    descArray: [
      {
        desc: (
          <>
            Ride hailing is a fast-growing industry currently valued at $80
            billion and projected to cross $220 billion by 2025, giants in the
            industry include{" "}
            <a href="https://www.uber.com/" target="_blank">
              {" "}
              Uber,{" "}
            </a>
            <a href="https://www.lyft.com/" target="_blank">
              {" "}
              Lyft,{" "}
            </a>
            <a href="https://web.didiglobal.com/" target="_blank">
              {" "}
              Didi Chuxing{" "}
            </a>
            in several ways. It is developed specially for limo businesses and
            is based on the some key factors like luxury service provision,
            treatment of valued clients, and time management. This system often
            includes features such as route optimization, reservations, billing
            and invoicing, dispatch, and tracking through GPS. The idea is to
            improve business management, organizational effectiveness, and
            services delivery to clients.
          </>
        ),
      },
    ],
  },
];
const nonTitleBlogSeventeen = [
  {
    id: 1,
    title: "",
    descArray: [
      {
        desc: "Taxi booking software is an application used to offer a platform for transportation hailing where clients can easily hire cars and get an appropriate driver ready to take them to the desired destination. Besides enabling customers to order pickups from their preferred locations, this technology solution addresses traditional manual dispatching by providing an automated form of hailing taxis.",
      },
      {
        desc: (
          <>
            The history of cab booking applications began in the early 2000s and
            initially aimed at enhancing taxi dispatchers’ communications with
            drivers. Yet it took a long time to gain popularity which was seen
            with the introduction of new{" "}
            <Link href="/industries/ride-hailing/">
              <a> ride hailing platforms </a>
            </Link>
            such as Uber in the year 2009.
          </>
        ),
      },
    ],
  },
  {
    id: 2,
    title: "",
    img: blogImgSeventeenFirst,
    imgalt: "Taxi booking software",
    descArray: [
      {
        desc: "",
      },
    ],
  },
  {
    id: 3,
    title: "",
    descArray: [
      {
        desc: "The quick growth and the subsequent advancement of such platforms immensely fostered the advancement of the taxi booking software, making it an essential tool for taxi operations worldwide.",
      },
    ],
  },
];
const nonTitleBlogEighteen = [
  {
    id: 1,
    title: "",
    descArray: [
      {
        desc: "A taxi dispatch system serves as the backbone of any modern taxi business, managing the entire lifecycle of a ride from booking to payment. These intelligent software platforms automate key processes, improve efficiency, and provide valuable data-driven insights to taxi operators.",
      },
      {
        desc: "In this blog, we will explore the key benefits of taxi cab dispatch systems and why they are a must-have for any taxi business looking to stay competitive and profitable in the long run.",
      },
    ],
  },
  {
    id: 2,
    title: "",
    img: blogImgEighteenFirst,
    imgalt: "Taxi dispatch system",
    descArray: [
      {
        desc: "",
      },
    ],
  },
  {
    id: 3,
    title: "What is a Taxi Dispatch System?",
    descArray: [
      {
        desc: "At its core, a online taxi dispatch system is software that manages cab bookings, dispatching, and routing. It optimizes the entire process, from the moment a customer books a ride to when the driver drops them off.",
      },
    ],
  },
];

const nonTitleBlogNineTeen = [
  {
    id: 1,
    title: "",
    img: "",
    imgalt: "",

    descArray: [
      {
        desc: "As the taxi industry continues to evolve, businesses are seeking efficient ways to manage their operations, improve customer satisfaction, and increase revenue. One of the most effective tools for achieving this is taxi dispatch software. Whether you're a small taxi company or a large fleet operator, finding the right dispatch solution can streamline your processes, improve driver coordination, and boost overall performance.",
      },
      {
        desc: "In this blog, we'll explore the top 5 taxi dispatch software solutions for 2024. We'll compare key features, pricing, and benefits to help you choose the right system for your business.",
      },
    ],
  },
];

const BlogSingleFeatureData = [
  {
    id: 0,
    title: "",
    desc: "As the demand for ride-hailing services grows worldwide, taxi app development has become a great opportunity for businesses looking to tap into the mobility market. The taxi industry is changing fast, and having a reliable, easy-to-use taxi app is now a must to stay competitive.",
    blogImage: blogImgFirstThree,
    imgalt: "Taxi apps",
    subHead: [
      {
        subTitle: "",
        subDesc:
          "In this guide, we will walk you through the key steps, features, costs, and technologies involved in taxi app development. Whether you're a taxi business owner or an entrepreneur planning to launch your own ride-hailing service, this guide is designed to provide valuable insights.       ",
      },
    ],
  },
  {
    id: 1,
    title: "What is a Taxi App?",
    desc: (
      <p>
        A{" "}
        <Link href="/solutions/taxi-dispatch-software/">
          <a>taxi app</a>
        </Link>{" "}
        is a digital solution that connects passengers with drivers through
        mobile platforms, providing real-time location tracking, fare
        calculations, and seamless payments. Leading examples include Uber,
        Lyft, and Bolt.
      </p>
    ),
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
  },
  {
    id: 2,
    title: "Why Taxi Apps Are Essential in Today’s Market",
    desc: "",
    subHead: [
      {
        subTitle: "",
        subDesc: (
          <p>
            <strong>Growth in Ride-Hailing Industry: </strong> The global
            ride-hailing market is expected to reach{" "}
            <a href="https://www.alliedmarketresearch.com/ride-hailing-service-market">
              {" "}
              $126.52 billion by 2025.{" "}
            </a>{" "}
            This growth is fueled by convenience, urbanization, and the
            increasing demand for on-demand services.
          </p>
        ),
      },
      {
        subTitle: "",
        subDesc: (
          <p>
            <strong>Increased User Base: </strong> More than 100 million people
            globally use taxi apps regularly, making it essential for taxi
            companies to digitize and streamline operations.
          </p>
        ),
      },
      {
        subTitle: "",
        subDesc: (
          <p>
            <strong>Technological Advancements: </strong> Integration of AI, IoT
            , and big data makes ride-hailing apps more intelligent and
            efficient.
          </p>
        ),
      },
      {
        subTitle: "",
        subDesc: (
          <p>
            <strong>Post-Pandemic Growth: </strong> A surge in travel and
            tourism has revitalized the demand for ride-hailing services.
          </p>
        ),
      },
    ],
  },
  {
    id: 3,
    title: "Understand User Needs",
    desc: (
      <div>
        <p>
          T o create a successful taxi app, it’s important to know what your
          users want.
        </p>
        <p>
          <strong>
            <i>
              “The best way to find out what customers want is by asking them. ”
              — Steve Jobs
            </i>
          </strong>
        </p>
        <p>
          This quote reminds us that talking to your audience is key. By
          listening to their feedback and understanding their preferences, you
          can design your app to meet their needs. This will not only make users
          happier but also help you stand out in a crowded market.
        </p>

        <Read
          content={"How to Choose the Best Taxi Dispatch Software"}
          link={"/blog/how-to-choose-the-best-taxi-dispatch-software/"}
          also
        />
      </div>
    ),
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
  },
  {
    id: 4,
    blogImage: "",
    imgalt: "",
    title: "Key Features to Include in a Taxi App",
    desc: "An effective taxi app consists of three core components: Passenger App, Driver App, and Admin Panel. Here's what each part should include:",
    subHead: [
      {
        subTitle: "",
        subDesc: (
          <table className="table align-middle mt-5">
            <thead className="">
              <tr className="align-middle">
                <th className="text-left">Feature</th>
                <th className="text-left">Passenger App </th>
                <th className="text-left">Driver App </th>
                <th className="text-left">Admin Panel </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="content-list text-left list-unstyled col-1">
                  User Registration
                </td>
                <td className={`content-list text-left list-unstyled col-1`}>
                  Easy sign-up via email, phone, or social media
                </td>
                <td className={`content-list text-left list-unstyled col-1`}>
                  Driver verification and onboarding
                </td>
                <td className={`content-list text-left list-unstyled col-1`}>
                  Manage user and driver accounts
                </td>
              </tr>
              <tr>
                <td className="content-list text-left list-unstyled col-1">
                  Real-Time Tracking
                </td>
                <td className={`content-list text-left list-unstyled col-1`}>
                  GPS tracking to see available drivers nearby
                </td>
                <td className={`content-list text-left list-unstyled col-1`}>
                  Navigation support for drivers
                </td>
                <td className={`content-list text-left list-unstyled col-1`}>
                  Track all rides and drivers
                </td>
              </tr>
              <tr>
                <td className="content-list text-left list-unstyled col-1">
                  Booking Interface
                </td>
                <td className={`content-list text-left list-unstyled col-1`}>
                  Quick ride booking and scheduling options
                </td>
                <td className={`content-list text-left list-unstyled col-1`}>
                  Accept/reject ride requests
                </td>
                <td className={`content-list text-left list-unstyled col-1`}>
                  View all booking transactions
                </td>
              </tr>
              <tr>
                <td className="content-list text-left list-unstyled col-1">
                  Fare Calculation
                </td>
                <td className={`content-list text-left list-unstyled col-1`}>
                  Estimate fare based on distance and time
                </td>
                <td className={`content-list text-left list-unstyled col-1`}>
                  Track earnings and trips completed
                </td>
                <td className={`content-list text-left list-unstyled col-1`}>
                  Set and update fare pricing
                </td>
              </tr>
              <tr>
                <td className="content-list text-left list-unstyled col-1">
                  Payment Gateway
                </td>
                <td className={`content-list text-left list-unstyled col-1`}>
                  Multiple payment options (card, cash, wallet)
                </td>
                <td className={`content-list text-left list-unstyled col-1`}>
                  View and manage payments
                </td>
                <td className={`content-list text-left list-unstyled col-1`}>
                  Monitor financial reports
                </td>
              </tr>
              <tr>
                <td className="content-list text-left list-unstyled col-1">
                  Push Notifications
                </td>
                <td className={`content-list text-left list-unstyled col-1`}>
                  Alerts for ride updates, promotions
                </td>
                <td className={`content-list text-left list-unstyled col-1`}>
                  Ride request alerts
                </td>
                <td className={`content-list text-left list-unstyled col-1`}>
                  Send notifications and updates
                </td>
              </tr>
            </tbody>
          </table>
        ),
      },
      ,
    ],
  },
  {
    id: 5,
    title: "Steps to Build a Taxi App",
    blogImage: blogImgFirstFour,
    imgalt: "Steps to build a taxi app",
    desc: "Building a taxi app involves several key steps to ensure it meets user needs and stands out in a competitive market. Here’s a straightforward guide to help you through the process.",
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
    subHeadAfterImage: [
      {
        subTitle: "1. Market Research & Planning ",
        subDesc:
          "Start by analyzing your target audience, competitors, and current market trends. Understand who your potential users are, what features they value, and how your competitors operate. This research will help you tailor your app to meet specific needs and ensure it offers something unique to outshine competitors.",
      },
      {
        subTitle: "2. Choose the Right Tech Stack ",
        subDesc: (
          <div>
            <p>
              Selecting the right technology is essential for your app’s
              performance and reliability. Your taxi app needs a strong backend,
              a secure database, and smooth integrations. Here are some
              recommended tools for each aspect:
            </p>
            <table className="table align-middle mt-3 mb-3">
              <thead className="">
                <tr className="align-middle">
                  <th className="text-left">Technology Aspect </th>
                  <th className="text-left">Recommended Tools</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="content-list text-left list-unstyled col-1">
                    Programming Language
                  </td>
                  <td className={`content-list text-left list-unstyled col-1`}>
                    JavaScript, Kotlin, Swift
                  </td>
                </tr>
                <tr>
                  <td className="content-list text-left list-unstyled col-1">
                    Backend Framework
                  </td>
                  <td className={`content-list text-left list-unstyled col-1`}>
                    Node.js, Ruby on Rails
                  </td>
                </tr>
                <tr>
                  <td className="content-list text-left list-unstyled col-1">
                    Database
                  </td>
                  <td className={`content-list text-left list-unstyled col-1`}>
                    MongoDB
                  </td>
                </tr>
                <tr>
                  <td className="content-list text-left list-unstyled col-1">
                    Maps & Geolocation
                  </td>
                  <td className={`content-list text-left list-unstyled col-1`}>
                    Google Maps API
                  </td>
                </tr>
                <tr>
                  <td className="content-list text-left list-unstyled col-1">
                    Payment Gateway
                  </td>
                  <td className={`content-list text-left list-unstyled col-1`}>
                    Stripe, PayPal
                  </td>
                </tr>
                <tr>
                  <td className="content-list text-left list-unstyled col-1">
                    Push Notifications
                  </td>
                  <td className={`content-list text-left list-unstyled col-1`}>
                    Firebase Cloud Messaging, Twilio
                  </td>
                </tr>
                <tr>
                  <td className="content-list text-left list-unstyled col-1">
                    Cloud Hosting
                  </td>
                  <td className={`content-list text-left list-unstyled col-1`}>
                    AWS, Google Cloud
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ),
      },
      {
        subTitle: "3. Design User-Friendly Interfaces",
        subDesc:
          "A seamless user experience is crucial for your app's success. Focus on creating an intuitive interface that allows users to navigate easily. Use clean layouts and visually appealing designs to make the app enjoyable to use. Remember that a well-designed interface can significantly impact user satisfaction.",
      },
      {
        subTitle: "4. Develop the Core Functionality ",
        subDesc:
          "Start by developing the Minimum Viable Product (MVP) that includes essential features needed for your app to function effectively. Key features should include ride booking, real-time tracking, and secure payment systems. Once the MVP is launched and tested, you can gradually add more advanced features based on user feedback.",
      },
      {
        subTitle: "5. Testing & Quality Assurance ",
        subDesc:
          "Testing is a critical step in the app development process. Conduct rigorous testing to identify and fix any bugs, ensuring a smooth user experience. T est the app on multiple devices and platforms to avoid unexpected issues after launch. Quality assurance will help you deliver a reliable product to your users.",
      },
      {
        subTitle: "6. Launch & Promote the App",
        subDesc:
          "Once your app is thoroughly tested and refined, it's time to launch it to the public. Focus on marketing strategies that will effectively reach your target audience. Use social media, email marketing, and partnerships to promote your app and drive downloads. Consider offering promotions or incentives to encourage users to try your app.",
      },
    ],
  },
  {
    id: 6,
    title: "How Much Does It Cost to Build a Taxi App?",
    desc: (
      <div>
        <p>
          The cost of building a taxi app can vary depending on factors such as
          app complexity, features, and geographical location of the development
          team. On average, it can cost anywhere from $30,000 to $150,000.
          Here’s a breakdown:
        </p>
        <ul style={{ listStyleType: "none" }}>
          <li>
            <strong>MVP Development: </strong> $30,000 - $50,000
          </li>
          <li>
            <strong>Full-Scale App Development: </strong> $80,000 - $150,000
          </li>
          <li>
            <strong>Maintenance & Updates: </strong> ~20% of the development
            cost annually
          </li>
        </ul>
        <p>
          This estimate is for a basic version of the app. For a more advanced,
          fully-featured taxi app that meets modern standards, the cost can
          increase to $200,000 to $300,000, depending on the level of
          customization and additional requirements.
        </p>
        <div style={{ marginLeft: "-12px" }}>
          <BlogBottomBox
            content={
              "Launch your white label taxi app in 3 days at an affordable price! "
            }
            buttonContent={"Contact Us"}
            link={"/industries/contact-sales/"}
          />
        </div>
      </div>
    ),
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
  },
  {
    id: 7,
    title: "Monetization Models for Taxi Apps",
    desc: (
      <div>
        <p>
          Understanding how to generate revenue from your taxi app is critical
          for long-term success. Here are some common monetization models:
        </p>
        <ul style={{ listStyleType: "none" }}>
          <li>
            <strong>Commission Model: </strong> Earn a percentage from each ride
            booked through the platform.
          </li>
          <li>
            <strong>Subscription Model: </strong> Drivers or passengers pay a
            subscription fee to use premium features.
          </li>
          <li>
            <strong>Advertising: </strong> Partner with brands and display
            targeted ads to your user base.
          </li>
        </ul>
      </div>
    ),
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
  },
  {
    id: 8,
    title: "",
    desc: (
      <blockquote className="bg-white custom-shadow p-5 mt-0 rounded-custom border-4 border-primary border-top">
        <h2 className="h5">Final Thoughts</h2>
        <p className="text-muted">
          <p>
            {" "}
            <i className="fas fa-quote-right me-2 text-primary"></i>Developing a
            taxi app is a smart investment in today’s on-demand economy.
            However, success depends on understanding your market, choosing the
            right features, and working with an experienced development team. By
            following the steps outlined in this guide, you can launch a taxi
            app that delivers value to both passengers and drivers while helping
            you stay ahead in the competitive ride-hailing industry.
            <i className="fas fa-quote-right ms-2 text-primary"></i>
          </p>
        </p>
      </blockquote>
    ),
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
  },
];
const BlogSingleFeatureDataTwo = [
  {
    id: 1,
    title: "Understanding the Benefits of Taxi Apps",
    desc: "Taxi apps offer several advantages, each catering to different needs. Uber, for example, provides a quick and cost-effective ride with transparent upfront pricing, which is useful when budgeting. Lyft is known for its friendly drivers and affordability, while TaxiApp offers a more traditional taxi experience with the convenience of digital payments. Regardless of the choice, these apps have made taking a taxi much simpler and hassle-free.Let's explore these advantages in more detail",
    subHead: [
      {
        subTitle: "Convenience",
        subDesc:
          "One of the significant benefits of taxi apps is the convenience they offer. With just a few taps on your smartphone, you can request a ride from anywhere at any time. Taxi apps eliminate the need for physical effort and waiting times by bringing the cab directly to you.",
      },
      {
        subTitle: "Transparent Pricing",
        subDesc:
          "Taxi apps provide upfront pricing, allowing you to know the cost of your ride before you even book it. This feature is particularly helpful for budget-conscious travelers or those looking to plan their expenses more effectively. You can see the estimated fare based on your chosen destination, ensuring that there are no surprises when it comes to payment.        ",
      },
      {
        subTitle: "Variety of Options",
        subDesc:
          "Different taxi apps offer a variety of options to cater to individual preferences. For example, Uber provides a range of services, including economy cars, luxury vehicles, and even shared rides. Lyft focuses on affordable rides and promotes a friendly and engaging experience. These options allow riders to choose the service that best suits their needs and comfort.",
      },
      {
        subTitle: "Safety and Security",
        subDesc:
          "Taxi apps prioritize safety and security for both passengers and drivers. The drivers undergo a vetting process, including background checks, to ensure a reliable and trustworthy experience. Additionally, the apps provide features like driver ratings and feedback, giving riders the opportunity to choose drivers with a good track record. The ability to share trip details with friends and family adds an extra layer of security.        ",
      },
      {
        subTitle: "Cashless Payments",
        subDesc:
          "Many taxi apps facilitate cashless payments, eliminating the need to carry physical currency or worry about having exact change. You can link your debit or credit card to the app, making the payment process seamless and hassle-free. Digital payment options also provide a record of your transaction, adding transparency and accountability to the process.        ",
      },
    ],
  },
  {
    id: 2,
    title: "The Impact on Traditional Taxis",
    desc: "",
    subHead: [
      {
        subTitle: "",
        subDesc:
          "The rise of taxi apps, led by platforms like Uber and Lyft, has had a profound impact on the traditional taxi industry. The convenience, affordability, and ease of use offered by app-based ridesharing services have attracted a large customer base, leading to a shift in consumer preferences and behaviors.",
      },
      {
        subTitle: "",
        subDesc:
          "For traditional taxi drivers and companies this disruption has posed challenges in adapting to the changing landscape. Many have struggled to compete with the convenience and lower prices offered by taxi apps. The traditional taxi model, with dispatchers and street hails, has faced significant competition from the seamless, on-demand experience provided by app-based services.        ",
      },
      {
        subTitle: "",
        subDesc:
          "As a result, some traditional taxi drivers and companies have protested against the rise of taxi apps, arguing that it has affected their livelihoods. In some cases, there have been instances of hostility and conflicts between taxi drivers and rideshare drivers. These tensions highlight the impact and transformative nature of the emerging industry.",
      },
      {
        subTitle: "",
        subDesc:
          "The traditional taxi companies have had to grapple with the need to modernize their operations and adopt technology to remain competitive. Many have invested in developing their own apps or partnering with existing app-based platforms to retain customers and improve their services.        ",
      },
      {
        subTitle: "",
        subDesc:
          "Overall, while the rise of taxi apps has presented challenges for traditional taxi drivers and companies, it has also created new opportunities and expanded transportation options for the public. The industry continues to evolve as various stakeholders find ways to navigate this transformative period, ultimately shaping the future of urban transportation.",
      },
    ],
  },
];
const BlogSingleFeatureDataThree = [
  {
    id: 1,
    title: "Understanding the Basics of Taxi Apps    ",
    desc: "Taxi apps, also known as ride-hailing apps, have transformed the way people commute by providing convenient and efficient transportation services. These digital platforms connect passengers with nearby drivers through user-friendly mobile applications, revolutionizing the traditional taxi experience. By leveraging the power of smartphones and GPS technology, taxi apps offer a range of features that enhance the overall ride-hailing process.",
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
  },
  {
    id: 2,
    title: "What are Taxi Apps?",
    desc: "Taxi apps are innovative platforms that seamlessly connect passengers with drivers through the use of mobile applications. These apps allow users to book rides, track their drivers in real-time, make cashless payments, and provide feedback on their experience. By eliminating the need for traditional street hailing or calling dispatch centers, taxi apps provide a more convenient and streamlined way to request transportation services.    ",
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
  },
  {
    id: 3,
    title: "Key Players in the Taxi App Industry",
    desc: "The ride-hailing market is dominated by several key players that have achieved global recognition. Companies such as Uber, Lyft, Didi, and Grab have established themselves as leading providers of ride-hailing services. These companies have not only transformed the way we commute but have also ignited a wave of innovation and competition within the transportation industry.    ",
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
  },
  {
    id: 4,
    title: "The Technical Architecture of Taxi Apps",
    desc: "To provide seamless and efficient ride-hailing services, taxi apps rely on a complex technical architecture that combines user-facing components, driver-facing components, and a robust backend infrastructure. Understanding the technical aspects of these apps is crucial to appreciate their functionality and the benefits they offer to both passengers and drivers.",
    subHead: [
      {
        subTitle: "User-Facing Components",
        subDesc:
          "User-facing components are the features and interfaces that passengers interact with when using a taxi app. These components ensure a smooth and user-friendly experience throughout the booking and ride process. Some key user-facing components include:",
      },
      {
        subTitle: "User Registration and Account Creation Process",
        subDesc:
          "Passengers are required to create an account within the app by providing their personal information, contact details, and preferred payment methods. This registration process allows for personalized service and convenient access to ride-hailing features.        ",
      },
      {
        subTitle: "Ride Booking Interface and Options",
        subDesc:
          "The ride booking interface within the app allows passengers to input their desired pickup and drop-off locations, select the type of vehicle they prefer, and even schedule rides in advance. This user-friendly interface ensures a smooth booking process and provides passengers with the flexibility to customize their ride preferences.",

        subImage: blogImgThreeSec,
        imgalt: "Driver facing components of driver app",
      },
      {
        subTitle: "Driver-Facing Components        ",
        subDesc:
          "Driver-facing components are the features and interfaces that drivers interact with when using a taxi app. These components streamline the operations for drivers, enabling them to efficiently manage ride requests and provide a high level of service. Some key driver-facing components include:",
      },
      {
        subTitle: "Driver Registration and Verification        ",
        subDesc:
          "Prospective drivers undergo a registration process that involves submitting their personal information, driver's license details, and vehicle documentation. Ride-hailing companies perform comprehensive background checks and verification procedures to ensure passenger safety and maintain service quality.",
      },
      {
        subTitle: "Driver Availability and Ride Acceptance",
        subDesc:
          "Once registered, drivers can indicate their availability within the app. They receive ride requests from nearby passengers and have the flexibility to accept or decline based on their availability and proximity. This feature allows drivers to efficiently manage their time and optimize their earnings.        ",
        subImage: blogImgThreeThired,
        imgalt: "Backend infrastructure",
      },
      {
        subTitle: "Backend Infrastructure",
        subDesc:
          "The backend infrastructure of taxi apps is responsible for handling the complexities of managing users, drivers, and rides. It involves databases, servers, and algorithms that work together to provide a seamless experience for all parties involved. Some key elements of the backend infrastructure include:        ",
      },
      {
        subTitle: "Database Management and Data Storage",
        subDesc:
          "The backend of taxi apps stores and manages vast amounts of data, including user profiles, driver information, payment details, and ride history. This information is securely stored and easily accessible for smooth service delivery.        ",
      },
      {
        subTitle: "Payment Gateway Integration and Security Measures",
        subDesc:
          "To facilitate cashless transactions, taxi apps integrate secure payment gateways. These gateways encrypt sensitive financial information, ensuring secure and reliable transactions for both passengers and drivers. Implementing robust security measures protects user data and builds trust in the platform.",
        subImage: blogImgThreeFourth,
        imgalt: "GPS location and tracking",
      },
      {
        subTitle: "GPS and Location Tracking",
        subDesc:
          "A key technological component of taxi apps is GPS (Global Positioning System) technology, which enables real-time tracking and accurate navigation. Here's how it works:",
      },
      {
        subTitle:
          "Utilizing GPS Technology for Real-Time Tracking and Navigation        ",
        subDesc:
          "Taxi apps leverage GPS technology to track the location of drivers and passengers in real time. This information allows the app to match passengers with nearby drivers efficiently and provide accurate estimated time of arrival (ETA) estimates.        ",
      },
      {
        subTitle: "",
        subDesc:
          "Accurate location data plays a crucial role in enhancing the overall ride-hailing experience. It helps minimize pickup times, optimize routes, and ensure drivers can easily locate passengers. Moreover, accurate location tracking increases passenger safety and provides a reliable means of monitoring and sharing trip details with trusted contacts.        ",
      },
    ],
  },
  {
    id: 5,
    title: "The Ride-Hailing Process",
    desc: "The ride-hailing process involves several steps that seamlessly connect passengers with drivers. Here's an overview:",
    subHead: [
      {
        subTitle: "Ride Request and Matching",
        subDesc:
          "When a passenger needs a ride, they open the taxi app and provide their pickup location. The app's algorithm then matches the passenger with the nearest available driver based on factors such as proximity, driver ratings, and vehicle type. The matching process aims to ensure quick and efficient pickups for passengers while maximizing driver utilization.        ",
      },
      {
        subTitle: "Driver-Customer Communication        ",
        subDesc:
          "Once a driver is assigned to a passenger, both parties can communicate through in-app messaging features. This communication allows passengers to convey any special instructions or updates to the driver, such as gate codes or meeting points. It also enables drivers to request clarifications or notify passengers of their arrival.",
      },
      {
        subTitle: "Fare Calculation and Payment",
        subDesc:
          "Taxi apps employ dynamic pricing models based on factors such as distance, duration, and demand. Passengers can view an estimated fare before confirming the ride. After the trip, the app calculates the actual fare based on the distance traveled and any applicable surge pricing. Payment is automatically deducted from the passenger's registered payment method, eliminating the need for cash transactions.",
      },
    ],
  },
];
const BlogSingleFeatureDataFour = [
  {
    id: 0,
    title: "",
    desc: "On-demand services have become a big part of our lives today. They make it easy to get things like food, rides, or home services with just a few clicks on your phone. But what exactly are these on-demand services, and why are they becoming so popular?",
    blogImage: blogImgFourSecond,
    imgalt: "On-demand services",
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
    subHeadAfterImage: [
      {
        subTitle: "",
        subDesc:
          "In this article, we’ll explain what on-demand services are, how they work, their benefits, and why they are growing so quickly.",
      },
    ],
  },
  {
    id: 1,
    title: "The Growth of On-Demand Services",
    desc: (
      <p>
        Over the last few years, on-demand services have grown a lot. Experts
        say the on-demand economy could reach $400 billion by 2025. Well-known
        companies like Uber, Airbnb, DoorDash, and Instacart are just a few
        examples of services that have changed industries like{" "}
        <Link href="/industries/ride-hailing/">
          <a>ride hailing</a>
        </Link>{" "}
        ,{" "}
        <Link href="/industries/food-delivery/">
          <a>food delivery</a>
        </Link>{" "}
        , and grocery shopping. The U.S. market has been particularly active,
        with millions of Americans relying on these services for convenience.
        These platforms are popular because they offer convenience and save
        time.
      </p>
    ),
    subHead: [
      {
        subTitle: "",
        subDesc:
          "Technology is the main reason behind this growth. With smartphones, real-time tracking, and easy online payments, people can now order a ride, food, or other services from anywhere. The pandemic also boosted the need for these services, especially those that offered contactless delivery.",
      },
    ],
  },
  {
    id: 2,
    title: "What Are On-Demand Services?",
    desc: "On-demand services are platforms that allow you to quickly access a product or service. Whether you need a ride, a meal, or someone to clean your house, on-demand platforms make it possible to get what you need immediately. All you have to do is use an on demand app, and the service is delivered to you in real-time.",
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
  },
  {
    id: 3,
    title: "Why Are On-Demand Services So Popular? ",
    desc: "The rise of on-demand services can be explained by three main reasons:",
    blogImage: "",
    imgalt: "",
    subHead: [
      {
        subTitle: "",
        subDesc: (
          <p>
            <strong>Technology: </strong>As smartphones and apps have improved,
            it has become much easier to use these services. With GPS and online
            payments, you can get services without any hassle.
          </p>
        ),
      },
      {
        subTitle: "",
        subDesc: (
          <p>
            <strong>Convenience: </strong>People today want things fast and
            easy. On-demand services let you order something in minutes, saving
            you time.
          </p>
        ),
      },
      {
        subTitle: "",
        subDesc: (
          <p>
            <strong>Gig Economy: </strong>The rise of the gig economy has also
            played a significant role in the growth of on-demand services. Many
            people who work for on-demand platforms are independent workers or
            freelancers. This flexibility makes it easier for these platforms to
            have a lot of service providers available whenever consumers need
            them.
          </p>
        ),
      },
    ],
  },
  {
    id: 4,
    title: "Key Industries Changed by On-Demand Services",
    desc: "Several industries have been completely changed by on-demand services. Some of the biggest ones include:  ",
    blogImage: blogImgFourThird,
    imgalt: "On-demand services industries",
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
    subHeadAfterImage: [
      {
        subTitle: "Transportation",
        subDesc: (
          <div>
            <p>
              The impact of on-demand services on the transportation industry
              has been particularly significant. Ride hailing services like Uber
              and Lyft have made it easy to get a ride whenever you need one,
              quickly replacing traditional taxi services. These platforms offer
              faster service, upfront pricing, and the ability to track your
              ride in real-time, making them incredibly popular among consumers.
            </p>
            <div className="pb-3" style={{ marginLeft: "-13px" }}>
              <BlogBottomBox
                content={
                  "Looking for a ride-hailing platform? Grow your business with our solution today! "
                }
                buttonContent={"Try for Free"}
                link={"/industries/contact-sales/"}
              />
            </div>
          </div>
        ),
      },
      {
        subTitle: "Food and Grocery Delivery ",
        subDesc:
          "Food and grocery delivery services have grown massively in recent years. Services like Instacart, Uber Eats and Grubhub provide on-demand delivery of groceries and meals, which allow you to get your meals or groceries delivered right to your door. These services have become essential, especially after the COVID-19 pandemic, as more people prefer contactless delivery and the convenience of shopping from home.",
      },
      {
        subTitle: "Home Services",
        subDesc:
          "On-demand platforms like T askRabbit, Thumbtack, and Handy have made it easier than ever to get help with tasks like cleaning, home repairs, or even furniture assembly. These services allow people to find and hire professionals quickly, often for same-day appointments. This saves time and effort for busy individuals who need assistance with household tasks.",
      },
      {
        subTitle: "Logistics and Delivery Services",
        subDesc:
          "The logistics industry has been reshaped by on-demand platforms like Amazon, Postmates, and DoorDash. These services help consumers receive everything from packages to food deliveries quickly. Even local businesses have started using these platforms to offer faster delivery options to customers.",
      },
    ],
  },
  {
    id: 5,
    title: "How Do On-Demand Services Work?",
    desc: "On-demand services work through a straightforward process. First, users access a service through a mobile app, whether it's for ordering a ride, food, or home cleaning. These apps use GPS technology to track the service in real-time, allowing users to know exactly when it will arrive or be delivered. The platforms typically operate on a commission-based model, meaning they take a small percentage from each transaction. This ensures that prices remain reasonable for customers while allowing service providers to earn a fair income.",
    subHead: [
      {
        subTitle: "Technology’s Role",
        subDesc:
          "Technology is the backbone of on-demand services. Mobile apps, GPS, and easy payment systems make it simple for people to book services like rides, food delivery, or home repairs. With just a few taps, users can order what they need, track its progress, and pay securely online. GPS helps service providers find the exact location quickly, and secure payments keep everything safe for both users and workers. This use of technology makes on-demand services fast, easy, and convenient, which is why they’ve become so popular.",
      },
      {
        subTitle: "Supply and Demand",
        subDesc:
          "On-demand services work on a supply and demand model. Service providers are motivated to offer their services at competitive prices, while consumers look for the best value. During busy times, many platforms use surge pricing, which raises prices when demand is high. This encourages more service providers to work during peak hours, balancing the supply of services with consumer demand. This model ensures that both customers and service providers benefit from the system.",
      },
      {
        subTitle: "",
        subDesc:
          "By connecting consumers with providers in real-time, on-demand services make it easier than ever to get what you need, when you need it. With technology, a smart business model, and the use of supply and demand principles, these platforms create a win-win situation for everyone involved.",
      },
    ],
  },
  {
    id: 6,
    title: "Benefits of On-Demand Services",
    blogImage: blogImgFourThird,
    imgalt: "Benefits of on demand services",
    desc: "On-demand services provide several key benefits for both consumers and service providers:",
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
    subHeadAfterImage: [
      {
        subTitle: "1. Convenience for Consumers",
        subDesc:
          "One of the biggest benefits for consumers is the convenience. These platforms offer easy access to a wide range of services, with transparent pricing and real-time updates. Using GPS technology, consumers can expect timely and reliable service every time they book through an app.",
      },
      {
        subTitle: "2. Flexibility for Service Providers",
        subDesc:
          "For service providers, on-demand platforms offer flexibility and independence. Providers can choose when and how much they work, making it a great way to earn extra income or even a full-time living. The freedom to set flexible hours is a major appeal of these platforms.",
      },
      {
        subTitle: "3. Economic Advantages",
        subDesc:
          "On-demand services offer significant economic advantages. For service providers, lower overhead costs and increased productivity mean more profit potential. For consumers, these platforms often provide affordable pricing and access to a wide range of services that would otherwise be harder to find.",
      },
      {
        subTitle: "4. Personalization for Consumers",
        subDesc:
          "Consumers can choose exactly what they want, from the type of service to the provider, based on reviews, ratings, and availability. Many apps allow users to save preferences, making future bookings faster and more tailored to their needs. This personalized experience is a big reason why on-demand services have become so popular.",
      },
      {
        subTitle: "5. Faster Response Times",
        subDesc:
          "Another key benefit of on-demand services is speed. Whether it's ordering meals, booking a ride, or scheduling a handyman, these platforms are built for quick responses. Real-time technology ensures that consumers can get the services they need without long wait times, making them ideal for those who value efficiency and instant access.",
      },
    ],
  },
  {
    id: 7,
    title: "Challenges and Criticisms",
    desc: "Even though on-demand services are very popular, they face some challenges:",
    subHead: [
      {
        subTitle: "Regulatory and Legal Issues",
        subDesc:
          "The regulatory landscape surrounding on-demand services is uncertain and often controversial. Service providers are not always subject to the same regulations as traditional businesses, which can create a level of ambiguity around issues like worker safety and liability.",
      },
      {
        subTitle: "Worker Rights and Protections",
        subDesc:
          "There is a growing concern about the rights and protections of on-demand service providers. Independent contractors are not entitled to the same benefits as traditional employees and may lack access to healthcare, retirement benefits, and other protections.",
      },
      {
        subTitle: "Environmental and Social Concerns",
        subDesc:
          "The increase in the use of on-demand services has raised environmental concerns. Services like ride-sharing and food delivery have been criticized for increasing traffic congestion and pollution. Also, the use of low-wage workers has raised questions about the income inequality that on-demand services may perpetuate.",
      },
    ],
  },
  {
    id: 8,
    blogImage: "",
    imgalt: "",
    title: "",
    desc: (
      <blockquote className="bg-white custom-shadow p-5 mt-0 rounded-custom border-4 border-primary border-top">
        <h2 className="h5">Final Thoughts</h2>
        <p className="text-muted">
          <p>
            {" "}
            <i className="fas fa-quote-right me-2 text-primary"></i>On-demand
            services have changed how we live by making it easier to get what we
            need when we need it. With technology improving every day, these
            services will continue to grow. However, it’s important that they
            address challenges like legal issues and environmental impact to
            ensure long-term success. In the future, on-demand services will
            likely be an even bigger part of our everyday lives.
            <i className="fas fa-quote-right ms-2 text-primary"></i>
          </p>
        </p>
      </blockquote>
    ),
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
  },
];
const BlogSingleFeatureDataFive = [
  {
    id: 1,
    title: "Importance of Taxi Dispatch System for Taxi Businesses",
    desc: "To keep ahead of the competition in today's highly competitive taxi market, it is critical to have fast and dependable taxi software. A well-designed and feature rich software can bring numerous benefits to your business.",
    subHead: [
      {
        subTitle: "",
        subDesc:
          "First and foremost, a taxi dispatching software can help you optimize your operations by streamlining the dispatch process. It eliminates the need for manual coordination and allows you to assign trips to drivers quickly and accurately. This not only saves time but also reduces the chances of errors and miscommunication.",
      },
      {
        subTitle: "",
        subDesc:
          "The right software can improve efficiency by providing real-time data on driver availability, location, and trip status. This enables you to make informed decisions and allocate resources effectively, resulting in faster response times and improved customer satisfaction.",
      },
      {
        subTitle: "",
        subDesc:
          "It also enhances the overall customer experience. With features like GPS tracking, automated fare calculation, and online booking, you can offer your customers convenience, transparency, and reliability. This, in turn, can lead to increased customer loyalty and positive word-of-mouth referrals.",
      },
      {
        subTitle: "",
        subDesc:
          "In the next section, we will explore what to consider before choosing a perfect cab dispatch software.",
      },
    ],
  },
  {
    id: 2,
    title: "Assess Your Business Needs",
    desc: (
      <>
        Before choosing a{" "}
        <Link href="/" passHref>
          <a>white label taxi dispatch software</a>
        </Link>
        , it is essential to evaluate your business needs thoroughly.
        Understanding your requirements will help you identify the features and
        functionalities that are crucial for your operations. Here are some
        factors to consider:
      </>
    ),
    blogImage: blogImgFiveSecond,
    imgalt: "White label taxi software for taxi business",
    subHead: [
      {
        subTitle: "1. Fleet size:",
        subDesc:
          "Take into account the number of vehicles in your fleet. Different taxi booking software solutions offer varying capabilities for managing different fleet sizes.",
      },
      {
        subTitle: "2. Scalability:",
        subDesc:
          "Consider whether the software can accommodate your future growth. It is important to choose a solution that can scale with your business as you expand.",
      },
      {
        subTitle: "3. Integration:",
        subDesc:
          "Evaluate whether the software can seamlessly integrate with your existing systems such as accounting software, GPS trackers, or customer relationship management tools.",
      },
      {
        subTitle: "4. Customization:",
        subDesc:
          "Determine if the software can be tailored to meet your specific business requirements. Look for options that allow you to modify workflows and branding. This is especially important for white label taxi software which needs to be customized for individual taxi companies.",
      },
      {
        subTitle: "5. Support and Training:",
        subDesc:
          "Make sure the software provider offers reliable customer support and training resources. This will help you and your staff adapt to the new system efficiently. By carefully assessing your business needs, you can narrow down your options and select a white label taxi dispatch solution that aligns with your operational goals.",
      },
    ],
  },
  {
    id: 3,
    title: "The Key Features to Consider in Taxi Dispatching Software",
    desc: "Once you have identified your business needs, it is important to examine the key features that are essential in taxi dispatch system. These features will not only streamline your operations but also contribute to the overall success of your business. Consider the following features when making your decision:",
    subHead: [
      {
        subTitle: "1. Real-time tracking and GPS integration:",
        subDesc:
          "A reliable cab dispatch software should offer real-time tracking capabilities and seamless integration with GPS systems. This will allow you to monitor the location of your vehicles and provide accurate arrival times to customers.",
      },
      {
        subTitle: "2. Automated dispatch and routing:",
        subDesc:
          "Look for a software solution that offers automated dispatch and routing. This feature will enable you to efficiently assign trips to drivers based on their proximity, reducing response time and improving customer satisfaction. ",
      },
      {
        subTitle: "3. Mobile applications for drivers and passengers:",
        subDesc: (
          <>
            In today's digital age, it is crucial to have apps that make it
            convenient for both drivers and passengers to use your taxi
            services. Ensure that the software provides user-friendly{" "}
            <Link href="/industries/ride-hailing" passHref>
              <a>white label taxi app</a>
            </Link>
            (passenger app and driver app) that offer features like trip
            booking, real-time tracking, trip scheduling, and flexible payment
            options.
          </>
        ),
      },
      {
        subTitle: "4. Analytics and reporting:",
        subDesc:
          "Consider a software solution that provides comprehensive analytics and reporting tools. These features will help you gather valuable insights into your business performance, allowing you to make data-driven decisions and optimize your operations.",
      },
      {
        subTitle: "5. Payment processing and integration:",
        subDesc:
          "Choose a software solution that offers seamless payment processing and integration with popular payment gateways. This will enable you to provide convenient and secure payment options to your customers, enhancing their overall experience.",
      },
      {
        subTitle: "",
        subDesc:
          "By carefully examining these key features, you can select the taxi software that best meets your business needs and sets you up for success in the highly competitive taxi industry.",
      },
    ],
  },
  {
    id: 4,
    title: "Evaluate the Scalability and Flexibility of the Software",
    desc: "After carefully considering the essential features , it is crucial to evaluate the scalability and flexibility of the software before making your final decision.",
    subHead: [
      {
        subTitle: "",
        subDesc:
          "Scalability refers to the software's ability to grow and adapt to the changing needs of your business. As your business expands, you might need to add more vehicles, drivers, and dispatchers to your operations. Therefore, it is important to choose a system that can accommodate this growth without any significant disruptions or additional costs.",
      },
      {
        subTitle: "",
        subDesc:
          "Flexibility is another important factor to consider. Your business requirements may evolve over time, and the dispatch system should be able to adapt to these changes seamlessly.",
      },
      {
        subTitle: "",
        subDesc:
          "Consider the future goals and ambitions of your business when evaluating the scalability and flexibility of the software. By selecting a software solution that can grow and evolve with your business, you can ensure long-term success and avoid the hassle of switching systems in the future.",
      },
    ],
  },
  {
    id: 5,
    title: "Consider Support and Maintenance",
    desc: "One of the most important thing to consider is the customer and technical support options provided by the software provider. No matter how user-friendly a software solution may be, there will always be questions, issues, and the need for ongoing assistance.",
    blogImage: blogImgFiveThird,
    imgalt: "Taxi dispatch system",
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
  },
  {
    id: 6,
    title: "",
    desc: "Look for a software provider that offers robust technical and customer support, including options for contacting their support team such as phone, email, or live chat. Consider the provider's response time and availability, as prompt and reliable support can be crucial during critical situations.",
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
  },
  {
    id: 7,
    title: "Conduct a Cost vs Benefit Analysis",
    desc: "Finally, it is crucial to conduct a cost vs benefit analysis. This analysis will help you determine the financial impact and potential return on investment of implementing the software.",
    subHead: [
      {
        subTitle: "",
        subDesc:
          "Start by evaluating the cost of the software itself, including any upfront fees and monthly or annual subscription charges. Consider whether the software requires additional hardware or integration with other systems, as these can add to the overall cost.",
      },
      {
        subTitle: "",
        subDesc:
          "Next, assess the benefits that the software can bring to your business. Look for features that can streamline your operations, improve efficiency, and enhance the customer experience. Consider how these benefits translate into tangible outcomes, such as increased bookings, reduced driver idle time, and improved customer satisfaction.",
      },
      {
        subTitle: "",
        subDesc:
          "Compare the projected financial gains with the costs of the software to determine if the investment is worthwhile. Remember to consider the long-term benefits and potential for  scalability as your business grows.",
      },
    ],
  },
];
const BlogSingleFeatureDataSix = [
  {
    id: 3,
    title: "",
    desc: (
      <>
        If the entrepreneur wants to enter the taxi business, then the{" "}
        <Link href="/uber-clone/">
          <a> Uber clone </a>
        </Link>{" "}
        is the best option that meets the needs. It covers the source code that
        implements the functionality of the Uber app such as the driver and
        rider applications and the administrator panel. As entrepreneurs can
        build the taxi application solution according to their preferred booking
        system they will have technical support and help in case of need. It
        helps taxi companies in their daily activities which is seen to improve
        their operations from all areas which enables the owners to succeed in
        the taxi business competition.
      </>
    ),
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
  },
  {
    id: 2,
    title: "What is an Uber clone script?",
    desc: (
      <>
        It is a ready-made software solution that replicates the features and
        functionalities of the original Uber app. This allows businesses to
        promptly and effortlessly set up the on-demand{" "}
        <Link href="/" passHref>
          <a>ride-hailing platform. </a>
        </Link>
        of their own. This clone script will allow passengers to book trips
        anddrivers to take the cab and then the admin can operate the whole
        system.
      </>
    ),
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
  },
  {
    id: 3,
    title: "Why start a taxi startup?",
    desc: "There are several reasons why one should consider starting a taxi startup:",
    subHead: [
      {
        subTitle: "",
        subDesc:
          "In recent years, the demand for convenient transport services has increased a lot, making it a lucrative market to enter. The global taxi market was worth more than $69.18 billion in 2019 and it is projected that it will increase up to $120.89 billion by the end of 2027, with a compound annual growth rate (CAGR) of 12.3% from 2019. The size of the taxi app market is projected to be $283 billion in 2028, with an average growth rate of 4.2% per year, between the years 2023 and 2028.",
      },
      {
        subTitle: "",
        subDesc:
          "In view of the technology improvements, cab business can be started rapidly and economically by using readily available scripts. A taxi app startup has the ability to disrupt the traditional taxi industry (Uber, one of the most successful taxi startups, had a revenue of $11.3 billion in 2018) by offering lower prices, improved customer experience, and innovative features. Lastly, scalability is possible as the app can be expanded to other cities or countries (Uber is in over 900 metropolitan areas worldwide).",
      },
    ],
  },
  {
    id: 4,
    title: "Why choose the best Uber clone for your startup?",
    desc: "Launching your own taxi startup becomes easier and more cost-effective with a taxi booking script. Exploit a ready made set of iOS and Android apps (passenger app and driver app). Having separate panels for the service providers and dispatchers plus the robust tech support, the clone taxi solution is the best option for the entrepreneurs.",
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
  },
  {
    id: 5,
    title: "Identify the target market and competition",
    blogImage: blogImgSixSecond,
    imgalt: "Uber clone script",
    desc: "First, use market research to find out who your potential clients are. Identification of the target market and a competition analysis are keys to the success of your clone script. Search for demographics, locations, and activities that link to the script's features and benefits. This will guide you to target your marketing campaign and make your product more effective.",
    subHead: [
      {
        subTitle: "",
        subDesc:
          "Secondly, analyze your competitors to find out their strengths and weaknesses. Emphasize on what differentiates your taxi booking script from the existing ones and how it is meeting the unmet needs of the desired market segment. Put these insights into practice by formulating a value proposition that sets you apart from competitors.",
      },
    ],
  },
  {
    id: 6,
    title: "Analyze existing taxi startups",
    desc: "The existing taxi startups offer us useful guidance. Through the analysis of their strengths and weaknesses,organizations can highlight the main functions and capabilities that users need.",
    subHead: [
      {
        subTitle: "",
        subDesc:
          "For example, a good interface which is user-friendly and easy to navigate around is important and the customers need to book a ride and do it fast and with no much effort.",
      },
      {
        subTitle: "",
        subDesc:
          "As well as that, matching algorithms and tracking in real-time are crucial for providing a seamless user experience. Looking into other taxis can also help see what is missing in the market and spark new ideas to be unique to your competitors.",
      },
    ],
  },
  {
    id: 7,
    title: "Know your customer needs and demands",
    desc: "To achieve success, it is crucial to understand your customers and meet their needs and preferences. Through analyzing of customer behavior and feedback, companies can customize their services according to the particular needs of their market segment. For example, if customers are demanding more payment choices, the script should include the feature to accept different payment methods.",
    subHead: [
      {
        subTitle: "",
        subDesc:
          "Tracking customer preferences can also help companies identify trends and make informed decisions on future updates or features. By continuously staying in tune with customer demands, businesses can ensure they deliver a seamless and personalized experience, driving customer satisfaction and loyalty.",
      },
    ],
  },
  {
    id: 8,
    title: "Choosing the right development company",
    blogImage: blogImgSixThird,
    imgalt: "Uber clone software development company",
    desc: "During the process of choosing the right app development team or company for your project, it is important to consider a number of factors. Seek expertise in mobile app development, especially in creating clone scripts. Experienced teams are aware of the potential problems and impediments. Check their portfolio and find out what their clients think about them.",
    subHead: [
      {
        subTitle: "",
        subDesc:
          "Effective communication is crucial. Look for a responsive and cooperative team to ensure a smoother development process.",
      },
    ],
  },
  {
    id: 9,
    title: "Researching and shortlisting potential development partners",
    desc: "First of all, take a look at their expertise and experience in creating such platforms. Look for companies that have a proven track record and a portfolio of successful projects.",
    subHead: [
      {
        subTitle: "",
        subDesc:
          "Next, assess the compatibility of their technology stack with your requirements. Make sure they possess the required technical skills and the appropriate tools to handle your project perfectly.",
      },
      {
        subTitle: "",
        subDesc:
          "Also, take a look at the cost structure and their pricing models. Look for vendors that provide transparent pricing and flexible payment terms. Through extensive research and careful selection, you can identify a dependable and appropriate development team.",
      },
    ],
  },
  {
    id: 10,
    title: "Assess the expertise and experience of the development team",
    desc: (
      <>
        Look for a team that has a solid track record of developing clone apps
        like{" "}
        <Link href="/uber-clone/" passHref>
          <a> Uber clone, </a>
        </Link>
        <Link href="/bolt-clone/" passHref>
          <a> Bolt clone, </a>
        </Link>
        <Link href="/lyft-clone/" passHref>
          <a> Lyft clone, </a>
        </Link>
        and on-demand platforms. They should demonstrate a deep understanding of
        the requirements and challenges of building such a complex system. The
        team's expertise can be evaluated by reviewing their portfolio of
        previous projects, particularly those related to ride-hailing apps.
      </>
    ),
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
  },
];
const BlogSingleFeatureDataSixSecond = [
  {
    id: 1,
    title: "How to customize the Uber-like app to your need?",
    desc: "Customizing your app solution is essential to meet your specific business requirements. Add features like branding and modify the app design. Integrate new features, payment gateways, and promo code options for a unique user experience. The app development stresses on user experience, powerful admin panel, and technical support.",
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
  },
  {
    id: 2,
    title: "Add essential features",
    blogImage: blogImgSixFourth,
    imgalt: "Uber clone script app",
    desc: "Adding unique features and functionalities can give your app a competitive edge and attract more users. Here are some practical examples to consider:",
    subHead: [
      {
        subTitle: "1. Customizable ride options:",
        subDesc:
          "Provide users with a variety of options, such as luxury cars or electric vehicles, to meet different needs and promote sustainability.",
      },
      {
        subTitle: "2. In-app chat:",
        subDesc:
          "Ensure a smooth communication between riders and drivers to help them address questions, give updates or implement special requests in order to improve the user experience.",
      },
      {
        subTitle: "3. Advanced safety measures:",
        subDesc:
          "Implement features like an emergency button, real-time tracking, and driver background checks to prioritize user safety and build trust in your platform.",
      },
      {
        subTitle: "4. Integration with third-party services:",
        subDesc: (
          <>
            Collaborate with famous{" "}
            <Link href="/industries/food-delivery">
              <a> food delivery </a>
            </Link>
            services or entertainment apps to ensure convenience and value
            addition to your customers.
          </>
        ),
      },
      {
        subTitle: "",
        subDesc:
          "Note that adding unique features is about setting yourself apart from the competition while providing practical solutions to improve the user experience.",
      },
    ],
  },
  // {
  //   id: 3,
  //   title: "Know user requirements and preferences",
  //   desc: "Understanding what users need and want allows developers to create a product that aligns with their expectations. This can be done by conducting market research, collecting feedback from similar platforms, and considering industry trends.",
  //   subHead: [
  //     {
  //       subTitle: "",
  //       subDesc:
  //         "For example, if users prefer a seamless taxi booking process, the app should incorporate a user-friendly interface and intuitive navigation. By keeping user preferences at the forefront of the development process, developers can ensure that the taxi app meets the needs of its target audience.",
  //     },
  //   ],
  // },
  {
    id: 4,
    title: "Personalize with your brand identity",
    desc: "Customizing the clone app solution to reflect your taxi business’s unique branding and identity is crucial for standing out in the competitive taxi industry. Personalize the app with your company’s logo, color scheme, and brand name to create a distinct user experience. Tailoring the app solution to resonate with your target audience and reflect your company's core values will help build trust and loyalty among users. It is crucial to ensure that each part of the app solution, from the user interface to app design, reflects your brand identity that makes your taxi company to stand out among the rivals.",
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
  },
  {
    id: 5,
    title: "Include local languages and currencies",
    desc: "To accommodate a diverse customer base, you can add local languages and currencies in your app solution. This creates the environment of user trust and comfort by providing a user-friendly experience in their native language. You give convenience and clarity to your customers from the simple fact that you link local currencies. Moreover, providing language options increases the accessibility as well as the inclusiveness of the app around the world. As a consequence, they will make the user experience more rich and rewarding, giving the taxi Uber app clone script a competitive advantage in the taxi market due to its versatility and adaptability.",
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
  },
];
const BlogSingleFeatureDataSixThird = [
  {
    id: 1,
    blogImage: blogImgSixFifth,
    imgalt: "Taxi booking script",
    title: "The revenue model",
    desc: "Profit maximization in the taxi business is only possible with diversification of revenue streams such as commission fees, subscription models and promotional partnerships. Applying user behavioral characteristics and market trends leads to a strong strategic decision for a flow of money. Balancing user affordability and business profitability is a key factor to long-term success. Continuously enhance and optimize the revenue model to guarantee financial viability and stability.",
    subHead: [
      {
        subTitle: "Commission from drivers",
        subDesc:
          "When drivers join your platform, you can earn revenue by charging them a commission. Attract more drivers with a competitive commission structure and give them an option to choose their plans. Transparent communication about the commission percentage and payment schedule must be provided in order to gain trust. The commission from drivers is a crucial component of your revenue model, and the right strategy will help to achieve long-term profit and growth.",
      },
      {
        subTitle: "In-app advertisements",
        subDesc:
          "Develop partnership with businesses and display targeted ads, which are tailored-made for the local industries. Make ads non-invasive and smooth as well as relevant and engaging for the users. Provide an array of placements to satisfy every advertiser's preferences, improving the results of ads. Hence, not only will you generate more revenue but the users will have a seamless experience of your taxi application.",
      },
      {
        subTitle: "Premium services",
        subDesc:
          "Grow your income by providing high quality services to satisfy the various needs of your customers. Provide unique features like priority booking and vehicle customization. Consider offering subscription-based premium service plans for frequent users, tailored to their preferences and history. Develop a smooth payment gateway for accessing this premium services that will improve user experience and customer satisfaction. By providing personal services and emphasizing premium services, you can make your app's service to stand out from the competition within the taxi industry.",
      },
    ],
  },
  {
    id: 2,
    title: "Maintaining your taxi booking script",
    desc: "The seamless functioning of your taxi app solution requires updating the features to promote advanced features, address user feedback, and keep up-to-date with trends. Routine app updates and rapid customer service delivery are important aspects that will enable you to remain competitive in the industry.",
    subHead: [
      {
        subTitle: "Routine app updates",
        subDesc:
          "Rolling out periodic app updates is essential to improve app performance and security. User-suggested features are regularly incorporated to meet evolving needs, and updates are strategically scheduled during off-peak hours to minimize user inconvenience. It's crucial to communicate upcoming app updates and new features in advance to keep users informed. Timely updates also ensure app compatibility with the latest mobile operating systems, providing a seamless experience for users.",
      },
      {
        subTitle: "Address user feedback",
        subDesc:
          "Respond to user feedback is key to increasing app workability and developing an excellent user experience. Regularly gathering and examining user feedback helps make app functions a priority and updates. User suggestions can be implemented and customer support should serve as a proper means of addressing the user issues and concerns in the first place. Involve your app users by conducting app surveys and reviews, which contribute additional data to the loop and create a user-oriented approach. From the user’s feedback, the app improves. On the other hand, the feedback shows users that their opinions count, which leads to a stronger link between the app and the users.",
      },
      // {
      //   subTitle: "Offering promotional discounts",
      //   subDesc:
      //     "Attracting new users is achievable by providing limited-time promotional discounts. User retention can be incentivized through personalized offers, and customer referrals can be encouraged through referral programs. Strategic scheduling of discounts during peak hours enhances user engagement, while the analysis of user behavior allows for tailored discounts based on preferences. Implementing these promotional strategies can help set your taxi app solution apart in the competitive taxi business, foster customer loyalty, and ensure the success of your startup.",
      // },
    ],
  },
  // {
  //   id: 3,
  //   title: "Maintaining your Uber taxi clone",
  //   desc: (
  //     <>
  //       Ensuring the smooth operation of your{" "}
  //       <Link href="/industries/ride-hailing" passHref>
  //         <a> taxi app solution </a>
  //       </Link>
  //       involves regular updates to integrate advanced features, address user
  //       feedback, and align with industry trends. A robust admin panel
  //       facilitates seamless app management, while providing technical expertise
  //       supports app development and maintenance. Routine app updates and
  //       responsive customer service are critical components in maintaining your
  //       Uber taxi clone amidst the competitive taxi industry landscape.
  //     </>
  //   ),
  //   subHead: [
  //     {
  //       subTitle: "Routine App Updates",
  //       subDesc:
  //         "Rolling out periodic app updates is essential to improve app performance and security. User-suggested features are regularly incorporated to meet evolving needs, and updates are strategically scheduled during off-peak hours to minimize user inconvenience. It's crucial to communicate upcoming app updates and new features in advance to keep users informed. Timely updates also ensure app compatibility with the latest mobile operating systems, providing a seamless experience for users.",
  //     },
  //     {
  //       subTitle: "Address User Feedback",
  //       subDesc:
  //         "Addressing user feedback is important for improving app functionality and enhancing the overall user experience. Actively collecting and analyzing user feedback helps in prioritizing app features and updates. Implementing user-suggested changes and providing responsive customer support are essential for addressing user concerns and queries. Encourage user participation through app surveys and reviews, which further enhances the feedback loop and fosters a user-centric approach. Utilizing user feedback not only improves the app but also demonstrates to users that their opinions are valued, fostering a stronger bond between the app and its users.",
  //     },
  //   ],
  // },
];
const BlogSingleFeatureDataListSix = [
  {
    id: 1,
    title: "Integrate additional payment gateways",
    desc: "It allows seamless transactions and enhances the user experience. Here are some practical tips to achieve this:",
    subHead: [
      {
        subDesc:
          "Research various payment gateways and third-party services to find the best fit for your platform.",
      },
      {
        subDesc:
          "Integrate popular payment gateways to cater to a wider audience and offer flexibility.",
      },
      {
        subDesc:
          "Offer multiple payment options, such as credit/debit cards, mobile wallets, and net banking to accommodate diverse user preferences.",
      },
      {
        subDesc:
          "Ensure secure payment processing by following industry-standard security protocols.",
      },
      {
        subDesc:
          "Regularly update and test the integrations for smooth operation and user satisfaction.",
      },
    ],
    end: "A well-integrated payment gateway contribute significantly to the success of your clone taxi app.",
  },
];
const BlogSingleFeatureDataListSeven = [
  {
    id: 1,
    title: "Benefits of surge pricing",
    desc: "",
    subHead: [
      {
        subDesc:
          "Surge pricing ensures optimal resource allocation by incentivizing drivers to operate in areas with high demand, reducing passenger wait times and improving overall service reliability.",
      },
      {
        subDesc:
          "Drivers are fairly compensated for their time and effort during peak demand times.",
      },
    ],
    end: (
      <>
        The introduction of the surge pricing represents a significant milestone
        in our mission,revolutionizing our{" "}
        <Link href="/industries/ride-hailing" passHref>
          <a>ride-hailing platform. </a>
        </Link>
        We remain committed to innovation and will continue to enhance our
        platform to meet the evolving needs of our clients.
      </>
    ),
  },
];
const BlogSingleFeatureDataEight = [
  {
    id: 1,
    title: "Why Does Your Rental Business Need Car Rental Software?",
    blogImage: blogImgEightSecond,
    imgalt: "Car rental company software",
    desc: "Modern businesses require optimum efficiency and accuracy to stay competitive, and the car rental industry is no exception. In this age of digital advancement, the utilization of rental management bears significant importance. This technology facilitates a significant shift from manual operations and traditional structures to a fundamentally automated framework.",
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
  },
  {
    id: 2,
    title: "",
    blogImage: "",
    imgalt: "",
    desc: "",
    subHead: [
      {
        subTitle: "Importance of Vehicle Booking System in Modern Businesses",
        subDesc:
          "Car rental business software has become an indispensable asset for modern businesses, primarily due to its ability to automate various tasks and streamline operations. It enables real-time fleet and availability tracking, offering dynamic pricing models and robust inventory management. This powerful tool aids in coordinating activities across multiple locations, leading to optimal fleet usage and efficiency. Automation of paperwork and driver verification ensures accuracy while reducing chances of human error.",
      },
      {
        subTitle: "",
        subDesc:
          "Adopting such software promotes data-driven decision-making, fueled by comprehensive analytics reporting on utilization and revenue trends. The inclusion of third-party integration expands distribution while simultaneously ensuring data security. Thus, car rental software continues to deliver significant operational gains for businesses.",
      },
      {
        subTitle: "Transition from Traditional Methods to Automated Software",
        subDesc:
          "Shifting to automated software from traditional methods does not only mean less paperwork and reduced errors; it ushers businesses into an era of 24/7 online bookings and payments, demonstrating the strength of an automated booking system. Customers can make vehicle bookings anytime, from anywhere, bringing convenience to their fingertips.",
      },
      {
        subTitle: "",
        subDesc:
          "Additionally, such transition replaces traditional fleet tracking with an integrated vehicle management system. This highlights the status of various parameters such as maintenance alerts, insurance coverage, and even car washes. Monitoring of these factors fosters timeliness in service delivery and extends the lifespan of the fleet.",
      },
      {
        subTitle: "Impact on Customer Experience",
        subDesc:
          "The seamless process fostered by an automated car rental software system enhances overall service delivery. Whether it's the user-friendly interface of mobile apps, quick payment processing, or the transparency of pricing, these factors collectively pitch for customer satisfaction.",
      },
      {
        subTitle: "",
        subDesc:
          "Moreover, the establishment of customer profiles enables businesses to tailor promotional offers based on individual preferences. This personalized touch converts customer interaction into a unique journey rather than a traditional business transaction, thus enhancing customer retention and loyalty in the long run.",
      },
    ],
  },
  {
    id: 3,
    title: "Benefits of Car Rental Solutions",
    blogImage: blogImgEightThird,
    imgalt: "Car rental booking software",
    desc: "The advantages brought by car rental system are numerous, and they contribute significantly to the growth and efficiency of businesses in the car rental industry. The transformative impact of this technology can be seen in areas such as competitive edge, operational productivity, revenue generation, and real-time insights.",
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
  },
  {
    id: 4,
    title: "",
    blogImage: "",
    imgalt: "",
    desc: "",
    subHead: [
      {
        subTitle: "Revenue Generation",
        subDesc:
          "This software also proves to be a crucial factor in driving revenue. By offering a more efficient and satisfactory customer service, car rental booking software encourages repeat customers, leading to improved income generation. Furthermore, the software aids in reducing overhead costs related to staffing and manual fleet management, thereby enhancing profitability margins.",
      },
      {
        subTitle: "Competitive Edge",
        subDesc:
          "Incorporating vehicle rental management software can considerably enhance a car rental company's competitiveness. The effectiveness and efficiency offered by automated online booking, paired with the ability to offer a smooth, uninterrupted service 24/7, distinctly separates a company from its industry peers. Features like the driver and customer review systems augment trust in the business, effectively contributing to its market reputation. The software’s easy integration into existing websites makes it invaluable for companies striving to stay ahead in a digital-first business environment.",
      },
      {
        subTitle: "Operational Efficiency",
        subDesc:
          "A significant benefit of car rental booking software is the enhanced operational efficiency. By automating billing processes and centralizing tracking of rentals, user errors are drastically decreased, and managerial tasks are streamlined. This leads to a better organization of reservations, vehicles, and customers. The software also permits remote access and management, ensuring service delivery continuity beyond the physical location of the business. Notifications for maintenance alerts also ensure timely maintenance, thereby increasing the life of the vehicle, and optimizing fleet utilization.",
      },
      {
        subTitle: "Real-time Insights and Tracking",
        subDesc:
          "Another notable advantage of car rental booking software solution is the availability of real-time insights and tracking. The reporting tools offered by this software enable the analysis of key metrics. These insights facilitate strategic decision-making, improve visibility into operational inefficiencies, and help identify areas for improvement. Furthermore, integration of standardized measures into software help companies benchmark service performances over time, leading to continual improvements and business growth.",
      },
      {
        subTitle: "",
        subDesc: "",
      },
    ],
  },
];
const BlogSingleFeatureDataEightTable = [
  {
    id: 1,
    heading1: "Benefit",
    heading2: "Description",
    heading3: "Impact on Business",
    desc1: "Improved Efficiency",
    desc2:
      "Car rental management software automates manual processes, allowing staff to focus on customer service instead of paperwork. This leads to faster rental transactions and vehicle readiness.",
    desc3: "Increased customer satisfaction, higher rental volumes",
    desc4: "Enhanced Tracking",
    desc5:
      "Detailed logging of rental transactions, vehicle status, and customer interactions provides invaluable business insights. Identifying usage patterns and operational bottlenecks facilitates data-driven decisions.",
    desc6:
      "Optimized utilization and acquisition of rental assets, targeted marketing campaigns",
    desc7: "Reduced Costs",
    desc8:
      "By eliminating reliance on paper files and legacy systems, car rental platform reduces expenses associated with supplies, storage, and maintenance. Automation also minimizes costly human errors.",
    desc9:
      "Improved profit margins and opportunities to pass some savings to customers",
    desc10: "Versatile Reporting",
    desc11:
      "Custom reports can be generated on demand to analyze revenue, inventory, staffing needs, customer details, and other rental KPIs. This supports budgeting, expansion planning, and identification of growth opportunities.",
    desc12: "Data-informed strategy decisions to improve competitiveness",
    desc13: "Anywhere Accessibility",
    desc14:
      "Cloud-based solutions enable remote access to rental software via web and mobile apps. Staff can perform key tasks from any location while on the go.",
    desc15: "Enhanced customer service levels and responsiveness",
  },
];
const BlogSingleFeatureDataEightSecond = [
  {
    id: 1,
    title: "Must Have Car Rental Software Features",
    blogImage: "",
    imgalt: "",
    desc: "In selecting an optimal software solution for car rental businesses, several crucial features should be kept in mind. These essential characteristics help improve operational efficiency, enhance customer experience, and optimize business growth.",
    subHead: [
      {
        subTitle: "User-Friendly Interface",
        subDesc:
          "Intuitive and easy-to-navigate interfaces are vital in ensuring smooth operations. Complex or poorly designed systems are detrimental to productivity and can lead to potential losses in revenue if inefficiencies turn potential customers away. An accessible online reservation system, for instance, can significantly improve the vehicle booking experience for customers.",
      },
      {
        subTitle: "Flexibility and Scalability",
        subDesc:
          "In today's rapidly evolving market conditions, a software solution that offers flexibility is essential. Car rental companies need to adapt their services to changing customer demands and industry trends. A car rental system with features such as seasonal pricing capabilities and customizability of rental agreements offers the needed flexibility. Scalability is also key, ensuring the software grows along with the business, supporting an increasing number of vehicles, locations, and transactions without compromising performance.",
      },
      {
        subTitle: "Analytics and Reporting",
        subDesc:
          "Robust analytics and reporting capabilities are crucial in making data-driven decisions. From tracking fleet utilization to reviewing revenue growth, these features deliver actionable insights. Advanced rental management software also streamlines aspects like demand forecasting and promotional offers, ultimately contributing to optimizing business strategy and enhancing revenue generation.",
      },
      {
        subTitle: "Comprehensive Dashboard",
        subDesc:
          "The comprehensive dashboard is a crucial feature, providing a central area to access and manage all the information necessary for thriving in the rental industry. It allows for instant access to vital data such as fleet status, booking system details, and revenue generation trends. The dashboard quickly presents these in a digestible format, aiding decision-making and presenting a clear picture of all rental operations.",
      },
      {
        subTitle: "Security Features",
        subDesc:
          "Data security ranks high on priority due to increasing cyber threats. Make sure the preferred software has robust security features including customer and driver verification systems and secure payment processing mechanisms. This ensures customer confidence and shields your business from legal and financial mishaps.",
      },
      {
        subTitle: "",
        subDesc: "",
      },
    ],
  },
  {
    id: 2,
    title: "Choosing the Best Car Rental Software",
    blogImage: blogImgEightFourth,
    imgalt: "Vehicle booking system",
    desc: "Navigating the plethora of vehicle rental software on the market and deciding on the most viable option for your business can seem daunting. However, considering the following factors can simplify the selection process.",
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
  },
  {
    id: 3,
    title: "",
    blogImage: "",
    imgalt: "",
    desc: "",
    subHead: [
      {
        subTitle: "Operational Budget",
        subDesc:
          "The choice you make should take into consideration the financial strength of your business. It's paramount to find a balance between value and cost, ensuring that you do not compromise essential features for the affordability of the software. High-quality software will often have flexible pricing models, adjusting to your business size and specific needs.",
      },
      {
        subTitle: "Required Features",
        subDesc:
          "Understanding the needs of your business is a significant determiner of the software you should select. Look for features like vehicle booking, rental management, fleet tracking, reservation system, customer profiles, availability calendar, pricing models, payment processing, driver verification, maintenance alerts, and analytics reporting. All these features have been proven to be invaluable in streamlining operations and boosting efficiency.",
      },
      {
        subTitle: "Size and Nature of Business",
        subDesc:
          "Whether you're running a small operation or a multinational car rental corporation, the size and nature of your business should dictate the software you opt for. Ideally, your choice of software platform should easily scale with your business, allowing room for growth and adaptation to future expansions.",
      },
      {
        subTitle: "Reviews and Ratings",
        subDesc:
          "Make sure to scrutinize customer reviews and ratings for each prospective software. Real-life experiences from other businesses can offer valuable insights into the software's efficiency, reliability, and customer support. Credible platforms should have majority positive testimonials, giving a clear marker of the utility one can expect.",
      },
      {
        subTitle: "Technical Support, Compliance, and Security",
        subDesc:
          "Finally, consider the quality of customer support, compliance with license regulations, coverage of insurance issues, and overall data security measures in place.",
      },
      {
        subTitle: "",
        subDesc:
          "A necessary yet often overlooked aspect is the consideration of paperwork automation. Streamlining administrative tasks frees up valuable time and resources, significantly boosting your productivity.",
      },
    ],
  },
];
const BlogSingleFeatureDataNine = [
  {
    id: 1,
    title: "Challenges Faced",
    blogImage: "",
    imgalt: "",
    desc: "ZayRide’s journey with their previous software providers was riddled with frustration and setbacks. Their system consistently underperformed and it leads to dissatisfied customers and  operational inefficiencies. The challenges they encountered included:",
    subHead: [
      {
        subTitle: "Slow System Performance",
        subDesc:
          "The system struggled to keep up with the demands of real time dispatching caused delays and inefficiencies.",
      },
      {
        subTitle: "Inaccurate Location Tracking",
        subDesc:
          "The software often displayed incorrect locations, which caused confusion and inconvenience for both drivers and passengers. Customers often experienced frustration due to inaccurate location data, leading to missed pickups and longer wait times.",
      },
      {
        subTitle: "Inaccurate Fare Calculation",
        subDesc:
          "Fare calculations were inconsistent and incorrect, causing confusion and dissatisfaction among passengers. Billing discrepancies often leads to disputes and customer complaints.",
      },
      {
        subTitle: "Lack of Support",
        subDesc:
          "ZayRide struggled to receive timely support and assistance when encountering issues or seeking guidance for optimizing their operations. The inadequate support from their previous software solution providers caused many troubles and ended up in losing more customers.",
      },
      {
        subTitle: "Outdated Version & Scalability Issues",
        subDesc:
          "Their outdated software lacked essential updates and failed to keep pace with the increasing  demands of their expanding business, which resulted in limited scalability and hindering growth potential.",
      },
    ],
  },
  {
    id: 2,
    title: "Previous Solutions Tried",
    blogImage: "",
    imgalt: "",
    desc: "Prior to engaging with us, ZayRide experimented with four different software solutions in a quest to overcome their challenges. However, each attempt proved futile and left the client disillusioned and desperate for a viable solution.",
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
  },
];
const BlogSingleFeatureDataNineSecond = [
  {
    id: 1,
    title: "Solutions Provided",
    blogImage: blogImgNineSecond,
    imgalt: "Advanced ride hailing features",
    desc: "",
    subHead: [
      {
        subTitle: "Enhanced Performance:",
        subDesc:
          "Our software solution ensures swift and efficient real-time dispatching that eliminates delays and operational inefficiencies. Now they can handle peak demands without any disruption and it has resulted in them getting more customers than ever before.",
      },
      {
        subTitle: "Improved Location Tracking:",
        subDesc:
          "Our software now provides precise location tracking so that there will be no confusion for drivers and passengers. Now their customers do not experience any missed pickups and wait times, leading to increased reliability and trust in their service.",
      },
      {
        subTitle: "Accurate Fare Calculation:",
        subDesc:
          "We have advanced fare calculation algorithms in our system, so after started using our software there are no fare calculation errors. We eliminated billing discrepancies as a result it brought down customer complaints and increased customer satisfaction.",
      },
      {
        subTitle: "Responsive Support:",
        subDesc:
          "ZayRide now receives prompt and reliable support from our team whenever they encounter issues or require assistance. Our proactive approach to customer support has helped our client overcome challenges swiftly and minimizing disruptions to their operations.",
      },
      {
        subTitle: "Up-to-Date Software:",
        subDesc:
          "Our software solution is regularly updated to incorporate the latest features and improvements. This ensures that our client’s operations remain up-to-date and scalable, allowing them to accommodate their growing business needs and seize new opportunities for expansion.",
      },
    ],
  },
];
const BlogSingleFeatureDataNineThird = [
  {
    id: 1,
    title: "Results Achieved",
    blogImage: "",
    imgalt: "",
    desc: "Before adopting our software solution, ZayRide’s operations were marred by inefficiencies and limitations. However, the implementation of our solution has resulted in a dramatic transformation:",
    subHead: [
      {
        subTitle: "Improved Performance",
        subDesc:
          "Our client’s system can now handle heavy loads with ease, facilitating smoother operations and enhancing the overall customer experience.",
      },
      {
        subTitle: "Business Growth",
        subDesc:
          "The enhanced capabilities of our software have enabled ZayRide to attract new customers and expand their market reach. With the ability to offer reliable and efficient service, ZayRide has positioned itself as a trusted partner in the transportation industry.",
      },
      {
        subTitle: "Increased Profits",
        subDesc:
          "By optimizing operations and delivering superior service, ZayRide has experienced a significant increase in profitability. The elimination of inefficiencies and ability to operate at peak performance levels have translated into tangible financial gains for ZayRide.",
      },
      {
        subTitle: "",
        subDesc:
          "The collaborative partnership between ZayRide and our team exemplifies the transformative power of our ZervX ride hailing platform. Through our tailored approach, comprehensive support, and continuous innovation, we have enabled our client to overcome their challenges, achieve operational excellence, and emerge as a market leader in the Ethiopian ride hailing industry.",
      },
    ],
  },
];
const BlogSingleFeatureDataTen = [
  {
    id: 1,
    title: "Challenges Faced",
    blogImage: "",
    imgalt: "",
    desc: "Before partnering with us, JetRide faced several limitations with their existing taxi dispatch software.",
    subHead: [
      {
        subTitle: "Sluggish System Performance",
        subDesc:
          "JetRide's existing ride-hailing software system experienced frequent slowdowns and performance bottlenecks, which resulted in delays and frustration for both drivers and passengers. The sluggish system affected JetRide's ability to handle high demand efficiently, leading to missed opportunities and dissatisfied customers.",
      },
      {
        subTitle: "Inaccurate Fare Calculation",
        subDesc:
          "Transparent and accurate fare calculations are essential for any ride-hailing service to build trust with its customers. JetRide's fare calculation algorithms were outdated and prone to errors, leading to incorrect fares being charged to passengers. This inconsistency eroded customer confidence and resulted in frequent disputes and complaints.",
      },
      {
        subTitle: "Lack of Proper Technical Support",
        subDesc:
          "JetRide faced challenges in receiving timely and responsive technical support from their existing technology partner. In a dynamic industry where downtime can have severe consequences, the lack of prompt assistance affects their ability to resolve issues quickly and maintain uninterrupted operations.",
      },
      {
        subTitle: "Outdated Software Version",
        subDesc:
          "Another significant challenge for JetRide was the outdated version of their software. JetRide's technology platform has not undergone any significant upgrades since launch. New features requested by users could not be added and emerging technologies like geofencing were out of reach. This not only restricted them from accessing the latest features and improvements but also made it difficult to compete with more technologically advanced rivals.",
      },
    ],
  },
  {
    id: 2,
    title: "Solution Provided",
    blogImage: blogImgTenSecond,
    imgalt: "Advanced taxi app features",
    desc: "",
    subHead: [
      {
        subTitle: "Upgraded System Performance",
        subDesc:
          "JetRide's decision to address the issue of slow system performance by implementing our tailored software solution, marked a significant turning point in their operations. By investing in our system with optimized performance capabilities, JetRide was able to eliminate frequent slowdowns and performance bottlenecks that had been causing delays and frustration for both drivers and passengers. The system's scalable infrastructure allowed JetRide to handle high demand efficiently, ensuring smooth operations even during peak times when the demand for rides was at its highest.",
      },
      {
        subTitle: "Enhanced Fare Calculation Accuracy",
        subDesc:
          "The enhancement of fare calculation accuracy was a crucial step in rebuilding customer trust and satisfaction for JetRide. Our software solution’s fare calculation algorithms are more precise and transparent. After migration, JetRide was able to significantly reduce errors and ensure that passengers were charged accurate fares for their rides. Real-time fare adjustments based on factors like traffic conditions and route changes further improved customer trust and satisfaction, as passengers could see transparency and fairness in the pricing of their rides.",
      },
      {
        subTitle: "Dedicated Technical Support",
        subDesc:
          "We provided JetRide with a dedicated technical support team available 24/7 to address any issues promptly. By offering proactive monitoring, quick response times, and personalized assistance, we ensured that JetRide received the necessary support to resolve technical challenges swiftly. This proactive approach minimized downtime and disruptions, allowed JetRide to maintain seamless operations.",
      },
      {
        subTitle: "Regular Software Updates and Feature Enhancements",
        subDesc:
          "We initiated a structured update schedule for JetRide's software platform, ensuring that the system remained up-to-date with the latest technologies and features. By incorporating user feedback, industry trends, and emerging technologies like geo-fencing, we continuously enhanced JetRide's software capabilities. These regular updates not only improved performance but also empowered JetRide to stay competitive in the Nigerian ride-hailing market.",
      },
    ],
  },
  {
    id: 3,
    title: "",
    blogImage: "",
    imgalt: "",
    desc: "",
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
  },
  {
    id: 4,
    title: "Results Achieved",
    blogImage: "",
    imgalt: "",
    desc: "",
    subHead: [
      {
        subTitle: "",
        subDesc:
          "The implementation of ZervX's tailored solution yielded remarkable results for JetRide. The once slow and laggy system transformed into a high-performing platform, delivering near-instantaneous response times and seamless user experiences. This not only improved customer satisfaction but also boosted driver engagement, resulting in increased retention rates.",
      },
      {
        subTitle: "",
        subDesc:
          "With the new software solution in place, JetRide is now equipped to handle peak demands without any disruptions. During busy periods when the demand for rides is at its highest, JetRide can efficiently allocate resources, manage driver availability, and ensure that all customer requests are met promptly. This capability not only improves customer satisfaction but also allows JetRide to capitalize on high-demand situations, maximizing their revenue potential and market share.",
      },
      {
        subTitle: "",
        subDesc:
          "Our software solution has had a direct impact on their customer acquisition efforts. With improved dispatching efficiency and the ability to handle peak demands seamlessly, JetRide has attracted more customers than ever before. The positive customer experiences resulting from swift and reliable service have translated into increased word-of-mouth referrals, positive reviews, and a growing customer base for JetRide.",
      },
      {
        subTitle: "",
        subDesc:
          "Through our innovative solutions and problem-solving approach, we successfully addressed JetRide's challenges and transformed their ride-hailing services. As a forward-thinking technology solutions provider, we remains committed to staying updated with emerging trends, ensuring that JetRide and other clients continue to thrive in the ride hailing industry.",
      },
    ],
  },
];
const BlogSingleFeatureDataEleven = [
  {
    id: 1,
    title: "Types of Businesses that Need a Super App",
    blogImage: blogImgElevenSecond,
    imgalt: "All in one app for on demand businesses",
    desc: "Various industry sectors can leverage the power of these apps to bring digital transformation to their operational processes and extend their market reach.",
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
  },
  {
    id: 2,
    title: "",
    blogImage: "",
    imgalt: "",
    desc: "",
    subHead: [
      {
        subTitle: "On-Demand Service Businesses",
        subDesc: (
          <>
            Super apps are particularly characterized by proficiency in
            delivering on-demand services. This extends from transportation to{" "}
            <Link href="/industries/food-delivery/" passHref>
              <a>food delivery, </a>
            </Link>
            and even household services. For instance, apps like Grab and Gojek
            are renowned for their ride-hailing services, commanding substantial
            market reach across the region. The one-stop convenience the apps
            like Gojek offer is fundamentally transforming the on-demand service
            landscape, further spurred by digital transformation.
          </>
        ),
      },
      {
        subTitle: "Transport and Logistics Companies",
        subDesc:
          "Transport and logistics services also greatly benefit from the all in one app. Real-time route optimization, delivery tracking, and demand forecasting are some examples of how these all in one apps streamline and accelerate service delivery. It helps logistics businesses deal with inventory management, predicting delivery times, driver management, and accepting cashless payments in an efficient and seamless manner.",
      },
      {
        subTitle: "Food Services and Hospitality Industry",
        subDesc:
          "For the food services and hospitality industry, super apps can act as invaluable tools for demand forecasting and inventory management. App like Meituan in China has already paved the way in this sector, providing consumers a streamlined service experience, thereby gaining a broader market reach.",
      },
      {
        subTitle: "E-commerce Businesses",
        subDesc:
          "In the realm of e-commerce, these apps bring a new dimension of enhanced customer experience with improved UX and simplified processes. Major players like Amazon have also incorporated more on-demand local services, evolving towards becoming a super app themselves. By offering a plethora of services under one digital roof, it facilitates an engaging shopping experience, and directly impact the revenue growth.",
      },
      {
        subTitle: "Online Marketplaces",
        subDesc:
          "Online marketplaces find value in these apps due to their inherent nature of hosting multiple vendors and services concurrently. Similar to messaging apps like WeChat, which host mini programs for various services, these platforms make use of super apps' capabilities for integrated service provision. By enabling partnerships and integrations, they provide a unified platform that promotes cashless payments, discounts, and promotions, thereby strengthening customer retention strategies.",
      },
      {
        subTitle: "",
        subDesc:
          "The success of these apps lies in their unparalleled convenience and their ability to bridge business and service gaps across different industries. They not only support businesses to thrive, but also provide digitally prepared solutions that cater to the diverse needs of consumers in one place.",
      },
    ],
  },
  {
    id: 3,
    title: "Why On-Demand Businesses Need It",
    blogImage: blogImgElevenThird,
    imgalt: "What is a super app",
    desc: "Current market trends indicate a growing demand for on-demand services. Consumers today value convenience, and the proliferation of smart devices has made it easier for businesses to access potential customers right at their fingertips. This change in user behavior has been a driving factor in the rise of platforms on demand where several services (mini apps) are packed into a single app.",
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
  },
  {
    id: 4,
    title: "",
    blogImage: "",
    imgalt: "",
    desc: "",
    subHead: [
      {
        subTitle: "The Rising Demand for On-Demand Services: A Market Analysis",
        subDesc: (
          <>
            The growing trend of the on-demand platforms in services is a
            phenomenon worth noting. Modern buyers go beyond expectation, to
            seek simplified procedure, quick problem resolutions and outstanding
            services. This on-demand services industry has become a global
            phenomenon offering people a wide array of services including food
            delivery,{" "}
            <Link href="/industries/ride-sharing/" passHref>
              <a>ride-sharing, </a>
            </Link>
            package delivery, laundry services, to fitness classes and home
            repairs again through the click of a button. Hence, in the current
            dynamic market a need for the platform having such functionality
            that would be able to fulfill the different demand becomes
            inevitable.
          </>
        ),
      },
      {
        subTitle: "How Super Apps Meet This Demand",
        subDesc:
          "These apps are able to meet the demands of consumers and businesses to various extents. Through their services, including making food orders, booking a ride, doing payment, and engaging in conversations with the friends, the operations could be made more efficient as well as user-friendly. They begin to act like one absorbed medium and show up an individual with a unified and integrated digital experience, and at the same time the organization be able to access a unique data stream. This combination of solutions help build loyal customers who in turn results in high growth and cost saving for the demand services of the platforms.",
      },
      {
        subTitle: "",
        subDesc:
          "Super apps, with their wide range of integrated services, can also offer businesses substantial benefits. These apps offer a significant opportunity for growth and efficiency by granting on-demand businesses access to vast existing user bases. They not only reduce costs and streamline development but also increase revenue through in-app purchases and advertising, leading to higher user engagement and retention. Additionally, its modular architecture and code splitting allow for efficient expansion.",
      },
    ],
  },
  {
    id: 5,
    title: "What Advantages For On-Demand Businesses It Offers",
    blogImage: "",
    imgalt: "",
    desc: "The unique design of these apps which combines diverse services in a single platform has multiple competitive benefits that can be leveraged by businesses. These benefits are multifaceted and include operational efficiency, market penetration, customer retention, revenue generation, and data analytics.",
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
  },
];
const BlogSingleFeatureDataElevenSecond = [
  {
    id: 1,
    title: "How to Choose the Right Super App Provider",
    blogImage: blogImgElevenFourth,
    imgalt: "Super app development",
    desc: "",
    subHead: [
      {
        subTitle: "",
        subDesc:
          "Properly selecting an app provider demands examination of multiple things which all contribute largely toward the business growth. Employing a systematic approach in this selection can pave the way for efficient on-demand services and convenience, both of paramount importance in the modern business landscape.",
      },
      {
        subTitle: "",
        subDesc:
          "The first stage shall be conducted with assessment and discovery where the scope of the issue, values and priorities will be found. Another important aspect is an evaluation of the development company’s expertise and track record. A company with a proven record of delivering a seamless user experience, integrated functionality, effective customer retention techniques, and strong security system can be a valuable asset for businesses. Success factors such as the agility to expand features and services over time demonstrate a provider's professional prowess and potential for market reach growth.",
      },
      {
        subTitle: "",
        subDesc:
          "Ensuring a provider's strong technological framework is crucial. This includes capabilities like advanced AI and machine learning integration, which can provide more sophisticated features and innovation. Further, a good performing technical support team is a key element, which aims to be a fast and solving tool for the occasional problems and assuring the whole operation runs smoothly.",
      },
    ],
  },
  {
    id: 3,
    title: "",
    blogImage: "",
    imgalt: "",
    desc: "",
    subHead: [
      {
        subTitle: "",
        subDesc:
          "With regard to the data security and privacy as the subject which is key for nurturing of the customers' confidence, the provider has to comply with all privacy regulations including secure payment systems, which have encryption feature and provide multifactor authentication feature, too.",
      },
      {
        subTitle: "",
        subDesc:
          "Last but not least, considering the provider's scalability and ability for timely updates is of crucial importance. A super application ought to grow with the business, adapting to evolving needs and expanding its range of services when necessary. Regular updates also ensure that the platform remains current, incorporates advanced functionality, and continues to meet user demands, contributing positively to customer retention and satisfaction.",
      },
      {
        subTitle: "",
        subDesc:
          "The choice of a super app development company significantly affects business performance. Therefore, taking time to select a provider is a valuable investment that should not be overlooked. The right platform can open doors to numerous benefits, from broader market reach to increased customer retention, simplifying processes, and fostering business growth in the dynamic digital age.",
      },
    ],
  },
];
const BlogSingleFeatureDataElevenTable = [
  {
    id: 1,
    heading1: "Benefit",
    heading2: "Explanation",
    heading3: "Example",
    desc1: "Increased reach and visibility",
    desc2:
      "It allows businesses to access a large existing user base and gain visibility among many potential new customers.",
    desc3:
      "Gojek provides food delivery services to over 2 million users in Indonesia daily, increasing visibility for restaurants.",
    desc4: "Convenient for customers",
    desc5:
      "Customers can access many services through one app instead of multiple apps, improving convenience.",
    desc6:
      "Users can order food delivery, pay bills, shop online, and hail rides through the Gojek app.",
    desc7: "Data and insights",
    desc8:
      "These apps gather data across services that provide valuable insights into customer behavior.",
    desc9:
      "Gojek can gain insights across food orders, locations, payments to improve recommendations.",
    desc10: "Operational efficiencies",
    desc11:
      "Super apps allow streamlining operations and reducing costs by consolidating multiple services.",
    desc12:
      "Gojek optimizes driver routing and coordination across food delivery, rides, parcel delivery etc.",
    desc13: "New revenue streams",
    desc14:
      "Businesses can offer and monetize new digital services through the super app ecosystem.",
    desc15:
      "SMEs can set up virtual shops on Gojek to sell goods and services to the large user base.",
  },
];

const BlogSingleFeatureDataTwelveFirst = [
  {
    id: 1,
    blogImage: blogImgTwelveSecond,
    imgalt: "White label taxi dispatch software",
    title: "",
    desc: "The taxi industry has changed a lot in recent years, especially with the rise of ride hailing giants like Uber and Lyft. These companies have revolutionized how people book and use taxis. Traditional taxi businesses that once relied on phone calls for bookings or customers hailing cabs from the street now face tough competition. T o stay relevant in this modern, fast-moving industry, taxi companies need to embrace technology. One of the most effective ways to do this is by using white label taxi dispatch software.",
    subHead: [
      {
        subTitle: "",
        subDesc:
          "But what is white label taxi dispatch software? Why is it a great option for your taxi business? Let’s break it down. Find out how white label software can help your taxi business.",
      },
    ],
  },
  {
    id: 2,
    blogImage: blogImgTwelveThrid,
    imgalt: "White label taxi dispatch software features",
    title: "What is White Label Taxi Dispatch Software?",
    desc: (
      <div>
        <div>
          <p>
            White label taxi dispatch software is a ready-to-use application
            designed to help taxi companies manage their bookings, dispatch
            drivers, and track vehicles. The term "white-label" means the
            software is generic but can be fully customized and branded with
            your company’s logo, colors, and any special features you want to
            include. Think of it like a blank canvas. It’s already built, but
            you get to paint it with your unique brand, making it your own.
          </p>
          <p>
            For example, if you own a taxi business and want to launch an app
            similar to Uber or Lyft but don't have the time or money to build it
            from scratch, a white label solution allows you to do this quickly.
            You can get the app running under your own brand name, customize it
            to suit your needs, and start attracting customers without the long
            wait and high cost of developing your own software.
          </p>
        </div>
        <div>
          <h2>Key Benefits of White Label Taxi Dispatch System</h2>
          <p>
            There are several key reasons why{" "}
            <Link href="/solutions/taxi-dispatch-software">
              <a> white label taxi dispatch software </a>
            </Link>{" "}
            is such a powerful tool for taxi businesses:
          </p>
        </div>
      </div>
    ),
    subHead: [
      {
        subTitle: "1. Cost-Effective Solution",
        subDesc: (
          <p>
            Building an app from scratch is not only expensive, but it also
            takes a lot of time and effort. You would need a team of developers,
            designers, testers, and project managers to create a reliable app.
            On the other hand, white label software is already built and tested.
            This saves you a lot of money because you’re not paying for the
            development process. You simply purchase the software and customize
            it to fit your brand, cutting down costs significantly.
          </p>
        ),
      },
      {
        subTitle: "2. Quick to Launch",
        subDesc: (
          <p>
            Time is money. The longer you wait to launch your app, the more
            customers you lose to competitors. A white label solution lets you
            get your app up and running quickly. Since the core features like
            booking, tracking, payments and all other important features are
            already built, all you need to do is customize it to match your
            brand’s identity. This speed is especially important in today’s
            competitive environment, where new services are launching every day.
            By using white label software, you can get ahead of the competition
            faster.
          </p>
        ),
      },
      {
        subTitle: "3. Customization for Brand Identity",
        subDesc: (
          <p>
            Even though the software is pre-built, it doesn’t look generic.{" "}
            <Link href="/mobility-platform/taxi-cab-dispatch-software/">
              <a> White label taxi cab dispatch software </a>
            </Link>{" "}
            allows you to fully customize the app to reflect your brand. You can
            change the colors, add your logo, and even tweak certain features to
            better serve your customers. When users open your app, they will see
            your brand, not a third-party company. This helps build trust and
            recognition, as customers associate the app with your business. A
            well-branded app increases loyalty and makes your business stand out
            from the crowd.
          </p>
        ),
      },
      {
        subTitle: "4. Packed with Advanced Features",
        subDesc: (
          <p>
            Time is money. The longer you wait to launch your app, the more
            customers you lose to competitors. A white label solution lets you
            get your app up and running quickly. Since the core features like
            booking, tracking, payments and all other important features are
            already built, all you need to do is customize it to match your
            brand’s identity. This speed is especially important in today’s
            competitive environment, where new services are launching every day.
            By using white label software, you can get ahead of the competition
            faster.
          </p>
        ),
      },
    ],
    subHeadAfterImage: [
      {
        subTitle: "",
        subDesc: (
          <div>
            <p>
              These softwares come with a range of advanced features right out
              of the box. These features include:
            </p>
            <p>
              <strong>Real-time tracking: </strong> Customers can see where
              their taxi is and when it will arrive.
            </p>
            <p>
              <strong>Automated dispatch: </strong> The system automatically
              assigns the closest driver to a booking request, ensuring faster
              service.
            </p>
            <p>
              <strong>Multiple payment gateways: </strong> Customers can pay
              through various options, including cash, card, and digital
              wallets.
            </p>
            <p>
              <strong>Driver and customer apps: </strong> Separate apps for both
              drivers and passengers (android & iOS), designed for smooth and
              easy use.
            </p>
            <p>
              <strong>Analytics and reporting: </strong> Track your business
              performance, driver efficiency, and customer satisfaction through
              detailed reports and analytics.
            </p>
            <p>
              These features help improve the customer experience while making
              your operations more efficient. You can also integrate additional
              features like loyalty programs, ride sharing options, or even
              special promotions to attract more customers.
            </p>
          </div>
        ),
      },
      {
        subTitle: "5. Improved Operational Efficiency",
        subDesc: (
          <div>
            <p>
              White label taxi dispatch software simplifies many of the
              operational challenges that taxi businesses face. Traditionally,
              dispatching taxis involved manual processes, which were time
              consuming and prone to human errors. With automated dispatching,
              drivers are matched with passengers based on proximity and
              availability. This reduces the waiting time for passengers and
              increases the number of rides a driver can complete in a day.
            </p>
            <p>
              The software also provides drivers with navigation assistance,
              which helps them take the fastest and most efficient routes. This
              minimizes fuel costs and reduces travel time, benefiting both the
              driver and the passenger. From a business perspective, this
              increased efficiency leads to higher profits and better service.
            </p>
          </div>
        ),
      },
      {
        subTitle: "6. Enhanced Customer Experience",
        subDesc: (
          <div>
            <p>
              Customer experience is crucial to any business’s success. T oday’s
              customers expect fast, reliable, and easy-to-use services.
              Features like real-time tracking, accurate estimated time of
              arrival (ETA), and cashless payments create a seamless experience
              for users. Customers want convenience, and with this software,
              they can book a ride in just a few taps on their phone. This kind
              of ease and reliability can turn first-time users into loyal,
              repeat customers.
            </p>
            <p>
              Additionally, features like customer ratings and feedback options
              give you valuable insights into areas where you can improve your
              service. Offering top-notch customer experience will set you apart
              from the competition and encourage customers to choose your
              service time and time again.
            </p>
          </div>
        ),
      },
      {
        subTitle: "7. Scalability",
        subDesc: (
          <div>
            <p>
              One of the greatest advantages of this software is its ability to
              scale with your business. Whether you are a small local taxi
              company with a handful of cars or a large fleet serving multiple
              cities, white label software can grow with you. As your business
              expands, you can easily add more drivers, integrate new features,
              and even expand to new markets without having to rebuild the app.
            </p>
            <p>
              For instance, if you start with a small fleet of taxis and later
              decide to add luxury cars or electric vehicles, the software can
              easily accommodate this change. The system is flexible and
              scalable, allowing you to manage your operations efficiently
              regardless of the size of your business.
            </p>
          </div>
        ),
      },
      {
        subTitle: "8. Competing with Ride Hailing Giants",
        subDesc: (
          <div>
            <p>
              With the rise of global ride hailing companies, it might feel like
              smaller taxi businesses are being pushed out of the market.
              However, white label taxi dispatch software levels the playing
              field. By using this software, your business can offer the same
              advanced services that Uber or Lyft provides, such as easy
              booking, GPS tracking, and multiple payment options. But unlike
              these giants, your company will have more control over pricing,
              customer relationships, and local market needs.
            </p>
            <p>
              By offering a personalized service that focuses on the needs of
              your local customers, you can carve out your niche in the market.
              Plus, it is designed to keep up with new technology trends, so you
              won’t fall behind.
            </p>
          </div>
        ),
      },
    ],
  },
  {
    id: 3,
    blogImage: blogImgTwelveFourth,
    imgalt: "Benefits of white label taxi dispatch system",
    title:
      "Why Choosing White Label Taxi Software is a Smart Business Decision",
    desc: "",
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
    subHeadAfterImage: [
      {
        subTitle: "",
        subDesc: (
          <p>
            There are many reasons why taxi companies are choosing white label
            solutions over developing their own app from scratch. Here’s why
            it’s a smart business move:
          </p>
        ),
      },
      {
        subTitle: "",
        subDesc: (
          <p>
            <strong className="">Saves Time and Money: </strong>
            <span>
              Building an app from scratch can take years and cost a fortune.
              With white label solutions, you can avoid the hassle and get a
              high-quality app at a fraction of the cost.
            </span>
          </p>
        ),
      },
      {
        subTitle: "",
        subDesc: (
          <p>
            <strong className="">Full Control: </strong>
            <span>
              You get to design the app to match your brand and decide how you
              want it to function. Unlike partnering with third-party
              ride-hailing companies, you have full control over your business.
            </span>
          </p>
        ),
      },
      {
        subTitle: "",
        subDesc: (
          <p>
            <strong className="">Built-in Support: </strong>
            <span>
              Most white label software providers offer ongoing technical
              support. If anything goes wrong with your app, there is a
              dedicated team ready to fix it, so you don’t have to worry about
              tech issues.
            </span>
          </p>
        ),
      },
      {
        subTitle: "",
        subDesc: (
          <p>
            <strong className="">Quick Adaptation to Market Trends: </strong>
            <span>
              Since the software is highly customizable, you can quickly adapt
              to new trends or customer demands. For example, you could add
              eco-friendly features like electric vehicle options as customer
              preferences shift towards sustainability.
            </span>
          </p>
        ),
      },
    ],
  },
  {
    id: 4,
    blogImage: "",
    imgalt: "",
    title: "How to Choose the Best White Label Taxi Dispatch Software Solution",
    desc: "Picking the right white label taxi booking system is important for your taxi business’s success. Here are some easy tips to help you make the best decision:",
    subHead: [
      {
        subTitle: "1. Understand Your Needs",
        subDesc:
          "Start by figuring out what your business really needs. Do you need features like route optimization, multiple payment options, ride-sharing, or detailed analytics? Knowing your needs will help you focus on the software that meets those specific requirements.",
      },
      {
        subTitle: "2. Research and Compare",
        subDesc:
          "Once you know what you need, research different white label taxi software providers. Check out their features, prices, customer reviews, and the support they offer. Make a list of pros and cons for each option, so you can easily compare them and choose the best one.",
      },
      {
        subTitle: "3. Think About Scalability",
        subDesc:
          "As your taxi business grows, your software needs to grow with it. Choose a system that can expand to handle more drivers, vehicles, and customers without major issues. Look for software that can scale up smoothly as your business gets bigger.",
      },
      {
        subTitle: "4. Test Before You Decide",
        subDesc: (
          <div>
            <div>
              <p>
                Most white label taxi system providers offer free trials or
                demos. Take advantage of these to test how the software works
                and see if it’s a good fit for your business. This hands-on
                experience will help you make a more confident decision.
              </p>
              <p>
                By following these simple steps, you can choose a white label
                taxi software solution that will help your business succeed and
                grow.
              </p>
            </div>
            <div>
              <Read
                content="How to Choose the Best Taxi Dispatch Software"
                link="/blog/how-to-choose-the-best-taxi-dispatch-software/"
                also
              />
            </div>
          </div>
        ),
      },
    ],
  },
  {
    id: 5,
    blogImage: "",
    imgalt: "",
    title: "Conclusion",
    desc: "In today’s competitive world, having your own branded white label taxi dispatch software is essential for success. It offers a cost-effective, scalable, and feature-packed solution that empowers taxi businesses to provide a modern, quality service to customers. With the ability to quickly launch, customize, and expand your operations, white label software puts you in control, allowing you to compete effectively in the rapidly evolving transportation industry.",
    subHead: [
      {
        subTitle: "",
        subDesc:
          "If you want to future-proof your taxi business and offer the best possible service to your customers, investing in white label software is the smartest choice you can make.",
      },
      {
        subTitle: "",
        subDesc: (
          <p>
            So, why wait? The right white labeled taxi app can anchor your taxi
            business on the right path to better performance. Take the{" "}
            <Link href="/industries/contact-sales/">
              <a> first step </a>
            </Link>{" "}
            and choose the best white label taxi software for your taxi business
            needs.
          </p>
        ),
      },
    ],
  },
];

const BlogSingleFeatureDataThirteenTable = [
  {
    id: 1,
    title: "What are the functions of fleet management solution?",
    description:
      "Fleet management software provides a range of functions which will ensure the smooth running of a business and the reduction of expenditure. Here's a breakdown of its key functions:",
    heading1: "Function",
    heading2: "Description",
    heading3: "",
    desc: [
      {
        desc1: "Vehicle Tracking",
        desc2: "Real-time monitoring of vehicle locations and routes",
        desc3: "",
      },
      {
        desc1: "Maintenance",
        desc2: "Scheduling and tracking of vehicle maintenance tasks",
        desc3: "",
      },
      {
        desc1: "Fuel Management",
        desc2:
          "Tracking fuel consumption along with improving fuel efficiencies",
        desc3: "",
      },
      {
        desc1: "Route Optimization",
        desc2: "Optimal route planning to minimize time and fuel consumption",
        desc3: "",
      },
      {
        desc1: "Driver Management",
        desc2: "Managing driver information, performance, and schedules",
        desc3: "",
      },
      {
        desc1: "Reporting",
        desc2: "Generating insightful reports on fleet performance",
        desc3: "",
      },
    ],
  },
];
const BlogSingleFeatureDataThirteenOneline = [
  {
    id: 1,
    title: "Which businesses can use white label fleet management software?",
    blogImage: "",
    imgalt: "",
    desc: "Fleet management software is invaluable for businesses of all sizes and across various industries. Businesses across various industries can benefit from this software solution. Here's a list of businesses that can benefit from using this software:",
    subHead: [
      {
        subTitle: "1. Taxi and ride hailing companies:",
        subDesc: (
          <>
            Taxi companies,{" "}
            <Link href="/industries/ride-hailing" passHref>
              <a> ride-hailing </a>
            </Link>
            services, and car rental businesses can use the software for fleet
            management to track their vehicles, manage bookings, and boost
            customer service.
          </>
        ),
      },
    ],
  },
];
const BlogSingleFeatureDataThirteenOnelineSecond = [
  {
    id: 1,
    title: "",
    blogImage: blogImgThirteenSecond,
    imgalt: "Fleet management system",
    desc: "",
    subHead: [
      {
        subTitle: "2. Delivery services:",
        subDesc: (
          <>
            {" "}
            <Link href="/industries/food-delivery" passHref>
              <a> Food delivery, </a>
            </Link>{" "}
            <Link href="/industries/package-delivery" passHref>
              <a> package delivery, </a>
            </Link>
            and courier services can optimize their routes, monitor drivers, and
            track deliveries efficiently.
          </>
        ),
      },
      {
        subTitle: "3. Transportation and logistics companies:",
        subDesc: (
          <>
            This includes trucking companies, freight carriers,{" "}
            <Link href="/industries/city-couriers" passHref>
              <a> courier </a>
            </Link>
            services, and any business involved in moving goods from one
            location to another.
          </>
        ),
      },
      {
        subTitle: "4. Construction companies:",
        subDesc:
          "Firms owning a fleet of vehicles like trucks, bulldozers, and cranes can incorporate the fleet management system to schedule maintenance, track equipment usage, and improve project efficiency.",
      },
      {
        subTitle: "5. Field service businesses:",
        subDesc: (
          <>
            The{" "}
            <Link href="/industries/field-services" passHref>
              <a> field services </a>
            </Link>
            companies such as HVAC repair, plumbing, and electrical maintenance
            can use the vehicle management software to schedule appointments,
            assign jobs, and track their technicians’ locations.
          </>
        ),
      },
    ],
  },
];
const BlogSingleFeatureDataThirteen = [
  {
    id: 1,
    title:
      "Important factors to consider when choosing vehicle management software",
    blogImage: blogImgThirteenThird,
    imgalt: "Vehicle management software",
    desc: "Choosing the right fleet management software is not just a merely one step; it is a strategically important one which may enormously change the prospects of your business. In the environment of today's constantly changing markets, where every efficiency gain counts, the choice of the correct software solution can often be the difference between thriving and merely surviving. Here's why:",
    subHead: [
      {
        subTitle: "1. Ease of use",
        subDesc:
          "The usability of software plays a pivotal role in its adoption within an organization. An intuitive interface and user-friendly features ensure that employees can quickly grasp the functionalities, reducing the need for extensive training sessions. Moreover, it fosters a smoother transition from traditional methods to digital platforms, minimizing resistance and maximizing productivity from the get-go.",
      },
      {
        subTitle: "2. Technology integration",
        subDesc:
          "As the world is getting interconnected, flexibility of software to quickly plug into existing systems and third-party applications is surprisingly vital. This allows different platforms to exchange data among each other unhindered, eradicating silos and providing a whole picture of operations. This integration not only boosts an organization’s productivity but also allows it to take advantage of its investments made in technology, thereby optimizing the return on investment.",
      },
      {
        subTitle: "3. Scalability and upgrades",
        subDesc:
          "Along with the changing business landscape, software needs will also adjust accordingly. The decision to implement a solution that will be adequate and grow as your business flourishes ensures continuous operation without the need for repetitive migrations or unplanned replacements. Additionally, continuous updates and upgrades serve to keep the software at par with the industry trends as well as the latest technologies that are rolling out, preventing the investment from becoming obsolete and maintaining a competitive edge.",
      },
      {
        subTitle: "4. Security and safety features",
        subDesc:
          "The cyber security threats and regulatory compliance requirements we face today emphasize the necessity of a robust security approach. The fleet management solution must contain encryption procedures, access controls, and audit trails to address security matters while complying with industry regulations.",
      },
    ],
  },
  {
    id: 2,
    title: "",
    blogImage: "",
    imgalt: "",
    desc: "",
    subHead: [
      {
        subTitle: "5. Technical support",
        subDesc:
          "Despite the best efforts to select user-friendly software, occasional technical issues and questions are inevitable. That's where reliable technical support comes into play. Choosing a provider that guarantees speedy and competent response guarantees that any complications or questions that might arise are dealt with instantly, thus ensuring minimal downtime and interruptions. Moreover, ongoing support fosters a sense of partnership between the provider and the client, ensuring a positive and productive user experience.",
      },
      {
        subTitle: "",
        subDesc:
          "In short, choosing the right white label fleet management software shouldn’t be about checking all the boxes, instead, it should be about making an informed decision that is in line with the business goals and which is a platform for steady growth and success.",
      },
    ],
  },
  {
    id: 3,
    title: "Wrapping up",
    blogImage: "",
    imgalt: "",
    desc: "It's so important to weigh up all the factors before choosing a fleet management solution. Choosing the right provider is tricky, but when you carefully analyze each provider, taking into account the factors described here, you'll be able to make an informed decision that aligns with your business goals.",
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
  },
];
const BlogSingleFeatureDataFourteen = [
  {
    id: 1,
    blogImage: blogImgFourteenSecond,
    imgalt: "How to start a taxi business like Uber",
    title: "",
    desc: "Are you thinking about starting your own taxi business, like Uber? The transportation industry has changed a lot due to new technology and people’s changing needs. Starting a taxi business today can be a great idea and a very profitable venture, but it requires careful planning and smart decisions.",
    subHead: [
      {
        subTitle: "",
        subDesc:
          "Here’s a simple guide to help you get started and succeed in the competitive world of ride-hailing services. It will walk you through the essential steps you need to take to launch a successful ride-hailing business, we’ll cover everything you need to know to get started.",
      },
    ],
  },
  {
    id: "",
    blogImage: blogImgFourteenThird,
    imgalt: "Taxi market size estimates",
    title: "Why Starting a Taxi Business Is a Good Idea in 2025",
    desc: (
      <div>
        <p>
          In 2024, the demand for transportation services remains high. People
          always need to get around, whether it's for work, socializing, or
          simply convenience. Starting a taxi business can be a great
          opportunity because it offers flexibility, scalability, and the
          potential to generate high returns.
        </p>
        <p>
          One of the main reasons taxi services are still successful today is
          because they can easily be adapted to meet the changing needs of
          consumers. People now prefer services that are fast, reliable, and
          affordable. A well-managed taxi business can fulfill all these needs,
          making it a profitable and sustainable business option.
        </p>
      </div>
    ),
    subHead: [
      {
        subTitle: "Overview of the Taxi Industry",
        subDesc:
          "The taxi industry has been around for a long time and is still relevant today. With the rise of smartphones, companies like Uber and Lyft changed how people use taxis. These apps made it easy for people to get a ride anytime they need it, without owning a car. T oday, there is a huge demand for reliable, fast, and affordable transportation services.",
      },
      {
        subTitle: "Why Start Your Own Taxi Business Now?",
        subDesc: (
          <div>
            <p>Starting a taxi business has many advantages:</p>
            <p>
              <strong>High Demand: </strong> People always need transportation
              for work, social events, and convenience. Changing Consumer
              Habits: More people prefer using ride-hailing services instead of
              owning a car.
            </p>
            <p>
              <strong>Good Profit Potential: </strong> Taxi businesses can make
              money from ride fares, surge pricing (charging more during busy
              times), and partnerships with other companies.
            </p>
            <p>
              <strong>Easy to Grow: </strong> You can start small with just a
              few cars and expand as your business grows.
            </p>
          </div>
        ),
      },
      {
        subTitle: "Market Size Estimates",
        subDesc: (
          <div>
            <p>
              As per the study published by{" "}
              <a
                href="https://www.grandviewresearch.com/industry-analysis/ride-hailing-taxi-market-report"
                target="_blank"
              >
                {" "}
                Grand View Research,{" "}
              </a>{" "}
              the global ride-hailing and taxi market was valued at USD 199.14
              billion in 2021 and is expected to expand at a compound annual
              growth rate (CAGR) of 11.7% from 2022 to 2028.
            </p>
            <p>
              Another report from{" "}
              <a
                href="https://www.mordorintelligence.com/industry-reports/taxi-market"
                target="_blank"
              >
                {" "}
                Mordor Intelligence{" "}
              </a>{" "}
              estimates the taxi market size at USD 278.65 billion in 2024,
              expected to reach USD 432.56 billion by 2029, growing at a CAGR of
              9.01% during the forecast period (2024-2029).
            </p>
          </div>
        ),
      },
    ],
  },
  {
    id: 2,
    blogImage: "",
    imgalt: "",
    title: "The Basics of Starting a Taxi Business",
    desc: "Starting a taxi business requires careful planning and a clear understanding of the market. It’s not just about buying a few cars and hitting the road. You’ll need to:",
    subHead: [
      {
        subTitle: "",
        subDesc: (
          <p>
            <strong> Do Market Research: </strong> Know your competitors,
            understand customer needs, and find out what gaps exist in the
            market.
          </p>
        ),
      },
      {
        subTitle: "",
        subDesc: (
          <p>
            <strong>Create a Business Plan: </strong> You’ll need to think about
            your business model, budget, and how to market your service.
          </p>
        ),
      },
      {
        subTitle: "",
        subDesc: (
          <p>
            <strong> Comply with Legal Requirements: </strong> Make sure you
            follow local laws and obtain the necessary licenses.
          </p>
        ),
      },
      {
        subTitle: "",
        subDesc: (
          <p>
            <strong> Use Technology: </strong> Create or buy a taxi booking app
            like Uber to make it easy for customers to book rides.
          </p>
        ),
      },
      {
        subTitle: "",
        subDesc: "Let’s dive deeper into each of these steps.",
      },
    ],
  },
  {
    id: 3,
    blogImage: "",
    imgalt: "",
    title: "Market Research: Understand the Industry",
    desc: "Before starting your own taxi service, you need to understand the market. Market research is the process of gathering information about your potential customers, competitors, and industry trends. This will help you figure out how to make your business stand out.",
    subHead: [
      {
        subTitle: "1. Understand Your Customer Needs",
        subDesc: (
          <div>
            <p>
              Your customers are the key to your business’s success. To meet
              their needs, you must first understand what they expect from a
              taxi service. Generally, customers look for:
            </p>
            <p>
              <strong>Reliability: </strong> They want a taxi that shows up on
              time.
            </p>
            <p>
              <strong>Affordability: </strong> They expect the prices to be fair
              and transparent. Safety: Passengers want to feel safe when they
              use your service.
            </p>
            <p>
              <strong>Convenience: </strong> Customers appreciate easy booking
              methods and cashless payment options.
            </p>
            <p>
              To gather information, you can use surveys, interviews, or online
              research. By understanding your customers’ preferences, you can
              design your business around what they need most.
            </p>
          </div>
        ),
      },
      {
        subTitle: "2. Identify Market Gaps",
        subDesc:
          "Once you know what customers want, the next step is to look at your competitors. What services do they offer? Are there any gaps in the market that your business could fill? For example, maybe your competitors don’t offer rides during late-night hours, or perhaps they don’t have a reliable mobile app. Finding these gaps can help you create a unique selling point for your business, making you stand out from the competition.",
      },
      {
        subTitle: "3. Competitor Analysis",
        subDesc: (
          <p>
            When analyzing your competitors, you should look at both their
            strengths and weaknesses. Big companies like Uber have been
            successful for several reasons: they offer convenient technology,
            good customer service, and competitive pricing. However, they also
            face challenges, such as handling driver complaints or managing{" "}
            <Link href="/blog/surge-pricing/">
              <a> surge pricing </a>
            </Link>{" "}
            during busy times. Learn from both their successes and their
            mistakes to create a more balanced business model.
          </p>
        ),
      },
      {
        subTitle: "4. Target Audience Identification",
        subDesc:
          "Understanding your target audience is crucial. Your audience will include people who need transportation regularly, such as commuters, tourists, or people attending events. You’ll also need to consider factors like age, gender, income, and lifestyle habits. For example, younger customers might prefer app-based bookings, while older customers might still appreciate phone calls to book a ride. T ailoring your services to your audience’s needs will help you gain loyal customers.",
      },
    ],
  },
  {
    id: 4,
    blogImage: blogImgFourteenFourth,
    imgalt: "Taxi business plan",
    title: "Planning Your Taxi Business",
    desc: "Once you’ve gathered all your market research, it’s time to create a solid business plan. This plan will serve as the roadmap for your taxi cab business.",
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
    subHeadAfterImage: [
      {
        subTitle: "1. Choose a Business Model",
        subDesc: (
          <div>
            <p>
              There are different business models you can choose for your taxi
              service. The two most common models are:
            </p>
            <p>
              <strong> Owner-Driver Model: </strong> In this model, you own the
              cars, and drivers work for you. This gives you more control over
              the business, but it also requires more upfront investment.
            </p>
            <p>
              <strong>App-Based Model (Like Uber): </strong> In this model,
              drivers use their own cars, and you provide the technology to
              connect them with customers. This model is more flexible and
              requires less initial investment, but you’ll need to focus on
              building and managing the technology platform.
            </p>
            <p>
              Choose the model that best fits your budget, goals, and risk
              tolerance.
            </p>
          </div>
        ),
      },
      {
        subTitle: "2. Develop a Financial Plan",
        subDesc: (
          <div>
            <p>
              A well-thought-out financial plan is essential for any business.
              You’ll need to budget for both the initial costs and the ongoing
              expenses of running your taxi service.
            </p>
            <p>Initial costs may include:</p>
            <ul style={{ listStyleType: "" }}>
              <li>Buying vehicles (if you choose the owner-driver model)</li>
              <li>Developing or purchasing a taxi booking app</li>
              <li>Marketing and advertising</li>
              <li>Obtaining licenses and permits</li>
            </ul>
            <p>Ongoing expenses will likely include:</p>
            <ul style={{ listStyleType: "" }}>
              <li>Vehicle maintenance and fuel</li>
              <li>Driver wages or commissions</li>
              <li>App maintenance and updates</li>
              <li>Insurance and other legal costs</li>
            </ul>
            <p>
              Make sure your budget is realistic, and consider setting aside
              extra funds for unexpected expenses.
            </p>
          </div>
        ),
      },
      {
        subTitle: "3. Setting Business Goals",
        subDesc: (
          <div>
            <p>
              Every successful business needs clear goals. These might include:
            </p>
            <ul style={{ listStyleType: "" }}>
              <li>Expanding your service to new areas</li>
              <li>Being the top-rated taxi service in your region</li>
              <li>Achieving a specific revenue target within the first year</li>
            </ul>
            <p>
              Setting measurable goals will help keep you focused and allow you
              to track your progress over time.
            </p>
          </div>
        ),
      },
    ],
  },
  {
    id: 5,
    blogImage: "",
    imgalt: "",
    title: "Legal Requirements",
    desc: "Running a taxi business comes with a number of legal responsibilities. Before you start, make sure you’re familiar with the laws in your area.",
    subHead: [
      {
        subTitle: "1. Get the Right Licenses and Permits",
        subDesc: (
          <div>
            <p>
              Every country, and often each city, has its own rules for
              operating a taxi business. You’ll likely need:
            </p>
            <ul style={{ listStyleType: "" }}>
              <li>
                <i>Driver Licenses: </i>Drivers must have valid licenses to
                operate a taxi.
              </li>
              <li>
                <i>Vehicle Licenses: </i>Taxis need to be registered and pass
                safety inspections.
              </li>
              <li>
                <i>Business Permits: </i>Depending on your location, you may
                need a permit to operate your business.
              </li>
            </ul>
            <p>
              Make sure you follow all the rules to avoid fines or legal
              problems down the road.
            </p>
          </div>
        ),
      },
      {
        subTitle: "2. Follow Local Laws and Regulations",
        subDesc:
          "You also must follow local laws. These laws cover things like registering your taxi, ensuring it is safe to drive, setting fair prices for rides, and knowing when you can operate your taxi service. It is important to understand these laws because failure to follow them can lead to fines, business shutdowns, or even lawsuits. Staying compliant with local laws not only keeps your business safe from fines but also helps build trust with your customers.",
      },
    ],
  },
  {
    id: 6,
    blogImage: "",
    imgalt: "",
    title: "The Role of Technology in a Taxi Business",
    desc: (
      <div>
        <p>
          Technology is at the heart of modern taxi businesses. The most
          successful companies, like Uber, use mobile apps to make it easy for
          customers to book rides, pay for services, and track their drivers in
          real time. If you want to compete in today’s market, having a reliable
          taxi booking system is essential.
        </p>
        <Read
          also
          content={"How to Choose the Best Taxi Dispatch Software"}
          link={"/blog/how-to-choose-the-best-taxi-dispatch-software/"}
        />
      </div>
    ),
    subHead: [
      {
        subTitle: "Building an App or Buying an White Label Taxi Solution",
        subDesc: (
          <p>
            When taxi businesses need a booking app, they have two main choices:
            building an app from scratch or buying a white-label solution.
            Creating an in-house app allows the business to match its brand
            perfectly, but it requires a lot of time, money, and expert
            developers. On the other hand, a white-label solution, like an{" "}
            <Link href="/uber-clone/">
              <a> Uber clone, </a>
            </Link>{" "}
            <Link href="/bolt-clone/">
              <a> Bolt clone, </a>
            </Link>{" "}
            <Link href="/lyft-clone/">
              <a> Lyft clone, </a>
            </Link>{" "}
            is cheaper and faster to launch. However, it might not offer as much
            customization. The best option depends on the business’s needs,
            budget, and timeline.
          </p>
        ),
      },
    ],
  },
  {
    id: 7,
    blogImage: blogImgFourteenFifth,
    imgalt: "Grow your taxi business",
    title: "Marketing and Growing Your Taxi Business",
    desc: "",
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
    subHeadAfterImage: [
      {
        subTitle: "",
        subDesc: (
          <div>
            <p>
              Once your taxi business is set up, you need to market it to
              attract customers. There are various marketing techniques by which
              your taxi business can attract customers & secure its place within
              the market. Here are some simple strategies to get started:
            </p>
            <ul>
              <li>Building a Strong Brand Identity</li>
              <li>Social Media Marketing</li>
              <li>SEO to Improve Online Visibility</li>
              <li>Email Marketing and Retargeting Strategies</li>
              <li>Use of Print Media and Outdoor Advertising</li>
              <li>Partnering with Local Businesses</li>
              <li>Participating in Local Events for Brand Visibility</li>
              <li>Loyalty and Referral Programs</li>
            </ul>
            <p>
              By focusing on good customer service and smart marketing
              strategies, you can grow your taxi business and attract a loyal
              customer base.
            </p>
          </div>
        ),
      },
    ],
  },
  {
    id: 8,
    blogImage: "",
    imgalt: "",
    title: "Wrapping Up",
    desc: "The current market is full of opportunities for a taxi business. Many successful taxi companies continue to expand and generate good profits while providing essential services to their communities.",
    subHead: [
      {
        subTitle: "",
        subDesc:
          "To thrive in this competitive landscape, business owners must conduct thorough market analysis, develop effective strategies, and comply with all legal requirements. Having a reliable taxi booking system is vital for enhancing efficiency, ensuring customer satisfaction, and building loyalty.",
      },
      {
        subTitle: "",
        subDesc:
          "In summary, the key to a successful taxi business is understanding your customers, offering safe and high-quality services at fair prices, and adapting to their evolving needs. With the right approach, you can create a thriving taxi business that meets the demands of today’s riders.",
      },
    ],
  },
];
const BlogSingleFeatureDataFifteen = [
  {
    id: 1,
    title: "The Role of Limo Software in Fleet Management",
    desc: "Limo software features a significant importance to any contemporary limousine company which guarantees good management approaches and optimal performance of vehicles. One must know its roles, characteristics, and the advantages that it offers businesses.",
    blogImage: blogImgFifteenThird,
    imgalt: "limo reservation & booking software",
    subHead: [
      {
        subTitle: "Organized Scheduling and Dispatching",
        subDesc:
          "Limousine software is used in the management of dispatch and scheduling processes. By having real-time data accessibility, driver and vehicle scheduling, limo businesses can optimize their positions, prevent overbooking, and effectively assign available options. It eradicates the procedures that would otherwise be carried out by manpower and therefore cuts instances of errors and slow business pace.",
      },
      {
        subTitle: "Efficient Route Planning",
        subDesc:
          "Route planning can often be problematic especially so in urban areas where there are many buildings and structures. The use of affixed GPS and mapping facilities in limousine reservation software enhances the determination of appropriate routes, avoiding traffic jams to ensure timely delivery of services.",
      },
      {
        subTitle: "Personalized Service Delivery for VIP Customers",
        subDesc:
          "Limos are luxury vehicles that offer highly individualized services in their line of work. Limo software is flexible and can be programmed to meet the needs and preferences of customers thus making the experience even better and they are likely to return for more services. With the help of this important data about the VIP clients, the software helps in providing the most positive experiences to these valuable customers.",
      },
    ],
  },
  {
    id: 2,
    title:
      "A Real-Life Case of Successful Implementation of Limo Booking Software",
    desc: "",
    blogImage: "",
    imgalt: "",
    subHead: [
      {
        subTitle: "",
        subDesc:
          "When the limousine management software was implemented in a certain organization it was successfully used and it has been agreed that it proved to be of immense benefit.",
      },
      {
        subTitle: "",
        subDesc:
          "The use of limousine software plays a significant role in the adjustment of limousine operators. For example, a mid-size limousine service provider located in the United States witnessed a tremendous amount of improvement after adopting a limousine reservation software.",
      },
      {
        subTitle: "",
        subDesc:
          "At the beginning, they experienced difficulties in organization, route planning, and work requests specifically from the clients categorized as VIPs that led to poor quality services and low customer satisfaction. But, once they opted for limousine booking system, they got a positive impact in doing bookings, efficient in mapping the routes, providing customized services, and managing their finance. Therefore, the limo dispatch software case focuses on the importance of limo software to improve the operation of limousine services.",
      },
    ],
  },
];
const BlogSingleFeatureDataFifteenSecond = [
  {
    id: 1,
    title:
      "Importance of Using Limousine Booking Software in Improving Customer Experience",
    desc: "Although booking software on its own can be categorized as a technology tool, it becomes apparent as how it enriches customers’ experiences. Apart from making basic tasks of limo fleet and bookings easier, the booking software also improves customer satisfaction by offering numerous features.",
    blogImage: blogImgFifteenSecond,
    imgalt: "limo software",
    subHead: [
      {
        subTitle: "Easy Booking Process",
        subDesc:
          "The user-friendly interfaces of the limo reservation software make it easier for one to book a limousine service. Almost all software allows the users to browse through the available vehicles, select their desired car and confirm the order in several clicks.",
      },
      {
        subTitle: "Real-Time Updates and Notifications",
        subDesc:
          "Another important advantage is the opportunity for giving updates and notifications in real time, which is highly valued by customers. Such information may be in relation to the vehicle location, driver information or changes in a ride schedule that make customers feel more secure.",
      },
      {
        subTitle: "Detailed Route and Receipt",
        subDesc:
          "On the other hand, booking apps used for limousines usually provide a clear schedule and the electronic receipt upon the end of the trip. The openness of such specific information without many formalities is definitely an advantage that the current generation of tech-savvy customers will appreciate.",
      },
      {
        subTitle: "Secure Payment Process",
        subDesc:
          "Lastly, the limo reservation software ensures that the payment process is as secure as possible. Through inclusion of recognized payment processors, these software systems provide not only convenience to customers but also security.",
      },
    ],
  },
];
const BlogSingleFeatureDataFifteenTable = [
  {
    id: 1,
    title:
      "How Top Limo Services Currently Use Limousine Dispatch Software to Improve the Client Experience",
    description:
      "Effective limo services make 100% utilization of options available in the limo booking software for customer satisfaction. Such services can achieve the highest levels of customer satisfaction in terms of ease of operation through careful selection of software carrying features like better booking interfaces, real-time notifications etc.",
    heading1: "Software Name",
    heading2: "Key Features",
    desc: [
      {
        desc1: "Limo Anywhere",
        desc2:
          "Dispatch system, Reservations, Driver & Vehicle management, Billing & Invoicing",
      },
      {
        desc1: "Book Rides Online",
        desc2:
          "Online booking, Estimate fare and distance, SMS/email notifications, Affiliate networking",
      },
      {
        desc1: "Rental Limo",
        desc2:
          "Search engine & Booking system, Real-time updates, Mobile friendly",
      },
      {
        desc1: "LimoLive24",
        desc2: "Booking, Reservation & Dispatch, Billing and Reporting tools",
      },
      {
        desc1: "Gazoop",
        desc2:
          "Dispatch Management, Fleet Management, Auto Dispatching, GPS Tracking",
      },
    ],
  },
];
const BlogSingleFeatureDataFifteenThird = [
  {
    id: 1,
    title: "How to Choose the Right Limousine Management Software",
    blogImage: "",
    imgalt: "",
    desc: "The most crucial aspect of investing in a limousine management software is determining the right one to use. There is a list of factors that can affect the decision-making as to which management software for limousine is most suitable. Some of these attributes include the following and more depend on the company’s intentions with the software:",
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
  },
  {
    id: 2,
    title: "Factors to Consider When Choosing Limo Dispatch Software",
    blogImage: "",
    imgalt: "",
    desc: "",
    subHead: [
      {
        subTitle: "Ease of Use and Interface Design",
        subDesc:
          "First and foremost, the software should be straightforward and user-friendly. The design of the interface plays a crucial role here, as it directly impacts the efficiency of everyday operations. A clean, intuitive and well-organized dashboard enables easier navigation and helps in appropriately managing tasks.",
      },
      {
        subTitle: "Robust Booking Features",
        subDesc:
          "The limo dispatch software should be equipped with strong booking capabilities that can take care of all the booking requirements. This includes an online reservation system, scheduling and even payment processing functions. A robust booking system can yield better insight into customer satisfaction, which is also likely to ease out the booking overloads on drivers and fleet managers.",
      },
      {
        subTitle: "Advanced and Comprehensive Reporting",
        subDesc:
          "The chosen limousine dispatch software should provide comprehensive and precise reporting functionalities. These features should allow for the generation of valuable business insights from raw data, leading to better decision-making. Additionally, the software should offer both pre-designed reports and customizable options to fit the unique reporting requirements of the business.",
      },
      {
        subTitle: "Real-Time Tracking Capabilities",
        subDesc:
          "A significant factor to weigh in is the real-time tracking feature. This feature ensures managers have unfiltered access to vehicle status and locations. Real-time tracking not only enhances efficiency but also improves client satisfaction, as it allows for accurate and timely arrivals and departures.",
      },
      {
        subTitle: "Quality of Customer Support Provided",
        subDesc:
          "Last but not least, the effectiveness of the customer support offered by the software vendor needs to be also taken into consideration. Customer support can significantly affect business performance by being more or less responsive in emergent situations.",
      },
    ],
  },
  {
    id: 3,
    title: "Wrapping Up",
    blogImage: "",
    imgalt: "",
    desc: "",
    subHead: [
      {
        subTitle: "",
        subDesc:
          "The onset of digitalization presents an opportunity and a challenge for businesses. It offers tools for efficiency, accuracy, and customer satisfaction but demands constant adaptation and learning. This is quite evident in the context of limousine service providers, where customer satisfaction is deeply connected with seamless, timely, and hassle-free service.",
      },
      {
        subTitle: "",
        subDesc:
          "Limo software, a fusion of information technology and fleet management, serves as a path for limousine service providers to adapt to this new environment. It remains to be seen how advancements in limousine software will further reshape the landscape of limo fleet management. But, one cannot doubt that, for those companies that want to establish themselves and perform well in today’s highly competitive environment, using such software is not only a good strategy, but also an absolute must.",
      },
      {
        subTitle: "",
        subDesc:
          "Do not let your business fall behind. Our advanced limousine booking software is just what you need. Get ready for a new level of your limo business and upgrade your service with the help of our limousine booking system. So why wait?",
      },
    ],
  },
];
const BlogSingleFeatureDataSixteen = [
  {
    id: 1,
    title: "Evolution of Ride Hailing Apps",
    blogImage: blogImgSixteenSecond,
    imgalt: "Ride hailing app development",
    desc: "",
    subHead: [
      {
        subTitle: "Past trends in existing taxi services",
        subDesc:
          "The taxi industry, until recently, had been with conventional systems acting as the backbone of its functioning. People were used to finding taxis parked at taxi ranks or stands or the conventional method of finding one nearby on a busy street.",
      },
      {
        subTitle: "",
        subDesc:
          "While, in the past, hailing a taxi meant standing at the side of the road and flagging down an approaching taxi. This was due to its complete reliance on the cab’s presence in the area. People who were potential customers would gather around areas which they anticipated may have high traffic of cabs like junctions or famous attractions.",
      },
      {
        subTitle: "",
        subDesc:
          "Yes, the traditional taxi industry was relatively reliable, but it also had some issues. First of all, it could be significantly more expensive in certain areas or during certain periods of the day. It could take a while just to flag down a cab and in cases where the weather was poor it was almost impossible to get one.",
      },
      {
        subTitle: "Rise of Ride Hailing Apps",
        subDesc:
          "The process of hailing cabs gradually evolved as technology advanced (ride hailing app development). In other words, it changed with time. This was which has faced problems associated with the traditional services was solved by the emergence of ride hailing applications. These apps allowed consumers to order a ride, conveniently through their mobile devices, to check the cost of the requested ride, find the driver and their car on the map in real-time, and share their thoughts and feelings about a particular driver.",
      },
    ],
  },
  {
    id: 2,
    title: "",
    blogImage: "",
    imgalt: "",
    desc: "",
    subHead: [
      {
        subTitle: "",
        subDesc: (
          <>
            The ease of getting rides through the applications made{" "}
            <Link href="/industries/ride-hailing/" passHref>
              <a> ride hailing platforms </a>
            </Link>
            popular among people and grow at a high rate in different parts of
            the world. These apps thrived due to their use of digital and
            cashless transaction, high levels of transparency in the
            transaction, as well as providing the convenience of tracking trips
            mainly for safety purposes. Their success has reshaped the ride
            hailing industry and encouraged many startups as well as the ride
            hailing app development companies in the field.
          </>
        ),
      },
    ],
  },
];
const BlogSingleFeatureDataSixteenSecond = [
  {
    id: 1,
    title: "Key Features of Successful Ride Hailing Platforms",
    blogImage: blogImgSixteenThird,
    imgalt: "Ride hailing platform",
    desc: "As for main characteristics of the best ride hailing applications, it is possible to highlight the following factors:",
    subHead: [
      {
        subTitle: "On-Demand Booking",
        subDesc:
          "The most important attribute of the ride hailing applications is that it has the feature of on-demand booking service. This entails that the users can request a ride within a short time, any time of the day via the app. Data about other nearby drivers, their current time en-route, and probable fare for the ride is provided to the user on the spot.",
      },
      {
        subTitle: "",
        subDesc:
          "Such immediate availability of taxi increases customer receptiveness and satisfaction since they do not have to go through the effort of finding transportation.",
      },
      {
        subTitle: "Live Tracking",
        subDesc:
          "GPS based services are fundamental in applications of ride hailing. Once a ride is booked, GPS technology is used to locate the customer's pickup point and the driver's current location. It also helps in suggesting the most efficient routes to the destination, giving estimated arrival times, and identifying nearby available drivers.",
      },
      {
        subTitle: "",
        subDesc:
          "This tracking in real-time works well for the users as well as the drivers. Both the customer and the driver can navigate their way around and know the location of the other in real time hence no extra time is wasted waiting for each other or misunderstanding each other’s location.",
      },
      {
        subTitle: "Multiple Payment Methods",
        subDesc:
          "Modern ride hailing apps typically support several payment methods. This may include traditional methods like cash and credit cards as well as mobile wallets and in-app payments. Some apps even allow users to tip their drivers within the app.",
      },
      {
        subTitle: "",
        subDesc:
          "Thus, the availability of many payment options helps make the use of ride hailing applications convenient. Customers get to use the kind of payment they prefer, guaranteeing that they do not have to search around for change that they need during a trip.",
      },
      {
        subTitle: "Review and Rating System",
        subDesc:
          "The review and rating system present in ride hailing apps play a crucial role in maintaining service quality. After each trip, users can rate their driver and write a review about their experience. These ratings are visible to future customers, thereby influencing their choice of drivers.",
      },
      {
        subTitle: "",
        subDesc:
          "Reviews and ratings push drivers towards delivering better service. Drivers are motivated to provide excellent service to receive positive reviews, which draws more customers.",
      },
    ],
  },
  {
    id: 2,
    title: "Transformative Impact on the Taxi Business",
    blogImage: "",
    imgalt: "",
    desc: "Ride hailing apps have proven to be a significant game changer for the traditional taxi industry. They have brought about significant improvements and transformations that are far-reaching. It increases customer experience, helps in economic growth and development, and much more – the effects of these apps are rather important to explore.",
    subHead: [
      {
        subTitle: "Better Customers’ Experience and Services",
        subDesc:
          "Among the changes that have been witnessed in the taxi business due to ride hailing apps, one of the key areas that have enhanced the service delivery. These apps have provided a platform where customer feedback is instant, service providers can respond promptly, and improvements are quickly implemented. The customer experience has been enriched with the service features like live tracking, estimated time of arrival, convenient payment and selection between the types of vehicles.",
      },
      {
        subTitle: "Opportunities for Driver Growth and Benefits",
        subDesc:
          "The growth of ride hailing applications has also created a range of opportunities for drivers. The rigid structure of the traditional taxi business model has been replaced with flexibility. Drivers now have the freedom to work at their own pace and manage their schedules. Moreover, these apps have introduced several benefits for drivers like incentives for hard work, adding a significant boost to their work and livelihood.",
      },
      {
        subTitle:
          "Influence on Economic Factors and Traditional Taxi Business Models",
        subDesc:
          "Last but not the least, the ride hailing platforms have created direct and indirect economic benefits and altered traditional taxi business models. It has led to creation of employment and enhanced income of drivers who operate independently. Customers now have the freedom to choose amongst a variety of ride options that best suit their budget. Therefore, there is increased adoption of innovation such as online booking systems, and customer centered services as traditional taxi business strive to cope with this changing market structure.",
      },
      {
        subTitle: "Positive Impacts on the Environment",
        subDesc: (
          <>
            One notable measure of progress is the impact of ride hailing
            applications on minimizing the carbon footprint. Carpooling, a
            popular feature in these apps, encourages{" "}
            <Link href="/industries/ride-sharing/">
              <a> ride-sharing </a>
            </Link>
            thereby reducing the number of vehicles on the road. This
            contributes to lower carbon emissions and offers a path towards
            sustainable transportation.
          </>
        ),
      },
    ],
  },
  {
    id: 3,
    title: "Wrapping Up",
    blogImage: "",
    imgalt: "",
    desc: "",
    subHead: [
      {
        subTitle: "",
        subDesc:
          "Ride hailing apps, once a novel concept, are now a significant part of urban life. They have spurred a phenomenal transformation in the taxi sector because of their immense potential, utility and convenience.",
      },
      {
        subTitle: "",
        subDesc:
          "If you're a taxi business looking to stay ahead of the competition, consider integrating a ride hailing app into your operations. Our white label ride hailing app offers all the features you need to provide a seamless and customer-focused service. Ride hailing app development is not needed, as our pre-built solution is ready to be customized with your branding and specific features.",
      },
      {
        subTitle: "",
        subDesc:
          "Take the first step towards transforming your taxi business by contacting us today! Click the button below to get started.",
      },
    ],
  },
];
const BlogSingleFeatureDataSeventeen = [
  {
    id: 1,
    title: "Role of Cab Booking System in Modern Taxi Businesses",
    blogImage: "",
    imgalt: "",
    desc: "In the present world, the taxi booking system is more than the technology layer; it’s an essential tool that could be the backbone of the new age taxi companies. This software has enhanced the efficiency of the taxi service through GPS tracking, in-app payments, and automatic dispatch, and this has had an overall impact of improved satisfaction of riders and higher turnover on the side of the taxi service providers.",
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
  },
  {
    id: 2,
    title: "1. Convenience and User Experience ",
    blogImage: "",
    imgalt: "",
    desc: "The introduction of taxi booking system has had a significant impact on the overall user experience. Modern-day taxi businesses strive to prioritize customer satisfaction, and by utilizing such software, companies can greatly enhance the level of comfort.",
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
  },
  {
    id: 3,
    title: "Impact of Taxi Booking System on User Experience",
    blogImage: "",
    imgalt: "",
    desc: "Another significant advantage of incorporating this software into the operations of a taxi business is an improvement in the usability or the end-user’s experience. Several factors contribute to this improved experience:",
    subHead: [
      {
        subTitle: "Ease of Booking",
        subDesc:
          "One of the most significant benefits of taxi online booking system is its convenience. Customers no longer have to face the hassles of traditional taxi hailing methods. A simple tap on the application allows them to order a ride which is easy and convenient.",
      },
      {
        subTitle: "Trackability of Taxis",
        subDesc:
          "The other major advantage that is likely to be realized through use of taxi booking software is trackability. Software systems provide real-time tracking features that allow customers to accurately track the location of their booked taxi. This feature reduces concerns over punctuality and potentially long wait times.",
      },
      {
        subTitle: "Option for Feedback and Review",
        subDesc:
          "Taxi booking system includes built-in features for the customers to leave feedback and write reviews about their rides. This particular method of communication helps businesses to know their performance and make any adjustment if need be.",
      },
      {
        subTitle: "Accessibility for Differently Abled Customers",
        subDesc:
          "Today’s cab booking softwares are easy to use for everyone, including customers who have some disabilities. It is very easy to navigate around the app and gesture used is very important and selections like text-to-speech and other forms of accessibility features, make sure that every person to use the service comfortably.",
      },
      {
        subTitle: "On-Demand Booking Facility",
        subDesc:
          "This feature allows customers to book rides as and when needed, without any pre-booking constraints. This flexibility is valued by customers and contributes significantly to the improvement of their overall satisfaction.",
      },
    ],
  },
];
const BlogSingleFeatureDataSeventeenSecond = [
  {
    id: 1,
    title: "2. Operational Efficiency",
    blogImage: "",
    imgalt: "",
    desc: (
      <>
        Through the adoption of{" "}
        <Link href="/">
          <a> ride hailing platforms </a>
        </Link>
        companies improves operational efficiency and can easily control costs,
        which remain key factors to success of modern day taxi companies.
      </>
    ),
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
  },
  {
    id: 1,
    title: "Benefits for Operational Efficiency",
    blogImage: blogImgSeventeenThird,
    imgalt: "Taxi booking system",
    desc: "The key advantages in terms of operational efficiency can be observed in two fundamental aspects: automation of tasks and real-time tracking for improved dispatch.",
    subHead: [
      {
        subTitle: "Automation of Tasks",
        subDesc:
          "The introduction of software solutions into the taxi business accelerates task execution through automation. Functions including those of assigning drivers to their duties, or estimating fares, which may otherwise would demand a lot of time, are done automatically, hence increasing the overall speed of operations. It eliminates redundancy, prevents errors, and enhances service delivery, ultimately contributing to increased operational efficiency.",
      },
      {
        subTitle: "Real-time Tracking for Better Dispatch",
        subDesc: "",
      },
    ],
  },
  {
    id: 2,
    title: "",
    blogImage: "",
    imgalt: "",
    desc: "",
    subHead: [
      {
        subTitle: "",
        subDesc:
          "Taxi online booking system incorporates real-time tracking features, resulting in a more efficient dispatch system. This has the capability of tracking taxi fleets in real time so that a dispatcher can assign a nearby, available taxi to a customer. Through real-time visualization of taxi and customer movement data, businesses can make tremendous progress in minimizing wait time and even increase customer satisfaction.",
      },
    ],
  },
  {
    id: 3,
    title: "3. Cost Management",
    blogImage: "",
    imgalt: "",
    desc: "",
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
  },
  {
    id: 3,
    title: "Advantages of Cost Management",
    blogImage: "",
    imgalt: "",
    desc: "The cab booking software solutions targeted for taxi businesses play a substantial role in strategic cost management. It helps in their reduction of employee needs and decreases empty runs, thereby optimizing expenses.",
    subHead: [
      {
        subTitle: "Lower Employee Requirements",
        subDesc:
          "With job functions such as booking, dispatching, and fare calculation getting automated, the need for human intervention is limited and thus, the requisite staff strength significantly decreases. This reduction in workforce requirements substantially mitigates overhead expenses, paving the way for more cost-effective business operations.",
      },
      {
        subTitle: "Decreased Empty Runs",
        subDesc:
          "Taxi booking software's real-time tracking affords businesses the advantage of minimizing empty taxi runs. Fleet managers can keep track of where each of their company’s vehicles is located in real-time, can therefore dispatch pickups and drop offs in a way that greatly reduces dead mileage, thereby directly contributing to where fuel costs are concerned.",
      },
    ],
  },
  {
    id: 4,
    title: "4. Improved Business Management",
    blogImage: "",
    imgalt: "",
    desc: "With the help of an efficient taxi booking and dispatch system, businesses have at their disposal a number of tools to work more effectively and make more confident decisions. Such software solutions make it possible to watch daily business operations as well as guarantee that enterprises can grow in the right manner to meet the increasing demands.",
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
  },
  {
    id: 5,
    title: "The Role of Taxi Online Booking System in Overseeing Operations",
    blogImage: "",
    imgalt: "",
    desc: "Taxi booking software offers features like real-time tracking, ride assignment and scheduling, and driver feedback systems. These allow business owners to gain oversight into their fleet’s operations. Errors and operational inefficiencies can be identified and rectified, and driver accountability can be improved, leading to enhanced service quality. This strengthens the reputation of the taxi business and increases customer satisfaction.",
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
  },
  {
    id: 6,
    title: "Benefits of Taxi Booking Software in Scaling Taxi Businesses",
    blogImage: "",
    imgalt: "",
    desc: "When it comes to growth and expansion, the role of taxi booking and dispatch system cannot be overemphasized. Software solutions help facilitate two critical aspects of scaling: regional scaling and fleet size expansion.",
    subHead: [
      {
        subTitle: "Regional Scaling",
        subDesc:
          "The software lets the firms spread their services to new territories and regions for the efficient management of taxi dispatch and bookings thereby it provides the opportunity for managers to even cover those areas where they cannot set up their offices. With the aid of automatic dispatch features, taxi businesses can ensure timely delivery of services in newly covered areas, enhancing their reach and scope.",
      },
      {
        subTitle: "Fleet Size Expansion",
        subDesc:
          "As the number of vehicles in a taxi company's fleet increases, the challenges of oversight, maintenance, and operational efficiency can rise exponentially. However, taxi booking systems can handle these concerns effectively. It allows easy tracking and management of a larger fleet, seamless assignment of drivers, and simplified communication channels. Through these features, an expanding fleet size does not compromise deliverance of quality service.",
      },
    ],
  },
  {
    id: 7,
    title: "5. Data Analytics and Business Insights",
    blogImage: blogImgSeventeenSecond,
    imgalt: "Taxi online booking system",
    desc: "Data analytics play a pivotal role in transforming taxi businesses into smarter, more efficient operations. By acquiring high-quality rich data, businesses can gain invaluable insights into their operations and markets.",
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
  },
  {
    id: 8,
    title: "Role of Data Analytics in Taxi Businesses",
    blogImage: "",
    imgalt: "",
    desc: "The application of data analytics in taxi businesses delivers significant benefits in two primary areas: predictive capabilities and customer profile behavior analysis.",
    subHead: [
      {
        subTitle: "Predictive Capabilities",
        subDesc:
          "Data analytics provides predictive capabilities that help taxi businesses plan ahead. By analyzing historical data, businesses can predict peak times for demand, likely routes, and anticipate operational challenges. This allows businesses to manage resources effectively, provide timely services, and consequently, increase revenues.",
      },
      {
        subTitle: "Customer Behavior Analysis",
        subDesc:
          "Data analytics can also be useful in gaining an insight into customer behavior. As mentioned, analyzing factors such as fare variation, trip frequency, preferred routes, and feedback, businesses gain insights into customer preferences and satisfaction. This knowledge serves to  guide service improvements and tailor offerings to customer needs, often resulting in increasedloyalty and customer retention.",
      },
    ],
  },
  {
    id: 9,
    title: "Generating Business Insights",
    blogImage: "",
    imgalt: "",
    desc: "In addition, data analytics plays a significant role in assisting organizations in developing business insights. More importantly, it facilitates the identification of peak hours and enables effective demand forecasting.",
    subHead: [
      {
        subTitle: "Identification of Peak Hours",
        subDesc:
          "Analyzing historical data sets can provide businesses with an understanding of their busiest periods. Whether this means regular weekday rush hours, weekend spikes, or seasonal patterns, such granular insights into peak hours can equip businesses to optimize scheduling and service delivery.",
      },
      {
        subTitle: "Demand Forecasting",
        subDesc:
          "Data analytics also assists taxi companies in forecasting demand variations. This means that one can use factors such as time of the day, days of the week, holidays, seasonality, and even climatic conditions in demand forecasting. These forecasts also help businesses to manage supply and demand requirements in order to provide better customer service and business performance.",
      },
      {
        subTitle: "",
        subDesc:
          "In sum, data analytics, when applied correctly, provides an opportunity for taxi businesses to operate more intelligently. By using data for predictive analysis and the generation of practical business insights, businesses are thus more capable of meeting customer expectations in an increasingly competitive marketplace.",
      },
    ],
  },
  {
    id: 10,
    title: "Wrapping Up",
    blogImage: "",
    imgalt: "",
    desc: "",
    subHead: [
      {
        subTitle: "",
        subDesc:
          "There is no doubt that the software used in booking taxis has the priceless value that makes it an essential application in the taxi business’s daily running. It assists in the management of bookings through live tracking of vehicles, computation of auto fare and other charges, integrated payment function, latest information of drivers, and features tailored to a company’s needs.",
      },
      {
        subTitle: "",
        subDesc:
          "Resisting the adaptation of technology, specifically taxi booking software, could potentially have significant economic implications. Taxi companies may encounter reduced efficiency in  caused by slower booking times and manual dispatch systems. As a result, there is a possibility to have a negative impact on the level of customer satisfaction, which in turn, can negatively affect overall profitability.",
      },
      {
        subTitle: "",
        subDesc:
          "Take the first step towards a more efficient and customer-centric taxi business. Contact us today to learn more about our comprehensive taxi booking software and how it can revolutionize your operations.",
      },
    ],
  },
];
const BlogSingleFeatureDataEighteen = [
  {
    id: 1,
    title: "How Do Taxi Dispatch Systems Work?",
    blogImage: blogImgEighteenSecond,
    imgalt: "taxi cab dispatch system",
    desc: "",
    subHead: [
      {
        subTitle: "1. Booking a Ride",
        subDesc: (
          <>
            When a customer requests a ride, the{" "}
            <Link href="/">
              <a> taxi dispatch software </a>
            </Link>
            captures the details and checks for cab availability. If a cab is
            free, the system dispatches it to the customer’s location. If not,
            it keeps searching until an available cab is found.
          </>
        ),
      },
      {
        subTitle: "2. Dispatching the Cab",
        subDesc:
          "Once an available cab is found, the system sends its details to the driver’s mobile device (Driver app). Drivers can accept or reject the booking based on their availability. If declined, the system alerts the next available driver.",
      },
      {
        subTitle: "3. Routing",
        subDesc:
          "After the driver accepts the ride request, the system sends the optimized route to their device. It factors in passenger location, traffic, and other relevant data to make sure the quickest route, saving time and fuel.",
      },
      {
        subTitle: "4. Payment",
        subDesc:
          "Taxi dispatch software simplifies payment. Most of the current systems facilitate cashless modes, which includes debit/credit cards and mobile wallets, to ensure seamless transactions between customers and drivers.",
      },
    ],
  },
  {
    id: 2,
    title: "The Contribution of Taxi Cab Dispatch Systems on Operations",
    blogImage: "",
    imgalt: "",
    desc: "",
    subHead: [
      {
        subTitle: "Boosts Operational Efficiency",
        subDesc:
          "The important role of online taxi dispatch systems is that they greatly improve operational activity. Concerning booking, dispatching, and routing, the systems increase efficiency since they automate the processes. The running of the business becomes efficient and this cuts on the expenses.",
      },
      {
        subTitle: "Improves Customer Satisfaction",
        subDesc:
          "Dispatch systems create an efficient booking mechanism through the use of an application in a short time to obtain rides. Which is why real time updates are such a valued addition to the customer’s experience.",
      },
    ],
  },
];
const BlogSingleFeatureDataEighteenTable = [
  {
    id: 1,
    title: "Key Features of Top-Performing Taxi Dispatch Systems",
    description: "",
    heading1: "Feature",
    heading2: "Benefit to Driver",
    heading3: "Benefit to Passenger",
    heading4: "Benefit to Taxi Company",
    desc: [
      {
        desc1: "Real-Time Tracking",
        desc2: "Allows for efficient route planning and tracking",
        desc3: "Provides an accurate ETA and route transparency",
        desc4: "Improves fleet management and monitoring",
      },
      {
        desc1: "Automated Dispatching",
        desc2: "Reduces the need for manual dispatching tasks, saves time",
        desc3: "Provides faster and more reliable service",
        desc4: "Increases operational efficiency",
      },
      {
        desc1: "Booking and Payment Integration",
        desc2: "Enables seamless payment process and increases convenience",
        desc3: "Allows for cashless and easy payment methods",
        desc4: "Increases payment transparency and reduces discrepancies",
      },
      {
        desc1: "Driver Performance Analytics",
        desc2: "Helps to identify areas of improvement",
        desc3: "Ensures better service due to a more efficient driver",
        desc4:
          "Improves overall service quality by tracking driver performance",
      },
      {
        desc1: "Fare Estimation",
        desc2: "Facilitates easy income estimation and tracking",
        desc3: "Provides upfront fare information",
        desc4: "Allows for reliable record keeping and fare management",
      },
      {
        desc1: "Trip History",
        desc2: "Enables easy tracking of past rides and earnings",
        desc3: "Facilitates easy reference for past rides",
        desc4: "Aids in dispute resolution and tracking performance",
      },
      {
        desc1: "In-App Navigation",
        desc2: "Helps with optimal route selection",
        desc3: "Ensures shortest route is taken for faster service",
        desc4: "Reduces fuel consumption leading to cost savings",
      },
      {
        desc1: "Push Notifications",
        desc2: "Keeps drivers informed about new ride requests",
        desc3: "Keeps passengers informed about ride status updates",
        desc4: "Allows real-time communication with driver and passenger",
      },
    ],
  },
];
const BlogSingleFeatureDataEighteenSecond = [
  {
    id: 1,
    title: "Optimize Your Taxi Dispatch System for Maximum Efficiency",
    blogImage: "",
    imgalt: "",
    desc: "One of the key areas with a high potential to improve the efficiency of a taxi business is the optimization of the dispatch system. This provides high returns to your business. It incorporates activities such as regular system updates and monitoring driver performance. By dedicating just a little more attention to these actions, cab firms can guarantee that their business processes and, in turn, the customers’ experience will be enhanced.",
    subHead: [
      {
        subTitle: "Regular System Updates",
        subDesc: (
          <>
            Maintenance is critical for the software to work optimally,
            contributing to the enhancement of the software, removing glitches,
            and making improvements. Outdated systems mean you are working with
            older models which slower your system, or software glitches that
            affect service delivery. So either select a reliable software
            development company or opt for the{" "}
            <Link href="/industries/ride-hailing/">
              <a> white label ride hailing solutions </a>
            </Link>
            captures the details and checks for cab availability. If a cab is
            free, the system dispatches it to the customer’s location. If not,
            it keeps searching until an available cab is found.
          </>
        ),
      },
      {
        subTitle: "",
        subDesc:
          "Regular updates can help in great extent to enhance the performance of the system. When there is constant update, the feature operates as expected and downtime is negligible. So, staying ahead with updates not only maintains the system's functionality but can actually improve it.",
      },
      {
        subTitle: "Driver Performance Monitoring and Feedback",
        subDesc:
          "A well-organized taxi operations are dependent heavily on the performance of taxi drivers. That way, from the information that would be obtained through monitoring, taxi operators could get the details of drivers’ behavior, driving pattern, and productivity on the roads. The said data can be used to give pertinent feedback and training, positive behavioral modification and enhance the operational effectiveness.",
      },
      {
        subTitle: "",
        subDesc:
          "Feedback is crucial which helps drivers to understand where improvements can be made, based on objective data and analysis. Regular, constructive feedback helps make drivers more effective and efficient, which in turn improves the taxi operation as a whole.",
      },
    ],
  },
];
const BlogSingleFeatureDataEighteenTableSecond = [
  {
    id: 1,
    title: "",
    description: "",
    heading1: "Factor",
    heading2: "Why it is Important",
    desc: [
      {
        desc1: "Demand Prediction",
        desc2:
          "Accurate prediction helps to distribute taxis efficiently, reducing waiting time.",
      },
      {
        desc1: "Location Tracking",
        desc2:
          "Allows real-time tracking of taxis, helping dispatch operators to distribute taxis efficiently.",
      },
      {
        desc1: "Routing",
        desc2:
          "Efficient routing allows taxis to reach customers in the shortest time, which results in customer satisfaction.",
      },
      {
        desc1: "Supply Management",
        desc2:
          "Balancing taxi supply with demand prevents oversupply or undersupply situations.",
      },
      {
        desc1: "Dynamic Pricing",
        desc2: "Helps to regulate demand and supply.",
      },
      {
        desc1: "Customer Experience",
        desc2:
          "Positive customer experience can lead to repeat customers and good reviews.",
      },
      {
        desc1: "Driver Satisfaction",
        desc2: "Satisfied drivers are more likely to provide better service.",
      },
    ],
  },
];
const BlogSingleFeatureDataEighteenThird = [
  {
    id: 1,
    title: "Why Online Taxi Dispatch System is a Sound Investment",
    blogImage: blogImgEighteenThird,
    imgalt: "best online taxi dispatch system",
    desc: "Investing in the best taxi dispatch systems is an important decision that redefines the entire operational dynamics of taxi cab businesses. The integration of technology into day-to-day operations drastically improves performance metrics and boosts overall operational efficiency. The real-time data made available in these dispatch systems is vital in providing solutions and planning for business development.",
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
  },
  {
    id: 2,
    title: "",
    desc: "",
    subHead: [
      {
        subTitle: "",
        subDesc:
          "In a business where time is money, the utilization of dispatch systems can significantly reduce error rates and idle time, thereby increasing the profitability. Moreover, the implementation of these systems impacts customer satisfaction positively, leading to increased loyalty and better customer retention.",
      },
      {
        subTitle: "A Smart Decision that Carries Profound Benefits",
        subDesc:
          "There are multiple benefits a taxi business or private hire can get from the taxi cab dispatch system. Right from improved fleet management to reduced operational costs, efficient route planning to easier booking options for customers, these systems are worth their weight in gold. They give taxi businesses the power to adapt to changing market demands swiftly and make sure that they remain competitive and profitable.",
      },
    ],
  },
];
const BlogSingleFeatureDataEighteenFourth = [
  {
    id: 1,
    title: "Wrapping Up",
    desc: "",
    subHead: [
      {
        subTitle: "",
        subDesc:
          "Getting an innovative dispatch technologies is crucial for taxi businesses to thrive in the long run. Making the switch to a modern, feature-rich dispatch system is a strategic investment that can propel a taxi company towards greater operational efficiency, customer satisfaction, and sustainable growth.",
      },
      {
        subTitle: "",
        subDesc:
          "As the taxi industry continues to evolve, taxi businesses that fully uses the power of intelligent dispatch systems will be well-positioned to adapt to changing market dynamics, stay ahead of the competition, and deliver exceptional service to their customers. Investing in the best taxi dispatch system is a smart decision that can lead to significant long-term benefits for any taxi company.",
      },
    ],
  },
];

const BlogSingleFeatureDataNineteenFirst = [
  {
    id: 1,
    blogImage: blogImgNineteenSecond,
    imgalt: "Top taxi dispatch software",
    title: "Why You Need Taxi Dispatch Software",
    desc: "Taxi dispatch software is a digital platform designed to automate the process of assigning taxis to passengers. It enables real-time management of taxi fleets, helping businesses efficiently match drivers with customer requests. As the backbone of modern taxi services, dispatch software reduces manual effort, eliminates operational inefficiencies, and increases productivity.",
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
  },
  {
    id: 2,
    title: "",
    desc: "",
    subHead: [
      {
        subTitle: "How Taxi Dispatch Software Streamlines Operations",
        subDesc:
          "With features like real-time tracking, automated driver assignments, and route optimization, dispatch software allows businesses to operate more smoothly. It ensures that rides are assigned quickly, customers experience shorter wait times, and drivers have clear directions for pickups and drop-offs.",
      },
      {
        subTitle: "Benefits for Business Growth and Customer Satisfaction",
        subDesc:
          "A good dispatch system not only helps you manage your fleet but also enhances the customer experience by reducing wait times and providing accurate ETAs. As a result, businesses can see increased customer retention and revenue growth.",
      },
    ],
  },
  {
    id: 3,
    title: "Key Features to Look for in a Taxi Dispatch System",
    desc: "When selecting taxi dispatch software, it’s important to focus on features that match your business needs. Here are the most essential features to consider:",
    subHead: [
      {
        subTitle: "Real-Time Tracking & GPS Integration",
        subDesc:
          "This feature allows you to monitor your fleet’s location in real time. It helps optimize routes, track vehicle status, and ensure that drivers are on schedule, improving efficiency and customer satisfaction.",
      },
      {
        subTitle: "Automated Dispatch",
        subDesc:
          "Automated dispatch make sure that rides are allocated to the nearest available drivers. This not only speeds up the dispatch process but also reduces idle time for drivers, making the business more efficient.",
      },
      {
        subTitle: "Data Analytics for Performance Insights",
        subDesc:
          "Many dispatch systems offer analytics tools that provide insights into fleet performance. These reports can help identify areas for improvement, track driver productivity, and ensure your business is running at its best.",
      },
    ],
  },
  {
    id: 4,
    title: "Best Taxi Dispatch Software Solutions for 2024",
    desc: (
      <>
        Now that we’ve covered the key benefits and features of{" "}
        <Link href="/">
          <a> taxi dispatch software, </a>
        </Link>
        let’s explore the top 5 software solutions for 2024.
      </>
    ),
    subHead: [
      {
        subTitle: "1. ZervX",
        subDesc:
          "ZervX is a powerful and scalable taxi dispatch software designed for taxi businesses of all sizes. It offers a reliable solution that helps businesses streamline their operations, manage fleets, and improve customer service with ease. ZervX focuses on delivering flexibility, efficiency, and cost-effectiveness.",
      },
    ],
  },
];

const BlogSingleFeatureDataNineteenOneline = [
  {
    id: 1,
    title: "Pros of ZervX",
    blogImage: "",
    imgalt: "",
    desc: "",
    subHead: [
      {
        subTitle: "User-Friendly Interface:",
        subDesc: "Easy to navigate for drivers, passengers, and dispatchers.",
      },
      {
        subTitle: "Scalable:",
        subDesc:
          "Suitable for both small and large fleets, adapting to your growing business needs.",
      },
      {
        subTitle: "Comprehensive Features:",
        subDesc:
          "Includes everything from real-time tracking to automated dispatching.",
      },
      {
        subTitle: "Customizable:",
        subDesc:
          "Allows for customization according to specific business requirements.",
      },
      {
        subTitle: "24/7 Technical Support:",
        subDesc:
          "Offers round-the-clock customer support, make sure that your business stays up and runs smoothly.",
      },
    ],
  },
  {
    id: 2,
    title: "Key Features",
    blogImage: "",
    imgalt: "",
    desc: "",
    subHead: [
      {
        subTitle: "Real-Time GPS Tracking:",
        subDesc:
          "Keep track of your vehicles at all times, ensuring better fleet management.",
      },
      {
        subTitle: "Automated Dispatch:",
        subDesc:
          "Reduces manual work with intelligent, automated dispatching based on proximity and availability.",
      },
      {
        subTitle: "Driver & Passenger Apps",
        subDesc:
          "Dedicated apps for both drivers and passengers (iOS & Android) with easy booking and communication.",
      },
      {
        subTitle: "Analytics & Reporting:",
        subDesc:
          "Gain insights into fleet performance, revenue, and customer satisfaction with in-depth reports.",
      },
      {
        subTitle: "Voice Input Feature:",
        subDesc:
          "Dispatchers can use voice commands for faster and hands-free operations.",
      },
      {
        subTitle: "Package Delivery:",
        subDesc: "Offers an additional feature for small package deliveries.",
      },
    ],
  },
  {
    id: 3,
    title: "",
    blogImage: blogImgNineteenThird,
    imgalt: "Best taxi dispatch software solutions for 2024",
    desc: "",
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
  },
  {
    id: 3,
    title: "",
    blogImage: "",
    imgalt: "",
    desc: (
      <>
        ZervX offers{" "}
        <Link href="/plans/">
          <a> flexible pricing plans </a>
        </Link>
        based on fleet size and feature requirements. Businesses can choose from
        a variety of subscription models, guarantees a cost-effective solution
        that meets their needs.
      </>
    ),
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
  },
];

const BlogSingleFeatureDataNineteenSecond = [
  {
    id: 1,
    blogImage: "",
    imgalt: "",
    title: "",
    desc: "",
    subHead: [
      {
        subTitle: "2. YelowTaxi",
        subDesc:
          "YelowTaxi is an all-in-one cloud-based dispatch solution designed for businesses of various sizes, from small taxi operators to large-scale fleet owners. The platform is known for its user-friendly interface and scalability, making it a great choice for companies looking to grow.",
      },
      {
        subTitle: "",
        subDesc:
          "YelowTaxi excels in scalability, allow businesses to expand across cities or regions without disrupting operations. Its easy-to-use interface ensures quick onboarding for both drivers and dispatchers, while its cloud-based nature ensures uptime and reliability.",
      },
      {
        subTitle: "",
        subDesc:
          "Businesses that provide ride-hailing, airport transfers, or corporate transportation services can consider Yelowsoft for a scalable and flexible solution.",
      },
    ],
  },
];

const BlogSingleFeatureDataNineteenThird = [
  {
    id: 1,
    blogImage: "",
    imgalt: "",
    title: "",
    desc: "",
    subHead: [
      {
        subTitle: "3. Taxicaller",
        subDesc:
          "Taxicaller is a cloud-based dispatch platform tailored for small to medium-sized taxi businesses and private hire services. It offers an affordable solution with a range of essential features that make it easy to implement and use.",
      },
      {
        subTitle: "",
        subDesc:
          "It is an affordable, no-fuss option for smaller businesses looking for a simple but effective dispatch system. Its flexibility and ease of use make it a perfect fit for companies with limited technical resources.",
      },
      {
        subTitle: "",
        subDesc:
          "Best for small taxi businesses and private hire firms needing a cost-effective and easy-to-use dispatch system.",
      },
    ],
  },
  {
    id: 2,
    blogImage: "",
    imgalt: "",
    title: "",
    desc: "",
    subHead: [
      {
        subTitle: "4. iCabbi",
        subDesc:
          "iCabbi is a well-established taxi dispatch software known for its advanced automation and integrations. It is designed to cater to medium and large taxi companies, with a focus on optimizing fleet operations and enhancing customer service.",
      },
      {
        subTitle: "",
        subDesc:
          "iCabbi's strength lies in its customization options. The platform allows fleet managers to tailor the system to meet their specific business needs, from setting custom dispatch rules to integrating with third-party platforms.",
      },
      {
        subTitle: "",
        subDesc:
          "Taxi businesses that require a highly customizable solution and operate on a larger scale, with a focus on maximizing fleet performance and customer satisfaction, can consider iCabbi.",
      },
    ],
  },
];

const BlogSingleFeatureDataNineteenFourth = [
  {
    id: 1,
    blogImage: "",
    imgalt: "",
    title: "",
    desc: "",
    subHead: [
      {
        subTitle: "5. Limo Anywhere",
        subDesc:
          "Limo Anywhere is a specialized platform for limousine and executive car services, offering features that cater to high-end and luxury transportation needs. It combines booking management, dispatching, and payment processing in a sleek and intuitive system.",
      },
      {
        subTitle: "",
        subDesc:
          "Limo Anywhere’s client management system is a standout feature, allowing businesses to provide a personalized and luxurious experience to their high-end clientele. The platform also integrates with major booking platforms, enabling seamless operations.",
      },
      {
        subTitle: "",
        subDesc:
          "It is the best option for limousine services and executive car businesses looking for a solution tailored to high-end transportation and personalized service.",
      },
    ],
  },
  {
    id: 2,
    blogImage: blogImgNineteenFourth,
    imgalt: "Taxi dispatch software",
    title: "How to Choose the Right Taxi Dispatch Software for Your Business",
    desc: (
      <div>
        <p>
          When selecting a taxi dispatch system, several factors come into play.
          Here’s how to choose the right one based on your specific business
          needs:
        </p>
        <p>Factors to Consider: Scalability and Cost</p>
      </div>
    ),
    subHead: [
      {
        subTitle: "",
        subDesc: (
          <span>
            <strong>Scalability: </strong> If you plan to expand your fleet or
            services in the future, choose software that can grow with your
            business. Look for scalable solutions that allow you to add more
            vehicles, drivers, and service areas.
          </span>
        ),
      },
      {
        subTitle: "",
        subDesc: (
          <span>
            <strong>Cost: </strong> While price is a key factor, focus on value
            rather than just the cost. Compare the features offered and consider
            the long-term benefits of each solution before making a decision.
          </span>
        ),
      },
    ],
  },
  {
    id: 3,
    blogImage: "",
    imgalt: "",
    title: "",
    desc: "",
    subHead: [
      {
        subTitle: "Customization Options for Specific Business Needs",
        subDesc:
          "Every business has unique requirements. Choose a dispatch system that allows for customization, such as addition of some extra features, tailor-made reports, or specific changes in the system. Customization ensures that the software aligns perfectly with your operational goals and customer demands.",
      },
      {
        subTitle: "",
        subDesc: (
          <>
            We covered this topic in detail in another blog, where we explain
            the factors to consider when choosing a taxi dispatch software for
            your business. You can read it here:{" "}
            <Link href="/blog/how-to-choose-the-best-taxi-dispatch-software/">
              <a> How to Choose the Best Taxi Dispatch Software. </a>
            </Link>{" "}
          </>
        ),
      },
    ],
  },
  {
    id: 4,
    blogImage: "",
    imgalt: "",
    title: "Wrapping Up",
    desc: "",
    subHead: [
      {
        subTitle: "",
        subDesc:
          "In conclusion, choosing the right taxi dispatch software for your business in 2024 depends on your specific needs, budget, and long-term goals. By focusing on features such as real-time tracking, automated dispatch, scalability, and customization, you can find a solution that supports both your current operations and future growth.",
      },
      {
        subTitle: "",
        subDesc:
          "Remember to weigh the benefits of each system and choose the one that best aligns with your business needs for long-term success.",
      },
      {
        subTitle: "",
        subDesc: (
          <>
            If you want to streamline your taxi operations, ZervX is worth a
            try. It provides a{" "}
            <Link href="/industries/contact-sales/">
              <a> 14-day free trial, </a>
            </Link>{" "}
            giving you the chance to see how this customizable solution can
            refine your dispatch process, manage resources more effectively, and
            boost both customer satisfaction and revenue.
          </>
        ),
      },
      {
        subTitle: "",
        subDesc:
          "Start your journey toward greater efficiency and profitability by choosing the right dispatch software for your taxi business.",
      },
    ],
  },
];

const BlogSingleFeatureDataTwentyFirst = [
  {
    id: 0,
    blogImage: blogImgTwentyFirst,
    imgalt: "Super app",
    title: "",
    desc: "",
    subHead: [
      {
        subTitle: "",
        subDesc:
          "What if there was one app that you could use for shopping, booking rides, messaging your friends, paying bills, and taking care of your money? Sounds convenient, right? This is the concept behind super apps – a one-stop solution for all your daily digital needs.",
      },
      {
        subTitle: "",
        subDesc:
          "The super app model is revolutionizing how we use mobile applications, combining multiple services under one roof. But what exactly makes super apps so appealing? Why are they rapidly becoming the preferred choice for millions of users around the world?",
      },
      {
        subTitle: "",
        subDesc:
          "In this article, we will talk about what super apps are, how they work, and why they are the future of digital platforms. We will also discuss the challenges they face and provide a comparison between super apps and traditional applications.",
      },
    ],
  },
  {
    id: 1,
    blogImage: "",
    imgalt: "",
    title: "What is a Super App?",
    desc: "A super app is a platform that provides more than one service like shopping to communication in a single interface of an app. Super apps bring multiple services into one app but unlike most traditional apps, super apps are focused on just one function, making them easy to use for users.",
    subHead: [
      {
        subTitle: "Examples of Super Apps",
        subDesc: (
          <p>
            <strong className="">WeChat (China): </strong>
            <span>
              Started as a messaging app and now includes payment services,
              shopping, games, and much more.
            </span>
          </p>
        ),
      },
      {
        subTitle: "",
        subDesc: (
          <p>
            <strong className="">Grab (Southeast Asia): </strong>
            <span>
              Grab provides{" "}
              <Link href="/industries/ride-hailing">
                <a> ride hailing, </a>
              </Link>{" "}
              food delivery, grocery shopping, and financial services.
            </span>
          </p>
        ),
      },
      {
        subTitle: "",
        subDesc: (
          <p>
            <strong className="">Gojek (Indonesia): </strong>
            <span>
              Known for ride-hailing, Gojek offers over 20 services, including
              payments and entertainment, with over 38 million monthly active
              users.
            </span>
          </p>
        ),
      },
    ],
  },
  {
    id: 2,
    blogImage: "",
    imgalt: "",
    title: "Key Features of a Super App",
    desc: "To understand why multi-service platform are so effective, let’s explore their main features:",
    subHead: [
      {
        subTitle: "",
        subDesc: (
          <table className="table align-middle mt-5">
            <thead className="">
              <tr className="align-middle">
                <th className="text-left">Feature</th>
                <th className="text-left">Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="content-list text-left list-unstyled col-1">
                  Multiple Services
                </td>
                <td className={`content-list text-left list-unstyled col-1`}>
                  Offers a variety of services (e.g., messaging, payments)
                  within one app.
                </td>
              </tr>
              <tr>
                <td className="content-list text-left list-unstyled col-1">
                  Single Sign-On
                </td>
                <td className={`content-list text-left list-unstyled col-1`}>
                  Users log in once to access all the app’s features,
                  eliminating the need for multiple accounts.
                </td>
              </tr>
              <tr>
                <td className="content-list text-left list-unstyled col-1">
                  Personalization
                </td>
                <td className={`content-list text-left list-unstyled col-1`}>
                  Uses data to tailor the experience to each user’s preferences.
                </td>
              </tr>
              <tr>
                <td className="content-list text-left list-unstyled col-1">
                  Ecosystem Growth
                </td>
                <td className={`content-list text-left list-unstyled col-1`}>
                  Allows third-party developers to integrate their services,
                  expanding the app’s ecosystem.
                </td>
              </tr>
              <tr>
                <td className="content-list text-left list-unstyled col-1">
                  AI Integration
                </td>
                <td className={`content-list text-left list-unstyled col-1`}>
                  Artificial intelligence helps predict user behavior and
                  provide suggestions based on usage patterns.
                </td>
              </tr>
            </tbody>
          </table>
        ),
      },
      ,
    ],
  },
  {
    id: 3,
    blogImage: "",
    imgalt: "",
    title: "The Evolution of Multi-Service Platform",
    desc: (
      <div>
        <p>
          The popularity of super apps did not happen over night. Their rise
          correlates with the rising demand for convenience and penetration of
          smartphones in those emerging markets. The boundaries between
          different kinds of services are starting to blur as users want more
          seamless experiences. That set the stage for the proliferation of
          super apps.
        </p>
        <p>Here’s a quick look at the evolution of super apps:</p>
      </div>
    ),
    subHead: [
      {
        subTitle: "",
        subDesc: (
          <table className="table align-middle mt-5">
            <thead className="">
              <tr className="align-middle">
                <th className="text-left">Year</th>
                <th className="text-left">Development Milestone</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="content-list text-left list-unstyled col-1">
                  2011
                </td>
                <td className={`content-list text-left list-unstyled col-1`}>
                  WeChat launches in China as a simple messaging app, then
                  gradually becomes a super app by bringing on other services
                  like payments and social media.
                </td>
              </tr>
              <tr>
                <td className="content-list text-left list-unstyled col-1">
                  2013
                </td>
                <td className={`content-list text-left list-unstyled col-1`}>
                  Gojek starts in Indonesia, initially offering ride-hailing
                  services, later expanding its offerings significantly.
                </td>
              </tr>
              <tr>
                <td className="content-list text-left list-unstyled col-1">
                  2016
                </td>
                <td className={`content-list text-left list-unstyled col-1`}>
                  Grab expands beyond ride-hailing to offer food delivery and
                  financial services.
                </td>
              </tr>
              <tr>
                <td className="content-list text-left list-unstyled col-1">
                  2019
                </td>
                <td className={`content-list text-left list-unstyled col-1`}>
                  Tech giants, such as Facebook and Google explore the super app
                  model, which aims to pack multiple services under a single
                  platform.
                </td>
              </tr>
              <tr>
                <td className="content-list text-left list-unstyled col-1">
                  2022 - Present
                </td>
                <td className={`content-list text-left list-unstyled col-1`}>
                  The trend of super apps has been dominant in Asia, with the
                  trend slowly spreading to other regions such as Africa, and
                  South America.
                </td>
              </tr>
            </tbody>
          </table>
        ),
      },
      ,
    ],
  },
  {
    id: 4,
    blogImage: blogImgTwentySecond,
    imgalt: "Key reasons for the popularity of super apps",
    title: "Why Are Super Apps So Popular?",
    desc: "Super apps are becoming popular for several reasons. One of the biggest reason is convenience. Users are not required to constantly switch from app to app for every service they use, instead they can get everything from ordering food to managing their finances, all within one app. It saves time, and it makes the user experience easy and simpler.",
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
    subHeadAfterImage: [
      {
        subTitle: "Key Reasons for Their Popularity",
        subDesc: (
          <p>
            <strong className="">Seamless Experience: </strong>
            <span>Users get a unified interface for multiple services.</span>
          </p>
        ),
      },
      {
        subTitle: "",
        subDesc: (
          <p>
            <strong className="">Time Saving: </strong>
            <span>
              No need to download multiple apps or remember different passwords.
            </span>
          </p>
        ),
      },
      {
        subTitle: "",
        subDesc: (
          <p>
            <strong className="">Cost Effective: </strong>
            <span>
              Businesses save on development costs by integrating into a super
              app ecosystem.
            </span>
          </p>
        ),
      },
      {
        subTitle: "",
        subDesc: (
          <p>
            <strong className="">Loyalty Programs: </strong>
            <span>
              Super apps often offer rewards or loyalty points across different
              services, which keep the users engaged.
            </span>
          </p>
        ),
      },
    ],
  },
  {
    id: 5,
    blogImage: "",
    imgalt: "",
    title: "The Business Model of Super Apps",
    desc: (
      <div>
        <p>
          The business model behind super apps is equally interesting. These
          platforms generate revenue by charging transaction fees, offering
          premium services, and integrating ads. Since they control a large user
          base, super apps have access to valuable data, which they can use to
          personalize services and increase user engagement.
        </p>
        <p>Here’s how super apps earn:</p>
      </div>
    ),
    subHead: [
      {
        subTitle: "",
        subDesc: (
          <table className="table align-middle mt-5">
            <thead className="">
              <tr className="align-middle">
                <th className="text-left">Revenue Stream </th>
                <th className="text-left">Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="content-list text-left list-unstyled col-1">
                  Transaction Fees
                </td>
                <td className={`content-list text-left list-unstyled col-1`}>
                  Earn a percentage of each transaction made within the app
                  (e.g., food delivery, financial services).
                </td>
              </tr>
              <tr>
                <td className="content-list text-left list-unstyled col-1">
                  Advertising
                </td>
                <td className={`content-list text-left list-unstyled col-1`}>
                  Sell ad space to businesses looking to reach a highly engaged
                  audience.
                </td>
              </tr>
              <tr>
                <td className="content-list text-left list-unstyled col-1">
                  Subscription Plans
                </td>
                <td className={`content-list text-left list-unstyled col-1`}>
                  Offer premium services for a monthly or yearly fee (e.g.,
                  faster delivery, exclusive content).
                </td>
              </tr>
              <tr>
                <td className="content-list text-left list-unstyled col-1">
                  Partnerships
                </td>
                <td className={`content-list text-left list-unstyled col-1`}>
                  Collaborate with third-party service providers and charge for
                  integration or commissions.
                </td>
              </tr>
            </tbody>
          </table>
        ),
      },
      ,
    ],
  },
  {
    id: 6,
    blogImage: blogImgTwentyThird,
    imgalt: "Super app USA",
    title: "Are Super Apps Coming to the West?",
    desc: "Currently, super apps are most popular in Asia, where platforms like WeChat, Gojek, and Grab dominate. But could they be the future of app development worldwide? Many experts believe that super apps will eventually become a global trend.",
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
    subHeadAfterImage: [
      {
        subTitle: "",
        subDesc:
          "In Western markets, tech giants like Facebook and Amazon are exploring the idea. For example, Facebook’s integration of messaging, shopping, and payments into a single app could be seen as the beginning of its own super app journey.",
      },
      {
        subTitle: "Why the West Is Slower to Adopt Super Apps",
        subDesc: (
          <div>
            <p>
              <strong>Regulation: </strong> Western governments have stricter
              regulations on data privacy and monopolistic practices, which
              might slow the development of super apps.
            </p>
            <p>
              <strong>Competition: </strong> The market is more fragmented, with
              strong competitors in each service category.
            </p>
            <div className="pt-0">
              <Read
                content="Why On-Demand Businesses Need a Super App?"
                link="/blog/why-on-demand-businesses-need-a-super-app/"
              />
            </div>
          </div>
        ),
      },
    ],
  },
  {
    id: 7,
    blogImage: "",
    imgalt: "",
    title: "Challenges Faced by Super Apps",
    desc: "While super apps are convenient, they are not without challenges. Managing such a wide array of services requires significant resources and can lead to issues like:",
    subHead: [
      {
        subTitle: "",
        subDesc: (
          <p>
            <strong className="">Data Privacy: </strong>
            <span>
              Collecting vast amounts of user data raises concerns about privacy
              and security.
            </span>
          </p>
        ),
      },
      {
        subTitle: "",
        subDesc: (
          <p>
            <strong className="">Overwhelming Interface: </strong>
            <span>
              As more services are added, the app can become cluttered and
              harder to use.
            </span>
          </p>
        ),
      },
      {
        subTitle: "",
        subDesc: (
          <p>
            <strong className="">Dependence on Ecosystem: </strong>
            <span>
              Third-party developers may have too much influence, affecting the
              app's quality.
            </span>
          </p>
        ),
      },
    ],
  },
  {
    id: 8,
    blogImage: "",
    imgalt: "",
    title: "Super Apps vs Traditional Apps",
    desc: "Let’s compare super apps with traditional single-function apps to better understand their differences.",
    subHead: [
      {
        subTitle: "",
        subDesc: (
          <table className="table align-middle mt-5">
            <thead className="">
              <tr className="align-middle">
                <th className="text-left">Feature</th>
                <th className="text-left">Super Apps </th>
                <th className="text-left">Traditional Apps</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="content-list text-left list-unstyled col-3">
                  Functionality
                </td>
                <td className={`content-list text-left list-unstyled col-3`}>
                  Multiple services in one app
                </td>
                <td className={`content-list text-left list-unstyled col-3`}>
                  Focus on one service (e.g., chatting, payment)
                </td>
              </tr>
              <tr>
                <td className="content-list text-left list-unstyled col-3">
                  User Experience
                </td>
                <td className={`content-list text-left list-unstyled col-3`}>
                  Unified experience across services
                </td>
                <td className={`content-list text-left list-unstyled col-3`}>
                  Specialized experience for a single task
                </td>
              </tr>
              <tr>
                <td className="content-list text-left list-unstyled col-3">
                  Ecosystem
                </td>
                <td className={`content-list text-left list-unstyled col-3`}>
                  Third-party integrations are common
                </td>
                <td className={`content-list text-left list-unstyled col-3`}>
                  Limited or no third-party integrations
                </td>
              </tr>
              <tr>
                <td className="content-list text-left list-unstyled col-3">
                  User Base
                </td>
                <td className={`content-list text-left list-unstyled col-3`}>
                  Large, as users depend on it for various needs
                </td>
                <td className={`content-list text-left list-unstyled col-3`}>
                  Small or niche, focusing on a specific audience
                </td>
              </tr>
              <tr>
                <td className="content-list text-left list-unstyled col-3">
                  Revenue Model
                </td>
                <td className={`content-list text-left list-unstyled col-3`}>
                  Diverse (ads, transactions, subscriptions)
                </td>
                <td className={`content-list text-left list-unstyled col-3`}>
                  Focused (in-app purchases, ads)
                </td>
              </tr>
            </tbody>
          </table>
        ),
      },
      ,
    ],
  },
  {
    id: 9,
    blogImage: "",
    imgalt: "",
    title: "",
    desc: (
      <blockquote className="bg-white custom-shadow p-5 mt-0 rounded-custom border-4 border-primary border-top">
        <h2 className="h5">Final Thoughts</h2>
        <p className="text-muted">
          <p>
            {" "}
            <i className="fas fa-quote-right me-2 text-primary"></i>Super apps
            represent the next phase of app development, offering all-in-one
            solutions that make life easier for users. As more companies adopt
            this model, we can expect to see super apps gain popularity
            globally, though they might take different forms depending on
            regional preferences.
          </p>
          <p>
            Whether you’re a business looking to develop your own super app or a
            consumer curious about the future of mobile technology, one thing is
            certain: super apps are here to stay.{" "}
            <i className="fas fa-quote-right ms-2 text-primary"></i>
          </p>
        </p>
      </blockquote>
    ),
    subHead: [
      {
        subTitle: "",
        subDesc: "",
      },
    ],
  },
];

const tableOfContentsBlogTwenty = [
  {
    id: 0,
    title: "",
  },
  {
    id: 1,
    title: "What is a Super App?",
  },
  {
    id: 2,
    title: "Key Features of a Super App",
  },
  {
    id: 3,
    title: "The Evolution of Multi-Service Platform",
  },
  {
    id: 4,
    title: "Why Are Super Apps So Popular?",
  },
  {
    id: 5,
    title: "The Business Model of Super Apps",
  },
  {
    id: 6,
    title: "Are Super Apps Coming to the West?",
  },
  {
    id: 7,
    title: "Challenges Faced by Super Apps",
  },
  {
    id: 8,
    title: "Super Apps vs Traditional Apps",
  },
  {
    id: 9,
    title: "Final Thoughts",
  },
];

const tableOfContentsBlogFour = [
  {
    id: 0,
    title: "",
  },
  {
    id: 1,
    title: "The Growth of On-Demand Services",
  },
  {
    id: 2,
    title: "What Are On-Demand Services?",
  },
  {
    id: 3,
    title: "Why Are On-Demand Services So Popular?",
  },
  {
    id: 4,
    title: "Key Industries Changed by On-Demand Services",
  },
  {
    id: 5,
    title: "How Do On-Demand Services Work?",
  },
  {
    id: 6,
    title: "Benefits of On-Demand Services",
  },
  {
    id: 7,
    title: "Challenges and Criticisms",
  },
  {
    id: 8,
    title: "Final Thoughts",
  },
];

const tableOfContentsBlogOne = [
  {
    id: 0,
    title: "",
  },
  {
    id: 1,
    title: "What is a Taxi App?",
  },
  {
    id: 2,
    title: "Why Taxi Apps Are Essential in Today’s Market",
  },
  {
    id: 3,
    title: "Understand User Needs",
  },
  {
    id: 4,
    title: "Key Features to Include in a Taxi App",
  },
  {
    id: 5,
    title: "Steps to Build a Taxi App",
  },
  {
    id: 6,
    title: "How Much Does It Cost to Build a Taxi App?",
  },
  {
    id: 7,
    title: "Monetization Models for Taxi Apps",
  },
  {
    id: 8,
    title: "Final Thoughts",
  },
];

const tableOfContentsBlogTwelve = [
  {
    id: 0,
    title: "",
  },
  {
    id: 1,
    title: "What is White Label Taxi Dispatch Software?",
  },
  {
    id: 2,
    title: "Key Benefits of White Label Taxi Dispatch System",
  },
  {
    id: 3,
    title:
      "Why Choosing White Label Taxi Software is a Smart Business Decision",
  },
  {
    id: 4,
    title: "How to Choose the Best White Label Taxi Dispatch Software Solution",
  },
  {
    id: 5,
    title: "Conclusion",
  },
];

const tableOfContentsBlogFourteen = [
  {
    id: 0,
    title: "",
  },
  {
    id: 1,
    title: "Why Starting a Taxi Business Is a Good Idea in 2025",
  },
  {
    id: 2,
    title: "The Basics of Starting a Taxi Business",
  },
  {
    id: 3,
    title: "Market Research: Understand the Industry",
  },
  {
    id: 4,
    title: "Planning Your Taxi Business",
  },
  {
    id: 5,
    title: "Legal Requirements",
  },
  {
    id: 6,
    title: "The Role of Technology in a Taxi Business",
  },
  {
    id: 7,
    title: "Marketing and Growing Your Taxi Business",
  },
  {
    id: 8,
    title: "Wrapping Up",
  },
];

const OverviewAdvantage = [
  {
    id: 1,
    icon: <BsStarFill />,
    title: "Empowering Business Models with a Versatile Platform.    ",
    desc: "From lightning-fast grocery delivery to flexible logistics service and efficient fleet servicing, ZervX serves as the catalyst for success for leading operators in diverse business models. Kickstart your journey  with our industry templates, and experience the transformative power of our platform.    ",
    link: "Explore industry templates",
    href: "/industries/overview",
  },
  {
    id: 2,
    icon: <BiTrip />,
    title: "Embrace the Future with Unequaled Flexibility.",
    desc: "ZervX goes above and beyond by offering more than just whitelabeling or custom dispatching parameters. Our user-friendly cloud platform seamlessly integrates into your operations, enabling your company to swiftly launch customized software solutions and scale with utmost cost-efficiency.    ",
    link: "Explore our platform",
    href: "/platform/overview",
  },
  {
    id: 3,
    icon: <FaBezierCurve />,
    title: "Your Triumph is Our Top Priority.    ",
    desc: " At ZervX, we are dedicated to your success. Our platform features instant chat support directly embedded in the product, ensuring you are never left stranded. Moreover, our comprehensive help center resources equips your business to harness the full capabilities of our platform, empowering you to achieve remarkable results.",
    link: "Visit help center",
    href: "/coming-soon",
  },
];
const workingEnvironment = [
  {
    id: 1,
    icon: <FaHouseUser />,
    title: "Healthcare Insurance",
    desc: "We believe that everyone should have access to quality healthcare, and we're committed to providing our employees with the resources they need to stay healthy.",
  },
  {
    id: 2,
    icon: <FaClock />,
    title: "Vacation / Paid Time Off",
    desc: " We offer generous vacations and paid time off policies to our employees. Whether you need to take a vacation or just need a mental health day, we have got you covered.",
  },
  {
    id: 3,
    icon: <FaUserFriends />,
    title: "Performance Bonus",
    desc: "We believe in rewarding our employees for their hard work. Our performance bonus program allows employees to earn additional compensation based on their achievements.    ",
  },
  {
    id: 4,
    icon: <FaBriefcaseMedical />,
    title: "Paid Sick Days",
    desc: "We believe in taking care of our employees, and that includes providing them with the time and resources they need to take care of themselves and their loved ones.",
  },
];
const homeScript1 = {
  "@context": "http://schema.org/",
  "@type": "BreadcrumbList",
  itemListElement: [
    {
      "@type": "ListItem",
      position: 1,
      item: {
        "@id": "https://www.zervx.com/",
        name: "Home",
        image:
          "https://www.zervx.com/_next/static/media/ZervXBanner.ab26085e.webp",
      },
    },
    {
      "@type": "ListItem",
      position: 2,
      item: {
        "@id": "https://www.zervx.com/about-us/",
        name: "About us",
        image:
          "https://www.zervx.com/_next/static/media/ZervXBanner.ab26085e.webp",
      },
    },
    {
      "@type": "ListItem",
      position: 3,
      item: {
        "@id": "https://www.zervx.com/industries/ride-hailing/",
        name: "Ride Hailing Software",
        image:
          "https://www.zervx.com/_next/static/media/Ride%20Hailing.51f95b45.webp",
      },
    },
    {
      "@type": "ListItem",
      position: 4,
      item: {
        "@id": "https://www.zervx.com/industries/overview/",
        name: "Industries Overview",
        image:
          "https://www.zervx.com/_next/static/media/ZervXBanner.ab26085e.webp",
      },
    },
  ],
};
const homeScript2 = {
  "@context": "http://schema.org/",
  "@type": "WebSite",
  name: "ZervX",
  url: "https://www.zervx.com/",
  potentialAction: {
    "@type": "SearchAction",
    target: "https://www.zervx.com/{search_term_string}",
    "query-input": "required name=search_term_string",
  },
};

const rideHailingFaqScript = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: [
    {
      "@type": "Question",
      name: "How does your ride-hailing software help my taxi business?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Our ride-hailing software connects your taxi business with more customers through a user-friendly app, increasing ride requests. It optimizes driver dispatching to ensure that the closest available driver reaches the customer quickly. All these features work together to improve your service quality and boost your revenue, making your business more efficient and profitable.",
      },
    },
    {
      "@type": "Question",
      name: "What sets your ride hailing software apart from a typical Uber clone?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "While uber clones mimic the basic functionality, our system goes beyond imitation. We offer scalability, tailor-made customization, and a comprehensive suite of features that allow your business to stand out in a competitive market. Our mobility platform is designed for your unique needs, not just a cookie-cutter solution.",
      },
    },
    {
      "@type": "Question",
      name: "Can I customize the app to fit my brand?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Yes, our software is highly customizable. You can incorporate your logo, colors, and other elements to make the app uniquely yours, ensuring it represents your business identity. This flexibility helps create a consistent and professional look for your customers.",
      },
    },
    {
      "@type": "Question",
      name: "Does your ride hailing solution include a passenger app?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Yes, our solution includes a passenger app where customers can request rides, track their drivers in real-time, and make payments easily. It provides a convenient and user-friendly way for passengers to book and manage their rides anytime, anywhere.",
      },
    },
    {
      "@type": "Question",
      name: "What kind of support is available for clients?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "We offer technical support and customer service via phone, email, and chat. Our team is available to help with any issues or questions you have throughout your journey, guaranteeing you receive the assistance you need.",
      },
    },
  ],
};

const processStepsTaxi = [
  {
    icon: <FaChartLine />,
    title: "Increased Bookings",
    description:
      "Streamlined booking process and efficient taxi dispatch system help you attract more customers, reduce wait times, and increase ride volume.",
  },
  {
    icon: <FaExpandArrowsAlt />,
    title: "Scalability and Future Growth",
    description:
      "Whether you have a small fleet or a large network of drivers, our system can accommodate your needs and adapt to your expanding operations.",
  },
  {
    icon: <FaClipboardCheck />,
    title: "Reduced Administrative Burden",
    description:
      "Automates various administrative tasks which will help you reduce the burden on your team and free up resources to focus on core business activities.",
  },
  {
    icon: <FaSyncAlt />,
    title: "Continuous Innovation and Upgrades",
    description:
      "Ongoing upgrades help you stay competitive and provide a superior service, drive long-term growth and success for your taxi business.",
  },
];

const processStepsLimo = [
  {
    icon: <FaRocket />,
    title: "Streamlined Operations",
    description:
      "Automates and simplifies processes like booking management, dispatching, and fleet tracking, saving you countless hours",
  },
  {
    icon: <FaExpandAlt />,
    title: "Scalability",
    description:
      "As your business expands, our software can grow with you, to provide the flexibility and functionality you need to manage a larger fleet and customer base.",
  },
  {
    icon: <FaMoneyBillWave />,
    title: "Improved Cost Control",
    description:
      "Reduces operational costs associated with manual management, communication, and inefficient practices, allowing you to boost your bottom line.",
  },
  {
    icon: <FaCog />,
    title: "Customizable Solutions",
    description:
      "Fully customizable with your branding, allowing you to create a unique, branded experience for your customers while integrating with your existing systems.",
  },
];

const processStepsRideShare = [
  {
    icon: <FaChartLine />,
    title: "Increased Bookings",
    description:
      "Streamlined booking process and efficient carpool app help you attract more customers, reduce wait times, and increase ride volume.",
  },
  {
    icon: <FaHeadset />,
    title: "Support and Updates",
    description:
      "We provide 24/7 customer support to assist you whenever needed and regularly update our software to adapt to your changing business needs.",
  },
  {
    icon: <FaPuzzlePiece />,
    title: "Customizable",
    description:
      "Our advanced ride share app offers a range of customizable features to address the unique challenges you face in the carpooling operations.",
  },
  {
    icon: <FaSmile />,
    title: "Excellent Customer Experience ",
    description:
      "Provide real-time tracking, GPS-optimized routes, and multi-language support to ensure a seamless experience for your customers.",
  },
];

const processStepsAirport = [
  {
    icon: <FaChartLine />,
    title: "Scalability",
    description:
      "As your business grows, our software can grow with you, can handle increased demand without compromising performance.",
  },
  {
    icon: <FaMoneyBillWave />,
    title: "Profitability",
    description:
      "Reduce operational costs, minimize errors, and maximize revenue with automated processes and data-driven decision making.",
  },
  {
    icon: <FaChartBar />,
    title: "Increased Revenue",
    description:
      "Optimize your fleet utilization, offer dynamic pricing, and identify revenue-generating opportunities with detailed analytics.",
  },
  {
    icon: <FaSmile />,
    title: "Excellent Customer Experience",
    description:
      "Provide real-time tracking, optimized routes, and multi-language support to ensure a seamless experience for your customers.",
  },
];

const processStepsEmployee = [
  {
    icon: <FaCogs />,
    title: "Increased Operational Efficiency",
    description:
      "Get rid of manual procedures, cut down on mistakes and simplify employee transportation management by adopting a centralized technology-based solution.",
  },
  {
    icon: <FaShieldAlt />,
    title: "Employee Safety and Well-Being",
    description:
      "Prioritize the safety of your workforce with features like real-time vehicle tracking, panic buttons, and female-only driver options.",
  },
  {
    icon: <FaExpandArrowsAlt />,
    title: "Scalable and Adaptable Solution",
    description:
      "As your business grows, your employee transport management software can easily scale to accommodate your changing transportation needs.",
  },
  {
    icon: <FaTruck />,
    title: "Streamlined Fleet Management",
    description:
      "Centralize the management of your vehicle fleet using tools that schedule trips and keep an eye on how well drivers perform.",
  },
];

const processStepsFleet = [
  {
    icon: <FaGlobeAmericas />,
    title: "Remote Fleet Management",
    description:
      "Allows you to keep track of all your fleets from a single platform, with live GPS tracking and history of the vehicles’ locations.",
  },
  {
    icon: <FaExpandArrowsAlt />,
    title: "Scalability and Customization",
    description:
      "You can always find what you are looking for and you can adjust to the software based on your business needs.",
  },
  {
    icon: <FaRoute />,
    title: "Improved Dispatching and Productivity",
    description:
      "Route optimization and job assignment features enable you to dispatch vehicles and drivers more effectively, boosting productivity.",
  },
  {
    icon: <FaPiggyBank />,
    title: "Reduced Operational Costs",
    description:
      "Software's data-driven insights help you optimize routes and reduce idle time, leading to significant cost savings.",
  },
];

const solutionCardTaxi1 = [
  {
    title:
      "Improve customer satisfaction by offering faster, more reliable service",
  },
  {
    title:
      "Adapt quickly to changing market conditions and customer preferences",
  },
  {
    title:
      "Gain valuable insights into your business performance with real-time analytics",
  },
  {
    title:
      "Increase revenue through dynamic and surge pricing during peak demand periods",
  },
];

const solutionCardTaxi2 = [
  {
    title: "Provides 24/7 premium customer support for quick resolution",
  },
  {
    title: "Offers route optimization algorithms for efficient trip planning",
  },
  {
    title: "Enables customization of the system to fit specific business needs",
  },
  {
    title:
      "Provides highly configurable settings for dispatching and operations",
  },
];

const solutionCardLimo1 = [
  {
    title: "Provides real-time visibility into fleet location and availability",
  },
  {
    title: "Reduces time spent on manual booking and dispatching processes",
  },
  {
    title:
      "Eliminates errors and inconsistencies associated with manual management",
  },
  {
    title:
      "Increases revenue through personalized recommendations and dynamic pricing",
  },
];

const solutionCardLimo2 = [
  {
    title: "Offers seamless integrations with your existing systems",
  },
  {
    title: "Supports multiple languages to cater to a diverse customer base",
  },
  {
    title:
      "Features a dynamic pricing engine that adjusts rates based on demand and events",
  },
  {
    title:
      "Enables you to deliver exceptional service and build long-term customer relationships",
  },
];

const solutionCardRideShare1 = [
  {
    title: "Easily adapt to changing market demands and regulations",
  },
  {
    title: "Offer more flexible and convenient options for passengers",
  },
  {
    title: "Tap into growing carpooling market and attract new customers",
  },
  {
    title: "Reduce overhead costs compared to traditional taxi fleets",
  },
];

const solutionCardRideShare2 = [
  {
    title:
      "Comprehensive solution to manage all aspects of your rideshare service",
  },
  {
    title: "Scalable platform to accommodate your growth and expansion",
  },
  {
    title: "Optimize routes and reduce fuel consumption for cost savings",
  },
  {
    title:
      "Improve driver satisfaction and retention with better tools and earnings",
  },
];

const solutionCardAirport1 = [
  {
    title:
      "It makes running your business easier by automatically handling bookings, scheduling, and dispatch",
  },
  {
    title:
      "Makes managing drivers simpler by letting you easily schedule shifts, track performance, and handle payments",
  },
  {
    title:
      "Adapts to changes in the market, customer preferences, and industry rules to keep your business running smoothly",
  },
  {
    title:
      "Helps you partner with other businesses like hotels and airlines to offer co-branded services and deals to new customers",
  },
];

const solutionCardAirport2 = [
  {
    title:
      "Our system can handle more customers and fleet as your business grows without slowing down",
  },
  {
    title:
      "Continuous improvement with regular software updates and enhancements",
  },
  {
    title: "We offer 24/7 customer support to quickly answer questions",
  },
  {
    title:
      "Uses smart pricing that changes based on demand, season, and competitors to maximize your earnings while staying competitive",
  },
];

const solutionCardEmployee1 = [
  {
    title:
      "Provides a modern, technology-driven transportation solution that sets your business apart from competitors",
  },
  {
    title:
      "Offers a seamless commuting experience, boosting employee satisfaction and retention",
  },
  {
    title:
      "Automates and simplifies the entire employee transportation process, reduces manual efforts",
  },
  {
    title:
      "Provides real-time tracking of vehicles and employees, allowing for better coordination and responsiveness",
  },
];

const solutionCardEmployee2 = [
  {
    title:
      "We have automated much of the usually manual work with advanced features which saves time and cost",
  },
  {
    title:
      "The system optimizes routes; this reduces mileage, fuel consumption, and your company's carbon footprint",
  },
  {
    title:
      "Provide your employees with a convenient commuting experience, lowering the risk of turnover due to difficult commutes",
  },
  {
    title:
      "You get complete visibility over your fleet, always know where your drivers and vehicles are",
  },
];

const solutionCardFleet1 = [
  {
    title:
      "Decrease delivery time and increase the overall reliability, which leads to higher customer satisfaction",
  },
  {
    title:
      "Access live data on vehicle locations, status, and performance to make quick informed decisions",
  },
  {
    title:
      "Integrate with other tools and systems used in business to have a cohesive workflow and better data management",
  },
  {
    title:
      "Plan the most efficient routes and significantly cut down the fuel costs",
  },
];

const solutionCardFleet2 = [
  {
    title:
      "It will enable your team to start using it right away and ensure that they get the most out of it due to the simple and uncluttered layout",
  },
  {
    title:
      "There are more features and functionality updates that are incorporated in the software on a regular basis to suit the market need",
  },
  {
    title:
      "We offer secure data protection measures and enhanced access controls for your fleet data and other assets",
  },
  {
    title:
      "Can accommodate fleets of any size, from small businesses to large enterprises, growing alongside your organization",
  },
];

const TaxiDispatchTabOne = [
  {
    id: 1,
    ServicesTitle:
      "Improve the passenger experience and stand out from the competition",
    ServicesTitleContent:
      "Passenger app provides a seamless booking experience, making it easy for customers to request rides, track their driver's location, and pay for their trip. A user-friendly app can reduce wait times, improve customer satisfaction, and attract more business.    ",
    img: PassengerApp,
    idKey: "PassengerApp",
    imgalt: "Taxi dispatch app",
  },
  {
    id: 2,
    ServicesTitle: "Drive smarter, earn more with our driver app",
    ServicesTitleContent:
      "The driver app is specifically designed to allow your drivers perform their duties optimally while earning as much as possible. Everything they’ll be needing from real-time updates to turn by turn navigation to customer info will be available at their fingertips. This allows them to focus on providing the best services as well as getting passengers to their destinations quickly",
    img: Driver_App,
    idKey: "DriverApp",
    imgalt: "Online taxi booking software",
  },
  {
    id: 3,
    ServicesTitle:
      "Take control of your taxi business with our comprehensive admin tools",
    ServicesTitleContent:
      "Admin panel makes you the complete master of your taxi operations. This covers every area of your business from managing fleets and schedules of drivers, management of finances, and customers. With the help of powerful analytics and customizable settings, it becomes possible to improve performance and increase profitability ",
    img: Admin_Panel,
    idKey: "AdminPanel",
    imgalt: "Taxi dispatch system",
  },
  {
    id: 4,
    ServicesTitle:
      "Streamline corporate account management and build lasting partnerships",
    ServicesTitleContent:
      "Corporate panel enables the client to create corporate accounts, monitor usage, and create invoices and bills easily through the panel. Offer a dedicated platform for your corporate clients. This will also assist you in developing a good rapport so as to gain more clients and more business.",
    img: Corporate,
    idKey: "CorporatePanel",
    imgalt: "Taxi dispatch platform",
  },
  {
    id: 5,
    ServicesTitle: "Dispatch with confidence using our user-friendly hub",
    ServicesTitleContent:
      "Dispatcher panel provides a centralized interface for managing taxi operations. Dispatchers can efficiently assign rides, track driver locations, and communicate with drivers and passengers using a simple and intuitive design. This ensures smooth operations and satisfied customers.",
    img: Dispatcher,
    idKey: "DispatcherPanel",
    imgalt: "Taxi booking and dispatch system",
  },
  {
    id: 6,
    ServicesTitle: "Expand your reach with our convenient web booking",
    ServicesTitleContent:
      "Our web booking solution allows customers to book rides from any device, anytime. Giving a user-friendly web booking option, you can attract new customers, increase bookings, and provide a consistent booking experience across all channels.",
    img: Web_Booking,
    idKey: "WebBooking",
    imgalt: "Automated taxi dispatch software",
  },
];

const LimoSolutionsApp = [
  { id: 1, name: "Passenger App" },
  { id: 2, name: "Chauffeur App" },
  { id: 3, name: "Admin Panel" },
  { id: 6, name: "Web Booking" },
];

const RideShareSolutionsApp = [
  { id: 1, name: "Passenger App" },
  { id: 2, name: "Driver App" },
  { id: 3, name: "Admin Panel" },
  { id: 6, name: "Web Booking" },
];

const AirportSolutionsApp = [
  { id: 1, name: "Passenger App" },
  { id: 2, name: "Driver App" },
  { id: 3, name: "Admin Panel" },
  { id: 4, name: "Corporate Panel" },
  { id: 6, name: "Web Booking" },
];

const EmployeeSolutionsApp = [
  { id: 1, name: "Passenger App" },
  { id: 2, name: "Driver App" },
  { id: 3, name: "Admin Panel" },
];

const FleetSolutionsApp = [
  { id: 1, name: "Passenger App" },
  { id: 2, name: "Driver App" },
  { id: 3, name: "Admin Panel" },
  { id: 4, name: "Dispatcher Panel" },
];

const LimoTabOne = [
  {
    id: 1,
    ServicesTitle: "Redefine the limo experience with our user app",
    ServicesTitleContent:
      "Our app elevates your passengers' experience with tailored ride recommendations, live tracking, multi lingual & payment support, and a premium booking experience that fosters brand loyalty, sets you apart from competitors",
    img: PassengerApp,
    idKey: "PassengerApp",
    imgalt: "Limousine dispatch software",
  },
  {
    id: 2,
    ServicesTitle: "Empower your chauffeurs, boost productivity",
    ServicesTitleContent:
      "Designed to help your chauffeurs work efficiently and maximize their earnings. The app has a simple design that makes it simple for drivers to track how much they're earning and stay in touch with dispatchers. Drivers can focus on giving great service without worrying about complicated tech.",
    img: Driver_App,
    idKey: "DriverApp",
    imgalt: "Limo app",
  },
  {
    id: 3,
    ServicesTitle: "Your limo business command center",
    ServicesTitleContent:
      "Complete visibility and control over chauffeur services is gained with our comprehensive admin panel. You can manage your limo business from anywhere and at any time. Our admin tools provide a wide range of functionalities, allow you to make data-driven decisions and position your business for long-term success. ",
    img: Admin_Panel,
    idKey: "AdminPanel",
    imgalt: "Limousine reservation software solutions",
  },
  {
    id: 6,
    ServicesTitle: "Expand your reach and increase bookings",
    ServicesTitleContent:
      "Our web booking expands reach and boosts conversions with personalized experiences. A user-friendly interface and secure payment processing provide an easy booking experience that encourages conversions and drives sustainable growth.",
    img: Web_Booking,
    idKey: "WebBooking",
    imgalt: "Limousine software solutions",
  },
];

const RideShareTabOne = [
  {
    id: 1,
    ServicesTitle:
      "Stand out from the competition by giving the best for your customers",
    ServicesTitleContent:
      "Our app is designed for ease of use, making it easy for customers to book rides. They could easily request a ride, track their driver in real-time and even manage payments securely - all directly from their mobile devices. This straightforward process not only delights the customers, but also makes them come back to you so that you can retain a large customer base.",
    img: PassengerApp,
    idKey: "PassengerApp",
    imgalt: "Rideshare platform",
  },
  {
    id: 2,
    ServicesTitle: "Your drivers can earn more with our driver app",
    ServicesTitleContent:
      "With the driver app, we equip your drivers with the tools they need to excel. Features like live navigation, ride alerts, and earnings tracking help them manage their time effectively and deliver top-notch service. By providing drivers with everything they require at their fingertips, you cultivate a motivated team, which leads to happier passengers.",
    img: Driver_App,
    idKey: "DriverApp",
    imgalt: "Ride Share App Solution",
  },
  {
    id: 3,
    ServicesTitle: "Manage your rideshare service with ease",
    ServicesTitleContent:
      "The admin panel provides you with a bird-eye view and full control over your rideshare business operations. You can view crucial data and performance metrics, manage your drivers and respond to passenger feedback. This allows you to control all the technical aspects and make fast, informed decisions. ",
    img: Admin_Panel,
    idKey: "AdminPanel",
    imgalt: "Ride sharing software solutions",
  },
  {
    id: 6,
    ServicesTitle: "Expand your reach with our convenient web booking",
    ServicesTitleContent:
      "Our web booking feature works alongside the mobile apps, letting passengers book rides from their browsers. This added convenience appeals to a wider range of users, especially those who prefer using a browser to book rides. Through offering different ways to book, you can attract more customers.",
    img: Web_Booking,
    idKey: "WebBooking",
    imgalt: "Carpooling Software",
  },
];

const AirportTabOne = [
  {
    id: 1,
    ServicesTitle: "Give a delightful booking experience for your customers",
    ServicesTitleContent:
      "Our passenger app provides a seamless booking experience, making it easy for customers to request rides, track their driver's location, and pay for their trip. A user-friendly app can reduce wait times, improve customer satisfaction, and attract more business to your taxi service.",
    img: PassengerApp,
    idKey: "PassengerApp",
    imgalt: "Airport transfer booking software",
  },
  {
    id: 2,
    ServicesTitle: "Drivers can drive smarter and earn more",
    ServicesTitleContent:
      "Driver app gives your drivers real-time updates, turn-by-turn navigation, and customer info at their fingertips. This helps them work efficiently, get passengers to their destinations quickly, and earn more.",
    img: Driver_App,
    idKey: "DriverApp",
    imgalt: "Airport taxi transfer booking app",
  },
  {
    id: 3,
    ServicesTitle: "Take control of your business",
    ServicesTitleContent:
      "You can manage your fleet, schedule drivers, track financials, and monitor customer data. Our intuitive interface and customizable settings empower you to optimize every aspect of your business. ",
    img: Admin_Panel,
    idKey: "AdminPanel",
    imgalt: "Airport taxi dispatch software solution",
  },
  {
    id: 4,
    ServicesTitle: "Streamline corporate accounts",
    ServicesTitleContent:
      "Our corporate panel makes it easy to set up and manage corporate accounts, track usage, and generate invoices. This helps you build strong relationships with corporate businesses and secure recurring business.",
    img: Corporate,
    idKey: "CorporatePanel",
    imgalt: "Airport transfer software",
  },
  {
    id: 6,
    ServicesTitle: "Book from anywhere",
    ServicesTitleContent:
      "Web booking solution allows customers to book rides from any device, anytime. This gives them a convenient way to book, and helps you attract new customers and increase bookings.",
    img: Web_Booking,
    idKey: "WebBooking",
    imgalt: "Cloud-based airport taxi transfer solution",
  },
];

const EmployeeTabOne = [
  {
    id: 1,
    ServicesTitle: "Best commuting experience for your employees",
    ServicesTitleContent:
      "Provide your employees with a user-friendly mobile app that puts them in control of their daily commute. With our passenger app, they can easily:",
    subList: [
      {
        subDesc: "Schedule their rides with just a few taps",
      },
      {
        subDesc: "Track the real-time location of their assigned vehicles",
      },
      {
        subDesc:
          "Receive timely notifications about any changes to their schedule or route",
      },
    ],
    img: PassengerApp,
    idKey: "PassengerApp",
    imgalt: "Limousine dispatch software",
    end: "By empowering your employees to manage their commutes, you can ensure they arrive at work on time, reducing stress and boosting overall productivity.",
  },
  {
    id: 2,
    ServicesTitle: "Optimized efficiency for your transportation team",
    ServicesTitleContent:
      "Equip your drivers with our user-friendly mobile app to help them navigate routes more efficiently.",
    subList: [
      {
        subDesc: "Turn-by-turn navigation for optimized routes",
      },
      {
        subDesc:
          "Access to all necessary trip details, including pickup and drop-off locations",
      },
      {
        subDesc: "Seamless communication with the admin team and passengers",
      },
    ],
    img: Driver_App,
    idKey: "DriverApp",
    imgalt: "Limo app",
    end: "Using our driver app, your transportation team can minimize travel time, avoid traffic delays, and maintain a high level of coordination throughout the commute.",
  },
  {
    id: 3,
    ServicesTitle: "Centralized control for streamlined operations",
    ServicesTitleContent:
      "Our employee transport management software provides you with a comprehensive admin panel to oversee all aspects of your workforce mobility.",
    subList: [
      {
        subDesc:
          "Route planning and optimization based on employee locations and shift timings",
      },
      {
        subDesc:
          "Real-time fleet management, including vehicle status and availability",
      },
      {
        subDesc:
          "Driver assignment, performance tracking, and compliance monitoring",
      },
    ],
    img: Admin_Panel,
    idKey: "AdminPanel",
    imgalt: "Limousine reservation software solutions",
    end: "You can streamline your employee transportation operations, reduce costs, and make informed decisions to continuously improve your workforce mobility.",
  },
];

const FleetTabOne = [
  {
    id: 1,
    ServicesTitle: "Happy customers lead to more rides",
    ServicesTitleContent:
      "Our app is packed with features to make sure that the passengers have a great experience. They can easily book, track, and pay for rides without any hassle. Satisfied customers mean higher profits and a stronger reputation for your taxi service.",
    img: PassengerApp,
    idKey: "PassengerApp",
    imgalt: "Taxi fleet management software",
  },
  {
    id: 2,
    ServicesTitle: "Drivers can earn more with our app",
    ServicesTitleContent:
      "Our driver app helps your drivers perform at their best while maximizing their earnings. It offers real-time updates, turn-by-turn navigation, and customer information, all at their fingertips. This allows them to focus on providing excellent service and getting passengers to their destinations quickly",
    img: Driver_App,
    idKey: "DriverApp",
    imgalt: "White label fleet management software solutions",
  },
  {
    id: 3,
    ServicesTitle:
      "Manage your taxi business effortlessly with our all-in-one admin tools",
    ServicesTitleContent:
      "Our admin panel gives you complete control over your taxi operations. Manage everything from fleet and driver schedules to finances and customer relations. With data analytics and customizable settings, you can enhance performance and boost profitability.",
    img: Admin_Panel,
    idKey: "AdminPanel",
    imgalt: "Fleet management system",
  },
  {
    id: 4,
    ServicesTitle: "Dispatch with confidence using our user-friendly hub",
    ServicesTitleContent:
      "Our dispatcher panel provides a centralized interface for managing taxi operations. Dispatchers can efficiently assign rides, track driver locations, and communicate with drivers and passengers using a simple and intuitive design. You can also grant access to separate hubs within your taxi service for more control.",
    img: Corporate,
    idKey: "CorporatePanel",
    imgalt: "Cab management software",
  },
];

const TaxiDispatchSolutionFeatures = [
  {
    color: "text-primary",
    icon: <FaMapMarkedAlt />,
    title: "Live Tracking",
    content:
      "Real-time updates keep passengers informed, building trust in your service.",
  },
  {
    color: "text-secondary",
    icon: <FaMobileAlt />,
    title: "User-Friendly Interface",
    content:
      "Simple app design reduces booking time boosts satisfaction, and attracts customers.",
  },
  {
    color: "text-warning",
    icon: <FaMoneyBillWave />,
    title: "Flexible Payment Options",
    content:
      "Cash, credit, mobile wallets cater to all preferences expanding your customer base.",
  },
  {
    color: "text-info",
    icon: <FaCalendarAlt />,
    title: "Schedule Booking",
    content:
      "Advance reservations secure rides provide stress-free experiences build customer loyalty.",
  },
  {
    color: "text-danger",
    icon: <FaCalculator />,
    title: "Automated Ride Calculation",
    content:
      "Upfront fares eliminate disputes, build trust and prevent overcharging complaints.",
  },
  {
    color: "text-dark",
    icon: <FaTaxi />,
    title: "Multiple Vehicle Options",
    content:
      "Sedans, SUVs, Prime - diverse fleet accommodates all requests, meeting varied needs.",
  },
];

const LimoSolutionFeatures = [
  {
    color: "text-blue",
    icon: <FaNetworkWired />,
    title: "Intelligent Dispatch System",
    content:
      "Our system optimizes resource allocation, reduces wait times, and ensures reliable service for your customers.",
  },
  {
    color: "text-green",
    icon: <FaRoute />,
    title: "Live Vehicle Tracking",
    content:
      "Monitor your fleet in real-time, track driver routes, and ensure on-time arrivals for improved service quality.",
  },
  {
    color: "text-orange",
    icon: <FaFileInvoiceDollar />,
    title: "Automated Invoicing and Payments",
    content:
      "Streamline your financial processes with automated invoicing, multi-currency support, and secure payment processing.",
  },
  {
    color: "text-purple",
    icon: <FaLanguage />,
    title: "Multilingual Support",
    content:
      "Cater to a diverse customer base with multilingual support, giving a seamless booking experience for clients worldwide.",
  },
  {
    color: "text-red",
    icon: <FaChartLine />,
    title: "Dynamic Pricing",
    content:
      "Optimize your pricing based on demand, events, and market trends, ensuring competitive rates and maximizing revenue.",
  },
  {
    color: "text-indigo",
    icon: <FaChartBar />,
    title: "Reporting and Analytics",
    content:
      "Access detailed reports and analytics on your business performance, enabling data-driven decision-making for growth.",
  },
];

const RideShareSolutionFeatures = [
  {
    color: "text-blue",
    icon: <FaHandshake />,
    title: "Rideshare Matching",
    content:
      "Our smart matching algorithm connects passengers with nearby drivers quickly, faster pickups and a smoother experience for everyone.",
  },
  {
    color: "text-green",
    icon: <FaLanguage />,
    title: "Multi-Language Support",
    content:
      "We offer multi-language options to cater to a diverse range of users, making it easy for everyone to navigate the app comfortably.",
  },
  {
    color: "text-orange",
    icon: <FaComment />,
    title: "In-App Chat ",
    content:
      "Our in-app chat feature allows drivers and passengers to communicate directly, easy to coordinate pickups and address any questions.",
  },
  {
    color: "text-purple",
    icon: <FaThumbsUp />,
    title: "Accept and Reject Rides",
    content:
      "Drivers have the freedom to accept or reject ride requests, giving them control over their work and helping them manage their time effectively.",
  },
  {
    color: "text-red",
    icon: <FaBell />,
    title: "Notifications",
    content:
      "Real-time notifications keep both drivers and passengers informed about ride details, updates, and important alerts.",
  },
  {
    color: "text-indigo",
    icon: <FaStar />,
    title: "Rating System",
    content:
      "Passengers can rate their drivers, and vice versa, promoting accountability and encouraging high-quality service",
  },
];

const AirportSolutionFeatures = [
  {
    color: "text-primary",
    icon: <FaRoute />,
    title: "Automated Dispatch and Routing",
    content:
      "Intelligently match vehicles and drivers to passenger requests based on factors like capacity, availability, and location.",
  },
  {
    color: "text-success",
    icon: <FaMapMarkedAlt />,
    title: "Geofencing and Automated Alerts",
    content:
      "Set up virtual boundaries around airports and other key locations to trigger automated alerts and optimize driver deployment.",
  },
  {
    color: "text-warning",
    icon: <FaChartLine />,
    title: "Dynamic Pricing and Surge Management",
    content:
      "Adjust prices based on factors such as demand, seasonality, and competitor rates to maximize revenue while remaining competitive.",
  },
  {
    color: "text-info",
    icon: <FaComments />,
    title: "Feedback and Rating System",
    content:
      "Collect and analyze customer feedback to monitor service quality and identify areas for improvement.",
  },
  {
    color: "text-danger",
    icon: <FaCreditCard />,
    title: "Integrated Payment Solutions",
    content:
      "Offer a variety of payment options, including mobile wallets, credit cards, and corporate billing, for a seamless checkout process.",
  },
  {
    color: "text-secondary",
    icon: <FaLanguage />,
    title: "Multilingual Support",
    content:
      "Cater to a diverse customer base by offering a user-friendly interface in multiple languages.",
  },
];

const EmployeeSolutionFeatures = [
  {
    color: "text-primary",
    icon: <FaRoute />,
    title: "Route Optimization",
    content:
      "Automatically plan the most efficient routes based on employee locations and shift timings.",
  },
  {
    color: "text-success",
    icon: <FaCalendarAlt />,
    title: "Employee Self-Scheduling",
    content:
      "Allow employees to easily schedule their rides through a user-friendly passenger app.",
  },
  {
    color: "text-info",
    icon: <FaTruck />,
    title: "Fleet Management",
    content:
      "Manage your entire vehicle fleet from a single centralized platform to make things easier.",
  },
  {
    color: "text-warning",
    icon: <FaMapMarkedAlt />,
    title: "Real-Time Tracking",
    content:
      "Monitor the location of your fleet in real-time, to make sure on-time pickups and drop-offs.",
  },
  {
    color: "text-danger",
    icon: <FaBell />,
    title: "Automated Notifications",
    content:
      "Send timely notifications to employees about their pickup and drop-off times.",
  },
  {
    color: "text-secondary",
    icon: <FaFileInvoiceDollar />,
    title: "Automated Billing",
    content:
      "Streamline the billing process and maintain a digital record of all transportation-related expenses.",
  },
];

const FleetSolutionFeatures = [
  {
    color: "text-success",
    icon: <FaRoute />,
    title: "Route Optimization",
    content:
      "Automatically calculate the most efficient routes to save time and reduce fuel consumption.",
  },
  {
    color: "text-primary",
    icon: <FaChartLine />,
    title: "Reporting and Analytics",
    content:
      "Access comprehensive reports and analytics to understand and improve fleet performance.",
  },
  {
    color: "text-info",
    icon: <FaUsersCog />,
    title: "Driver Management",
    content:
      "Assign and manage driver schedules, ensuring optimal coverage and performance.",
  },
  {
    color: "text-warning",
    icon: <FaRandom />,
    title: "Automated Dispatch",
    content:
      "Efficiently assign rides to the nearest available drivers with our smart dispatch system.",
  },
  {
    color: "text-danger",
    icon: <FaMapMarkedAlt />,
    title: "Fleet Tracking",
    content:
      "Monitor the exact location and status of all your vehicles at any moment.",
  },
  {
    color: "text-secondary",
    icon: <FaComments />,
    title: "In-App Communication",
    content:
      "Enable direct communication between drivers and dispatchers through the app for better coordination.",
  },
];

const TaxiSolutionWorkProcessLastData = [
  {
    id: 1,
    icon: <FaUsersCog />,
    title: "Dedicated team of experts ready to assist you",
    subList: [
      {
        desc: "Tailored Customization Options",
      },
      {
        desc: "Intelligent Dispatch Algorithms",
      },
      {
        desc: "Regular Platform Updates",
      },
      {
        desc: "Smooth Onboarding",
      },
      {
        desc: "24/7 Customer Support",
      },
      {
        desc: "Scalable Solutions for Growth",
      },
    ],
  },
  {
    id: 2,
    icon: <FaLightbulb />,
    title: "Innovative features designed for success",
  },
];

const LimoWorkProcessLastData = [
  {
    id: 1,
    icon: <FaUsersCog />,
    title: "Dedicated team of experts ready to assist you",
    subList: [
      {
        desc: "Tailored Customization Options",
      },
      {
        desc: "Intelligent Dispatch Algorithms",
      },
      {
        desc: "Regular Platform Updates",
      },
      {
        desc: "Smooth Onboarding",
      },
      {
        desc: "Branding and White-Labeling",
      },
      {
        desc: "Scalable Solutions for Growth",
      },
    ],
  },
  {
    id: 2,
    icon: <FaLightbulb />,
    title: "Innovative features designed for success",
  },
];

const RideShareWorkProcessLastData = [
  {
    id: 1,
    icon: <FaUsersCog />,
    title: "Dedicated team of experts ready to assist you",
    subList: [
      {
        desc: "Customization Options",
      },
      {
        desc: "Intelligent Dispatch Algorithms",
      },
      {
        desc: "Regular Platform Updates",
      },
      {
        desc: "Smooth Onboarding",
      },
      {
        desc: "24/7 Customer Support",
      },
      {
        desc: "Scalable Solution",
      },
    ],
  },
  {
    id: 2,
    icon: <FaLightbulb />,
    title: "Innovative features designed for success",
  },
];

const AirportWorkProcessLastData = [
  {
    id: 1,
    icon: <FaUsersCog />,
    title: "Dedicated team of experts ready to assist you",
    subList: [
      {
        desc: "Tailored Customization Options",
      },
      {
        desc: "Intelligent Dispatch Algorithms",
      },
      {
        desc: "Regular Platform Updates",
      },
      {
        desc: "Smooth Onboarding",
      },
      {
        desc: "Branding and White-Labeling",
      },
      {
        desc: "Scalable Solutions for Growth",
      },
    ],
  },
  {
    id: 2,
    icon: <FaLightbulb />,
    title: "Innovative features designed for success",
  },
];

const FleetWorkProcessLastData = [
  {
    id: 1,
    icon: <FaUsersCog />,
    title: "Dedicated team of experts ready to assist you",
    subList: [
      {
        desc: "Tailored Customization Options",
      },
      {
        desc: "Intelligent Algorithms",
      },
      {
        desc: "Regular Platform Updates",
      },
      {
        desc: "Smooth Onboarding",
      },
      {
        desc: "Branding and White-Labeling",
      },
      {
        desc: "Scalable Solutions for Growth",
      },
    ],
  },
  {
    id: 2,
    icon: <FaLightbulb />,
    title: "Innovative features designed for success",
  },
];

const TaxiSolutionsFaqData = [
  {
    id: 1,
    question: "What is taxi dispatch software?",
    solution:
      "The taxi dispatch software is an extensive solution that targets the management and control of the taxi services. It encompasses various components such as a dispatch console, a passenger app, a driver app, and an online booking platform. It helps the taxi companies to handle bookings, dispatch taxis, track the drivers, and run their business from a centralized dashboard.",
  },
  {
    id: 2,
    question: "How does taxi dispatch software work?",
    solution:
      "When the passenger books a ride, through the help of the intelligent algorithms; the application searches and assigns the best driver by factors such as proximity, type of car the driver owns and if the driver is available. The system automatically gives the ride to the chosen driver. It also uses GPS to track where the vehicles are in real-time and it helps dispatchers know where the cars are and give customers a good idea of when their ride will arrive.",
  },
  {
    id: 3,
    question: "What features are included in a taxi dispatch app?",
    solution:
      "A taxi dispatch application will often have options through which passengers can book a taxi and also select the kind of car they want and also options to track the booked car on a map in real time. The rides’ cost is estimated before booking it, the various methods of payment,notifications for updates, a record of the past rides and a place to provide feedback and ratings.",
  },
  {
    id: 4,
    question: "How does a taxi dispatch system improve efficiency?",
    solution:
      "A taxi dispatch system helps to increase efficiency in several aspects. The system uses intelligent algorithms to automatically assign bookings to the most suitable driver, thereby freezing the necessity of the human interface and also shortening response time.",
  },
  {
    id: 5,
    question: "What features are most important for small taxi businesses?",
    solution:
      "For small taxi businesses, important features include a clean and intuitive interface, the system’s ability to dispatch cars automatically, GPS route tracking, the possibility to choose flexible time parameters, and basic analytical tools that allow the business to monitor its activity and make some decisions based on the received data.",
  },
  {
    id: 6,
    question: "What are the key features of a modern taxi dispatch system?",
    solution:
      "A modern solution should encompass smart dispatching, GPS tracking, passenger and driver apps, an online web booking, various payment options, detailed reporting, analytics, and seamless expandability.",
  },
  {
    id: 7,
    question: "What are the advantages of using online taxi booking software?",
    solution:
      "Online taxi booking software brings benefits such as convenience and flexibility to the passengers, real-time tracking, booking and payment possibilities, planning of the vehicle’s distribution and routes, a possibility to gather data for decision making, and a scalability feature that allows accommodating to increased volumes and market changes.",
  },
  {
    id: 8,
    question:
      "How can small taxi companies benefit from using dispatch software?",
    solution:
      "A dispatch software can help small companies become more optimized in their operations, decrease manual interference, increase response times, save costs, and increase the customer satisfaction which leads to a competitive advantage.",
  },
];

const LimoFaqData = [
  {
    id: 1,
    question: "What is limo software?",
    solution:
      "Limousine software is a comprehensive system that helps limo businesses manage bookings, dispatching, fleet, customers, and finances. It automates various processes, enabling businesses to operate more efficiently and cost-effectively.",
  },
  {
    id: 2,
    question: "What are the key features of limousine dispatch software?",
    solution:
      "Our limousine dispatch software offers several key features, including an intelligent dispatch system, multiple payment options, live tracking, and fleet management. If you would like to learn more about how our advanced features can benefit your business, please don't hesitate to contact us.",
  },
  {
    id: 3,
    question:
      "How does limousine booking software improve customer experience?",
    solution:
      "Limousine booking system enhances customer experience by making the booking process quick and easy. Customers can book rides through a user-friendly interface, receive real-time updates on their vehicle's status, and enjoy seamless payment options.",
  },
  {
    id: 4,
    question:
      "What are the benefits of using limo software for business growth?",
    solution:
      "Using limo software provides several benefits for business growth, including increased efficiency by automating routine tasks, which reduces the time and effort required to manage operations, and cost savings by streamlining processes and reducing operational costs. It also improves customer satisfaction through enhanced service quality and better communication, leading to happier customers and more repeat business.",
  },
  {
    id: 5,
    question: "What is limo app",
    solution:
      "A limo app is a mobile application that allows passengers to book rides, track their driver, and communicate with the limo company. It typically includes features like real-time pricing, loyalty programs, push notifications and more",
  },
  {
    id: 6,
    question:
      "What is the pricing structure for limousine reservation software solutions?",
    solution:
      "Pricing for limousine reservation software varies based on factors such as the number of users, required features, and deployment method. Most providers offer subscription-based pricing, with options for monthly or annual payments. To learn more about our pricing, please contact us.",
  },
  {
    id: 7,
    question: "What are the customization options available in limo software?",
    solution:
      "Customization options in limo software can include branding, allowing businesses to customize the software with their company's logo, colors, and branding elements, and tailored booking forms that match specific business needs and requirements. These customization options help businesses tailor the software to their unique needs, to improve its effectiveness and usability. If you want to know more about our limousine booking software customization options, please contact us.",
  },
];

const RideShareFaqData = [
  {
    id: 1,
    question: "What is rideshare software?",
    solution:
      "Rideshare software is a digital platform that connects passengers who need transportation with drivers who are available to provide rides. It typically includes mobile apps for both passengers and drivers, as well as an admin panel for managing the entire operation. This software streamlines the process of booking rides, making it easier for users to find transportation quickly and efficiently.",
  },
  {
    id: 2,
    question: "How does rideshare matching work?",
    solution:
      "Rideshare matching uses smart algorithms to pair passengers with nearby drivers. When a passenger requests a ride, the software identifies the closest available driver based on their location. This connection happens in real-time, to make sure that passengers get picked up as quickly as possible.",
  },
  {
    id: 3,
    question:
      "Can I customize the rideshare software to fit my business needs?",
    solution:
      "Yes, software can be tailored to meet the specific requirements of your business. You can customize features such as branding, payment options, and more. This flexibility allows you to create a unique platform that aligns with your business goals and customer preferences.",
  },
  {
    id: 4,
    question: "Is the payment process secure?",
    solution:
      "Yes, security is a top priority in our carpooling software. The payment process is designed to be safe and reliable, passengers can pay for their rides directly through the app. Various payment methods, such as credit cards, debit cards, and digital wallets, are supported. All financial transactions are encrypted, that users' personal and payment information remains protected.",
  },
  {
    id: 5,
    question: "What is the price of your rideshare software?",
    solution:
      "To get details of our pricing structure and to find the best plan that fits your business needs, we encourage you to contact us directly. Our team will be happy to discuss your requirements and provide you with a customized quote.",
  },
];

const AirportFaqData = [
  {
    id: 1,
    question: "What is Airport Transfer Software?",
    solution:
      "Airport transfer software is a tool for businesses that provide transportation services to and from airports. It's a comprehensive platform that streamlines your entire operation, from booking to fleet management, so you can focus on delivering an exceptional experience for your customers.",
  },
  {
    id: 2,
    question:
      "What are the key features of your airport transfer booking software?",
    solution:
      "Our software includes features like online booking, real-time flight tracking, automated dispatch, GPS-based routing, driver management, and reporting. If you would like to learn more about how our advanced features can benefit your business, please don't hesitate to contact us.",
  },
  {
    id: 3,
    question:
      "Can your airport transfer booking software handle different payment methods?",
    solution:
      "Yes, our software supports multiple payment options, including credit cards, mobile wallets, and corporate billing. This flexibility ensures a seamless checkout process for your customers.",
  },
  {
    id: 4,
    question:
      "What are the benefits of using a airport taxi transfer booking app?",
    solution:
      "Our airport taxi booking app allows your customers to book rides, track their vehicle, and receive updates on the go. For your drivers, it provides turn-by-turn navigation, passenger details, and the ability to accept and complete trips efficiently.",
  },
  {
    id: 5,
    question:
      "Can your airport transfer booking software integrate with other systems?",
    solution:
      "Yes, our software can integrate with various systems like accounting, payment gateways, etc. This allows for seamless data exchange and streamlined operations for your business.",
  },
  {
    id: 6,
    question: "How much does airport transfer software cost?",
    solution:
      "Our pricing is tailored to meet the needs of businesses of all sizes. We offer a range of plans to suit your specific requirements, from small startups to large enterprises. If you're interested in learning more about our pricing and how it can benefit your business, please contact us. We'd be happy to provide you with a customized quote and discuss how our software can help you achieve your goals.",
  },
  {
    id: 7,
    question:
      "How does our airport transfer booking software help with driver management?",
    solution:
      "Our software allows your company to track driver performance metrics, such as on-time arrivals, acceptance rates, and customer ratings. It also facilitates communication, task assignment, and digital payment disbursements, improving oversight and accountability for your drivers.",
  },
  {
    id: 8,
    question:
      "What kind of support and maintenance is available for your software?",
    solution:
      "We offer comprehensive support, including initial setup and ongoing maintenance. We also provide regular software updates to ensure compatibility with the latest technologies and address any issues that arise, ensuring the smooth operation of your business.",
  },
];

const EmployeeFaqData = [
  {
    id: 1,
    question: "What is employee transport management software?",
    solution:
      "Employee transport management system is a solution that streamlines the entire process of managing employee transportation. It helps companies optimize routes, track vehicles, and improve the commuting experience for employees.",
  },
  {
    id: 2,
    question: "Can the software be customized to suit specific company needs?",
    solution:
      "Yes, our employee transport management solution can be tailored to the specific needs of clients and can be scaled to accommodate for organizations of any size.",
  },
  {
    id: 3,
    question:
      "Is the software easy to use for both employees and administrators?",
    solution:
      "Employee transport management software includes an easy-to-use mobile app for employees and drivers, and a web-based dashboard for administrators. The goal is to make the software accessible and easy to use for all involved in the transportation process.",
  },
  {
    id: 4,
    question:
      "What kind of reporting and analytics capabilities does your software provide? ",
    solution:
      "Our employee transport management solution offers comprehensive reporting and analytics features, giving you valuable insights into your transportation operations. You can access reports covering various aspects, such as fleet utilization, driver performance, transportation costs, and sustainability metrics. These reports can be easily downloaded.",
  },
  {
    id: 5,
    question: "Can the software help companies achieve sustainability goals?",
    solution:
      "Yes, through optimizing routes and reducing unnecessary mileage, employee transport management software can help companies lower their carbon footprint and promote sustainable transportation practices.",
  },
  {
    id: 6,
    question: "Can employee transportation solution help reduce costs?",
    solution:
      "Yes, the software can help reduce transportation costs by optimizing routes, minimizing fuel consumption, and identifying areas for cost savings. It also enables companies to track expenses and make data-driven decisions to optimize their spending.",
  },
  {
    id: 7,
    question: "How much does your employee transport management solution cost?",
    solution:
      "The cost of our corporate transportation solution varies based on your unique requirements. We understand that every business has different needs when it comes to managing employee transportation, which is why we offer customized pricing plans. To get an accurate quote that aligns with your specific needs, we recommend reaching out to our sales team for a personalized consultation.",
  },
];

const FleetFaqData = [
  {
    id: 1,
    question: "What is fleet management software?",
    solution:
      "Fleet management software helps taxis or any transportation business manage their fleet of vehicles more efficiently. It tracks vehicle locations, driver performance, and more.",
  },
  {
    id: 2,
    question: "Is your cab fleet management system easy to use?",
    solution:
      "Yes, our fleet management system is designed with user-friendliness in mind. It typically features an intuitive interface that allows users to easily navigate through various functions and access critical information.",
  },
  {
    id: 3,
    question: "Is the software compatible with different types of vehicles?",
    solution:
      "Yes, our fleet monitoring system is generally compatible with a wide range of vehicle types, including cars, trucks, vans, and specialized vehicles.",
  },
  {
    id: 4,
    question: "Can I customize the software to fit my business needs?",
    solution:
      "Yes, our white label fleet management software solutions offer customization options to meet the specific needs of your business. You can tailor features, reports, and dashboards to align with your operational requirements and preferences.",
  },
  {
    id: 5,
    question: "What is your pricing model?",
    solution:
      "Our pricing is based on a SaaS (Software as a Service) model. This means you pay a subscription fee for access to the software, which may vary depending on the size of your fleet and the features you require. For detailed pricing information, please visit our pricing page on our website or contact us directly for a customized quote based on your specific needs.",
  },
  {
    id: 6,
    question: "How can I get started with your taxi fleet management software?",
    solution:
      "Getting started with our taxi management software is simple. You can visit our contact page for a free trial, which allows you to explore the features and benefits of the software. If you have any questions or need assistance, our team is available to provide support and help you get set up. Feel free to contact us for more information and personalized assistance.",
  },
];

const featuresForHome = [
  {
    id: 1,
    icon: <FaTaxi />,
    title: "Taxi Dispatch",
    description:
      "Our software automates dispatching, optimizes routes, and provides real-time tracking to boost efficiency.",
    link: "/solutions/taxi-dispatch-software/",
    iconColor: "#FF6B00",
    bgColor: "#FFF0E6",
  },
  {
    id: 2,
    icon: <FaUsers />,
    title: "Ride Sharing",
    description:
      "Start your own carpool platform with our comprehensive software featuring driver and passenger apps.",
    link: "/solutions/rideshare-carpool-software/",
    iconColor: "#2E7D32",
    bgColor: "#E8F5E9",
  },
  {
    id: 3,
    icon: <FaBuilding />,
    title: "Employee Transportation",
    description:
      "Address transportation challenges by simplifying ride coordination and improving employee satisfaction.",
    link: "/solutions/employee-transport-management-software/",
    iconColor: "#1565C0",
    bgColor: "#E3F2FD",
  },
  {
    id: 4,
    icon: <FaCar />,
    title: "Limo Dispatch",
    description:
      "Solve booking inefficiencies and boost customer satisfaction with our intuitive management tools.",
    link: "/solutions/limousine-dispatch-software/",
    iconColor: "#6A1B9A",
    bgColor: "#F3E5F5",
  },
  {
    id: 5,
    icon: <FaPlane />,
    title: "Airport Transfer",
    description:
      "Provide smooth travel experiences with automated notifications and easy booking for airport pickups.",
    link: "/solutions/airport-taxi-transfer-software/",
    iconColor: "#00838F",
    bgColor: "#E0F7FA",
  },
  {
    id: 6,
    icon: <FaTruckMoving />,
    title: "Fleet Management",
    description:
      "A complete software suite to improve taxi management and help drivers deliver their best performance.",
    link: "/",
    iconColor: "#D84315",
    bgColor: "#FBE9E7",
  },
];

const CyberServiceUberClone = [
  {
    title: "Lack of Originality ",
    content:
      "Clone scripts copy existing platforms, which makes it hard for your business to stand out in a crowded market.",
    color: "colorblue",
  },
  {
    title: "Poor Support ",
    content:
      "It often come with limited or no technical support, leaving you to handle any issues or bugs on your own.",
    color: "colorRed",
  },
  {
    title: "Scalability Issues",
    content:
      "As your business grows, clone scripts may struggle to keep up, limiting your ability to adapt to new demands.",
    color: "colorgreen",
  },
  {
    title: "Security Risks",
    content:
      "Many scripts have poor code and lack strong security, which leaves them open to cyber-attacks and data breaches.",
    color: "coloryellow",
  },
  {
    title: "Legal Issues",
    content:
      "Using clone scripts can lead to intellectual property disputes and legal battles if the original creators decide to take action.",
    color: "coloryellow",
  },
  {
    title: "Limited Customization",
    content:
      "These solutions offer little or no customization, which restricts your ability to adjust the software to fit your business needs.",
    color: "coloryellow",
  },
];

const FeatureImgContentTwoUberClone = [
  {
    icon: <FaPuzzlePiece />,
    title: "Tailored Solutions",
    desc: "Allows you to tailor features and functionalities to meet the specific needs of your business. Unlike Uber clone scripts, you can create a unique user experience that reflects your brand.",
    color: "bg-primary",
  },
  {
    icon: <FaSlidersH />,
    title: "Highly Configurable",
    desc: "Our platform offers extensive configuration options, which allows you to tailor functionalities and features to meet the specific needs of your business and customers.",
    color: "bg-danger",
  },
  {
    icon: <FaTrophy />,
    title: "Proven Success",
    desc: "Join a growing list of satisfied clients who have successfully launched and scaled their ride-hailing businesses with ZervX. Our track record speaks for itself, demonstrating our commitment to help you succeed.",
    color: "bg-dark",
  },
];

const FeatureImgContentTwoUberBolt = [
  {
    icon: <FaPuzzlePiece />,
    title: "Tailored Solutions",
    desc: "Allows you to tailor features and functionalities to meet the specific needs of your business. Unlike bolt clone scripts, you can create a unique user experience that reflects your brand.",
    color: "bg-primary",
  },
  {
    icon: <FaSlidersH />,
    title: "Highly Configurable",
    desc: "Our platform offers extensive configuration options, which allows you to tailor functionalities and features to meet the specific needs of your business and customers.",
    color: "bg-danger",
  },
  {
    icon: <FaTrophy />,
    title: "Proven Success",
    desc: "Join a growing list of satisfied clients who have successfully launched and scaled their ride-hailing businesses with ZervX. Our track record speaks for itself, demonstrating our commitment to help you succeed.",
    color: "bg-dark",
  },
];

const FeatureImgContentTwoUberLyft = [
  {
    icon: <FaPuzzlePiece />,
    title: "Tailored Solutions",
    desc: "Allows you to tailor features and functionalities to meet the specific needs of your business. Unlike lyft clone scripts, you can create a unique user experience that reflects your brand.",
    color: "bg-primary",
  },
  {
    icon: <FaSlidersH />,
    title: "Highly Configurable",
    desc: "Our platform offers extensive configuration options, which allows you to tailor functionalities and features to meet the specific needs of your business and customers.",
    color: "bg-danger",
  },
  {
    icon: <FaTrophy />,
    title: "Proven Success",
    desc: "Join a growing list of satisfied clients who have successfully launched and scaled their ride-hailing businesses with ZervX. Our track record speaks for itself, demonstrating our commitment to help you succeed.",
    color: "bg-dark",
  },
];

const featuresForUberClone = [
  {
    id: 1,
    icon: <FaTaxi />,
    title: "Passenger App",
    description:
      "Our customer app makes booking rides easy. Your passengers can book rides quickly, see where their driver is, and pay effortlessly. Happy customers mean more business for you.",
    link: "/platform/tools/",
    iconColor: "#FF6B00",
    bgColor: "#FFF0E6",
  },
  {
    id: 2,
    icon: <FaUsers />,
    title: "Driver App",
    description:
      "Drivers get all the tools they need to do their job well. They receive directions, manage trips, and see their earnings. Better tools mean better service and more satisfied customers.",
    link: "/platform/tools/",
    iconColor: "#2E7D32",
    bgColor: "#E8F5E9",
  },
  {
    id: 3,
    icon: <FaBuilding />,
    title: "Admin Panel",
    description:
      "Control all operations from one place. Manage drivers, vehicles, monitor ride activity, generate reports and view data easily. This helps you make good decisions and run things smoothly.",
    link: "/platform/tools/",
    iconColor: "#1565C0",
    bgColor: "#E3F2FD",
  },
  {
    id: 4,
    icon: <FaCar />,
    title: "Dispatcher Panel",
    description:
      "Dispatchers can assign rides manually if needed and monitor real-time drivers locations. This ensures your fleet is used well, which reduce wait times and leads to improved service reliability.",
    link: "/platform/tools/",
    iconColor: "#6A1B9A",
    bgColor: "#F3E5F5",
  },
  {
    id: 5,
    icon: <FaBriefcase />,
    title: "Corporate Panel",
    description:
      "Business clients can manage employee transport, schedule rides, and track expenses. This makes your service a key part of their daily operations.",
    link: "/platform/tools/",
    iconColor: "#00838F",
    bgColor: "#E0F7FA",
  },
  {
    id: 6,
    icon: <FaGlobe />,
    title: "Web Booking",
    description:
      "Passengers can book rides from any device. This is perfect for businesses that want an easy way for customers to arrange transport without an app. More options, more customers for you.",
    link: "/platform/tools/",
    iconColor: "#D84315",
    bgColor: "#FBE9E7",
  },
];

const comparisonTableUberClone = [
  {
    id: 1,
    title: "",
    description: "",
    heading1: "Features",
    heading2: "ZervX Platform (SaaS)",
    heading3: "Clone Scripts ",
    heading4: "Custom Build",
    desc: [
      {
        desc1: "Launch in days/weeks",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-4" />,
        desc3: <IoMdCheckmarkCircleOutline className="bg-color fs-4" />,
        desc4: "Months to years",
      },
      {
        desc1: "Customization",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-4" />,
        desc3: "Limited to basic changes",
        desc4: <IoMdCheckmarkCircleOutline className="bg-color fs-4" />,
      },
      {
        desc1: "Scalability",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-4" />,
        desc3: "Limited",
        desc4: "Requires major revamps",
      },
      {
        desc1: "Maintenance",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-4" />,
        desc3: "High maintenance costs",
        desc4: "High, requires dedicated team",
      },
      {
        desc1: "Mobile apps",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-4" />,
        desc3: "May not have mobile compatibility",
        desc4: "Requires separate development",
      },
      {
        desc1: "Payment integration",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-4" />,
        desc3: "Limited options",
        desc4: "Custom integration needed",
      },
      {
        desc1: "Ongoing support",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-4" />,
        desc3: "No",
        desc4: "Ask the development team to do",
      },
      {
        desc1: "Analytics and reporting",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-4" />,
        desc3: "Basic analytics only",
        desc4: "Custom analytics needed",
      },
      {
        desc1: "Integration",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-4" />,
        desc3: "Limited integration capabilities",
        desc4: "Requires custom integration",
      },
      {
        desc1: "Multi-language support",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-4" />,
        desc3: "Limited",
        desc4: "Requires additional development",
      },
      {
        desc1: "Updates and features",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-4" />,
        desc3: "No",
        desc4: "Ask the development team to do",
      },
      {
        desc1: "Cost efficiency",
        desc2: "Subscription model",
        desc3: "Lower initial cost, high maintenance",
        desc4: "High upfront and ongoing costs",
      },
    ],
  },
];

const FeatureBgUberClone = [
  {
    icon: <FaHeadset />,
    passage: "Our dedicated support team is here for you 24/7 to assist you.",
  },
  {
    icon: <FaSyncAlt />,
    passage:
      "We regularly update our platform with new features and improvements.",
  },
  {
    icon: <FaShieldAlt />,
    passage:
      "We prioritize your security with top-notch measures to protect your data.",
  },
  {
    icon: <FaComments />,
    passage: "We listen to your feedback and make changes based on your needs.",
  },
];

const SingleServiceFeatureUberClone = [
  {
    icon: <FaRocket />,
    title: "Quick Setup Process",
    passage:
      "You can launch your app without any technical expertise and get on the road faster.",
  },
  {
    icon: <FaPaintBrush />,
    title: "Custom Branding",
    passage:
      "Personalize your app with your logo and colors to create a unique brand identity.",
  },
];

const UberCloneFaqData = [
  {
    id: 1,
    question: "What is an Uber clone?",
    solution:
      "An Uber clone is a software solution designed to replicate the features and functionalities of the Uber ride-hailing app. It allows entrepreneurs to create their own taxi service with similar capabilities.",
  },
  {
    id: 2,
    question: "How does an Uber clone script work?",
    solution:
      "An Uber clone operates by connecting users looking for rides with drivers in their vicinity. Passengers book rides through a mobile app, which sends requests to nearby drivers. Once a driver accepts, both parties can track each other’s locations in real time until the ride is completed.",
  },
  {
    id: 3,
    question: "Can I customize your Uber clone-like solution?",
    solution:
      "Yes, one of the main advantages of our mobility platform is its customizability. You can personalize the app with your own logo, color scheme, and additional features to create a unique brand identity that stands out in the market.",
  },
  {
    id: 4,
    question: "Is support available for your white label Uber clone?",
    solution:
      "Yes, we offer customer support services. This support includes assistance with setup, ongoing maintenance, and troubleshooting any issues that may arise, to make sure that you have help when you need it.",
  },
  {
    id: 5,
    question: "How much does your Uber clone software cost?",
    solution:
      "Our pricing is based on a SaaS (Software as a Service) model. This means you pay a subscription fee for access to the software, which may vary depending on the size of your fleet and the features you require. For detailed pricing information, please visit our pricing page or contact us directly for a customized quote based on your specific needs.",
  },
  {
    id: 6,
    question: "Who can benefit from using your Uber clone app?",
    solution:
      "Various stakeholders can benefit from an Uber clone app, including entrepreneurs aiming to enter the ride-hailing market, businesses seeking to offer transportation services, taxi fleet owners, and other on-demand transportation providers.",
  },
];

const BoltCloneFaqData = [
  {
    id: 1,
    question: "What is a Bolt clone?",
    solution:
      "A Bolt clone app is a ready-made taxi booking application that mimics the features and functionality of the popular ride-hailing service Bolt. It allows taxi businesses to quickly enter the online taxi market with a proven business model.",
  },
  {
    id: 2,
    question: "Can I customize the Bolt clone app to fit my brand?",
    solution:
      "Yes, one of the main advantages of our mobility platform is its customizability. You can personalize the app with your own logo, color scheme, and additional features to create a unique brand identity that stands out in the market.",
  },
  {
    id: 3,
    question: "What support will I receive after launching the app?",
    solution:
      "We offer ongoing support, including maintenance, updates, and troubleshooting assistance to ensure your app runs smoothly.",
  },
  {
    id: 4,
    question: "Is the Bolt clone app scalable for future growth?",
    solution:
      "Yes, our ride hailing platform is designed to be scalable, which allow you to expand your services and accommodate a growing user base as your business develops.",
  },
  {
    id: 5,
    question: "How can I get more information about pricing?",
    solution:
      "For more information about pricing, please visit our pricing page or contact us directly. We’re here to help you understand the costs involved and how we can meet your needs.",
  },
  {
    id: 6,
    question: "What is the free trial for the Bolt clone app?",
    solution:
      "We offer a 14-day free trial of the platform, allowing you to explore its features and functionalities without any financial commitment.",
  },
];

const LyftCloneFaqData = [
  {
    id: 1,
    question: "What is a Lyft clone?",
    solution:
      "A Lyft clone app is a ready made ride hailing platform that replicates the features and functionality of the popular Lyft app. It allows entrepreneurs to quickly launch their own on-demand taxi service.",
  },
  {
    id: 2,
    question: "Can I customize your Lyft clone app solution?",
    solution:
      "Yes, our solutions are highly customizable. You can white-label Lyft clone app with your branding, modify the features, and tailor it to your specific business requirements.",
  },
  {
    id: 3,
    question: "What if I need additional features beyond the Lyft clone?",
    solution:
      "If you need more features than a Lyft clone offers, our platform provides extensive customization options to meet your unique requirements. We offer advanced functionalities that go beyond typical solutions, allowing you to create a distinct identity for your app.",
  },
  {
    id: 4,
    question: "How much does your Lyft clone cost?",
    solution:
      "Our pricing is based on a SaaS (Software as a Service) model. This means you pay a subscription fee for access to the software, which may vary depending on the size of your fleet and the features you require.",
  },
  {
    id: 5,
    question: "What kind of support do you offer?",
    solution:
      "We offer comprehensive support, including initial setup, customization guidance, and ongoing technical assistance.",
  },
  {
    id: 6,
    question: "How flexible are the promotional options in the Lyft clone app?",
    solution:
      "The promotional options in our Lyft clone app are highly flexible, allowing you to tailor discounts and offers to meet your marketing goals and customer needs.",
  },
];

const contentZervx = [
  {
    content:
      "ZervX is a taxi dispatch software built for the transportation industry. It offers a simple platform for managing services like ride-hailing, ride-sharing, limo dispatch, airport transfer, corporate transport, and package delivery.",
    list: [
      {
        content: "Scale your business with features designed to grow with you.",
      },
      {
        content:
          "Gain valuable insights through comprehensive reporting and analytics.",
      },
      {
        content:
          "Customize and brand the software to align with your company's identity.",
      },
    ],
  },
  {
    content:
      "The software includes a strong booking system that improves the customer experience and helps increase bookings and revenue.",
  },
  {
    content:
      "ZervX gives taxi businesses the tools they need to succeed in a competitive market, with an easy-to-use interface and a customizable white-label solution that fits specific business needs.",
  },
];

const comparisonZervxvsTaxi = [
  {
    id: 1,
    title: "",
    description: "",
    heading1: "Features",
    heading2: "ZervX",
    heading3: "TaxiMobility",
    desc: [
      {
        desc1: "Free Trial",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
      },
      {
        desc1: "Technical Support",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: "Not specified",
      },
      {
        desc1: "Customization Option",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <MdOutlineCancel className="fs-5 text-danger" />,
      },
      {
        desc1: "User-Friendly Interface",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <MdOutlineCancel className="fs-5 text-danger" />,
      },
      {
        desc1: "Scalability",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <MdOutlineCancel className="fs-5 text-danger" />,
      },
      {
        desc1: "Flexible Pricing",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <MdOutlineCancel className="fs-5 text-danger" />,
      },
      {
        desc1: "Route Optimization",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <MdOutlineCancel className="fs-5 text-danger" />,
      },
      {
        desc1: "Customer & Driver Apps (iOS & Android)",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
      },
      {
        desc1: "API",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <MdOutlineCancel className="fs-5 text-danger" />,
      },
      {
        desc1: "Taxi Dispatching",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <MdOutlineCancel className="fs-5 text-danger" />,
      },
      {
        desc1: "Ride Sharing (Carpool)",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <MdOutlineCancel className="fs-5 text-danger" />,
      },
      {
        desc1: "Driver Incentive Programs",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <MdOutlineCancel className="fs-5 text-danger" />,
      },
      {
        desc1: "Automated Dynamic Pricing",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <MdOutlineCancel className="fs-5 text-danger" />,
      },
      {
        desc1: "Updates",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: "Not specified",
      },
      {
        desc1: "Branded Apps",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <MdOutlineCancel className="fs-5 text-danger" />,
      },
    ],
  },
];

const comparisonZervxvsIcabbi = [
  {
    id: 1,
    title: "",
    description: "",
    heading1: "Features",
    heading2: "ZervX",
    heading3: "iCabbi",
    desc: [
      {
        desc1: "Free Trial",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <MdOutlineCancel className="fs-5 text-danger" />,
      },
      {
        desc1: "Technical Support",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: "Not specified",
      },
      {
        desc1: "Customization Option",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <MdOutlineCancel className="fs-5 text-danger" />,
      },
      {
        desc1: "User-Friendly Interface",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <MdOutlineCancel className="fs-5 text-danger" />,
      },
      {
        desc1: "Scalability",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <MdOutlineCancel className="fs-5 text-danger" />,
      },
      {
        desc1: "Web Booking",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <MdOutlineCancel className="fs-5 text-danger" />,
      },
      {
        desc1: "Route Optimization",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <MdOutlineCancel className="fs-5 text-danger" />,
      },
      {
        desc1: "Customer & Driver Apps (iOS & Android)",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
      },
      {
        desc1: "API",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
      },
      {
        desc1: "Taxi Dispatching",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <MdOutlineCancel className="fs-5 text-danger" />,
      },
      {
        desc1: "Integration with Third-Party Apps",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
      },
      {
        desc1: "Driver Incentive Programs",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <MdOutlineCancel className="fs-5 text-danger" />,
      },
      {
        desc1: "Integrated Payment Solutions",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <MdOutlineCancel className="fs-5 text-danger" />,
      },
      {
        desc1: "Updates",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: "Not specified",
      },
      {
        desc1: "Branded Apps",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <MdOutlineCancel className="fs-5 text-danger" />,
      },
    ],
  },
];

const comparisonZervxvsOnde = [
  {
    id: 1,
    title: "",
    description: "",
    heading1: "Features",
    heading2: "ZervX",
    heading3: "Onde",
    desc: [
      {
        desc1: "Free Trial",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
      },
      {
        desc1: "Technical Support",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
      },
      {
        desc1: "Customization Option",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
      },
      {
        desc1: "User-Friendly Interface",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <MdOutlineCancel className="fs-5 text-danger" />,
      },
      {
        desc1: "Scalability",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <MdOutlineCancel className="fs-5 text-danger" />,
      },
      {
        desc1: "Web Booking",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <MdOutlineCancel className="fs-5 text-danger" />,
      },
      {
        desc1: "Route Optimization",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <MdOutlineCancel className="fs-5 text-danger" />,
      },
      {
        desc1: "Customer & Driver Apps (iOS & Android)",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
      },
      {
        desc1: "API",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <MdOutlineCancel className="fs-5 text-danger" />,
      },
      {
        desc1: "Taxi Dispatching",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <MdOutlineCancel className="fs-5 text-danger" />,
      },
      {
        desc1: "Integration with Third-Party Apps",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <MdOutlineCancel className="fs-5 text-danger" />,
      },
      {
        desc1: "Driver Incentive Programs",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <MdOutlineCancel className="fs-5 text-danger" />,
      },
      {
        desc1: "Integrated Payment Solutions",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <MdOutlineCancel className="fs-5 text-danger" />,
      },
      {
        desc1: "Updates",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: "Not specified",
      },
      {
        desc1: "Branded Apps",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <MdOutlineCancel className="fs-5 text-danger" />,
      },
    ],
  },
];

const comparisonZervxvsYellowsoft = [
  {
    id: 1,
    title: "",
    description: "",
    heading1: "Features",
    heading2: "ZervX",
    heading3: "Yelowsoft",
    desc: [
      {
        desc1: "Free Trial",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
      },
      {
        desc1: "Technical Support",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: "Not for all plans",
      },
      {
        desc1: "Customer & Driver Apps (iOS & Android)",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
      },
      {
        desc1: "User-Friendly Interface",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <MdOutlineCancel className="fs-5 text-danger" />,
      },
      {
        desc1: "Scalability",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
      },
      {
        desc1: "Web Booking",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
      },
      {
        desc1: "Driver Incentive Programs",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <MdOutlineCancel className="fs-5 text-danger" />,
      },
      {
        desc1: "Customization Option",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: "Limited",
      },
      {
        desc1: "API",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
      },
      {
        desc1: "Taxi Dispatching",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
      },
      {
        desc1: "Integrated Payment Solutions",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <MdOutlineCancel className="fs-5 text-danger" />,
      },
      {
        desc1: "Route Optimization",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
      },
      {
        desc1: "Integration with Third-Party Apps",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
      },
      {
        desc1: "Updates",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
      },
      {
        desc1: "Branded Apps",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <MdOutlineCancel className="fs-5 text-danger" />,
      },
    ],
  },
];

const comparisonZervxvsJugnoo = [
  {
    id: 1,
    title: "",
    description: "",
    heading1: "Features",
    heading2: "ZervX",
    heading3: "Jugnoo",
    desc: [
      {
        desc1: "Free Trial",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <MdOutlineCancel className="fs-5 text-danger" />,
      },
      {
        desc1: "Technical Support",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: "Not specified",
      },
      {
        desc1: "Customization Option",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <MdOutlineCancel className="fs-5 text-danger" />,
      },
      {
        desc1: "User-Friendly Interface",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <MdOutlineCancel className="fs-5 text-danger" />,
      },
      {
        desc1: "Scalability",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <MdOutlineCancel className="fs-5 text-danger" />,
      },
      {
        desc1: "Web Booking",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <MdOutlineCancel className="fs-5 text-danger" />,
      },
      {
        desc1: "Route Optimization",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <MdOutlineCancel className="fs-5 text-danger" />,
      },
      {
        desc1: "Customer & Driver Apps (iOS & Android)",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
      },
      {
        desc1: "API",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <MdOutlineCancel className="fs-5 text-danger" />,
      },
      {
        desc1: "Taxi Dispatching",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <MdOutlineCancel className="fs-5 text-danger" />,
      },
      {
        desc1: "Integration with Third-Party Apps",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <MdOutlineCancel className="fs-5 text-danger" />,
      },
      {
        desc1: "Driver Incentive Programs",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <MdOutlineCancel className="fs-5 text-danger" />,
      },
      {
        desc1: "Integrated Payment Solutions",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <MdOutlineCancel className="fs-5 text-danger" />,
      },
      {
        desc1: "Updates",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: "Not specified",
      },
      {
        desc1: "Branded Apps",
        desc2: <IoMdCheckmarkCircleOutline className="bg-color fs-5" />,
        desc3: <MdOutlineCancel className="fs-5 text-danger" />,
      },
    ],
  },
];

const FeatureImgContentTaxi = [
  {
    id: 1,
    image: compareImg1,
    imgAlt: "TaxiMobility alternatives",
    icon: <FaPalette />,
    title: "Customize and Brand Your Taxi Service",
    content:
      "ZervX gives you full control to customize your taxi service. Tailor the software to match your brand’s identity, from colors to logos, and create a unique app that reflects your business. By offering a personalized experience, you can set your taxi service apart from the competition, attract more customers, and strengthen your brand in a crowded market.",
  },
  {
    id: 2,
    image: compareImg2,
    imgAlt: "Alternative to TaxiMobility",
    icon: <FaHeadset />,
    title: "Reliable Support When You Need It",
    content:
      "We offer dependable technical support to keep your taxi operations running smoothly. Whenever you face issues or need help, our support team is ready to assist. You can count on quick, effective solutions to keep your business on track and minimize downtime, ensuring a seamless experience.",
  },
  {
    id: 3,
    image: compareImg3,
    imgAlt: "Top TaxiMobility alternatives",
    icon: <FaChartLine />,
    title: "Scale Your Business with Ease",
    content:
      "ZervX helps you grow your taxi business smoothly. With features that adapt as you expand, you can handle more rides, manage a larger fleet, and reach new markets effortlessly. Enjoy the flexibility to scale up without worrying about technical limits or disruptions to your service.",
  },
  {
    id: 4,
    image: compareImg4,
    imgAlt: "Best TaxiMobility alternatives",
    icon: <FaCog />,
    title: "Advanced Features for Your Taxi Business",
    content:
      "ZervX provides advanced features designed to enhance your taxi service. From detailed reporting and analytics to powerful booking tools, these features help you optimize operations and improve customer satisfaction. Explore our platform’s capabilities to see how these tools can support your business’s success.",
  },
];

const FeatureImgContentIcabbi = [
  {
    id: 1,
    image: compareImg1,
    imgAlt: "iCabbi alternatives",
    icon: <FaPalette />,
    title: "Customize and Brand Your Taxi Service",
    content:
      "ZervX gives you full control to customize your taxi service. Tailor the software to match your brand’s identity, from colors to logos, and create a unique app that reflects your business. By offering a personalized experience, you can set your taxi service apart from the competition, attract more customers, and strengthen your brand in a crowded market.",
  },
  {
    id: 2,
    image: compareImg2,
    imgAlt: "Alternative to iCabbi",
    icon: <FaHeadset />,
    title: "Reliable Support When You Need It",
    content:
      "We offer dependable technical support to keep your taxi operations running smoothly. Whenever you face issues or need help, our support team is ready to assist. You can count on quick, effective solutions to keep your business on track and minimize downtime, ensuring a seamless experience.",
  },
  {
    id: 3,
    image: compareImg3,
    imgAlt: "Top iCabbi alternatives",
    icon: <FaChartLine />,
    title: "Scale Your Business with Ease",
    content:
      "ZervX helps you grow your taxi business smoothly. With features that adapt as you expand, you can handle more rides, manage a larger fleet, and reach new markets effortlessly. Enjoy the flexibility to scale up without worrying about technical limits or disruptions to your service.",
  },
  {
    id: 4,
    image: compareImg4,
    imgAlt: "Best iCabbi alternatives",
    icon: <FaCog />,
    title: "Advanced Features for Your Taxi Business",
    content:
      "ZervX provides advanced features designed to enhance your taxi service. From detailed reporting and analytics to powerful booking tools, these features help you optimize operations and improve customer satisfaction. Explore our platform’s capabilities to see how these tools can support your business’s success.",
  },
];

const FeatureImgContentJugnoo = [
  {
    id: 1,
    image: compareImg1,
    imgAlt: "Jugnoo alternatives",
    icon: <FaPalette />,
    title: "Customize and Brand Your Taxi Service",
    content:
      "ZervX gives you full control to customize your taxi service. Tailor the software to match your brand’s identity, from colors to logos, and create a unique app that reflects your business. By offering a personalized experience, you can set your taxi service apart from the competition, attract more customers, and strengthen your brand in a crowded market.",
  },
  {
    id: 2,
    image: compareImg2,
    imgAlt: "Alternative to Jugnoo",
    icon: <FaHeadset />,
    title: "Reliable Support When You Need It",
    content:
      "We offer dependable technical support to keep your taxi operations running smoothly. Whenever you face issues or need help, our support team is ready to assist. You can count on quick, effective solutions to keep your business on track and minimize downtime, ensuring a seamless experience.",
  },
  {
    id: 3,
    image: compareImg3,
    imgAlt: "Top Jugnoo alternatives",
    icon: <FaChartLine />,
    title: "Scale Your Business with Ease",
    content:
      "ZervX helps you grow your taxi business smoothly. With features that adapt as you expand, you can handle more rides, manage a larger fleet, and reach new markets effortlessly. Enjoy the flexibility to scale up without worrying about technical limits or disruptions to your service.",
  },
  {
    id: 4,
    image: compareImg4,
    imgAlt: "Best Jugnoo alternatives",
    icon: <FaCog />,
    title: "Advanced Features for Your Taxi Business",
    content:
      "ZervX provides advanced features designed to enhance your taxi service. From detailed reporting and analytics to powerful booking tools, these features help you optimize operations and improve customer satisfaction. Explore our platform’s capabilities to see how these tools can support your business’s success.",
  },
];

const FeatureImgContentYellowsoft = [
  {
    id: 1,
    image: compareImg1,
    imgAlt: "Yelowsoft alternatives",
    icon: <FaPalette />,
    title: "Customize and Brand Your Taxi Service",
    content:
      "ZervX gives you full control to customize your taxi service. Tailor the software to match your brand’s identity, from colors to logos, and create a unique app that reflects your business. By offering a personalized experience, you can set your taxi service apart from the competition, attract more customers, and strengthen your brand in a crowded market.",
  },
  {
    id: 2,
    image: compareImg2,
    imgAlt: "Alternative to Yelowsoft",
    icon: <FaHeadset />,
    title: "Reliable Support When You Need It",
    content:
      "We offer dependable technical support to keep your taxi operations running smoothly. Whenever you face issues or need help, our support team is ready to assist. You can count on quick, effective solutions to keep your business on track and minimize downtime, ensuring a seamless experience.",
  },
  {
    id: 3,
    image: compareImg3,
    imgAlt: "Top Yelowsoft alternatives",
    icon: <FaChartLine />,
    title: "Scale Your Business with Ease",
    content:
      "ZervX helps you grow your taxi business smoothly. With features that adapt as you expand, you can handle more rides, manage a larger fleet, and reach new markets effortlessly. Enjoy the flexibility to scale up without worrying about technical limits or disruptions to your service.",
  },
  {
    id: 4,
    image: compareImg4,
    imgAlt: "Best Yelowsoft alternatives",
    icon: <FaCog />,
    title: "Advanced Features for Your Taxi Business",
    content:
      "ZervX provides advanced features designed to enhance your taxi service. From detailed reporting and analytics to powerful booking tools, these features help you optimize operations and improve customer satisfaction. Explore our platform’s capabilities to see how these tools can support your business’s success.",
  },
];

const FeatureImgContentOnde = [
  {
    id: 1,
    image: compareImg1,
    imgAlt: "Onde alternatives",
    icon: <FaPalette />,
    title: "Customize and Brand Your Taxi Service",
    content:
      "ZervX gives you full control to customize your taxi service. Tailor the software to match your brand’s identity, from colors to logos, and create a unique app that reflects your business. By offering a personalized experience, you can set your taxi service apart from the competition, attract more customers, and strengthen your brand in a crowded market.",
  },
  {
    id: 2,
    image: compareImg2,
    imgAlt: "Alternative to Onde",
    icon: <FaHeadset />,
    title: "Reliable Support When You Need It",
    content:
      "We offer dependable technical support to keep your taxi operations running smoothly. Whenever you face issues or need help, our support team is ready to assist. You can count on quick, effective solutions to keep your business on track and minimize downtime, ensuring a seamless experience.",
  },
  {
    id: 3,
    image: compareImg3,
    imgAlt: "Top Onde alternatives",
    icon: <FaChartLine />,
    title: "Scale Your Business with Ease",
    content:
      "ZervX helps you grow your taxi business smoothly. With features that adapt as you expand, you can handle more rides, manage a larger fleet, and reach new markets effortlessly. Enjoy the flexibility to scale up without worrying about technical limits or disruptions to your service.",
  },
  {
    id: 4,
    image: compareImg4,
    imgAlt: "Best Onde alternatives",
    icon: <FaCog />,
    title: "Advanced Features for Your Taxi Business",
    content:
      "ZervX provides advanced features designed to enhance your taxi service. From detailed reporting and analytics to powerful booking tools, these features help you optimize operations and improve customer satisfaction. Explore our platform’s capabilities to see how these tools can support your business’s success.",
  },
];

const DigitalTaxi = [
  {
    icon: <FaSync />,
    class: "red",
    title: "Regular Updates",
    content:
      "Stay current with frequent updates that improve performance and add new features to keep your taxi business running smoothly.",
  },
  {
    icon: <FaPuzzlePiece />,
    class: "yellow",
    title: "New Features",
    content:
      "Get access to the latest tools and enhancements regularly, designed to keep your taxi business ahead of the competition.",
  },
  {
    icon: <FaPaintBrush />,
    class: "blue",
    title: "White Label",
    content:
      "Customize and brand the platform to match your business identity, offering a seamless experience for your customers.",
  },
  {
    icon: <FaChartLine />,
    class: "pink",
    title: "Increased Revenue",
    content:
      "Optimize routes, cut costs, and boost revenue with surge pricing and advanced fleet management features.",
  },
];

const faqListCompareTaxi = [
  {
    id: 1,
    faqTitle: "What is the pricing for ZervX? ",
    faqTitleContent:
      "The pricing for ZervX varies based on the size of your business and the features you need. For detailed pricing information, it's best to contact us directly or visit our pricing page.",
    icons: <FaDollarSign className="fal mb-2" />,
  },

  {
    id: 2,
    faqTitle: "Is ZervX scalable?",
    faqTitleContent:
      "Yes, ZervX is designed to grow with your business. Whether you are expanding your fleet or adding more services, ZervX can handle increased demand and adapt to your needs.",
    icons: <FaExpandArrowsAlt className="fal mb-2" />,
  },
  {
    id: 3,
    faqTitle: "Does ZervX offer multilingual support for apps?",
    faqTitleContent:
      "Yes, the ZervX platform and apps can be configured to support multiple languages, making it easier to serve customers and drivers from diverse backgrounds.",
    icons: <FaLanguage className="fal mb-2" />,
  },
  {
    id: 4,
    faqTitle: "Is there a setup fee for ZervX?",
    faqTitleContent:
      "The setup fee for ZervX depends on the size of your business and the features you need. Please contact us for specific pricing details.",
    icons: <FaMoneyCheckAlt className="fal mb-2" />,
  },
  {
    id: 5,
    faqTitle: "How does ZervX handle customer support?",
    faqTitleContent:
      "ZervX offers 24/7 customer support to help you resolve any issues quickly. Our support team is always ready to assist you with any questions or concerns.",
    icons: <FaHeadset className="fal mb-2" />,
  },

  {
    id: 6,
    faqTitle: "How does ZervX support driver safety?",
    faqTitleContent:
      "ZervX includes features like real-time tracking, emergency alerts, and driver ratings to help ensure the safety of your drivers and passengers.",
    icons: <FaShieldAlt className="fal mb-2" />,
  },
];

const faqListCompareIcabbi = [
  {
    id: 1,
    faqTitle: "What sets ZervX apart from iCabbi?",
    faqTitleContent:
      "ZervX offers a complete set of features that are not available with iCabbi. This includes more advanced tools for managing rides, tracking drivers, and handling payments. Plus, ZervX provides customizable options and better scalability for growing businesses.",
    icons: <FaBalanceScale className="fal mb-2" />,
  },

  {
    id: 2,
    faqTitle: "What is the pricing for ZervX?",
    faqTitleContent:
      "The pricing for ZervX varies based on the size of your business and the features you need. For detailed pricing information, it's best to contact us directly or visit our pricing page.",
    icons: <FaDollarSign className="fal mb-2" />,
  },
  {
    id: 3,
    faqTitle: "What kind of support does ZervX offer?",
    faqTitleContent:
      "ZervX provides reliable technical support to help you with any issues or questions. Our support team is available to assist you promptly and ensure your business runs smoothly.",
    icons: <FaHeadset className="fal mb-2" />,
  },
  {
    id: 4,
    faqTitle: "Can I customize the ZervX platform?",
    faqTitleContent:
      "Yes, ZervX can be customized to fit your business needs. You can personalize the platform with your branding and adjust features to suit your operations.",
    icons: <FaPaintBrush className="fal mb-2" />,
  },
  {
    id: 5,
    faqTitle: "How does ZervX help increase bookings?",
    faqTitleContent:
      "ZervX has a strong booking system that makes it easy for customers to book rides quickly. It also includes features like promotions and discounts to attract more riders.",
    icons: <FaChartLine className="fal mb-2" />,
  },

  {
    id: 6,
    faqTitle: "Does ZervX offer a free trial?",
    faqTitleContent:
      "Yes, ZervX offers a free trial so you can explore all the features without any commitment. You don’t need a credit card to start the trial.",
    icons: <FaPlayCircle className="fal mb-2" />,
  },
];

const faqListCompareYellowsoft = [
  {
    id: 1,
    faqTitle: "What is the pricing for ZervX? ",
    faqTitleContent:
      "The pricing for ZervX varies based on the size of your business and the features you need. For detailed pricing information, it's best to contact us directly or visit our pricing page.",
    icons: <FaDollarSign className="fal mb-2" />,
  },

  {
    id: 2,
    faqTitle: "Can I customize the ZervX platform?",
    faqTitleContent:
      "Yes, ZervX can be customized to fit your business needs. You can personalize the platform with your branding and adjust features to suit your operations.",
    icons: <FaPaintBrush className="fal mb-2" />,
  },
  {
    id: 3,
    faqTitle: "Can I track the performance of individual drivers with ZervX?",
    faqTitleContent:
      "Yes, ZervX allows you to monitor driver performance through metrics like completed trips, customer ratings, and adherence to schedules.",
    icons: <FaUserCog className="fal mb-2" />,
  },
  {
    id: 4,
    faqTitle: "Is there a setup fee for ZervX?",
    faqTitleContent:
      "The setup fee for ZervX depends on the size of your business and the features you need. Please contact us for specific pricing details.",
    icons: <FaMoneyCheckAlt className="fal mb-2" />,
  },
  {
    id: 5,
    faqTitle: "How does ZervX handle customer support?",
    faqTitleContent:
      "ZervX offers 24/7 customer support to help you resolve any issues quickly. Our support team is always ready to assist you with any questions or concerns.",
    icons: <FaHeadset className="fal mb-2" />,
  },

  {
    id: 6,
    faqTitle: "Does ZervX offer multilingual support for apps?",
    faqTitleContent:
      "Yes, the ZervX platform and apps can be configured to support multiple languages, making it easier to serve customers and drivers from diverse backgrounds.",
    icons: <FaLanguage className="fal mb-2" />,
  },
];

const faqListCompareJugnoo = [
  {
    id: 1,
    faqTitle: "What sets ZervX apart from Jugnoo?",
    faqTitleContent:
      "ZervX offers a complete set of features that are not available with Jugnoo. This includes more advanced tools for managing rides, tracking drivers, and handling payments. Plus, ZervX provides customizable options and better scalability for growing businesses.",
    icons: <FaStar className="fal mb-2" />,
  },

  {
    id: 2,
    faqTitle: "Is ZervX scalable?",
    faqTitleContent:
      "Yes, ZervX is designed to grow with your business. Whether you are expanding your fleet or adding more services, ZervX can handle increased demand and adapt to your needs.",
    icons: <FaChartLine className="fal mb-2" />,
  },
  {
    id: 3,
    faqTitle: "What is the pricing for ZervX?",
    faqTitleContent:
      "The pricing for ZervX varies based on the size of your business and the features you need. For detailed pricing information, it's best to contact us directly or visit our pricing page.",
    icons: <FaTag className="fal mb-2" />,
  },
  {
    id: 4,
    faqTitle: "What kind of support does ZervX offer?",
    faqTitleContent:
      "ZervX provides reliable technical support to help you with any issues or questions. Our support team is available to assist you promptly and ensure your business runs smoothly.",
    icons: <FaHeadset className="fal mb-2" />,
  },
  {
    id: 5,
    faqTitle: "Can I customize the ZervX platform?",
    faqTitleContent:
      "Yes, ZervX can be customized to fit your business needs. You can personalize the platform with your branding and adjust features to suit your operations.",
    icons: <FaCogs className="fal mb-2" />,
  },

  {
    id: 6,
    faqTitle: "Is ZervX user-friendly?",
    faqTitleContent:
      "Yes, ZervX is designed to be easy to use. The platform has a simple interface that makes managing your taxi business straightforward and efficient.",
    icons: <FaSmile className="fal mb-2" />,
  },
  {
    id: 7,
    faqTitle: "Does ZervX offer a free trial?",
    faqTitleContent:
      "Yes, ZervX offers a free trial so you can explore all the features without any commitment. You don’t need a credit card to start the trial.",
    icons: <FaGift className="fal mb-2" />,
  },
];

const faqListCompareOnde = [
  {
    id: 1,
    faqTitle: "How easy is it to switch to ZervX from another platform?",
    faqTitleContent:
      "Switching to ZervX is simple. Our team can help you migrate your data and set up the platform to ensure a smooth transition without disrupting your business operations.",
    icons: <FaExchangeAlt className="fal mb-2" />,
  },

  {
    id: 2,
    faqTitle: "What analytics does ZervX provide?",
    faqTitleContent:
      "ZervX offers detailed analytics and reports that give you insights into your business performance. You can track rides, revenue, driver activity, and more to make informed decisions.",
    icons: <FaChartLine className="fal mb-2" />,
  },
  {
    id: 3,
    faqTitle: "What kind of businesses can use ZervX?",
    faqTitleContent:
      "ZervX is designed for a wide range of transportation businesses, including taxi services, limo companies, ride-sharing platforms, corporate transportation services and package delivery.",
    icons: <FaBriefcase className="fal mb-2" />,
  },
  {
    id: 4,
    faqTitle: "Is there a setup fee for ZervX?",
    faqTitleContent:
      "The setup fee for ZervX depends on the size of your business and the features you need. Please contact us for specific pricing details.",
    icons: <FaTag className="fal mb-2" />,
  },
  {
    id: 5,
    faqTitle: "Can I brand the customer and driver apps?",
    faqTitleContent:
      "Yes, with the white-label option, you can brand both the customer and driver apps with your company’s name, logo, and colors.",
    icons: <FaPaintBrush className="fal mb-2" />,
  },

  {
    id: 6,
    faqTitle: "How does ZervX handle customer support?",
    faqTitleContent:
      "ZervX offers 24/7 customer support to help you resolve any issues quickly. Our support team is always ready to assist you with any questions or concerns.",
    icons: <FaHeadset className="fal mb-2" />,
  },
];

const faqBlogNineteen = [
  {
    id: 1,
    faqTitle: "What is the cost of taxi dispatch software? ",
    faqTitleContent: (
      <>
        Pricing varies widely depending on features and service providers,
        ranging from monthly subscriptions to one-time fees. To know about our
        pricing visit our{" "}
        <Link href="/plans/">
          <a> pricing page </a>
        </Link>{" "}
        or{" "}
        <Link href="/industries/contact-sales/">
          <a> contact us. </a>
        </Link>
      </>
    ),
    icons: <FaDollarSign className="fal mb-2" />,
  },
  {
    id: 2,
    faqTitle:
      "Can I integrate taxi dispatch software with my current fleet system?",
    faqTitleContent:
      "Yes, most taxi dispatch software solutions, offer integration options with existing fleet management systems, payment gateways, and customer booking platforms.",
    icons: <FaExchangeAlt className="fal mb-2" />,
  },
  {
    id: 3,
    faqTitle: "What is the best taxi dispatch software for small businesses?",
    faqTitleContent:
      "ZervX is the perfect solution. We support businesses of all sizes, from small taxi companies to large enterprises. Our SaaS model offers affordable pricing, a cloud-based system, and a user-friendly interface, making it an ideal fit for small taxi businesses. However, feel free to compare us with other solutions to see which option suits you best.",
    icons: <FaBusinessTime className="fal mb-2" />,
  },
  {
    id: 4,
    faqTitle: "Can taxi dispatch software handle multiple payment options?",
    faqTitleContent:
      "Yes, many solutions support various payment methods, including credit cards, digital wallets, and cash payments.",
    icons: <FaCreditCard className="fal mb-2" />,
  },
  {
    id: 5,
    faqTitle: "How does taxi dispatch software support scalability?",
    faqTitleContent:
      "Taxi dispatch software supports scalability by making it easy for businesses to grow without disrupting daily operations. As your fleet expands, you can effortlessly add more vehicles, drivers, and service areas. It allows you to introduce new features or services as your business evolves, keeping your operations efficient as you scale.",
    icons: <FaExpandArrowsAlt className="fal mb-2" />,
  },
];

const faqBlogTwenty = [
  {
    id: 1,
    question: "Are there any super apps in the USA?",
    solution:
      "Yes, there are a few super apps in the USA, although they may not be as popular as those in Asia. Notable example is Uber, which has expanded from just ride-hailing to include services like food delivery (Uber Eats) and package delivery.",
  },
  {
    id: 2,
    question: "How much does a super app cost?",
    solution:
      "The cost of developing a super app can vary significantly based on several factors, including the number of features included, the complexity of the design, the expertise of the development team, and the location of the developers. On average, creating a super app can range from $50,000 to several million dollars, depending on how many services are integrated and how advanced the app is.",
    icons: <FaMoneyBillWave className="fal mb-2" />,
  },
  {
    id: 3,
    question: "What is the difference between a super app and a normal app?",
    solution:
      "The main difference lies in their functionality. A super app combines multiple services into one platform, enabling users to access various functions such as chatting, shopping, and payments all in one place. In contrast, a normal app typically focuses on a single function, like a messaging app or a shopping app, requiring users to switch between different applications to complete various tasks.",
    icons: <FaMobileAlt className="fal mb-2" />,
  },
  {
    id: 4,
    question: "What does a super app do?",
    solution:
      "A super app provides a wide range of services within a single platform. It allows users to chat with friends, order food, shop online, send money, and even book rides. The goal of a super app is to make everyday tasks easier and more convenient by offering multiple functions in one place, reducing the need for users to download and manage several different apps.",
    icons: <FaLayerGroup className="fal mb-2" />,
  },
];

const faqBlogFour = [
  {
    id: 1,
    question: "What is an On-Demand Service?",
    solution:
      "An on-demand service is a platform or system that allows consumers to instantly access goods or services whenever they need them. These services typically use mobile apps or websites to connect users with providers in real-time.",
    icons: <BsPhone className="fal mb-2" />,
  },
  {
    id: 2,
    question: "How Does an On-Demand Service Work?",
    solution:
      "An on-demand service works by connecting users with providers through an app or website. Consumers can browse, choose a service, and book it in real-time. The platform uses GPS technology to track and manage the service, while secure online payment systems make the transaction easy. Service providers receive requests, fulfill them, and often earn based on the number of jobs completed.",
    icons: <BiWorld className="fal mb-2" />,
  },
  {
    id: 3,
    question: "What is an On-Demand Business?",
    solution:
      "An on-demand business operates by offering goods or services to customers instantly, as needed, through a digital platform. Examples include businesses in transportation, delivery, and personal services.",
    icons: <BsBuilding className="fal mb-2" />,
  },
  {
    id: 4,
    question: "What is an On-Demand Delivery Service?",
    solution:
      "An on-demand delivery service allows consumers to order goods, such as groceries, meals, or packages, and have them delivered quickly to their location.",
    icons: <FaTruck className="fal mb-2" />,
  },
];

const faqBlogOne = [
  {
    id: 1,
    question: "How long does it take to develop a taxi app?",
    solution:
      "On average, it takes 3-6 months to develop a basic taxi app, depending on complexity and features.",
    icons: <BsClock className="fal mb-2" />,
  },
  {
    id: 2,
    question: "Is it better to build a native or cross-platform taxi app?",
    solution:
      "Cross-platform apps (like those built with React Native) are cost-effective and faster to develop, but native apps (iOS and Android-specific) provide better performance and user experience.",
    icons: <BiDevices className="fal mb-2" />,
  },
  {
    id: 3,
    question: "How can I monetize my taxi app?",
    solution:
      "You can monetize through various models such as commission-based revenue, subscription fees, or advertisements.",
    icons: <FaMoneyBillWave className="fal mb-2" />,
  },
  {
    id: 4,
    question: "What platforms should I launch my taxi app on?",
    solution:
      "It’s recommended to launch your app on both iOS and Android to reach a wider audience, as these are the most popular mobile platforms globally.",
    icons: <FaMobileAlt className="fal mb-2" />,
  },
];

const faqBlogTwelve = [
  {
    id: 1,
    question: "Is white label taxi software cost-effective?",
    solution:
      "Yes, it is generally more cost-effective than developing a custom solution from scratch. White label solutions allow businesses to save on development time and costs while providing essential features needed for operations.",
  },
  {
    id: 2,
    question: "Can I customize a white label taxi app?",
    solution:
      "Absolutely! White label taxi apps can be tailored with your branding elements such as logos, color schemes, and specific functionalities that align with your business model.",
  },
  {
    id: 3,
    question: "How can white label software help my business?",
    solution:
      "White label taxi software helps businesses by providing a cost-effective way to launch services quickly. It automates tasks to improve operational efficiency and improves customer satisfaction, making it easier to compete in the ride-hailing market.",
  },
  {
    id: 4,
    question: "Which white label software is the best?",
    solution:
      "There are many white-label software options available, and the best one depends on your business needs. For on-demand mobility businesses like taxi services, airport transfers, private hire, and limousine services, ZervX is the ideal solution. It is tailored to meet the needs of mobility companies, providing the tools and flexibility to manage and scale operations smoothly.",
  },
];

const faqBlogFourteen = [
  {
    id: 1,
    question: "How to Start a Taxi Business in the USA?",
    solution:
      "To start a taxi business in the USA, research local laws and obtain the necessary licenses and permits. Create a business plan that includes your budget and target market. Purchase or lease a reliable vehicle and set up a way for customers to book rides, like a phone number, app or website. Promote your business locally to attract customers.",
  },
  {
    id: 2,
    question: "How to Start a Taxi Business with One Car?",
    solution:
      "Starting a taxi cab business with one car involves getting the required licenses and permits. Choose a dependable vehicle that meets safety standards. Set up a contact method for customers, like a phone number, and begin offering rides in your area. Focus on providing excellent service to build your reputation.",
  },
  {
    id: 3,
    question: "How to Start a Taxi Business Like Uber?",
    solution:
      "To start a taxi business like Uber, focus on technology. Develop a user-friendly app for ride requests or use a white-label taxi software solution. Obtain the necessary licenses and recruit drivers with valid licenses. Market your app effectively to attract customers.",
  },
  {
    id: 4,
    question: "Is the Taxi Business Profitable?",
    solution:
      "Yes, the taxi business can be profitable, especially in busy areas. Earnings come from ride fares and surge pricing during peak hours. However, be aware of expenses like vehicle maintenance and insurance. With good management and service, a taxi business can generate a steady income.",
  },
];

const blogTwentyFaqScript = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: [
    {
      "@type": "Question",
      name: "What is a Super App?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "A super app is a platform that provides more than one service like shopping to communication in a single interface of an app. Super apps bring multiple services into one app but unlike most traditional apps, super apps are focused on just one function, making them easy to use for users.",
      },
    },
    {
      "@type": "Question",
      name: "Are there any super apps in the USA?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Yes, there are a few super apps in the USA, although they may not be as popular as those in Asia. Notable example is Uber, which has expanded from just ride-hailing to include services like food delivery (Uber Eats) and package delivery.",
      },
    },
    {
      "@type": "Question",
      name: "How much does a super app cost?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The cost of developing a super app can vary significantly based on several factors, including the number of features included, the complexity of the design, the expertise of the development team, and the location of the developers. On average, creating a super app can range from $50,000 to several million dollars, depending on how many services are integrated and how advanced the app is.",
      },
    },
    {
      "@type": "Question",
      name: "What is the difference between a super app and a normal app?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The main difference lies in their functionality. A super app combines multiple services into one platform, enabling users to access various functions such as chatting, shopping, and payments all in one place. In contrast, a normal app typically focuses on a single function, like a messaging app or a shopping app, requiring users to switch between different applications to complete various tasks.",
      },
    },
    {
      "@type": "Question",
      name: "What does a super app do?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "A super app provides a wide range of services within a single platform. It allows users to chat with friends, order food, shop online, send money, and even book rides. The goal of a super app is to make everyday tasks easier and more convenient by offering multiple functions in one place, reducing the need for users to download and manage several different apps.",
      },
    },
  ],
};

const blogFourFaqScript = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: [
    {
      "@type": "Question",
      name: "What is an On-Demand Service?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "An on-demand service is a platform or system that allows consumers to instantly access goods or services whenever they need them. These services typically use mobile apps or websites to connect users with providers in real-time.",
      },
    },
    {
      "@type": "Question",
      name: "How Does an On-Demand Service Work?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "An on-demand service works by connecting users with providers through an app or website. Consumers can browse, choose a service, and book it in real-time. The platform uses GPS technology to track and manage the service, while secure online payment systems make the transaction easy. Service providers receive requests, fulfill them, and often earn based on the number of jobs completed.",
      },
    },
    {
      "@type": "Question",
      name: "What Are On-Demand Services?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "On-demand services are platforms that allow you to quickly access a product or service. Whether you need a ride, a meal, or someone to clean your house, on-demand platforms make it possible to get what you need immediately. All you have to do is use an on demand app, and the service is delivered to you in real-time.",
      },
    },
    {
      "@type": "Question",
      name: "What is an On-Demand Business?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "An on-demand business operates by offering goods or services to customers instantly, as needed, through a digital platform. Examples include businesses in transportation, delivery, and personal services. ",
      },
    },
    {
      "@type": "Question",
      name: "What is an On-Demand Delivery Service?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "An on-demand delivery service allows consumers to order goods, such as groceries, meals, or packages, and have them delivered quickly to their location. ",
      },
    },
  ],
};

const blogOneFaqScript = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: [
    {
      "@type": "Question",
      name: "What is a Taxi App?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "A taxi app is a digital solution that connects passengers with drivers through mobile platforms, providing real-time location tracking, fare calculations, and seamless payments. Leading examples include Uber, Lyft, and Bolt. ",
      },
    },
    {
      "@type": "Question",
      name: "How long does it take to develop a taxi app?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "On average, it takes 3-6 months to develop a basic taxi app, depending on complexity and features. ",
      },
    },
    {
      "@type": "Question",
      name: "Is it better to build a native or cross-platform taxi app?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Cross-platform apps (like those built with React Native) are cost-effective and faster to develop, but native apps (iOS and Android-specific) provide better performance and user experience. ",
      },
    },
    {
      "@type": "Question",
      name: "How can I monetize my taxi app?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "You can monetize through various models such as commission-based revenue, subscription fees, or advertisements.",
      },
    },
    {
      "@type": "Question",
      name: "What platforms should I launch my taxi app on?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "It’s recommended to launch your app on both iOS and Android to reach a wider audience, as these are the most popular mobile platforms globally. ",
      },
    },
  ],
};

const blogTwelveFaqScript = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: [
    {
      "@type": "Question",
      name: "What is White Label Taxi Dispatch Software?",
      acceptedAnswer: {
        "@type": "Answer",
        text: 'White label taxi dispatch software is a ready-to-use application designed to help taxi companies manage their bookings, dispatch drivers, and track vehicles. The term "white-label" means the software is generic but can be fully customized and branded with your company’s logo, colors, and any special features you want to include. Think of it like a blank canvas. It’s already built, but you get to paint it with your unique brand, making it your own.',
      },
    },
    {
      "@type": "Question",
      name: "Is white label taxi software cost-effective?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Yes, it is generally more cost-effective than developing a custom solution from scratch. White label solutions allow businesses to save on development time and costs while providing essential features needed for operations.",
      },
    },
    {
      "@type": "Question",
      name: "Can I customize a white label taxi app?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Absolutely! White label taxi apps can be tailored with your branding elements such as logos, color schemes, and specific functionalities that align with your business model.",
      },
    },
    {
      "@type": "Question",
      name: "How can white label software help my business?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "White label taxi software helps businesses by providing a cost-effective way to launch services quickly. It automates tasks to improve operational efficiency and improves customer satisfaction, making it easier to compete in the ride-hailing market.",
      },
    },
    {
      "@type": "Question",
      name: "Which white label software is the best?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "There are many white-label software options available, and the best one depends on your business needs. For on-demand mobility businesses like taxi services, airport transfers, private hire, and limousine services, ZervX is the ideal solution. It is tailored to meet the needs of mobility companies, providing the tools and flexibility to manage and scale operations smoothly.",
      },
    },
  ],
};

const blogFourteenFaqScript = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: [
    {
      "@type": "Question",
      name: "How to Start a Taxi Business in the USA?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "T o start a taxi business in the USA, research local laws and obtain the necessary licenses and permits. Create a business plan that includes your budget and target market. Purchase or lease a reliable vehicle and set up a way for customers to book rides, like a phone number, app or website. Promote your business locally to attract customers. ",
      },
    },
    {
      "@type": "Question",
      name: "How to Start a Taxi Business with One Car?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Starting a taxi cab business with one car involves getting the required licenses and permits. Choose a dependable vehicle that meets safety standards. Set up a contact method for customers, like a phone number, and begin offering rides in your area. Focus on providing excellent service to build your reputation. ",
      },
    },
    {
      "@type": "Question",
      name: "How to Start a Taxi Business Like Uber?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "T o start a taxi business like Uber, focus on technology. Develop a user-friendly app for ride requests or use a white-label taxi software solution. Obtain the necessary licenses and recruit drivers with valid licenses. Market your app effectively to attract customers. ",
      },
    },
    {
      "@type": "Question",
      name: "Is the Taxi Business Profitable?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Yes, the taxi business can be profitable, especially in busy areas. Earnings come from ride fares and surge pricing during peak hours. However, be aware of expenses like vehicle maintenance and insurance. With good management and service, a taxi business can generate a steady income. ",
      },
    },
  ],
};
export {
  homeScript1,
  homeScript2,
  workingEnvironment,
  OverviewAdvantage,
  MovingServices,
  FleetServicing,
  FieldService,
  ServicesContentFleetservicing,
  ServicesContentFieldService,
  ServicesContentMovingServices,
  ServicesContentFacilityServicing,
  IndustiesMovingServices,
  IndustiesFleetServicing,
  IndustiesFieldService,
  IndustiesFacilityServicing,
  faqListFleetServicing,
  faqListFieldServices,
  faqListFacilityServicing,
  faqListMovingServices,
  Service,
  ServicesContent,
  faqList,
  ServiceRaidHailing,
  ServicesContentRideHailing,
  ServicesContentRideSharing,
  faqListRideHailing,
  faqListRideSharing,
  ServicesContentCorporateTransportation,
  ServicesContentAirport_VIPShuttle,
  faqListAirport_VIPShuttle,
  ServicePackageDelivery,
  ServicesContentPackageDelivery,
  faqListPackageDelivery,
  ServicesContentDeliveryOperators,
  faqListDeliveryOperators,
  ServicesContentThirdPartyLogistics,
  faqListThirdPartyLogistics,
  ServicesContentFoodDelivery,
  ServicesContentGroceryDelivery,
  ServicesContentPharmacyDelivery,
  ServicesContentMaterialsDelivery,
  faqListFoodDelivery,
  faqListGroceryDelivery,
  faqListPharmacyDelivery,
  faqListMaterialsDelivery,
  CorporateTransportationServicesList,
  ServicesChauffeurList,
  faqListCorporateTransportation,
  IndustiesRidehailing,
  IndustiesRideSharing,
  IndustiesCorporateTransportation,
  IndustiesAirport_VIP_Shuttle,
  IndustiesFoodDelivery,
  IndustiesGroceryDelivery,
  IndustiesPharmacyDelivery,
  IndustiesMaterialsDelivery,
  IndustiesCityCouriers,
  IndustiesPackageDelivery,
  IndustiesDeliveryOperators,
  IndustiesThirdPartyLogistics,
  ToolsList,
  BusinessPartners,
  BusinessAbout,
  CraftingSuccess,
  MobilityBusiness,
  ResourceHelpCenter,
  MobilindustrySpecificSolutionstyBusiness,
  zervXServices,
  RideHailling,
  Commerce,
  Logistics,
  Services,
  Industies,
  Resources,
  ItServiceData,
  TestimonialData,
  navHomeOne,
  footerIndusties1,
  footerIndusties2,
  footerIndusties3,
  footerIndusties4,
  footerResources,
  footerPrimaryPages,
  footerTemplate,
  pricingData,
  integrationFeature,
  ourTeam,
  blogFeatureData,
  blogFeatureDataTwo,
  blogFeatureDataThree,
  careerJobCard,
  helpCenterFaq,
  helpCenterFaqDetails,
  FeatureImgCommonList,
  FeatureImgCommonListToolsPage,
  HelpCenter,
  FeatureImgCommonList2,
  HelpCenter2,
  FeatureImgCommonList3,
  HelpCenter3,
  FeatureImgCommonList4,
  HelpCenter4,
  OfficeLocated,
  BlogSingleFeatureData,
  BlogSingleFeatureDataTwo,
  BlogSingleFeatureDataThree,
  BlogSingleFeatureDataFour,
  BlogSingleFeatureDataFive,
  BlogSingleFeatureDataSix,
  BlogSingleFeatureDataSixSecond,
  BlogSingleFeatureDataSixThird,
  BlogSingleFeatureDataListSix,
  BlogSingleFeatureDataListSeven,
  BlogSingleFeatureDataEight,
  BlogSingleFeatureDataEightSecond,
  BlogSingleFeatureDataEightTable,
  nonTitleBlog,
  nonTitleBlogTwo,
  nonTitleBlogThree,
  nonTitleBlogFive,
  nonTitleBlogSix,
  nonTitleBlogSixSecond,
  blogScriptOne,
  blogScriptTwo,
  blogScriptThree,
  blogScriptFour,
  blogScriptFive,
  blogScriptSix,
  blogScriptSeven,
  blogScriptEight,
  nonTitleBlogSeven,
  nonTitleBlogEight,
  blogScriptNine,
  nonTitleBlogNine,
  BlogSingleFeatureDataNine,
  BlogSingleFeatureDataNineSecond,
  BlogSingleFeatureDataNineThird,
  blogScriptTen,
  BlogSingleFeatureDataTen,
  nonTitleBlogTen,
  blogScriptEleven,
  nonTitleBlogEleven,
  BlogSingleFeatureDataEleven,
  BlogSingleFeatureDataElevenTable,
  BlogSingleFeatureDataElevenSecond,
  blogScriptTwelve,
  nonTitleBlogTwelve,
  BlogSingleFeatureDataTwelveFirst,
  blogScriptThirteen,
  nonTitleBlogThirteen,
  BlogSingleFeatureDataThirteenTable,
  BlogSingleFeatureDataThirteenOneline,
  BlogSingleFeatureDataThirteenOnelineSecond,
  BlogSingleFeatureDataThirteen,
  blogScriptFourteen,
  nonTitleBlogFourteen,
  BlogSingleFeatureDataFourteen,
  blogScriptFifteen,
  nonTitleBlogFifteen,
  BlogSingleFeatureDataFifteen,
  BlogSingleFeatureDataFifteenSecond,
  BlogSingleFeatureDataFifteenTable,
  BlogSingleFeatureDataFifteenThird,
  blogScriptSixteen,
  nonTitleBlogSixteen,
  BlogSingleFeatureDataSixteen,
  BlogSingleFeatureDataSixteenSecond,
  nonTitleBlogSeventeen,
  BlogSingleFeatureDataSeventeen,
  BlogSingleFeatureDataSeventeenSecond,
  blogScriptSeventeen,
  blogScriptEighteen,
  nonTitleBlogEighteen,
  BlogSingleFeatureDataEighteen,
  BlogSingleFeatureDataEighteenTable,
  BlogSingleFeatureDataEighteenSecond,
  BlogSingleFeatureDataEighteenTableSecond,
  BlogSingleFeatureDataEighteenThird,
  BlogSingleFeatureDataEighteenFourth,
  solutionLinks,
  processStepsTaxi,
  solutionCardTaxi1,
  solutionCardTaxi2,
  TaxiDispatchTabOne,
  TaxiDispatchSolutionFeatures,
  TaxiSolutionWorkProcessLastData,
  TaxiSolutionsFaqData,
  footerSolutions,
  processStepsLimo,
  solutionCardLimo1,
  solutionCardLimo2,
  LimoTabOne,
  LimoSolutionsApp,
  LimoSolutionFeatures,
  LimoWorkProcessLastData,
  LimoFaqData,
  processStepsRideShare,
  solutionCardRideShare1,
  solutionCardRideShare2,
  RideShareTabOne,
  RideShareSolutionFeatures,
  RideShareWorkProcessLastData,
  RideShareFaqData,
  processStepsAirport,
  solutionCardAirport1,
  solutionCardAirport2,
  AirportTabOne,
  AirportSolutionFeatures,
  AirportWorkProcessLastData,
  AirportFaqData,
  AirportSolutionsApp,
  RideShareSolutionsApp,
  processStepsEmployee,
  solutionCardEmployee1,
  solutionCardEmployee2,
  EmployeeTabOne,
  EmployeeSolutionsApp,
  EmployeeSolutionFeatures,
  EmployeeFaqData,
  featuresForHome,
  ServicesContentRideHailingForHome,
  processStepsFleet,
  solutionCardFleet1,
  solutionCardFleet2,
  FleetTabOne,
  FleetSolutionsApp,
  FleetSolutionFeatures,
  FleetWorkProcessLastData,
  FleetFaqData,
  CyberServiceUberClone,
  FeatureImgContentTwoUberClone,
  featuresForUberClone,
  comparisonTableUberClone,
  SingleServiceFeatureUberClone,
  FeatureBgUberClone,
  UberCloneFaqData,
  BoltCloneFaqData,
  FeatureImgContentTwoUberBolt,
  FeatureImgContentTwoUberLyft,
  LyftCloneFaqData,
  CompareMainData,
  contentZervx,
  comparisonZervxvsTaxi,
  FeatureImgContentTaxi,
  DigitalTaxi,
  faqListCompareTaxi,
  comparisonZervxvsIcabbi,
  comparisonZervxvsJugnoo,
  comparisonZervxvsOnde,
  comparisonZervxvsYellowsoft,
  FeatureImgContentIcabbi,
  FeatureImgContentJugnoo,
  FeatureImgContentOnde,
  FeatureImgContentYellowsoft,
  faqListCompareIcabbi,
  faqListCompareJugnoo,
  faqListCompareOnde,
  faqListCompareYellowsoft,
  blogScriptNineteen,
  nonTitleBlogNineTeen,
  BlogSingleFeatureDataNineteenFirst,
  BlogSingleFeatureDataNineteenOneline,
  BlogSingleFeatureDataNineteenSecond,
  BlogSingleFeatureDataNineteenThird,
  BlogSingleFeatureDataNineteenFourth,
  faqBlogNineteen,
  rideHailingFaqScript,
  blogScriptTwenty,
  blogTwentyFaqScript,
  BlogSingleFeatureDataTwentyFirst,
  tableOfContentsBlogTwenty,
  faqBlogTwenty,
  blogFourFaqScript,
  tableOfContentsBlogFour,
  faqBlogFour,
  blogOneFaqScript,
  tableOfContentsBlogOne,
  faqBlogOne,
  blogTwelveFaqScript,
  tableOfContentsBlogTwelve,
  tableOfContentsBlogFourteen,
  faqBlogTwelve,
  faqBlogFourteen,
  blogFourteenFaqScript,
};
