import React from "react";
import Link from "next/link";

function BlogBottomBox({
  content,
  buttonContent,
  link,
  innerPage,
  compare,
  content1,
  tableOfContents,
}) {
  return (
    <>
      {!compare ? (
        <section
          className={`container  ${
            innerPage ? "ptb-60 col-lg-10 col-md-10" : "ptb-0"
          }`}
        >
          <section className="bg-dark shadow-lg p-5 rounded-custom  mt-lg-4 mt-3">
            <section className="row align-items-center justify-content-between">
              <section className="col-lg-8">
                <section className="cta-content">
                  <h4 className="mb-2">{content}</h4>
                  {/* <p className="mb-lg-0 mb-xl-0">
              {content}
            </p> */}
                </section>
              </section>
              <section className="col-lg-3">
                <section className="action-btns text-lg-end">
                  <Link href={link} passHref>
                    <a className="btn btn-green text-white">{buttonContent}</a>
                  </Link>
                </section>
              </section>
            </section>
          </section>
        </section>
      )  : (
        <section
          className={`container ${
            innerPage ? "ptb-60 col-lg-10 col-md-10" : "ptb-0"
          }`}
        >
          <section className="bg-dark shadow-lg p-5 rounded-custom  mt-lg-4 mt-3">
            <section className="align-items-center justify-content-center text-center">
              <section className="">
                <section className="cta-content">
                  <h2 className="mb-2">{content}</h2>
                  <p className="mb-lg-0 mb-xl-0">{content1}</p>
                </section>
              </section>
              <section className="">
                <section className="action-btns text-center pt-3">
                  <Link href={link} passHref>
                    <a className="btn btn-green text-white">{buttonContent}</a>
                  </Link>
                </section>
              </section>
            </section>
          </section>
        </section>
      )}
    </>
  );
}

export default BlogBottomBox;
